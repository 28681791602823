import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from '../../_dialogs/confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from '../../DTOS/AdminViewAsClinicDTO';
import {
  AccountDetails,
  AccountOverview,
  Usage
} from '../../DTOS/Company/AccountOverview';
import {
  LoadingDialogComponent,
  LoadingDialogModel
} from '../../loading-dialog/loading-dialog.component';
@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
/** account-details component*/
export class AccountDetailsComponent implements AfterViewInit {
  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }
  UsagechartOptions = {
    responsive: true
  };

  UsagechartData = [{ data: [0, 0], label: 'Minutes' }];

  UsagechartLabels = ['Consumidos', 'Disponibles'];

  CallSmsChartData = [{ data: [0, 0], label: 'Minutes' }];
  CallSmsChartLabels = ['Llamadas', 'Mensajes de Texto'];
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  public AccountOverviewData: AccountOverview;
  public usage: Usage;
  public PastUsages: Usage[];
  public ViewAs: AdminViewAsClinicDTO;

  public barChartOptions: ChartOptions = {
    responsive: true
  };
  public barChartLabels: string[] = ['0'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: any[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' }
  ];
  public User: User;
  httpClient: HttpClient;
  baseUrl: string;

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.LoadOverview();
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadOverview();
  }
  public LoadOverview() {
    const userId = this.ViewAs ? this.ViewAs.viewAsUserID : this.User.userId;
    this.ShowLoading();
    this.httpClient
      .get<AccountDetails>(
        this.baseUrl + 'Accounts/GetOverview?userid=' + userId
      )
      .subscribe(
        (result) => {
          const res = result as AccountDetails;
          this.AccountOverviewData = res.accountOverview;
          this.usage = res.usage;
          this.PastUsages = res.lastUsages.map((e) => {
            e._periodFormatted = moment(e.period)
              .locale('es')
              .format('MMMM/YYYY')
              .toUpperCase();
            return e;
          });
          if (!this.usage) {
            this.usage = {
              minutesInPlan: '-',
              minutesUsed: 0,
              overage: 0,
              period: new Date(),
              totalCalls: 0,
              totalSms: 0,
              _periodFormatted: '-',
              totalAmount: 0
            };
          }
          this.AccountOverviewData.company._contractStartDateFormatted = moment(
            this.AccountOverviewData.company.contractStartDate
          )
            .locale('es')
            .format('DD MMMM YYYY');
          try {
            this.usage._periodFormatted = moment(this.usage.period)
              .locale('es')
              .format('MMMM YYYY');
          } catch (e) {}
          try {
            this.AccountOverviewData.company.emailAddress =
              this.AccountOverviewData.company.emailAddress.split(';')[0];
          } catch (e) {}
          if (this.usage.overage) {
            this.UsagechartData = [
              { data: [this.usage.minutesUsed, 0], label: 'Minutes' }
            ];
          } else {
            this.UsagechartData = [
              {
                data: [
                  this.usage.minutesUsed,
                  parseInt(this.usage.minutesInPlan) - this.usage.minutesUsed
                ],
                label: 'Minutes'
              }
            ];
          }
          this.barChartLabels = res.lastUsages.map((e) => e._periodFormatted);
          this.barChartLabels.push(
            this.usage._periodFormatted.toUpperCase() + '(Actual)'
          );
          const minutesUsedData = res.lastUsages.map((e) => e.minutesUsed);

          minutesUsedData.push(this.usage.minutesUsed);
          this.barChartData = [
            {
              data: minutesUsedData,
              label: 'Consumo Mensual',
              backgroundColor: new Array(res.lastUsages.length).fill('#a5dc86'),
              hoverBackgroundColor: '#f1e472',
              hoverBorderColor: '#c4b007'
            }
          ];
          this.barChartOptions = {
            responsive: true
          };
          this.CallSmsChartData = [
            {
              data: [this.usage.totalCalls, this.usage.totalSms],
              label: 'Total'
            }
          ];
          this.hideLoading();
        },
        (error) => {
          console.error(error);
          this.hideLoading();
          this.showError(error);
        }
      );
  }
  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false)
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }
  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }
  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null)
    });
  }
}
