import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { result } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { LocationDTO } from 'src/app/DTOS/Locations/Location';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-locations-details',
  templateUrl: './locations-details.component.html',
  styleUrls: ['./locations-details.component.scss']
})
export class LocationsDetailsComponent implements OnInit {
  @ViewChild('locateForm', { static: false })
  public locateForm: NgForm;
  public locationDetail: LocationDTO = {} as LocationDTO;
  public backup_locationDetail: LocationDTO = {} as LocationDTO;
  public User: User;
  public isLoading = false;
  public isEditing = true;
  public clinicId = 0;

  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
      this.route.params.subscribe((params) => {
        const locationId = params.locationId;
        this.clinicId = Number(params.clinicId);
        if (locationId) {
          this.GetlocationIdtDetail(Number(locationId));
        }
      });
      this.isLoading = false;
    });
  }

  GetlocationIdtDetail(locationId: number) {
    this.isLoading = true;
    this.API.GetLocationDetails(locationId, this.clinicId).subscribe(
      (result) => {
        this.locationDetail = result;
        this.isLoading = false;
      }
    );
  }

  public save = () => {
    this.isEditing = false;
    this.isLoading = true;
    if (this.locationDetail?.locationId) {
      this.doUpdate();
    } else {
      this.doCreate();
    }
  };

  doUpdate() {
    if (this.User?.isAdmin === false) {
      return;
    }
    this.isLoading = true;
    this.locationDetail.voiceAlias = this.locationDetail.locationAlias;
    this.API.UpdateLocation(this.locationDetail).subscribe((result) => {
      this.locationDetail = result;
      this.isLoading = false;
      this.showSuccessMessage('¡Listo!, Mensaje actualizado correctamente.');
    });
  }

  doCreate() {
    if (this.User?.isAdmin === false) {
      return;
    }
    this.isLoading = true;
    this.locationDetail.clinicId = this.clinicId;
    this.API.AddLocation(this.locationDetail).subscribe((result) => {
      this.locationDetail = result;
      this.locationDetail.voiceAlias = this.locationDetail.locationAlias;
      this.isLoading = false;
      this.showSuccessMessage('¡Listo!, Mensaje creado correctamente.');
    });
  }
  Delete(event: Event, locationId: number) {
    if (this.User?.isAdmin === false) {
      return;
    }
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer eliminar este registro?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, eliminar',
      rejectLabel: 'Cancelar',
      accept: () =>
        this.API.DeleteLocation(locationId).subscribe((r) => {
          this.GetlocationIdtDetail(this.locationDetail.locationId);
        }),
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  showSuccessMessage(text: string) {
    this._snackBar.open(`${text} `, 'OK ', {
      duration: 70000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      panelClass: 'success-dialog'
    });
  }

  public discardEditionChanges = () => {
    this.isEditing = false;
    if (this.locationDetail?.locationId) {
      this.GetlocationIdtDetail(this.locationDetail.locationId);
    } else {
      this.locationDetail = structuredClone(this.backup_locationDetail);
      this.backup_locationDetail = {} as LocationDTO;
    }
  };
  public enableEdition = () => {
    this.isEditing = true;
    this.backup_locationDetail = structuredClone(this.locationDetail);
  };
}
