import { IntakeFormDTO } from './IntakeForm.DTO';

export interface CustomIntent {
  customIntentId: number;
  accountID: number;
  prompt: string;
  promptVariant?: string;
  response: string;
  deleteDate?: Date;
  customIntentName: string;
  endsTheSession?: boolean;
  isSystemIntent?: boolean;
  primaryColor: string;
  secondaryColor: string;
  botAction?: BotActionDto[];
}

export class BotActionDto {
  intakeFormId?: number;
  target: string;
  data: string;
  type: number;
  sortOrder: number;
  intentType?: number;
  customIntentId?: number;
  botActionId?: number;
  intakeForm?: IntakeFormDTO;

  constructor(
    IntakeFormId: number | null,
    Target: string | '',
    Data: string | '',
    Type: number | null,
    SortOrder: number | null,
    IntentType: number | null,
    CustomIntentId: number | null,
    BotActionId: number | null,
    intakeForm?: IntakeFormDTO
  ) {
    this.intakeFormId = IntakeFormId;
    this.target = Target;
    this.data = Data;
    this.type = Type;
    this.sortOrder = SortOrder;
    this.intentType = IntentType;
    this.customIntentId = CustomIntentId;
    this.botActionId = BotActionId;
    this.intakeForm = intakeForm;
  }
}

export enum IntentType {
  APPOINTMENT_REQUEST = 1,
  SERVICES_INFO = 2,
  PHYSICAL_ADDRES = 3,
  SCHEDULES = 4,
  RECORD_VOICE_MAIL = 6,
  TERMINATE = 5
}
export enum ActionType {
  SinAccion = 0,
  EnviarSMS = 1,
  EnviarEmail = 2,
  CompletarFormulario = 3,
  RecopilarArbolMenu = 4,
  EnviarSmsATercero = 5,
  Decir = 6
}
