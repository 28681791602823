import { HttpClient } from '@angular/common/http';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Dictionary, groupBy, orderBy, minBy, maxBy } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { SmsLogs } from 'src/app/DTOS/Chat/SmsLogs';
import {
  CustomerNoteEntityType,
  GetConversationsChangeLogItem,
  GetCustomerNoteDTO
} from 'src/app/DTOS/CustomerNotes/CustomerNote';
import { QuickReply } from 'src/app/DTOS/QuickReplies/quickReply';
import { removeFalsyValues } from 'src/app/Helpers/helper-functions';
import { ApiService } from 'src/app/api.service';
import {
  bounceOutDownOnLeaveAnimation,
  fadeInUpOnEnterAnimation
} from 'angular-animations';
import { LONG_DATE_FORMAT } from 'src/app/Constants/constants';
import { MatDialog } from '@angular/material/dialog';

export interface IAppChatLinkedAppointment {
  appointmentId: number;
  patientPhoneNumber: string;
  conversationId?: number;
  patientName?: string;
  patientRecord?: number;
  clinicId: number;
}
type ItemClass = 'sms' | 'note' | 'change';
type ItemsType = SmsLogs | GetCustomerNoteDTO | GetConversationsChangeLogItem;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [
    fadeInUpOnEnterAnimation({
      anchor: 'enter',
      duration: 1000,
      delay: 70,
      translate: '30px'
    }),
    bounceOutDownOnLeaveAnimation({
      anchor: 'leave',
      duration: 500,
      delay: 200,
      translate: '40px'
    })
  ]
})
export class ChatComponent
  implements OnChanges, OnInit, AfterViewChecked, OnDestroy
{
  @Input() appointment: IAppChatLinkedAppointment;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @Input() isFullWidth: boolean;

  httpClient: HttpClient;
  baseUrl: string;
  isLoading: boolean;
  userScrolled: boolean;
  public sendCustomerNote: boolean = false;
  public EntityTypes = CustomerNoteEntityType;
  public quickReplies: QuickReply[] = [];
  public sendFileDialogOpened: boolean = false;

  public messagesObs: BehaviorSubject<ItemsType[]> = new BehaviorSubject<
    ItemsType[]
  >([]);

  newMessage: string;
  timeout: any;

  public showQuickReplies: boolean = false;

  public onInputKeydown(value: KeyboardEvent) {
    if (value.key === '/') {
      this.showQuickReplies = true;
    } else {
      this.showQuickReplies = false;
    }
  }
  public onInputChange(val: string) {
    if (!val?.length) {
      this.showQuickReplies = false;
    }
  }
  constructor(
    @Inject('BASE_URL') baseUrl: string,
    http: HttpClient,
    private API: ApiService,
    private dialog: MatDialog
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
    moment.locale('es');
  }

  ngOnDestroy(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  public grouppedMessages: Dictionary<ItemsType[]> = {};
  public grouppedKeys: string[] = [];
  public fileUploadExpireDate: Date = moment()
    .startOf('day')
    .add(3, 'months')
    .toDate();
  public maxUploadExpireDate: Date;
  public minUploadExpireDate: Date;

  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    const _3MonthsFromNow = moment().startOf('day').add(3, 'months').toDate();
    this.minUploadExpireDate = moment().startOf('day').add(1, 'day').toDate();
    this.maxUploadExpireDate = moment().add(4, 'months').toDate();
    this.fileUploadExpireDate = _3MonthsFromNow;
    this.sendFileDialogOpened = true;
    this.dialog.open(templateRef);
  }
  ngOnInit() {
    this.scrollToBottom();

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.loadAppointmentData();
      }
    });

    this.messagesObs.subscribe((val) => {
      const grouped = groupBy(val, (c) =>
        moment.utc(c.createDate).local().format('DD MMMM YYYY')
      );
      this.grouppedKeys = Object.keys(grouped);
      this.grouppedMessages = grouped;
    });

    const _3MonthsFromNow = moment().startOf('day').add(3, 'months').toDate();
    this.minUploadExpireDate = moment().startOf('day').add(1, 'day').toDate();
    this.maxUploadExpireDate = moment().add(4, 'months').toDate();
    this.fileUploadExpireDate = _3MonthsFromNow;
  }

  ngAfterViewChecked() {
    if (!this.userScrolled) this.scrollToBottom();
  }

  public scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  public getJson(obj: any) {
    return JSON.stringify(obj);
  }

  public onCustomerNoteCreated(note: GetCustomerNoteDTO) {
    const currentLogs = this.messagesObs.value;
    this.messagesObs.next([
      ...currentLogs,
      { ...note, _timeSince: moment.utc(note.createDate).local().fromNow() }
    ]);
  }

  public loadAppointmentData() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.loadAppointmentData();
      // every 10 seconds 10000
    }, 1000 * 60);

    if (document.visibilityState !== 'visible') {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      return;
    }

    const queryparams = removeFalsyValues({
      appointmentId: this.appointment.appointmentId,
      conversationId: this.appointment.conversationId
    });

    const params = new URLSearchParams(queryparams as any).toString();

    this.httpClient
      .get(this.baseUrl + `SMS/GetAppointmentMessages?${params}`)
      .subscribe(async (r: SmsLogs[]) => {
        const messages = r;

        const currentLogs = this.messagesObs.value;

        const currIds = currentLogs
          .filter((c: SmsLogs) => c.smsLogId)
          .map((c: SmsLogs) => c.smsLogId);

        const newIds = messages.map((c) => c.smsLogId);

        const areThereNewMessages = newIds.some(
          (c) => currIds.includes(c) === false
        );

        const allDates = messages.map((c) => moment(c.createDate).toDate());
        const from = minBy(allDates, (d) => d);
        const to = maxBy(allDates, (d) => d);

        const entityId = this.appointment.conversationId
          ? this.appointment.conversationId
          : this.appointment.appointmentId;

        const entityType = this.appointment.conversationId
          ? this.EntityTypes.ChatCustomerNote
          : this.EntityTypes.Chat_Appointment;

        const todaysDate = moment().startOf('date').toDate();

        const notes = await firstValueFrom(
          this.API.GetCustomerNotes(
            1,
            10000,
            entityId,
            entityType,
            from,
            todaysDate
          )
        );

        const changeLogs = this.appointment.conversationId
          ? await firstValueFrom(
              this.API.GetConversationsChangeLogs(
                1,
                10000,
                this.appointment.conversationId,
                from,
                todaysDate
              )
            )
          : { items: [] };

        const allItems: ItemsType[] = orderBy(
          [
            ...messages,
            ...notes.items,
            ...changeLogs.items.map((item) => ({
              ...item,
              _formattedDate: moment
                .utc(item.createDate)
                .local()
                .format(LONG_DATE_FORMAT)
            }))
          ],
          (e) => e.createDate
        ).map((msg) => ({
          ...msg,
          _timeSince: moment.utc(msg.createDate).local().fromNow()
        }));

        if (areThereNewMessages) this.messagesObs.next(allItems);
      });
  }

  ngOnChanges() {
    if (this.appointment?.appointmentId || this.appointment.conversationId) {
      this.loadAppointmentData();
      this.userScrolled = false;
      this.API.GetAllQuickReplies(this.appointment.clinicId).subscribe((r) => {
        if (r?.length) {
          this.quickReplies = r.map((qr) => ({
            ...qr,
            shortcut: '/' + qr.shortcut
          }));
        } else {
          this.quickReplies = [];
        }
      });
    }
  }

  public shouldRenderAuthor = (
    mesage: SmsLogs,
    allLogs: SmsLogs[],
    index: number
  ) => {
    if (!(mesage.direction?.trim() === 'O' && mesage?.author?.username)) {
      return false;
    }
    if (!allLogs[index + 1]) {
      return true;
    }

    return allLogs[index + 1].authorUserId !== mesage.authorUserId;
  };

  public cantSendMessage = () =>
    !this.newMessage || this.newMessage.length > 160;

  public sendMessage(messageContent: string) {
    this.isLoading = true;
    const withConversation = Boolean(this.appointment.conversationId);

    let _route: string = '';
    let _payload: object;

    if (withConversation) {
      _route = `${this.baseUrl}api/Conversations/send-conversation-message`;
      _payload = {
        MessageContent: messageContent,
        conversationId: this.appointment.conversationId
      };
    } else {
      _route = `${this.baseUrl}SMS/sendmessage`;
      _payload = {
        AppointmentId: this.appointment.appointmentId,
        ToNumber: this.appointment.patientPhoneNumber,
        MessageContent: messageContent
      };
    }

    this.newMessage = '';

    this.httpClient.post(_route, _payload).subscribe(
      (newRecord: SmsLogs) => {
        const newMessages = this.messagesObs.value;

        newMessages.push(newRecord);
        this.messagesObs.next(newMessages);

        const contentContainer = document.getElementById(
          'chat-content-container'
        );
        if (contentContainer) {
          contentContainer.scrollTo({
            top: contentContainer.scrollHeight,
            behavior: 'smooth'
          });
        }
      },
      () => {}
    );
  }

  public onFileSent() {
    this.sendFileDialogOpened = false;
    const _3MonthsFromNow = moment().startOf('day').add(3, 'months').toDate();
    this.minUploadExpireDate = moment().startOf('day').add(1, 'day').toDate();
    this.maxUploadExpireDate = moment().add(4, 'months').toDate();
    this.fileUploadExpireDate = _3MonthsFromNow;
    this.loadAppointmentData();
    this.scrollToBottom();
    this.dialog.closeAll();
  }

  public getAttachmentDownloadUrl(attachmentId: number) {
    return `/Files/download-file?fileId=${attachmentId}&clinicId=${this.appointment.clinicId}`;
  }
  public getFileUploadUrl() {
    return `/Files/send-file-via-sms?ClinicId=${
      this.appointment.clinicId
    }&ConversationId=${this.appointment.conversationId}&ExpireDate=${moment(
      this.fileUploadExpireDate
    )
      .utc()
      .format('YYYY-MM-DD')}`;
  }
}
