import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Chart } from 'chart.js';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-va-stats',
  templateUrl: './va-stats.component.html',
  styleUrl: './va-stats.component.scss'
})
export class VaStatsComponent implements OnInit, OnChanges {
  constructor(private API: ApiService) {}
  ngOnInit(): void {
    this.loadStats();
  }
  ngOnChanges(): void {
    this.loadStats();
  }
  @Input() public clinicId?: number;
  from: Date = moment().startOf('month').toDate();
  to: Date = new Date();
  lastMonthTotalCalls: number;
  totalCalls: number;
  hasLoaded: boolean = false;

  private chart: Chart;

  public async loadStats() {
    this.hasLoaded = false;
    const lastMonthFirstDate = moment()
      .subtract(1, 'months')
      .startOf('month')
      .startOf('day')
      .toDate();

    const { stats: values, monthTotalCount } = await firstValueFrom(
      this.API.getVaCallStats(
        this.clinicId,
        this.from,
        this.to,
        lastMonthFirstDate
      )
    );

    this.lastMonthTotalCalls = monthTotalCount;

    this.totalCalls = values.reduce((acumulator, a) => acumulator + a.count, 0);

    this.chart && this.chart.destroy();

    this.chart = new Chart('calls-per-date-chart', {
      type: 'line',
      data: {
        labels: values.map((v) => moment(v.date).format('Do MMMM')),

        datasets: [
          {
            label: '',
            data: values.map((c) => c.count),
            borderColor: '#3BB9BB',
            fill: false,

            tension: 0.1,
            pointBackgroundColor: '#237775'
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });

    this.hasLoaded = true;
  }
}
