import {
  AddAppointmentDialogData,
  AddAppointmentsDialogComponent
} from '../_dialogs/add-appointments-dialog/add-appointments-dialog.component';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { ApiService } from '../api.service';
import { AuthenticationService } from '../_services';
import { ImportAppointmentsDialogComponent } from '../_dialogs/import-appointments-dialog/import-appointments-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import PermissionSlugs from '../Constants/PermissionSlugs';
import { PermissionSlugsService } from '../Services/PermissionSlugsService';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { TawkToService } from '../_services/TawkToService';
import { User } from '../_models';
import { applicationRoutes } from '../app.routes';
import '@fullcalendar/web-component/global';

export const RUN_TUTORIAL_SESSION_KEY = 'RunTutorialOnLoad';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app-component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private tawkToService: TawkToService,
    private swUpdate: SwUpdate,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private permissionSlugsService: PermissionSlugsService,
    private API: ApiService
  ) {
    if (window.sessionStorage['hideSpecialBanner']) {
      this.showBanner = false;
    }
    this.screenWidth = window.innerWidth;

    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth;
    });
  }
  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(async (user) => {
      if (user?.userId) {
        await this.permissionSlugsService.fetchInitialData();
      }
    });
  }
  public currentUser: User;

  public isLoggedIn = () => {
    if (!this.currentUser?.clinicId) {
      return false;
    }
    if (this.currentUser.mustResetPassword) {
      return false;
    }
    if (this.currentUser.clinicId) {
      return true;
    }
    return false;
  };
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  public showDWL = false;
  public hideAppointmentManagement = false;
  public showVirtualAssistant = false;
  public showCampaigns = false;
  public showTransitionalCare = false;
  public patientManagement = false;
  public isPrinter = false;
  public showBanner = true;
  public hasBookingPermission = false;
  public screenWidth: number;
  isExpanded = false;
  public menuExpanded: boolean = false;
  public isV2ToggleBannerVisible = true;
  public isSwitchingThemes: boolean = false;

  openLink(link) {
    window.open(link, '_blank');
  }

  public shouldOutletHaveMargin = () => {
    return !window.location.pathname.includes(applicationRoutes.chatPage);
  };
  ngAfterViewInit() {
    this.authenticationService.currentUser.subscribe((user) => {
      const widget = (window as any)?.fcWidget;
      if (widget && widget.setExternalId) {
        widget.setExternalId(user.userId);
        if (!widget.user) return;
        widget.user.setFirstName(user.username);
        widget.user.setEmail(user.emailAddress);
        widget.user.setLastName(user.clinicId + ' ' + user.clinicName);
        widget.user.setProperties({
          clinicName: `${user.clinicId} ${user.clinicName}`
        });
      }
    });

    this.authenticationService.currentUser.subscribe((currentUserValue) => {
      if (!currentUserValue?.clinicId) {
        this.currentUser = null;
        return;
      }
      this.currentUser = currentUserValue;

      this.tawkToService.StartChat(this.currentUser);
      if (this.currentUser.isAdmin) {
        this.isPrinter = this.currentUser.isAdmin;
      } else {
        this.API.IsUserPrinter(this.currentUser.clinicId).subscribe(
          () => {
            this.isPrinter = true;
          },
          (error) => {
            console.error(error);
          }
        );
      }
    });

    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.showDWL = r.includes(PermissionSlugs.DIGITAL_WAITING_LIST);
      this.hideAppointmentManagement = r.includes(
        PermissionSlugs.BLOCK_APPOINTMENT_MANAGEMENT
      );
      this.showVirtualAssistant = r.includes(PermissionSlugs.VIRTUAL_ASSISTANT);
      this.showCampaigns = r.includes(PermissionSlugs.CAMPAIGNS);
      this.showTransitionalCare = r.includes(PermissionSlugs.TRANSITIONAL_CARE);
      this.patientManagement = r.includes(PermissionSlugs.PATIENT_MANAGEMENT);
      this.hasBookingPermission = r.includes(PermissionSlugs.BOOKINGS);
    });

    // Transitional Care Management Users
    if (
      this.hideAppointmentManagement === true &&
      this.showTransitionalCare === true
    ) {
      this.router.navigate(['/Transitional-Care-admisions-and-discharges']);
    } else if (
      this.hideAppointmentManagement === true &&
      this.showCampaigns === true
    ) {
      this.router.navigate(['/campañas/crear']);
    } else if (
      this.hideAppointmentManagement === true &&
      this.showVirtualAssistant === true &&
      this.showTransitionalCare === false
    ) {
      this.router.navigate(['/llamadasPerdidas']);
    }
  }
  navigateTo(route: string) {
    this.router.navigate([route]);
  }
  public hidebanner() {
    window.sessionStorage['hideSpecialBanner'] = true;
    this.showBanner = false;
  }

  installPwa(): void {}
  OpenAppointmentAddDialog() {
    const data: AddAppointmentDialogData = {
      clinicID: this.currentUser.clinicId,
      UserId: this.currentUser.userId,
      ClinicName: this.currentUser.clinicName
    };
    this.dialog
      .open(AddAppointmentsDialogComponent, {
        data,
        width: '35%',
        disableClose: true
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this._snackBar.open(
            '¡Listo! La cita ha sido añadida correctamente.',
            'ok ',
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog'
            }
          );
        }
      });
  }
  logout() {
    this.authenticationService.logout();
  }
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  OpenImportAppointmentDialog() {
    this.dialog
      .open(ImportAppointmentsDialogComponent, {
        width: '50%',
        disableClose: false
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this._snackBar.open(
            '¡Listo! Las citas han sido importadas correctamente.',
            'ok ',
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog'
            }
          );
        }
      });
  }

  onSidenavClose() {
    this.sidenav.toggle();
  }
  SidenavClose() {
    if (this.screenWidth < 840) this.sidenav.close();
  }

  onMenuItemClicked() {
    this.menuExpanded = !this.menuExpanded;
  }
}
