import * as moment from 'moment';

import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ApiService, GetAvailableMobileNumbersResult } from '../../api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from '../../_dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AuthenticationService } from '../../_services';
import { AvailableVirtualPhoneNumber } from 'src/app/DTOS/AvailableVirtualPhoneNumbers/AvailableVirtualPhoneNumber';
import { ClinicDetails } from 'src/app/DTOS/Clinic/clinic-details';
// import { CodeModel } from '@ngstack/code-editor';
import { LoadingDialogComponent } from '../../loading-dialog/loading-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../../_models';
import {
  SpeechVoiceDetail,
  SpeechVoicesList,
  VirtualAssistantAccountDTO
} from '../../DTOS/VA_Configurations/VA_Configurations';
import { cloneDeep, isNull } from 'lodash-es';
import { LONG_DATE_FORMAT } from 'src/app/Constants/constants';

@Component({
  selector: 'app-va-account',
  templateUrl: './va-account.component.html',
  styleUrls: ['./va-account.component.scss']
})
export class AccountComponent
  implements AfterViewInit, OnChanges, AfterContentChecked
{
  public User: User;
  showList = false;
  public showForm: boolean;
  public isAdmin: boolean;
  public isEditing: boolean;
  public isEditingBotConfig: boolean;
  public _isActive_updt: boolean;
  public enableRecording: boolean;
  public isEditingFunctionalities: boolean;
  isSubmitting: Boolean = false;
  isLoading: Boolean = false;
  public clinicID: number;
  public clinic: ClinicDetails = {} as any;
  account: VirtualAssistantAccountDTO = {} as any;
  accountResult: VirtualAssistantAccountDTO = {} as any;
  backup_account: VirtualAssistantAccountDTO = {} as any;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  public isLoadingTwilioNumbers: boolean = false;
  public SpeechVoices: SpeechVoiceDetail[] = [];
  SpeechVoiceList: SpeechVoicesList;
  public pageSize = 20;
  public currentPage = 1;
  channelConfigs = [
    {
      label: 'Voz',
      value: 1
    },
    {
      label: 'Texto',
      value: 2
    },
    {
      label: 'Voz o Texto',
      value: 3
    }
  ];
  public isVirtualAgentNameRequired = false;
  public isVirtualAgentProjectRequired = false;
  public theme = 'vs-light';
  // public codeModel: CodeModel = {} as CodeModel;
  public showBuyNumberSidebar: boolean;
  options = {
    contextmenu: true,
    minimap: {
      enabled: false
    }
  };
  @Input() public isStandalone: boolean;
  @Output() hasAccount = new EventEmitter<{
    account: VirtualAssistantAccountDTO;
  }>();
  @Input() public accountID: number;
  availablePhoneNumbersResult: GetAvailableMobileNumbersResult;

  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private _snackBar: MatSnackBar,
    private cdref: ChangeDetectorRef
  ) {
    this.account = {} as any;
    this.showForm = false;
    this.isAdmin = false;
    this.isEditing = false;
    this._isActive_updt = true;
    this.enableRecording = true;
  }
  ngOnChanges(changes: SimpleChanges): void {
    const newAccountID = changes.accountID.currentValue;
    this.Load(newAccountID);
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
      this.isAdmin = user.isAdmin;
    });
  }

  public Load(accountID) {
    this.showForm = false;
    this.isLoading = true;

    this.API.LoadAccount(accountID).subscribe(
      (result) => {
        this.account = result as VirtualAssistantAccountDTO;
        if (this.account) {
          this._isActive_updt =
            this.account.deactivationDate === null ? true : false;
          this.showForm = true;
          this.isEditing = false;
          this.hasAccount.emit({ account: this.account });
          this.fetchSpeechVoices(accountID);
        } else {
          this.showForm = false;
          this.isEditing = false;
          this.isEditingBotConfig = false;
          this.isEditingFunctionalities = false;
          this.account = {} as any;
          this.hasAccount.emit({ account: this.account });
        }
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
        this.showError(error);
      }
    );
  }

  public Addaccount() {
    this.isLoading = true;
    this.isSubmitting = true;
    const AccountID = this.accountID;
    this.account.AccountID = AccountID;
    this.API.AddAccount(AccountID, this.account).subscribe(
      () => {
        this.Load(this.accountID);
        this.isLoading = false;
        this.isEditing = false;
        this.isEditingBotConfig = false;
        this.isSubmitting = false;
        this._snackBar.open(
          `¡Listo!, la cuenta de asistente virtual   ha sido creada. `,
          'OK ',
          {
            duration: 5 * 1000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog'
          }
        );
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
        this.isSubmitting = false;
        this.showError(error);
      }
    );
  }
  public Updateaccount() {
    this.isLoading = true;
    this.isSubmitting = true;
    this.account.deactivationDate =
      this._isActive_updt === false && isNull(this.account.deactivationDate)
        ? new Date()
        : this._isActive_updt === true && !isNull(this.account.deactivationDate)
          ? null
          : this.account.deactivationDate;
    const AccountID = this.accountID;
    // const accountCred = this.codeModel.value;
    const dto = {
      ...this.account
      //  serviceAccountCred: accountCred
    };
    this.API.UpdateAccount(AccountID, dto).subscribe(
      () => {
        this.Load(this.accountID);
        this.isLoading = false;
        this.isEditing = false;
        this.isEditingBotConfig = false;
        this.isEditingFunctionalities = false;
        this.isSubmitting = false;
        this._snackBar.open(
          `¡Listo!, la cuenta de asistente virtual ha sido actualizada. `,
          'OK ',
          {
            duration: 70000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog'
          }
        );
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
        this.isSubmitting = false;
        this.showError(error);
      }
    );
  }

  private showError(error: any) {
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null)
    });
  }

  public enableEdition = () => {
    this.isEditing = true;
    this.backup_account = cloneDeep(this.account);
  };

  public enableBotConfigEdition = () => {
    this.isEditingBotConfig = true;
    this.backup_account = cloneDeep(this.account);
  };
  public discardEditionChanges = () => {
    this.isEditing = false;
    this.showForm = false;
    this.isEditingBotConfig = false;
    this.account = cloneDeep(this.backup_account);
    this.backup_account = {} as any;
    this.Load(this.accountID);
  };
  public enableFunctionalitiesEdition = () => {
    this.isEditingFunctionalities = true;
    this.backup_account = cloneDeep(this.account);
  };
  public openFormAddNew = () => {
    this.isEditing = true;
    this.isEditingBotConfig = true;
    this.showForm = true;
    this.fetchClinic(this.accountID);
  };

  public discardCreate = () => {
    this.isEditing = false;
    this.showForm = false;
    this.account = {} as any;
  };

  public selectedChannelConfig(event) {
    if (event.value === 2 || event.value === 3) {
      this.isVirtualAgentNameRequired = true;
      this.isVirtualAgentProjectRequired = true;
    } else {
      this.isVirtualAgentNameRequired = false;
      this.isVirtualAgentProjectRequired = false;
    }
  }

  onCodeChanged(value) {
    if (this.isEditing === true) {
      this.account.serviceAccountCred = value;
    }
  }
  public fetchClinic = (clinicId: number) => {
    this.isLoading = true;
    this.API.GetClinic(clinicId).subscribe((r) => {
      this.clinic = r as ClinicDetails;
      this.isLoading = false;
      this.mapVaClinic(this.clinic);
    });
  };

  mapVaClinic(clinic: ClinicDetails) {
    this.account.clinicId = clinic.clinicId;
    this.account.city = clinic.city;
    this.account.addressLine1 = clinic.address1;
    this.account.addressLine2 = clinic.address2;
    this.account.state = clinic.state;
    this.account.welcomeMessageTranscript = `Gracias por comunicarse con ${clinic.clinicName}. Para efectos de calidad en el servicio, esta llamada podría estar siendo grabada.`;
  }

  public formatDate = (d: Date) => moment(d).format(LONG_DATE_FORMAT);

  public openBuyNumberPanel = () => {
    this.showBuyNumberSidebar = true;
    this.availablePhoneNumbersResult = null;
    this.isLoadingTwilioNumbers = true;
    this.API.GetAvailablePhoneNumbers(this.accountID).subscribe((r) => {
      this.availablePhoneNumbersResult = r;
      this.isLoadingTwilioNumbers = false;
    });
  };

  public buyAndActivateNumber = (number: AvailableVirtualPhoneNumber) => {
    const bold = (text: string) => `<b>${text}</b>`;
    this.showBuyNumberSidebar = false;
    this.dialog
      .open(ConfirmDialogComponent, {
        maxWidth: '600px',
        data: new ConfirmDialogModel(
          'Comprar y activar número de télefono',
          null,
          'Comprar y activar',
          'Cancelar',
          null,
          `¿Estás segur@ de querer comprar el número de télefono ${bold(
            number.phoneNumber
          )}?
         Este número de télefono tiene un costo de   ${bold(
           `${number.priceUnit} ${number.priceAmount}`
         )} mensual.`
        )
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.isEditing = false;
          this.isEditingBotConfig = false;
          this.API.BuyAndActivateVANumber(
            this.accountID,
            number.phoneNumber
          ).subscribe((r) => {
            this._snackBar.open(
              `¡Listo!, el número de télefono ha sido comprado y activado. `,
              'OK ',
              {
                duration: 5 * 1000,
                horizontalPosition: 'left',
                verticalPosition: 'bottom',
                panelClass: 'success-dialog'
              }
            );
            this.Load(this.accountID);
          });
        }
      });
  };

  public fetchSpeechVoices = (accountID) => {
    this.isLoading = true;
    this.API.GetListVoicesVirtualAssistant(
      accountID,
      this.currentPage,
      this.pageSize
    ).subscribe((r) => {
      this.SpeechVoices = r.items;
      this.isLoading = false;
    });
  };
}
