import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { AdminViewAsClinicDTO } from 'src/app/DTOS/AdminViewAsClinicDTO';

@Component({
  selector: 'app-admin-appointments-stats',
  templateUrl: './admin-appointments-stats.component.html',
  styleUrls: ['./admin-appointments-stats.component.scss']
})
export class AdminAppointmentsStatsComponent implements OnInit {
  @Input() public selectedDate: Date;

  public clinicId?: number;
  constructor(private API: ApiService) {}

  ngOnInit(): void {}

  public onViewAsChanged(viewAs: AdminViewAsClinicDTO) {
    if (viewAs?.clinicId) {
      this.clinicId = viewAs.clinicId;
    }
  }
}
