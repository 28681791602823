import { Component, OnInit, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { AdminViewAsClinicDTO } from 'src/app/DTOS/AdminViewAsClinicDTO';
import { CSVImportDialogComponent } from '../csv-import-dialog/csv-import-dialog';
import { OcrResult } from 'src/app/DTOS/OCRResult/OcrResult';
import { ApiService } from 'src/app/api.service';
import { HttpEventType } from '@angular/common/http';
import { sample } from 'lodash-es';

/**
 * The possible import source options.
 */
type ImportSourceSelection = 'PDF' | 'CSV';

/**
 * The possible steps for adding appointments.
 */
type AddAppointmentsStep =
  | 'SourceSelector'
  | 'Form'
  | 'processing'
  | 'OcrResultReview';

@Component({
  selector: 'app-import-appointments-dialog',
  templateUrl: './import-appointments-dialog.component.html',
  styleUrls: ['./import-appointments-dialog.component.scss']
})
export class ImportAppointmentsDialogComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    public dialogRef: MatDialogRef<ImportAppointmentsDialogComponent>
  ) {}
  /**
   * The `AdminViewAsClinicDTO` instance used to view as a clinic.
   */
  ViewAs: AdminViewAsClinicDTO;

  /**
   * Whether the component is currently loading.
   */
  isLoading = false;

  /**
   * The current user.
   */
  public User: User;

  /**
   * The currently selected source for importing appointments.
   */
  public source: ImportSourceSelection = 'PDF';

  /**
   * The current step in the process of adding appointments.
   */
  public step: AddAppointmentsStep = 'Form';

  /**
   * The ID of the clinic.
   */
  public clinicID: number;

  /**
   * The processing message to display while appointments are being processed.
   */
  public processingMessageTitle: string = 'Procesando';

  /**
   * The file input element for importing a file.
   */
  @ViewChild('fileImportInput') fileImportInput: any;

  /**
   * Changes the processing message to a random message from a list of messages.
   */
  public changeProcessingMessage() {
    const messages = [
      'Procesando...',
      'Extrayendo las citas...',
      'Si el archivo tiene muchas citas, esto puede demorar unos minutos...',
      'Estamos trabajando para extraer tus citas...',
      'Ya casi...',
      'Realizando cálculos complejos, por favor espera...',
      'Estamos preparando tus resultados...'
    ];
    this.processingMessageTitle = String(sample(messages));
  }
  public ocrResult: OcrResult = {
    jobId: null,
    type: 0,
    reminderSchedulesPerDay: {},
    appointments: []
  };

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((u) => {
      this.User = u;
    });
  }

  /**
   * Handles when the `ViewAs` clinic ID changes.
   * @param $event The new `AdminViewAsClinicDTO` instance.
   */
  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
  }

  public onFileUploadStart() {
    this.step = 'processing';
  }

  /**
   * Changes the current step to the specified new step.
   * If the new step is 'Form' and the source is 'CSV', opens a CSV import dialog component.
   * @param newStep The new step to set.
   */
  public onStepChanged(newStep: AddAppointmentsStep) {
    if (newStep === 'Form' && this.source === 'CSV') {
      this.dialog.open(CSVImportDialogComponent, {
        maxWidth: '100vw',
        maxHeight: '95vh',
        height: '90%',
        width: '80%'
      });
    } else {
      this.step = newStep;
    }
  }

  /**
   * Gets the URL for uploading a file.
   * @returns The URL for uploading a file.
   */
  public getUrl() {
    const clinicid = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    return `/Printer/v2/file-in?clinicId=${clinicid}`;
  }

  /**
   * Opens an import result dialog component.
   * @param importUrl The URL to import the result from.
   */
  openDialog(importUrl: string) {
    const dialogRef = this.dialog.open(ImportResultDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '95vh',
      height: '95%',
      width: '100%',
      backdropClass: 'true',
      disableClose: true,
      data: {
        importUrl
      }
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  /**
   * Called when an upload is complete.
   *  Changes the step to 'Form' and opens a dialog if the response has a
   *  forwardUrl property. Otherwise, logs the response to the console.
   * @param event The upload event.
   */
  public uploadComplete(event: any) {
    const response = event.originalEvent?.body;

    this.step = 'Form';
    if (response?.forwardUrl) {
      this.openDialog(response.forwardUrl);
    } else {
      let casted = response as OcrResult;
    }
  }

  /**
   * Sets up pooling for a job by repeatedly calling the API to
   * continue printer extraction until the response has a jobId property (this means that the textract response is ready).
   * Then sets the clinic ID and step to 'OcrResultReview' and sets the ocrResult property to the response.
   * @param clinicId The clinic ID.
   * @param jobId The job ID.
   * @param type The request type.
   * @param ehr The ehr.
   */
  public SetPoolingForJob(
    clinicId: number,
    jobId: string,
    type: number,
    ehr: number
  ) {
    setTimeout(() => {
      this.API.ContinuePrinterExtractionFromJob(
        clinicId,
        jobId,
        type,
        ehr
      ).subscribe(
        (resp: OcrResult) => {
          if (resp.jobId) {
            this.changeProcessingMessage();
            this.SetPoolingForJob(clinicId, jobId, type, ehr);
          } else {
            this.clinicID = clinicId;
            this.step = 'OcrResultReview';
            this.ocrResult = resp;
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }, 3000);
  }
  public ehr: number = 0;
  /**
   * Handles file upload event
   * @param {any} event - file upload event
   */
  public onUpload(event: any) {
    this.step = 'processing';

    let fileList: FileList = event.files;
    if (fileList.length > 0) {
      this.API.UploadFile(this.getUrl(), fileList[0]).subscribe(
        (r) => {
          if (r.type === HttpEventType.Response) {
            const response = Object(r.body);

            if (response.forwardUrl) {
              this.step = 'SourceSelector';
              this.openDialog(response.forwardUrl);
            } else {
              const jobId: string = response.jobId;
              const type: number = response.requestType;
              const ehr: number = response.ehr;

              this.ehr = ehr;
              const clinicid = this.ViewAs
                ? this.ViewAs.clinicId
                : this.User.clinicId;

              this.SetPoolingForJob(clinicid, jobId, type, ehr);
            }
          }
        },
        (e) => {
          this.step = 'SourceSelector';
          setTimeout(() => {
            alert(`Oops, ha ocurrido un error. ${e?.message || ''}`);
          }, 1000);
        }
      );
    }
  }
}

@Component({
  selector: 'app-import-result-dialog',
  templateUrl: 'import-result-dialog.html'
})
export class ImportResultDialogComponent {
  constructor(
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.importUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      data.importUrl
    );
  }
  public importUrl: SafeResourceUrl;
}
