import { AfterViewInit, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';
import { GetAccountWithClinicInfo } from 'src/app/DTOS/VA_Configurations/GetAccountWithClinicInfo';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';
import { User } from '../../_models';
import { AuthenticationService } from '../../_services';
import { AdminViewAsClinicDTO } from 'src/app/DTOS/AdminViewAsClinicDTO';

@Component({
  selector: 'app-va-configuration',
  templateUrl: './va-configuration.component.html',
  styleUrls: ['./va-configuration.component.scss']
})
export class VaConfigurationComponent implements AfterViewInit {
  public User: User;
  public ViewAs: AdminViewAsClinicDTO;
  isLoading = true;
  public isEditing = false;
  public hasVA_Permission = false;
  isLoadingPermissions = false;
  public account: any;
  public accountId: number;
  public clinicName: string;
  public vaConfig: GetAccountWithClinicInfo;

  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private permissionSlugsService: PermissionSlugsService,
    private route: ActivatedRoute,
    private API: ApiService,
    private router: Router
  ) {}

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.accountId = this.ViewAs.clinicId;
    this.router.navigate([
      '/asistente-virtual/Configuracion/' + this.accountId
    ]);
  }

  ngAfterViewInit() {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
    });

    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.hasVA_Permission = r.includes(PermissionSlugs.VIRTUAL_ASSISTANT);
    });

    this.route.params.subscribe((c) => {
      const accountID: number = c.accountID;
      this.accountId = accountID;
      this.loadClinic(accountID);
    });
  }

  checkHasAccount($event) {
    this.account = $event?.account;
  }

  public loadClinic(clinicId: number) {
    this.API.GetAccountWithClinicInfo(clinicId).subscribe((result) => {
      this.vaConfig = result;
      this.isLoading = false;
    });
  }
}
