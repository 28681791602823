import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import cloneDeep from 'lodash-es/cloneDeep';
import { InitialQuestions } from 'src/app/DTOS/VA_Configurations/VA_Configurations';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import { ConfirmationService } from 'primeng/api';
import { OrderList } from 'primeng/orderlist';

@Component({
  selector: 'app-va-initial-questions',
  templateUrl: './va-initial-questions.component.html',
  styleUrl: './va-initial-questions.component.scss'
})
export class VaInitialQuestionsComponent implements AfterViewInit, OnChanges {
  @Input() public accountID: number;
  @Input() public isStandalone: boolean;
  public customIntentFormModel: InitialQuestions;
  public User: User;
  public pageSize = 10;
  public currentPage = 1;
  public totalSize = 0;
  slidePanelOpen: Boolean = false;
  isSubmitting: Boolean = false;
  isLoading: Boolean = false;
  sidepanelItems: InitialQuestions = {} as InitialQuestions;
  public listItems = [] as InitialQuestions[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('orderList') orderList!: OrderList;
  isEditing: boolean;
  showForm: boolean;
  account = {} as InitialQuestions;
  backup_account = {} as InitialQuestions;

  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar
  ) {}

  ngAfterViewInit() {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
      this.LoadLists();
    });
  }

  ngOnChanges(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
      this.LoadLists();
    });
  }
  public openAddNewSidePanel() {
    this.slidePanelOpen = true;
    this.sidepanelItems = {} as InitialQuestions;
  }
  public openEditSidePanel(item) {
    this.slidePanelOpen = true;
    this.sidepanelItems = cloneDeep(item);
  }
  public closeAddNewSidePanel() {
    this.slidePanelOpen = false;
    this.sidepanelItems = {} as InitialQuestions;
    this.LoadLists();
  }

  public LoadLists() {
    this.isLoading = true;
    this.API.GetInitialQuestions(this.accountID).subscribe((result) => {
      this.listItems = result;
      this.isLoading = false;
    });
  }

  public UpsertQuestions(botQuestionId?: number) {
    this.isSubmitting = true;
    this.isLoading = true;
    if (!botQuestionId) {
      this.sidepanelItems.sortOrder =
        this.listItems != null ? this.listItems?.length : 0;
    }
    let newQuestion = [...this.listItems, this.sidepanelItems];
    this.API.UpsertQuestions(this.accountID, newQuestion).subscribe(() => {
      this.LoadLists();
      this.isSubmitting = false;
      this.isLoading = false;
      this.closeAddNewSidePanel();
      this._snackBar.open(
        `¡Listo!,  Pregunta inicial actualizada correctamente. `,
        'OK ',
        {
          duration: 70000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog'
        }
      );
    });
  }
  public UpsertQuestionsOrder() {
    this.isSubmitting = true;
    this.isLoading = true;
    this.API.UpsertQuestions(this.accountID, this.listItems).subscribe(() => {
      this.LoadLists();
      this.isSubmitting = false;
      this.isLoading = false;
      this.closeAddNewSidePanel();
      this._snackBar.open(
        `¡Listo!,  Pregunta inicial actualizada correctamente. `,
        'OK ',
        {
          duration: 70000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog'
        }
      );
    });
  }

  public DeleteBotQuestion(event: Event, initialQuestion: InitialQuestions) {
    if (this.User?.isAdmin === false) {
      return;
    }
    const botQuestionId = initialQuestion.botQuestionId;
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer eliminar este registro?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, eliminar',
      rejectLabel: 'Cancelar',
      accept: () =>
        this.API.DeleteBotQuestion(this.accountID, botQuestionId).subscribe(
          (r) => {
            this._snackBar.open(
              `¡Listo!,  Registro eliminado  correctamente. `,
              'OK ',
              {
                duration: 70000,
                horizontalPosition: 'left',
                verticalPosition: 'bottom',
                panelClass: 'success-dialog'
              }
            );
            this.LoadLists();
          }
        ),
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  public handlePage(e: PageEvent) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.LoadLists();
  }

  public discardEditionChanges = () => {
    this.isEditing = false;
    this.showForm = false;
    this.account = cloneDeep(this.backup_account);
    this.backup_account = {} as InitialQuestions;
    this.LoadLists();
  };
  public openIntent = (record: InitialQuestions) => {
    this.customIntentFormModel = record;
    this.slidePanelOpen = true;
  };

  public onDrop(): void {
    this.listItems.forEach((item, index) => {
      item.sortOrder = index;
    });
  }
}
