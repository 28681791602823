import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import { BookingService } from 'src/app/DTOS/Bookings/BookingServiceDTO';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';

export interface BookingServiceEditDialogData {
  service: BookingService;
  name: String;
}

@Component({
  selector: 'app-bookings-configuration-dialog',
  templateUrl: './bookings-configuration-dialog.component.html',
  styleUrls: ['./bookings-configuration-dialog.component.scss']
})
export class BookingsConfigurationDialogComponent {
  User: User;
  isLoading = false;
  public service: BookingService;
  public permissions: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BookingServiceEditDialogData,
    private _snackBar: MatSnackBar,
    private API: ApiService,
    private permissionSlugsService: PermissionSlugsService,
    public dialogRef: MatDialogRef<BookingsConfigurationDialogComponent>,
    private authenticationService: AuthenticationService
  ) {
    if (this.data.service) {
      this.setData(this.data.service);
    }
    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.permissions = r;
    });
    this.authenticationService.currentUser.subscribe((u) => (this.User = u));
  }

  public setData(service: BookingService) {
    this.service = {
      bookingServiceId: service.bookingServiceId,
      bookingServiceName: service.bookingServiceName,
      bookingServiceDescription: service.bookingServiceDescription,
      serviceDurationInMinutes: service.serviceDurationInMinutes,
      clinicId: service.clinicId
    } as BookingService;
  }

  public close() {
    this.dialogRef.close();
  }

  public async Save() {
    const confirm = window.confirm(
      `¿Estás segur@ de querer cambiar el nombre del servicio?`
    );
    if (!confirm) {
      return;
    }
    this.service.clinicId = this.User.clinicId;
    this.updateService(this.service);
  }

  private async updateService(_service: BookingService) {
    this.API.UpdateBookingService(_service).subscribe(
      () => {
        this.isLoading = false;
        this.dialogRef.close(true);
        this._snackBar.open('¡Listo!, se ha actualizado el servicio.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog'
        });
      },
      (f) => {
        this.isLoading = false;
        console.error('ERROR AL actualizar el servicio', f);
      }
    );
  }
}
