import { Component, Input } from '@angular/core';
import { AuthorDto } from 'src/app/DTOS/AuthorDto';

@Component({
  selector: 'app-entity-author-label',
  templateUrl: './entity-author-label.component.html',
  styleUrl: './entity-author-label.component.scss'
})
export class EntityAuthorLabelComponent {
  @Input() public author: AuthorDto;
}
