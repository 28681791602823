// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conversation-summary {
  background: radial-gradient(circle at 100%, #736bad, #7f71eb 50%, #b618ff 75%, #5c52a5 75%);
  font-weight: 600;
  background-size: 200% auto;
  color: #000;
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animatedTextGradient 1.5s linear infinite;
}

@keyframes animatedTextGradient {
  to {
    background-position: 200% center;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFpLWJvdC1jaGF0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsMkZBQUE7RUFPQSxnQkFBQTtFQUNBLDBCQUFBO0VBQ0EsV0FBQTtFQUNBLDZCQUFBO1VBQUEscUJBQUE7RUFDQSxvQ0FBQTtFQUNBLG9EQUFBO0FBTEY7O0FBT0E7RUFDRTtJQUNFLGdDQUFBO0VBSkY7QUFDRiIsImZpbGUiOiJhaS1ib3QtY2hhdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jb252ZXJzYXRpb24tc3VtbWFyeSB7XG4gIGJhY2tncm91bmQ6IHJhZGlhbC1ncmFkaWVudChcbiAgICBjaXJjbGUgYXQgMTAwJSxcbiAgICAjNzM2YmFkLFxuICAgICM3ZjcxZWIgNTAlLFxuICAgICNiNjE4ZmYgNzUlLFxuICAgICM1YzUyYTUgNzUlXG4gICk7XG4gIGZvbnQtd2VpZ2h0OiA2MDA7XG4gIGJhY2tncm91bmQtc2l6ZTogMjAwJSBhdXRvO1xuICBjb2xvcjogIzAwMDtcbiAgYmFja2dyb3VuZC1jbGlwOiB0ZXh0O1xuICAtd2Via2l0LXRleHQtZmlsbC1jb2xvcjogdHJhbnNwYXJlbnQ7XG4gIGFuaW1hdGlvbjogYW5pbWF0ZWRUZXh0R3JhZGllbnQgMS41cyBsaW5lYXIgaW5maW5pdGU7XG59XG5Aa2V5ZnJhbWVzIGFuaW1hdGVkVGV4dEdyYWRpZW50IHtcbiAgdG8ge1xuICAgIGJhY2tncm91bmQtcG9zaXRpb246IDIwMCUgY2VudGVyO1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/ai-bot-chat/ai-bot-chat.component.scss"],"names":[],"mappings":"AAAA;EACE,2FAAA;EAOA,gBAAA;EACA,0BAAA;EACA,WAAA;EACA,6BAAA;UAAA,qBAAA;EACA,oCAAA;EACA,oDAAA;AALF;;AAOA;EACE;IACE,gCAAA;EAJF;AACF;AAEA,49BAA49B","sourcesContent":[".conversation-summary {\n  background: radial-gradient(\n    circle at 100%,\n    #736bad,\n    #7f71eb 50%,\n    #b618ff 75%,\n    #5c52a5 75%\n  );\n  font-weight: 600;\n  background-size: 200% auto;\n  color: #000;\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n  animation: animatedTextGradient 1.5s linear infinite;\n}\n@keyframes animatedTextGradient {\n  to {\n    background-position: 200% center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
