import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AdminViewAsClinicDTO } from 'src/app/DTOS/AdminViewAsClinicDTO';
import {
  LocationDTO,
  LocationDTOResultDTO
} from 'src/app/DTOS/Locations/Location';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from 'src/app/_dialogs/confirm-dialog/confirm-dialog.component';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import { LoadingDialogComponent } from 'src/app/loading-dialog/loading-dialog.component';
@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
  public isLoading: boolean;
  public showPermissionActive: boolean;
  public User: User;
  public ViewAs: AdminViewAsClinicDTO;
  public pageSize = 10;
  public currentPage = 1;
  public totalSize = 0;
  public searchTearm = '';
  public clinicId: number;
  Locations: LocationDTOResultDTO;
  listItems: LocationDTO[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: MatTableDataSource<LocationDTO>;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  @Input() public isStandalone: boolean;
  displayedColumns: string[] = [
    'locationId',
    'locationName',
    'locationAlias',
    'locationInternalIdentifier',
    'Details'
  ];
  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    public dialog: MatDialog,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
      this.LoadLocations();
    });
  }
  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadLocations();
  }
  public LoadLocations() {
    if (this.isStandalone) {
      return;
    }
    this.ShowLoading();
    this.clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.API.GetClinicLocations(
      this.clinicId,
      this.currentPage,
      this.pageSize,
      this.searchTearm
    ).subscribe(
      (result) => {
        const res = result as LocationDTOResultDTO;
        this.dataSource = new MatTableDataSource<LocationDTO>(res.items);
        this.Locations = res;
        this.totalSize = this.Locations.totalCount;
        this.pageSize = this.Locations.pageSize;
        this.currentPage = this.Locations.pageIndex;
        this.listItems = this.Locations.items;
        this.hideLoading();
      },
      (error) => {
        console.error(error);
        this.hideLoading();
        this.showError(error);
      }
    );
  }
  public ShowLoading() {
    this.isLoading = true;
  }
  public hideLoading() {
    this.isLoading = false;
  }
  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null)
    });
  }
  public handlePage(e: any) {
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.LoadLocations();
  }
  applyFilter(filterValue: string) {
    this.searchTearm = filterValue.trim().toLowerCase();
    this.LoadLocations();
  }
  public UpdateInsertLocate = (locationId?: number, clinicId?: number) => {
    if (locationId) {
      const route = `admin/locations/details/`;
      this.router.navigate([route, locationId, clinicId]);
    } else {
      const route = `admin/locations/create/`;
      this.router.navigate([route, this.clinicId]);
    }
  };
}
