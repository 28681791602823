import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import {
  ApiService,
  GetClinicsWithNoCallDates,
  GetClinincsByStatus
} from 'src/app/api.service';
import { AccountStatus } from 'src/app/DTOS/Enums/AccountStatus';

@Component({
  selector: 'app-configurando-clinics',
  templateUrl: './configurando-clinics.component.html',
  styleUrl: './configurando-clinics.component.scss'
})
export class ConfigurandoClinicsComponent implements OnInit {
  constructor(private API: ApiService) {}

  public clinics: GetClinincsByStatus[] = [];
  public clinicsWithNoCallDates: GetClinicsWithNoCallDates[] = [];

  async ngOnInit(): Promise<void> {
    this.GetClinicsByStatus();
    this.GetClinicsWithNoCallDates();
  }

  private async GetClinicsByStatus() {
    const clinics = await firstValueFrom(
      this.API.GetClinicsByStatus(AccountStatus.SettingUp)
    );

    this.clinics = clinics;
  }

  private async GetClinicsWithNoCallDates() {
    const clinicsWithNoCallDates = await firstValueFrom(
      this.API.GetClinicsWithNoCallDates()
    );
    this.clinicsWithNoCallDates = clinicsWithNoCallDates;
  }

  public formatDate = (date: Date) => moment(date).format('DD-MM-yyyy');
  public formatDateForLink = (date: Date) => moment(date).format('yyyy-MM-DD');
}
