// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slug {
  background-color: #fff7f0;
  padding-top: 5px;
  border-radius: 6px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: solid 1.5px #e7c29b;
  color: #8a4e0e;
  font-weight: bold;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXItcGVybWlzc2lvbnMtdGFibGUuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSx5QkFBQTtFQUNBLGdCQUFBO0VBQ0Esa0JBQUE7RUFDQSxtQkFBQTtFQUNBLGtCQUFBO0VBQ0EsbUJBQUE7RUFDQSwyQkFBQTtFQUNBLGNBQUE7RUFDQSxpQkFBQTtBQUNGIiwiZmlsZSI6InVzZXItcGVybWlzc2lvbnMtdGFibGUuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuc2x1ZyB7XG4gIGJhY2tncm91bmQtY29sb3I6ICNmZmY3ZjA7XG4gIHBhZGRpbmctdG9wOiA1cHg7XG4gIGJvcmRlci1yYWRpdXM6IDZweDtcbiAgcGFkZGluZy1ib3R0b206IDVweDtcbiAgcGFkZGluZy1sZWZ0OiAxMHB4O1xuICBwYWRkaW5nLXJpZ2h0OiAxMHB4O1xuICBib3JkZXI6IHNvbGlkIDEuNXB4ICNlN2MyOWI7XG4gIGNvbG9yOiAjOGE0ZTBlO1xuICBmb250LXdlaWdodDogYm9sZDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/user-permissions-table/user-permissions-table.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,2BAAA;EACA,cAAA;EACA,iBAAA;AACF;AACA,4sBAA4sB","sourcesContent":[".slug {\n  background-color: #fff7f0;\n  padding-top: 5px;\n  border-radius: 6px;\n  padding-bottom: 5px;\n  padding-left: 10px;\n  padding-right: 10px;\n  border: solid 1.5px #e7c29b;\n  color: #8a4e0e;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
