export interface IAppointmentDTO {
  appointmentId: number;
  appointmentDate: Date;
  doctorId: number;
  doctorName: string;
  patientName: string;
  patientPhoneNumber: string;
  patientAltPhoneNumber?: string;
  patientRecord?: string;
  otherInfo: string;
  _formatedTime?: string;
  callStatusName?: string;
  status: IStatusDTO;
}
export interface IStatusDTO {
  smallIconFilename: string;
  callStatusName: string;
  callStatusId: string;
}

export class AddAppointmentDto {
  clinicId: number;
  doctorId: number;
  reminderSetId: number;
  UserId: number;
  appointmentDate: Date;
  patientName: string;
  patientPhoneNumber: string;
  patientAltPhoneNumber: string;
  callDate: Date;
  callTime: string;
  appointmentTime: string;
  reminderScheduleId?: number;
}
