export const snakeCaseToNormal = (str: string) => {
  // Step 1: Replace uppercase letters with space and the same letter in lowercase
  let result = str.replace(/[A-Z]/g, (letter) => ` ${letter}`);

  // Step 2: Trim and capitalize the first letter
  result = result.charAt(0).toUpperCase() + result.slice(1);

  return result;
};

export function isValidUrl(url: string): boolean {
  // Regular expression for validating a URL
  const regex = new RegExp(
    '^(https?:\\/\\/)?' + // Optional scheme (http or https)
      '((([a-zA-Z0-9_-]+\\.)+[a-zA-Z]{2,6})|' + // Domain name
      '(([0-9]{1,3}\\.){3}[0-9]{1,3}))' + // OR IP address
      '(\\:[0-9]{1,5})?' + // Optional port
      '(\\/[-a-zA-Z0-9@:%._\\+~#=]*)*' + // Optional path
      '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // Optional query string
      '(\\#[-a-zA-Z0-9_]*)?$' // Optional fragment
  );

  return regex.test(url);
}

export function generateRandomString(n: number) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < n; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += characters[randomIndex];
  }

  return result;
}
