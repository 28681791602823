import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrl: './time-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeInputComponent),
      multi: true
    }
  ]
})
export class TimeInputComponent implements ControlValueAccessor {
  hours: string = '';
  minutes: string = '';
  period: 'AM' | 'PM' = 'AM';

  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};
  @Output() onTimeValueChange = new EventEmitter<string>();
  @Input() name: string = 'time';

  onTimeChange() {
    this.validateTimeInputs();
    const timeString = `${String(this.hours).padStart(2, '0')}:${String(
      this.minutes
    ).padStart(2, '0')} ${this.period}`;
    this.onChange(timeString); // Notify Angular forms of the change
    this.onTouched(); // Mark the control as touched
    this.onTimeValueChange.emit(timeString); // Emit the change event with the new time
  }

  validateTimeInputs() {
    // Validate hours (1-12)
    let hourValue = parseInt(this.hours, 10);
    if (isNaN(hourValue) || hourValue < 1) {
      hourValue = 1;
    } else if (hourValue > 12) {
      hourValue = 12;
    }
    this.hours = hourValue.toString().padStart(2, '0');

    // Validate minutes (0-59)
    let minuteValue = parseInt(this.minutes, 10);
    if (isNaN(minuteValue) || minuteValue < 0) {
      minuteValue = 0;
    } else if (minuteValue > 59) {
      minuteValue = 59;
    }
    this.minutes = minuteValue.toString().padStart(2, '0');
  }

  writeValue(value: string): void {
    if (value) {
      const [time, period] = value.split(' ');
      const [hours, minutes] = time.split(':');
      this.hours = hours;
      this.minutes = minutes;
      this.period = String(period).toUpperCase() as 'AM' | 'PM';
    } else {
      this.hours = '';
      this.minutes = '';
      this.period = 'AM';
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Implement if you want to handle the disabled state
  }
}
