import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { DialogSystemMessagesComponent } from 'src/app/Components/dialog-system-messages/dialog-system-messages.component';
import { CallStatusViewModel } from 'src/app/DTOS/CallStatus/DTOS';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import { isNil } from 'lodash';
import moment from 'moment';

@Component({
  selector: 'app-call-statuses-create',
  templateUrl: './call-statuses-create.component.html',
  styleUrls: ['./call-statuses-create.component.scss']
})
export class CallStatusesCreateComponent {
  public callStatusDetails: CallStatusViewModel;
  backup_callStatusDetails: CallStatusViewModel;
  public isLoading = false;
  public isEditing = true;
  public isActive = false;
  public showMessageHeader = false;
  public saveViewsLogs = false;
  public callStatusId = '';
  @ViewChild('statusForm', { static: false })
  public announcementsForm: NgForm;
  loadingRefMessage: MatDialogRef<DialogSystemMessagesComponent>;
  public User: User;

  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.callStatusDetails = {} as CallStatusViewModel;
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
    });
    this.route.params.subscribe((params) => {
      this.callStatusId = params.callStatusId;
      if (this.callStatusId) {
        this.GetCallStatusDetails(this.callStatusId);
      }
    });
    this.isLoading = false;
  }

  GetCallStatusDetails(callStatusId: string) {
    this.isLoading = true;
    this.API.GetAllCallStatuDetails(callStatusId).subscribe((response) => {
      this.callStatusDetails = response;
      this.isActive =
        isNil(this.callStatusDetails?.deleteDate) === true ? true : false;
      this.isLoading = false;
    });
  }

  public save = () => {
    this.showMessageHeader = false;
    this.isEditing = false;
    this.isLoading = true;
    if (this.callStatusId) {
      this.doUpdate();
    } else {
      this.doCreate();
    }
  };

  doUpdate() {
    this.isLoading = true;
    this.callStatusDetails.deleteDate =
      this.isActive === false
        ? moment().toDate()
        : this.callStatusDetails.deleteDate;
    this.API.UpdateCallStatusView(this.callStatusDetails).subscribe(
      (response) => {
        this.callStatusDetails = response;
        this.isLoading = false;
        this.showSuccessMessage('¡Listo!, Mensaje actualizado correctamente.');
      }
    );
  }

  doCreate() {
    this.isLoading = true;

    this.API.CreateCallStatusView(this.callStatusDetails).subscribe(
      (response) => {
        this.callStatusDetails = response;
        this.isLoading = false;
        this.showSuccessMessage('¡Listo!, Mensaje creado correctamente.');
      }
    );
  }

  showSuccessMessage(text: string) {
    this._snackBar.open(`${text} `, 'OK ', {
      duration: 70000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      panelClass: 'success-dialog'
    });
  }

  public discardEditionChanges = () => {
    this.isEditing = false;
    if (this.callStatusDetails?.callStatusId) {
      this.GetCallStatusDetails(this.callStatusDetails.callStatusId);
    } else {
      this.callStatusDetails = structuredClone(this.backup_callStatusDetails);
      this.backup_callStatusDetails = {} as CallStatusViewModel;
    }
  };

  public enableEdition = () => {
    this.isEditing = true;
    this.backup_callStatusDetails = structuredClone(this.callStatusDetails);
  };
}
