import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogSystemMessagesComponent } from '../Components/dialog-system-messages/dialog-system-messages.component';
import {
  SystemAnnouncementType,
  SystemMessage
} from '../DTOS/Appointments/AppointmentListItem';
import {
  SystemAnnouncementDetail,
  SystemMessagesConfig
} from '../DTOS/SystemMessages/System-MesagesDto';
import { AuthenticationService } from '../_services';
import { ApiService } from '../api.service';
import { User } from '../_models';

@Component({
  selector: 'app-system-messages-container',
  templateUrl: './system-messages-container.component.html',
  styleUrls: ['./system-messages-container.component.scss']
})
export class SystemMessagesContainerComponent implements OnInit {
  constructor(
    private API: ApiService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {}

  public bannersAnnouncements: SystemMessagesConfig[] = [];

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
      this.GetSystemMessage(user.userId);
    });
  }
  public User: User;

  public GetSystemMessage(userid: number) {
    this.API.GetSystemMessage(userid).subscribe((systemMessages) => {
      this.bannersAnnouncements = systemMessages
        .filter(
          (sysMessage) =>
            sysMessage.displayType === SystemAnnouncementType.BANNER
        )
        .map((el) => {
          return new SystemMessagesConfig(
            el.title,
            el.message,
            el.announcementType,
            true
          );
        });
      const modalAnnouncement = systemMessages.find(
        (msg) => msg.displayType === SystemAnnouncementType.MODAL
      );

      if (modalAnnouncement) {
        const systemMessagesConfig = new SystemMessagesConfig(
          modalAnnouncement.title,
          modalAnnouncement.message,
          modalAnnouncement.announcementType, //CSS style =>info, error, warn
          false
        );
        const loading_dialog = this.dialog.open(DialogSystemMessagesComponent, {
          maxWidth: '900px',
          maxHeight: '400px',
          panelClass: ['app-full-dialog', 'glassy-panel'],
          backdropClass: 'glassy-error',
          data: systemMessagesConfig
        });

        const maybeLogTheUserOut = () => {
          if (modalAnnouncement.announcementType === 'error') {
            this.authenticationService.logout();
          }
        };

        loading_dialog.afterClosed().subscribe(() => {
          if (modalAnnouncement.saveViewsLogs) {
            this.API.CreateNewViewLogSystemMessage(
              this.User.userId,
              modalAnnouncement.systemAnnouncementId
            ).subscribe(() => {
              maybeLogTheUserOut();
            });
          } else maybeLogTheUserOut();
        });
      }
    });
  }

  ShowSystemMessagessHeader(anouncementDetail: SystemMessagesConfig) {
    let systemMessagesConfigHeader = new SystemMessagesConfig(
      anouncementDetail.title,
      anouncementDetail.message,
      anouncementDetail.type,
      true
    );
    return systemMessagesConfigHeader;
  }
}
