export class SpecialAnnouncementCreateDTO {
  callDate: Date;
  callTime: string;
  clinicId: number;
  reminderSetId: number;
  appointmentsIds: number[];
  CallAttempts: number;
}
// public DateTime callDate { get; set; }
//            public string callTime { get; set; }
//            public int ClinicId { get; set; }
//            public int reminderSetId { get; set; }
//            public int[] AppointmentsIds { get; set; }
