import { AuthorDto } from '../AuthorDto';
import { Doctor } from '../Doctors/DoctorDTO';

export class NoCallDateCreateDTO {
  clinicId: number;
  NoCallDate: Date;
  NoCallDate_To: Date;
  DoctorsIds: number[];
  fromHour: string;
  toHour: string;
}
export interface NoCallDateGetDTO {
  noCallDateId: number;
  noCallDate: string;
  comments: string;
  createDate: Date;
  doctor: Doctor;
  author?: AuthorDto;
}
