import { Directive, ElementRef, HostListener } from '@angular/core';
const REGEX = /^[a-zA-Z0-9áéíóúÁÉÍÓÚüÜñÑ,.\s]+$/;

// Function to remove invalid characters from a string
function removeInvalidChars(input) {
  const regex = /[^a-zA-Z0-9áéíóúÁÉÍÓÚüÜñÑ,.\s]/g;
  return input.replace(regex, '');
}

@Directive({
  selector: '[appAlphanumeric]'
})
export class AlphanumericsDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const key = event.key.toLowerCase();

    if (!REGEX.test(key) && !this.isSpecialKey(event)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const pastedText = event.clipboardData?.getData('text/plain');

    if (pastedText) {
      document.execCommand('insertText', false, removeInvalidChars(pastedText));
    }
  }

  private isSpecialKey(event: KeyboardEvent): boolean {
    return (
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown' ||
      event.key === 'Home' ||
      event.key === 'End' ||
      event.key === 'Tab' ||
      event.key === 'Enter'
    );
  }
}
