import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from 'src/app/_dialogs/confirm-dialog/confirm-dialog.component';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import { AdminViewAsClinicDTO } from 'src/app/DTOS/AdminViewAsClinicDTO';
import {
  ReminderDetail,
  ReminderList
} from 'src/app/DTOS/Reminders/ReminderSequence';
import { ReminderSetType } from 'src/app/DTOS/ReminderSets/ReminderSetDto';
import {
  LoadingDialogComponent,
  LoadingDialogModel
} from 'src/app/loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-reminderset',
  templateUrl: './reminderset.component.html',
  styleUrl: './reminderset.component.scss'
})
export class RemindersetComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: MatTableDataSource<ReminderDetail>;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  @Input() public isStandalone: boolean;
  @Input() public ViewAs: AdminViewAsClinicDTO;
  public User: User;
  public isLoading: boolean;
  public pageSize = 10;
  public currentPage = 0;
  public clinicId: number;
  public messageType: ReminderSetType | null = null;
  searchTerm = '';
  ReminderList: ReminderList;
  listItems: ReminderDetail[];
  public totalSize = 0;
  displayedColumns: string[] = [
    'reminderSetId',
    'reminderSetType',
    'reminderSetName',
    'reminderScheduleCount',
    'clinicId',
    'Edit'
  ];

  public reminderSetTypes = [
    { value: ReminderSetType.SMS, viewValue: 'SMS' },
    { value: ReminderSetType.VoiceCall, viewValue: 'Llamada de Voz' }
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    public dialog: MatDialog
  ) {}

  ngAfterViewInit() {
    this.authenticationService.currentUser.subscribe((u) => (this.User = u));
    this.LoadReminders();
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadReminders();
  }

  public LoadReminders() {
    this.isLoading = true;
    const clinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    if (this.isStandalone && !this.ViewAs?.clinicId) {
      return;
    }
    this.API.GetListlReminderSet(
      this.currentPage,
      this.pageSize,
      clinicID,
      this.searchTerm,
      this.messageType
    ).subscribe(
      (r) => {
        const res = r as ReminderList;
        this.dataSource = new MatTableDataSource<ReminderDetail>(res.items);
        this.ReminderList = res;
        this.totalSize = this.ReminderList.totalCount;
        this.pageSize = this.ReminderList.pageSize;
        this.listItems = this.ReminderList.items;
        for (let i = 0; i < this.listItems.length; i++) {
          this.listItems[i].createDateView = moment(
            this.listItems[i].createDate
          ).format('DD MMMM [del] YYYY');
        }
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.hideLoading();
        this.showError(error);
      }
    );
  }

  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false)
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }

  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null)
    });
  }

  getReminderSetTypeById(id: number): string | undefined {
    switch (id) {
      case ReminderSetType.SMS:
        return 'SMS';
      case ReminderSetType.VoiceCall:
        return 'Voz';
      default:
        return undefined;
    }
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.LoadReminders();
  }
}
