import { Component, Input, OnInit } from '@angular/core';
import { SystemMessagesConfig } from 'src/app/DTOS/SystemMessages/System-MesagesDto';

@Component({
  selector: 'app-header-system-messages',
  templateUrl: './header-system-messages.component.html',
  styleUrls: ['./header-system-messages.component.scss']
})
export class HeaderSystemMessagesComponent implements OnInit {
  @Input() public systemMessagesConfigHeader: SystemMessagesConfig;

  constructor() {}
  ngOnInit(): void {
    this.systemMessagesConfigHeader = {} as SystemMessagesConfig;
  }

  onDismiss(): void {
    this.systemMessagesConfigHeader.showHeaderMessage = false;
  }
}
