import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import orderby from 'lodash-es/orderBy';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { AdminViewAsClinicDTO } from 'src/app/DTOS/AdminViewAsClinicDTO';
import { AuthenticationService } from 'src/app/_services';

@Component({
  selector: 'app-master-user-view-as-selector',
  templateUrl: './master-user-view-as-selector.component.html',
  styleUrls: ['./master-user-view-as-selector.component.scss']
})
/** MasterUserViewAsSelector component*/
export class MasterUserViewAsSelectorComponent implements AfterViewInit {
  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
    this.searchTermChanged
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((model) => {
        this.FetchClinics(model);
      });
  }
  fetchRequest: Subscription;
  @Input() asFab?: boolean;
  @Input() label: string;

  @Output() messageEvent = new EventEmitter<AdminViewAsClinicDTO>();
  httpClient: HttpClient;
  clinics: AdminViewAsClinicDTO[] = [];
  searchTerm: string;
  searchTermChanged: Subject<string> = new Subject<string>();
  selectedClinic: AdminViewAsClinicDTO;
  baseUrl: string;
  clinicID: number;
  isAdmin: boolean = false;
  ngAfterViewInit() {
    this.authenticationService.currentUser.subscribe((u) => {
      this.isAdmin = u.isAdmin;
    });
    if (!this.clinics?.length) {
      this.FetchClinics('', true);
    }
  }

  public onFabLongPress() {}
  public FetchClinics(searchTerm: string, isInitialLoad?: boolean) {
    if (this.isAdmin !== true) {
      return;
    }
    const isNumber = !!Number(searchTerm);
    const queryParams = {
      ...(isNumber ? { clinicID: Number(searchTerm) } : { searchTerm })
    };
    this.fetchRequest = this.httpClient
      .get<
        AdminViewAsClinicDTO[]
      >(this.baseUrl + `Clinics/GetClinics?${new URLSearchParams(queryParams as any).toString()}`)
      .subscribe(
        (result) => {
          if (isInitialLoad && this.selectedClinic) {
            return;
          }
          const results_ = result;
          this.clinics = orderby(results_, ['isActive'], ['desc']);
        },
        (error) => console.error(error)
      );
  }
  sendMessage(clinicID: number) {
    this.selectedClinic = this.clinics.find((c) => c.clinicId === clinicID);
    this.messageEvent.emit(this.selectedClinic);
  }
  onViewAsFilterStringChange(searchTerm: string) {
    this.searchTermChanged.next(searchTerm);
  }
  public selectValue(clinic: AdminViewAsClinicDTO) {
    if (this.fetchRequest) {
      this.fetchRequest.unsubscribe();
    }
    this.clinics = [clinic];
    this.selectedClinic = this.clinics[0];
    this.clinicID = this.selectedClinic.clinicId;
  }

  public navigateToClinic(clinicId: number) {
    this.router.navigate(['/clinic/' + clinicId]);
  }
}
