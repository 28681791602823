import * as moment from 'moment';

import { AfterViewInit, Input, ViewChild } from '@angular/core';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from 'src/app/_dialogs/confirm-dialog/confirm-dialog.component';
import {
  IncomingCall,
  IncomingCallsResultDTO
} from '../../DTOS/IncomingCall/IncomingCallsDTO';
import {
  ReportsViewerComponent,
  reportsViewerData
} from 'src/app/_dialogs/reports-viewer/reports-viewer.component';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

import { AdminViewAsClinicDTO } from '../../DTOS/AdminViewAsClinicDTO';
import { ApiService } from '../../api.service';
import { AuthenticationService } from '../../_services';
import { Component } from '@angular/core';
import { Howl } from 'howler';
import { IAppChatLinkedAppointment } from '../../Components/chat/chat.component';
import { IntentType } from 'src/app/DTOS/VA_Configurations/VA_Configurations';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';
import { SelectionModel } from '@angular/cdk/collections';
import { User } from '../../_models/user';
import { formatPhoneNumber } from 'src/app/Helpers/Formatters';
import { firstValueFrom } from 'rxjs';
import { UserDefinedCallStatus } from 'src/app/DTOS/IncomingCall/UserDefinedCallStatus';
import { ActivatedRoute, Router } from '@angular/router';
import { removeFalsyValues } from 'src/app/Helpers/helper-functions';
import { flatten, orderBy, uniq, uniqBy } from 'lodash';

type AllTypesIntent = {
  id: number;
  isGeneric: boolean;
  displayName: string;
};
@Component({
  selector: 'app-missed-calls',
  templateUrl: './missed-calls.component.html',
  styleUrls: ['./missed-calls.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class MissedCallsComponent implements AfterViewInit {
  User: User;
  isLoading = false;
  isPlayingAudio = false;
  searchTearm = '';
  isLoadingPermissions = false;
  hasVA_Permission = false;
  hasTwoWayMessage = false;
  expandedElement: IncomingCall | null;

  public dataSource: MatTableDataSource<IncomingCall>;
  public pageSize = 10;
  public currentPage = 1;
  public totalSize = 0;
  public screenWidth: number;
  selectedIntentTypes: AllTypesIntent[] = [];
  selectedStatuses: number[];
  shownInResultsQuestions: Array<{
    label: string;
    botQuestionId: number;
  }> = [];
  IncomingCalls: IncomingCallsResultDTO;
  listItems: IncomingCall[];
  from: Date = moment().startOf('day').toDate();
  to: Date = new Date();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  IntentTypes: IntentType[];
  intentTypes: AllTypesIntent[];
  checkedCalls: number[] = [];
  clinicId: number;
  chatAppointment: IAppChatLinkedAppointment = null;
  chatIsLoading: boolean;
  isCheckingForNewMessages: boolean;
  longPollingTimeout: any;
  selection = new SelectionModel<IncomingCall>(true, []);
  HeaderMobile: string[] = ['selectCalls'];
  userDefinedCallStatuses: UserDefinedCallStatus[];

  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private permissionSlugsService: PermissionSlugsService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.screenWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth;
    });
  }

  @Input() public ViewAs: AdminViewAsClinicDTO;
  @Input() public isStandalone: boolean;

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.router.navigate([
      'asistente-virtual/llamadas-perdidas',
      this.ViewAs.clinicId
    ]);
  }

  public removeNonNumeric = (str) => (str ? str.replace(/\D/g, '') : str);

  public statusesColors: Record<number, string>;

  loadVaShownInResultsQuestions() {
    this.API.getVaShownInResultsQuestions(this.clinicId).subscribe((r) => {
      this.shownInResultsQuestions = r;
    });
  }
  loadUserDefinedCallStatuses() {
    this.API.GetUserDefinedCallStatuses(this.clinicId, 1, 2000).subscribe(
      (response) => {
        this.userDefinedCallStatuses = response.items;
        this.statusesColors = {};
        this.userDefinedCallStatuses.forEach((status) => {
          this.statusesColors[status.userCallStatusId] = status.color;
        });
      }
    );
  }

  public updateRoute() {
    const from = moment(this.from);
    const to = moment(this.to);

    this.updateURL(
      from.isValid() ? from.format('YYYY-MM-DD') : '',
      to.isValid() ? to.format('YYYY-MM-DD') : ''
    );
  }

  updateURL(fromValue: string, toValue: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: removeFalsyValues({ from: fromValue, to: toValue }),
      queryParamsHandling: 'merge' // This will merge the new parameters with existing ones
    });
  }

  ngAfterViewInit() {
    this.route.params.subscribe((routeParams) => {
      const accountID: number = routeParams.accountID;

      this.authenticationService.currentUser.subscribe((userInfo) => {
        this.clinicId = accountID;

        this.User = userInfo;

        if (!this.clinicId) {
          this.clinicId = userInfo.clinicId;
        }

        // Get the current URL
        const currentURL = window.location.href;

        // Parse the URL to extract the query parameters
        const urlParams = new URLSearchParams(currentURL.split('?')[1]);

        // Get the "from" and "to" parameters
        const fromParam = moment(urlParams.get('from'));
        const toParam = moment(urlParams.get('to'));

        if (fromParam.isValid()) this.from = fromParam.toDate();
        if (toParam.isValid()) this.to = toParam.toDate();

        this.LoadMissedCalls();
        this.loadVaShownInResultsQuestions();
        this.LoadIntentTypes();
        this.loadUserDefinedCallStatuses();

        this.permissionSlugsService.permissionsListener.subscribe((r) => {
          this.hasVA_Permission = r.includes(PermissionSlugs.VIRTUAL_ASSISTANT);
        });

        this.API.HasTwoWayTextingEnabled(this.clinicId).subscribe(
          (r: { hasTwoWayTextingEnabled: boolean }) => {
            this.hasTwoWayMessage = r.hasTwoWayTextingEnabled;
          }
        );
      });
    });

    if (!Notification) {
      alert(
        'Las notificaciones de escritorio no están disponibles en su navegador. Prueba Google Chrome.'
      );
      return;
    }

    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }
  public baseUrl: string = '';

  DownloadAppointmentsReport(ExportType: string) {
    let link =
      this.baseUrl +
      `Reports/VaCallsReport?StartDate=${moment(this.from).format(
        'YYYY-MM-DD'
      )}&EndDate=${moment(this.to).format(
        'YYYY-MM-DD'
      )}&ExportType=${ExportType}&clinicId=${this.clinicId}`;

    if (ExportType === 'PDF') {
      let data: reportsViewerData = {
        link: link
      };
      this.dialog
        .open(ReportsViewerComponent, {
          data: data,
          width: '80%',
          disableClose: true
        })
        .afterClosed();
    } else {
      window.open(link, '_blank');
    }
  }
  private checkForNewMessages = () => {
    this.isCheckingForNewMessages = true;
    if (this.isStandalone && !this.ViewAs?.clinicId) {
      return;
    }

    const toFormat = moment(this.to).format('YYYY-MM-DD');
    const fromFormat = moment(this.from).format('YYYY-MM-DD');
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;

    const genericIntentTypes = this.selectedIntentTypes
      .filter((f) => f.isGeneric)
      .map((f) => f.id);
    const customIntentTypes = this.selectedIntentTypes
      .filter((f) => !f.isGeneric)
      .map((f) => f.id);

    this.API.GetMissedCalls(
      this.clinicId,
      this.currentPage,
      this.pageSize,
      toFormat,
      fromFormat,
      this.searchTearm,
      genericIntentTypes,
      customIntentTypes,
      this.selectedStatuses
    ).subscribe((result) => {
      return;
      // // const res = result as IncomingCallsResultDTO;
      // // const itemsWithUnreadMessages = res.items.filter(
      // //   (r) => r.unreadSmsResponsesCount
      // // );
      // // if (itemsWithUnreadMessages.length) {
      // //   this.notifyNewMessages();
      // //   itemsWithUnreadMessages.forEach((call) => {
      // //     const missedCallInDatasource = this.dataSource.data.find(
      // //       (datasourceCall) => datasourceCall.callID === call.callID
      // //     );
      // //     if (missedCallInDatasource) {
      // //       missedCallInDatasource.unreadSmsResponsesCount =
      // //         call.unreadSmsResponsesCount;
      // //     }
      // //   });
      // // }
      // // this.isCheckingForNewMessages = false;
      // // this.setLongPolling();
    });
  };
  public setLongPolling() {
    if (this.longPollingTimeout) {
      clearTimeout(this.longPollingTimeout);
    }
    if (this.dataSource.data.some((d) => d.appointmentId)) {
      this.longPollingTimeout = setTimeout(() => {
        this.checkForNewMessages();
      }, 60 * 1000);
    }
  }
  public async LoadIntentTypes() {
    const genericIntents = await firstValueFrom(
      this.API.GetIntentTypes(this.clinicId)
    );

    const customIntents = await firstValueFrom(
      this.API.GetCustomIntentTypes(this.clinicId)
    );

    this.intentTypes = [];

    genericIntents.forEach((gi) => {
      this.intentTypes.push({
        isGeneric: true,
        displayName: gi.displayName,
        id: gi.intentID
      });
    });

    customIntents.forEach((ci) => {
      this.intentTypes.push({
        isGeneric: false,
        displayName: ci.customIntentName,
        id: ci.customIntentId
      });
    });
  }

  missedCallsCommentsCounts: { [k: number]: number };

  public async LoadCustomerNotesCounts(callsIds: number[]) {
    const countsResultsArray = await firstValueFrom(
      this.API.GetNotesCounts(callsIds, 4)
    );

    const counter: { [k: number]: number } = {};
    countsResultsArray.forEach(({ entityId, count }) => {
      counter[entityId] = count;
    });

    this.missedCallsCommentsCounts = counter;
  }

  public getCallQuestionResponse(call: IncomingCall, botQuestionId: number) {
    return call.questionsResponses.find(
      (q) => q.botQuestionId === botQuestionId
    )?.response;
  }

  public async LoadMissedCalls() {
    this.isLoading = true;
    if (this.isStandalone && !this.ViewAs?.clinicId) {
      return;
    }

    const toFormat = moment(this.to).format('YYYY-MM-DD');
    const fromFormat = moment(this.from).format('YYYY-MM-DD');
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    this.checkedCalls = [];

    const genericIntentTypes = this.selectedIntentTypes
      .filter((f) => f.isGeneric)
      .map((f) => f.id);
    const customIntentTypes = this.selectedIntentTypes
      .filter((f) => !f.isGeneric)
      .map((f) => f.id);

    this.API.GetMissedCalls(
      this.clinicId,
      this.currentPage,
      this.pageSize,
      toFormat,
      fromFormat,
      this.searchTearm,
      genericIntentTypes,
      customIntentTypes,
      this.selectedStatuses
    ).subscribe(
      (result) => {
        const res = result;
        res.items.forEach((group) => {
          group.items.forEach((i) => {
            i.fromPhoneNumber = formatPhoneNumber(i.fromPhoneNumber);

            const dLocal = moment.utc(i.createDate).local();

            i._display_createDate = dLocal.format('DD MMMM [,] YYYY');
            i._display_createTime = dLocal.format('hh:mm A');

            if (i.appointmentRequests?.length) {
              i.callerName = i.appointmentRequests[0].patientName;
            }

            if (i.callDurationInSeconds < 60) {
              i._callDurationInMinutes = 1;
            } else if (i.callDurationInSeconds > 60) {
              i._callDurationInMinutes = Math.ceil(
                i.callDurationInSeconds / 60
              );
            } else if (!i.callDurationInSeconds) {
              i._callDurationInMinutes = 0;
            }

            const itentsSet = [
              ...new Set(
                i.petitions
                  .filter((p) => p.intentObj?.intentID)
                  .map((p) => p.intentObj?.intentID)
              )
            ].map(
              (intentId) =>
                i.petitions.find((p) => p.intentObj?.intentID === intentId)
                  .intentObj
            );

            const customItentsSet = [
              ...new Set(
                i.petitions
                  .filter((p) => p.customIntent?.customIntentId)
                  .map((p) => p.customIntent?.customIntentId)
              )
            ].map((customIntentId) => {
              const customIntent = i.petitions.find(
                (p) => p.customIntent?.customIntentId === customIntentId
              ).customIntent;

              return {
                displayName: customIntent.customIntentName,
                backgroundColor: customIntent.secondaryColor,
                color: customIntent.primaryColor
              };
            });

            i.distinctIntents = [...itentsSet, ...(customItentsSet as any)];
          });
        });

        const itemsArray: IncomingCall[] = [];

        res.items.forEach((group) => {
          const [pivot, ...rest] = group.items;
          const dLocal = moment.utc(group.createDate).local();

          pivot._display_createDate = dLocal.format('DD MMMM [,] YYYY');
          pivot._display_createTime = dLocal.format('hh:mm A');

          pivot.createDate = group.createDate;

          const relatedCalls = rest || [];
          pivot.relatedCalls = orderBy(
            relatedCalls,
            (el) => moment(el.createDate).toDate(),
            'desc'
          );

          pivot._relatedApptRequests = flatten([
            ...(pivot.appointmentRequests || []),
            ...(pivot.relatedCalls || []).map(
              (call) => call.appointmentRequests
            )
          ]);

          const restPettitions = flatten(rest.map((p) => p.petitions));

          pivot.petitions = [...pivot.petitions, ...restPettitions];

          pivot.distinctIntents = flatten([
            ...pivot.distinctIntents,
            ...pivot.relatedCalls.map((a) => a.distinctIntents)
          ]);

          pivot.distinctIntents = uniqBy(
            pivot.distinctIntents,
            (el) => el.displayName
          );

          itemsArray.push(pivot);
        });

        // const itemsWithUnreadMessages = flatten(
        //   res.items
        //     .map((group) => group.items)
        //     .filter((r) => r.map((d) => d.unreadSmsResponsesCount))
        // );
        // const itemsWithoutUnreadMessages = flatten(
        //   res.items
        //     .map((group) => group.items)
        //     .filter((r) => !r.map((d) => d.unreadSmsResponsesCount))
        // );

        const items = [
          ...itemsArray
          // ...itemsWithUnreadMessages,
          // ...itemsWithoutUnreadMessages,
        ];

        this.LoadCustomerNotesCounts(items.map((a) => a.callID));
        this.dataSource = new MatTableDataSource<IncomingCall>(items);
        this.IncomingCalls = res;
        this.totalSize = this.IncomingCalls.totalCount;
        this.pageSize = this.IncomingCalls.pageSize;
        this.currentPage = this.IncomingCalls.pageIndex;
        this.listItems = items; //this.IncomingCalls.items;
        this.isLoading = false;
        this.paginator._intl.itemsPerPageLabel = 'Llamadas por página';
        // if (itemsWithUnreadMessages.length) {
        //   this.notifyNewMessages();
        // }
        if (this.longPollingTimeout) {
          clearTimeout(this.longPollingTimeout);
        }
        this.setLongPolling();
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  public getCallRelatedCallsHistory = (call: IncomingCall) =>
    orderBy(
      [...call.relatedCalls, call],
      (el) => moment(el.createDate).toDate(),
      'desc'
    );

  public hasNotificationPermissions = () =>
    Notification.permission === 'granted';
  public requestNotificationsPermission = () =>
    Notification.requestPermission();
  notifyNewMessages() {
    const howl = new Howl({
      src: '/audio/sms-notification-sound.mp3'
    });
    howl.play();

    this._snackBar.open(
      'Notificacion: Tienes nuevos mensajes de texto ',
      'OK ',
      {
        duration: 70000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'success-dialog'
      }
    );
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    } else {
      this.triggerNotification();
    }
  }
  public triggerNotification() {
    const notification = new Notification('Tienes nuevos mensajes de texto ', {
      icon: '/images/logo_footer.png',
      body: 'Haz recibido nuevos mensajes de texto para una llamada perdida en el asistente virtual.'
    });
    notification.onclick = function () {
      window.open(
        'https://app.citamed.net/asistente-virtual/llamadas-perdidas'
      );
    };
  }
  public handlePage(e: any) {
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.LoadMissedCalls();
  }
  public onChatButtonClicked(element: IncomingCall) {
    this.chatIsLoading = true;
    if (element) {
      element.unreadSmsResponsesCount = 0;
    }

    if (this.hasTwoWayMessage) {
      if (element._AppChatLinkedAppointment) {
        this.chatAppointment = structuredClone(
          element._AppChatLinkedAppointment
        );
        this.chatIsLoading = false;
      } else {
        this.API.VA_GetAssociatedAppointment(
          element.callID,
          this.clinicId
        ).subscribe(
          (result: { appointmentId: number; patientPhoneNumber: string }) => {
            this.chatAppointment = {
              appointmentId: result.appointmentId,
              patientPhoneNumber: result.patientPhoneNumber,
              clinicId: this.clinicId
            };
            element._AppChatLinkedAppointment = structuredClone({
              appointmentId: result.appointmentId,
              patientPhoneNumber: result.patientPhoneNumber,
              clinicId: this.clinicId
            });
            const datasourceElement = this.dataSource.data.find(
              (c) => c.callID === element.callID
            );
            if (datasourceElement) {
              datasourceElement.appointmentId = result.appointmentId;
              if (!this.longPollingTimeout) {
                this.setLongPolling();
              }
            }
            this.chatIsLoading = false;
          }
        );
      }
    }
  }
  public clearFilter() {
    this.searchTearm = '';
    this.from = moment().startOf('day').toDate();
    this.to = new Date();
    this.selectedIntentTypes = [];
    this.checkedCalls = [];
    this.selectedStatuses = [];
    this.unselectAll();
    this.LoadMissedCalls();
  }

  public GetSelectedCalls() {
    return this.listItems.filter((a) => a.checked).length;
  }

  public unselectAll() {
    for (const i of this.listItems) {
      i.checked = false;
    }
    this.checkedCalls = [];
  }

  public selectUnselectAll(event) {
    if (event.checked) {
      this.dataSource.data.forEach((row) => {
        this.checkedCalls.push(row.callID);
        for (const i of this.listItems) {
          i.checked = true;
        }
      });
    } else {
      for (const i of this.listItems) {
        i.checked = false;
      }
      this.checkedCalls = [];
    }
  }

  onCheckboxChange(e, element) {
    if (e.checked) {
      this.checkedCalls.push(element.callID);
      element.checked = true;
    } else {
      element.checked = false;
      const index: number = this.checkedCalls.indexOf(element.callID);
      this.checkedCalls.splice(index, 1);
    }
  }

  deleteCalls() {
    const selected = this.GetSelectedCalls();
    const message = `¿Estas seguro de querer eliminar ${selected} llamadas?`;

    const dialogData = new ConfirmDialogModel(
      'Confirmación',
      message,
      'Si',
      'Cancelar'
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.isLoading = true;
        this.API.DeleteMissedCall(this.clinicId, this.checkedCalls).subscribe(
          () => {
            this.isLoading = false;
            this.LoadMissedCalls();
          },
          (error) => {
            this.showError(error);
            this.isLoading = false;
          }
        );
      }
    });
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null)
    });
  }

  public updateIncomingCall(call: IncomingCall) {
    if (!call.$$is_dirty) {
      return;
    }
    this.API.UpdateIncomingCall(this.clinicId, call.callID, {
      CallerName: call.callerName,
      UserCallStatusId: call.userCallStatusId
    }).subscribe((res) => {
      call.$$is_dirty = false;
    });
  }
  getDisplayedColumns() {
    const columns = [
      'selectCalls',
      'callerName',
      'geoReferencing',
      'petitions',
      'createDate',
      ...this.shownInResultsQuestions.map((q) => q.label),
      'acciones'
    ].filter((e) => e);

    return columns;
  }
  getDisplayedHeader() {
    const columns = this.getDisplayedColumns();
    return this.screenWidth > 850 ? columns : this.HeaderMobile;
  }

  ExpandedDetail(element: IncomingCall) {
    this.expandedElement = this.expandedElement === element ? null : element;
  }
}
