// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.passwd-recover-container {
  min-height: 100vh !important;
  background: linear-gradient(90deg, rgb(27, 103, 102) 0%, rgb(53, 162, 164) 71%, rgb(35, 119, 117) 100%);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBhc3N3b3JkLXJlY292ZXIuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSw0QkFBQTtFQUNBLHVHQUFBO0VBTUEsMEJBQUE7RUFDQSxxQ0FBQTtBQUpGOztBQU1BO0VBQ0U7SUFDRSwyQkFBQTtFQUhGO0VBS0E7SUFDRSw2QkFBQTtFQUhGO0VBS0E7SUFDRSwyQkFBQTtFQUhGO0FBQ0YiLCJmaWxlIjoicGFzc3dvcmQtcmVjb3Zlci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5wYXNzd2QtcmVjb3Zlci1jb250YWluZXIge1xuICBtaW4taGVpZ2h0OiAxMDB2aCAhaW1wb3J0YW50O1xuICBiYWNrZ3JvdW5kOiBsaW5lYXItZ3JhZGllbnQoXG4gICAgOTBkZWcsXG4gICAgcmdiYSgyNywgMTAzLCAxMDIsIDEpIDAlLFxuICAgIHJnYmEoNTMsIDE2MiwgMTY0LCAxKSA3MSUsXG4gICAgcmdiYSgzNSwgMTE5LCAxMTcsIDEpIDEwMCVcbiAgKTtcbiAgYmFja2dyb3VuZC1zaXplOiA0MDAlIDQwMCU7XG4gIGFuaW1hdGlvbjogZ3JhZGllbnQgMTVzIGVhc2UgaW5maW5pdGU7XG59XG5Aa2V5ZnJhbWVzIGdyYWRpZW50IHtcbiAgMCUge1xuICAgIGJhY2tncm91bmQtcG9zaXRpb246IDAlIDUwJTtcbiAgfVxuICA1MCUge1xuICAgIGJhY2tncm91bmQtcG9zaXRpb246IDEwMCUgNTAlO1xuICB9XG4gIDEwMCUge1xuICAgIGJhY2tncm91bmQtcG9zaXRpb246IDAlIDUwJTtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/Pages/password-recover/password-recover.component.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,uGAAA;EAMA,0BAAA;EACA,qCAAA;AAJF;;AAMA;EACE;IACE,2BAAA;EAHF;EAKA;IACE,6BAAA;EAHF;EAKA;IACE,2BAAA;EAHF;AACF;AACA,ggCAAggC","sourcesContent":[".passwd-recover-container {\n  min-height: 100vh !important;\n  background: linear-gradient(\n    90deg,\n    rgba(27, 103, 102, 1) 0%,\n    rgba(53, 162, 164, 1) 71%,\n    rgba(35, 119, 117, 1) 100%\n  );\n  background-size: 400% 400%;\n  animation: gradient 15s ease infinite;\n}\n@keyframes gradient {\n  0% {\n    background-position: 0% 50%;\n  }\n  50% {\n    background-position: 100% 50%;\n  }\n  100% {\n    background-position: 0% 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
