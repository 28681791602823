import { Input, OnChanges, SimpleChanges } from '@angular/core';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../../../app/api.service';

import { User } from '../../../app/_models';
import { AdminViewAsClinicDTO } from '../../../app/DTOS/AdminViewAsClinicDTO';
import {
  ClicPermissionDTO,
  ClinicPermissionDTO
} from '../../../app/DTOS/Permission/PermissionDTO';
import { LoadingDialogComponent } from '../../../app/loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-clinic-permissions',
  templateUrl: './clinic-permissions.component.html',
  styleUrls: ['./clinic-permissions.component.scss']
})
export class ClinicPermissionsComponent implements AfterViewInit, OnChanges {
  constructor(
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private API: ApiService
  ) {}
  isLoading: boolean;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  Permissions: ClinicPermissionDTO[];
  ClinicPermissions: ClinicPermissionDTO[];
  ListClinicPermission: ClicPermissionDTO[];
  baseUrl: string;
  public User: User;
  dataSource: MatTableDataSource<ClinicPermissionDTO>;
  public _isActive_updt: boolean;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['slug', 'permissionName', 'clinicPermissionId'];
  searchTearm: string;

  @Input() public ViewAs: AdminViewAsClinicDTO;
  @Input() public isStandalone: boolean;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ViewAs?.previousValue !== changes.ViewAs?.currentValue) {
      this.FetchData();
    }
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.FetchData();
  }

  async FetchData() {
    await this.FetchPermissions();
  }

  public async FetchPermissions() {
    this.isLoading = true;

    await this.API.GetAllPermissions(false)
      .toPromise()
      .then(
        (result) => {
          const res = result as ClinicPermissionDTO[];
          this.Permissions = res;
          this.isLoading = false;
          this.FetchClinicPermissions();
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
  }

  public async FetchClinicPermissions() {
    this.isLoading = true;

    if (this.isStandalone && !this.ViewAs?.clinicId) {
      return;
    }
    const clinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;

    await this.API.GetAllClinicPermissions(clinicID).subscribe(
      (result) => {
        const res = result as ClinicPermissionDTO[];
        this.ClinicPermissions = res;

        this.Permissions.forEach((permission) => {
          const doesClinicHaveIt = this.ClinicPermissions.some(
            (e) => e.permissionId === permission.permissionId
          );
          permission._enabled = doesClinicHaveIt;
        });

        this.dataSource = new MatTableDataSource<ClinicPermissionDTO>(
          this.Permissions
        );
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.FetchData();
  }

  public async Save(clicPermissionDTO: ClinicPermissionDTO) {
    if (clicPermissionDTO._enabled === false) {
      this.deletePermission(clicPermissionDTO);
    } else {
      this.createPermission(clicPermissionDTO);
    }
  }

  private async createPermission(clicPermissionDTO: ClinicPermissionDTO) {
    if (this.isStandalone && !this.ViewAs?.clinicId) {
      return;
    }
    const clinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    clicPermissionDTO.clinicId = clinicID;

    await this.API.CreateClinicPermission(clicPermissionDTO).subscribe(
      () => {
        this.isLoading = false;
        this._snackBar.open('¡Listo!, se ha activado el permiso.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog'
        });
      },
      (f) => {
        this.isLoading = false;
        console.error('ERROR AL AGREGAR PERMISO', f);
      }
    );
  }

  private async deletePermission(clicPermissionDTO: ClinicPermissionDTO) {
    const clinicPermission = this.ClinicPermissions.find(
      (c) => c.permissionId === clicPermissionDTO.permissionId
    );
    await this.API.DeleteClinicPermissions(
      clinicPermission.clinicPermissionId
    ).subscribe(
      () => {
        this.isLoading = false;
        this._snackBar.open('¡Listo!, se desactivado el permiso.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog'
        });
      },
      (f) => {
        this.isLoading = false;
        console.error('ERROR AL actualizar PERMISO', f);
      }
    );
  }
}
