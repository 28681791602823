import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import cloneDeep from 'lodash-es/cloneDeep';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from '../../api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from '../../_dialogs/confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from '../../DTOS/AdminViewAsClinicDTO';
import {
  VirtualAssistantServicesDTO,
  VirtualAssistantServicesResultDTO
} from '../../DTOS/VA_Configurations/VA_Configurations';

import { User } from '../../_models';
import { AuthenticationService } from '../../_services';
import {
  LoadingDialogComponent,
  LoadingDialogModel
} from '../../loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-va-services',
  templateUrl: './va-services.component.html',
  styleUrls: ['./va-services.component.scss']
})
export class VaServicesComponent implements AfterViewInit, OnChanges {
  public User: User;
  slidePanelOpen: Boolean = false;
  isSubmitting: Boolean = false;
  isLoading: Boolean = false;
  sidepanelItems: VirtualAssistantServicesDTO = {} as any;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  @Input() public accountID: number;
  @Input() public isStandalone: boolean;
  public pageSize = 10;
  public currentPage = 1;
  public totalSize = 0;
  service: VirtualAssistantServicesResultDTO;
  listItems: VirtualAssistantServicesDTO[];
  searchTearm = '';
  public dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'serviceID',
    'serviceName',
    'serviceDescription',
    'createDate',
    'tags',
    'Actions'
  ];
  from: Date;
  to: Date;
  isEditing: boolean;
  showForm: boolean;
  account: any;
  backup_account: any;

  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar
  ) {}

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.LoadLists();
  }

  ngOnChanges(): void {
    this.User = this.authenticationService.currentUserValue;
    this.LoadLists();
  }

  public openAddNewSidePanel() {
    this.slidePanelOpen = true;
    this.sidepanelItems = {} as any;
  }
  public openEditSidePanel(item) {
    this.slidePanelOpen = true;
    this.sidepanelItems = cloneDeep(item);
  }
  public closeAddNewSidePanel() {
    this.slidePanelOpen = false;
    this.sidepanelItems = {} as any;
    this.LoadLists();
  }

  public LoadLists() {
    this.isLoading = true;
    const accountId = this.accountID;
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;

    this.API.LoadListServicesVirtualAssistant(
      accountId,
      this.currentPage,
      this.pageSize,
      this.searchTearm
    ).subscribe(
      (result) => {
        const res = result as VirtualAssistantServicesResultDTO;
        this.listItems = res.items;
        for (let i = 0; i < this.listItems.length; i++) {
          this.listItems[i].createDateView = moment(
            this.listItems[i].createDate
          ).format('DD MMMM [del] YYYY');
        }
        this.dataSource = new MatTableDataSource<VirtualAssistantServicesDTO>(
          this.listItems
        );
        this.dataSource.paginator = this.paginator;
        this.service = res;
        this.totalSize = this.service.totalCount;
        this.pageSize = this.service.pageSize;
        this.currentPage = this.service.pageIndex;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);

        this.showError(error);
      }
    );
  }

  public Add() {
    this.isSubmitting = true;
    this.sidepanelItems.accountID = this.accountID;
    this.API.CreateServiceVirtualAssistant(
      this.sidepanelItems.accountID,
      this.sidepanelItems
    ).subscribe(() => {
      this.LoadLists();
      this.isSubmitting = false;

      this.closeAddNewSidePanel();
      this._snackBar.open(
        `¡Listo!, el servicio se creado correctamente. `,
        'OK ',
        {
          duration: 70000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog'
        }
      );
    });
  }

  public Update() {
    this.isSubmitting = true;
    this.API.UpdateServiceVirtualAssistant(
      this.sidepanelItems.accountID,
      this.sidepanelItems
    ).subscribe(() => {
      this.LoadLists();
      this.isSubmitting = false;
      this.closeAddNewSidePanel();
      this._snackBar.open(
        `¡Listo!, el servicio fue actualizado correctamente. `,
        'OK ',
        {
          duration: 70000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog'
        }
      );
    });
  }

  public Delete(event: Event, service: VirtualAssistantServicesDTO) {
    if (this.User?.isAdmin === false) {
      return;
    }
    const accountID = this.accountID;
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer eliminar este registro?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, eliminar',
      rejectLabel: 'Cancelar',
      accept: () =>
        this.API.DeleteServiceVirtualAssistant(
          accountID,
          service.serviceID
        ).subscribe((r) => {
          this.LoadLists();
        }),
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  private showError(error: any) {
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null)
    });
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.LoadLists();
  }

  public clearFilter() {
    this.searchTearm = '';
    this.from = new Date();
    this.to = new Date();
  }

  public discardEditionChanges = () => {
    this.isEditing = false;
    this.showForm = false;
    this.account = cloneDeep(this.backup_account);
    this.backup_account = {} as any;
    this.LoadLists();
  };
}
