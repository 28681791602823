import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
/** UpdateAppointmentCallStatus component*/
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { IAppointmentListItem } from '../../DTOS/Appointments/AppointmentListItem';
import { CallStatusGET_DTO } from '../../DTOS/CallStatus/DTOS';
import { Doctor } from '../../DTOS/Doctors/DoctorDTO';
import { ReminderSetDto } from 'src/app/DTOS/ReminderSets/ReminderSetDto';

export interface UpdateAppointmentDialogData {
  appointment: IAppointmentListItem;
  clinicID: number;
  callStatuses: CallStatusGET_DTO[];
}
export interface UpdateAppointmentDto {
  appointmentId: number;
  clinicId: number;
  doctorId: number;
  reminderSetId: number;
  appointmentDate: Date;
  appointmentTime: string;
  patientName: string;
  patientPhoneNumber: string;
  patientAltPhoneNumber: string;
  callStatusId: string;
  callDate: Date;
  callTime: string;
}
@Component({
  selector: 'app-update-appointment-dialog',
  templateUrl: './update-appointment.component.html',
  styleUrls: ['./update-appointment.component.scss']
})
export class UpdateAppointmentDialogComponent implements AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UpdateAppointmentDialogData,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UpdateAppointmentDialogComponent>,
    @Inject('BASE_URL') baseUrl: string,
    http: HttpClient
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;

    this.isStatusChangeDisabled =
      data.appointment.callStatusId?.toLowerCase().trim() !== 'pending';

    this.appointmentData = {
      appointmentId: data.appointment.appointmentId,
      callStatusId: data.appointment.callStatusId,
      appointmentDate: data.appointment._appointmentDateUnformatted,
      clinicId: data.appointment.clinicId,
      doctorId: data.appointment.doctorId,
      reminderSetId: data.appointment.reminderSetId,
      patientName: data.appointment.patientName,
      patientAltPhoneNumber: data.appointment.patientAltPhoneNumber,
      patientPhoneNumber: data.appointment.patientPhoneNumber,
      callDate: data.appointment._lastCallUnformatted,
      appointmentTime: moment(
        data.appointment._appointmentDateUnformatted
      ).format('HH:mm'),
      callTime: moment(data.appointment._lastCallUnformatted).format('HH:mm')
    };
  }
  public isStatusChangeDisabled: boolean;
  minSelectedDate: Date = new Date();
  public PendingCallStatusId = 'Pending';
  appointmentData: UpdateAppointmentDto;
  httpClient: HttpClient;
  isLoading = false;
  public doctors: Doctor[];
  public ReminderSets: ReminderSetDto[] = new Array<ReminderSetDto>();
  baseUrl: string;
  @ViewChild('AppointmentsDatePicker') AppointmentsDatePicker;
  @ViewChild('AppointmentsCallDatePicker') AppointmentsCallDatePicker;
  public selectedCallStatus: CallStatusGET_DTO;
  ngAfterViewInit() {
    this.FetchReminderSets();
    this.FetchDoctors();
  }
  public onDismiss() {
    this.dialogRef.close(false);
  }
  public FetchReminderSets() {
    this.httpClient
      .get<
        ReminderSetDto[]
      >(this.baseUrl + `ReminderSet/GetAll?ClinicID=` + this.data.clinicID)
      .subscribe(
        (result) => {
          const res = result as ReminderSetDto[];
          this.ReminderSets = res;
        },
        (error) => console.error(error)
      );
  }
  public FetchDoctors() {
    const ClinicID = this.data.clinicID;

    this.httpClient
      .get<Doctor[]>(this.baseUrl + 'Doctors/GetAll?ClinicID=' + ClinicID)
      .subscribe(
        (result) => {
          const res = result as Doctor[];
          this.doctors = res;
        },
        (error) => console.error(error)
      );
  }
  public SaveChanges() {
    this.isLoading = true;
    try {
      this.httpClient
        .post(
          this.baseUrl + `Appointments/UpdateAppointment`,
          this.appointmentData
        )
        .subscribe(
          (result) => {
            this.isLoading = false;
            this.dialogRef.close(true);
            window.setTimeout(() => {
              const snackBar = this._snackBar.open(
                `¡Listo!, la cita ha sido actualizada... `,
                'OK ',
                {
                  duration: 10000,
                  horizontalPosition: 'left',
                  verticalPosition: 'bottom',
                  panelClass: 'success-dialog'
                }
              );
            }, 1000);
          },
          (e) => {
            this.isLoading = false;
            console.error('ERROR', e);
            const snackBar = this._snackBar.open(
              `¡Oops!, ha ocurrido un error... `,
              'OK ',
              {
                duration: 10000,
                horizontalPosition: 'left',
                verticalPosition: 'bottom',
                panelClass: 'success-dialog'
              }
            );
          }
        );
    } catch (e) {
      this.isLoading = false;
      this.dialogRef.close(false);
      console.error('ERROR', e);
    }
  }
}
