import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CallStatusViewModel } from 'src/app/DTOS/CallStatus/DTOS';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from 'src/app/_dialogs/confirm-dialog/confirm-dialog.component';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import { LoadingDialogComponent } from 'src/app/loading-dialog/loading-dialog.component';
import GenericList from 'src/app/DTOS/Generics/GenericList';
import { debounce } from 'lodash';
import { Router } from '@angular/router';
import { AdminViewAsClinicDTO } from 'src/app/DTOS/AdminViewAsClinicDTO';

@Component({
  selector: 'app-call-statuses',
  templateUrl: './call-statuses.component.html',
  styleUrls: ['./call-statuses.component.scss']
})
export class CallStatusesComponent implements OnInit {
  public showFullText = false;
  public pageSize = 15;
  public currentPage = 1;
  public User: User;
  @Input() public isStandalone: boolean;
  @Input() public ViewAs: AdminViewAsClinicDTO;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  isLoading = false;
  searchTerm = '';
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  CallStatusestsList: GenericList<CallStatusViewModel>;
  dataSource: MatTableDataSource<CallStatusViewModel>;
  listItems: CallStatusViewModel[];
  public clinicId: number;
  public totalSize = 0;
  displayedColumns: string[] = [
    'callStatusId',
    'callStatusName',
    'description',
    'smallIconFilename',
    'mediumIconFilename',
    'language',
    'action'
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
    });
    this.LoadSCallStatuses();
  }

  async onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.clinicId = this.ViewAs.clinicId;
  }

  public goToCallStatusDetails = (callStatusId?: number) => {
    if (callStatusId) {
      const route = `admin/call-statuses/details/`;
      this.router.navigate([route, callStatusId]);
    } else {
      this.router.navigate(['admin/call-statuses/create']);
    }
  };

  public debouncedLoadSCallStatuses = debounce(
    () => this.LoadSCallStatuses(),
    1500,
    {}
  );

  public async LoadSCallStatuses() {
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    this.API.GetAllCallStatusList(
      this.currentPage,
      this.pageSize,
      this.searchTerm
    ).subscribe(
      (r) => {
        let res = r as GenericList<CallStatusViewModel>;
        this.listItems = res.items;
        this.dataSource = new MatTableDataSource<CallStatusViewModel>(
          this.listItems
        );
        this.CallStatusestsList = res;
        this.dataSource.paginator = this.paginator;
        this.totalSize = this.CallStatusestsList.totalCount;
        this.pageSize = this.CallStatusestsList.pageSize;
        this.currentPage = this.CallStatusestsList.pageIndex;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.hideLoading();
        this.showError(error);
      }
    );
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null)
    });
  }

  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.LoadSCallStatuses();
  }
}
