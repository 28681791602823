import { Author } from '../CustomerNotes/CustomerNote';

export interface Conversation {
  conversationId: number;
  createDate: string;
  name: any;
  number: string;
  author: Author;
  status: ConversationStatus;
  unreadSmsResponsesCount: number;
  lastMessageDateFormatted?: string;
  lastMessage: {
    messageContent: string;
    createDate: Date;
    direction: string;
  };
}

export enum ConversationSource {
  CHAT = 1,
  ENGINE = 2,
  VIRTUAL_ASSISTANT = 3,
  DIGITAL_WAITING_LIST = 4
}

export interface CreateConversationDto {
  patientRecordId?: number | null;
  clinicID: number;
  name: string;
  number: string;
  source?: ConversationSource | null;
}

export interface SendConversationMessage {
  messageContent: string;
  conversationId: number;
}

export enum ConversationStatus {
  ACTIVE = 1,
  ARCHIVED = 2
}

// Define the type for the array elements
export type ConversationStatusCount = {
  status: ConversationStatus;
  count: number;
};
