import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { orderBy } from 'lodash';
import moment from 'moment';
import { VaCallsSummary } from 'src/app/DTOS/Admin/VaCallsSummary';
import { ApiService } from 'src/app/api.service';

const DATE_ONLY_FORMAT = 'YYYY-MM-DD';
@Component({
  selector: 'app-admin-va-stats',
  templateUrl: './admin-va-stats.component.html',
  styleUrls: ['./admin-va-stats.component.scss']
})
export class AdminVaStatsComponent implements OnInit, OnChanges {
  constructor(private API: ApiService) {}

  public from: Date = moment().startOf('month').toDate();
  public to: Date = moment().endOf('month').toDate();
  public page: number = 1;
  public pageSize: number = 2000;
  public searchTerm: string = '';
  public isLoading: boolean = true;

  smsDataSource: VaCallsSummary[];
  displayedColumns: string[] = [
    'Clinica',
    'Última llamada',
    'Cantidad de llamadas',
    'Act'
  ];

  ngOnChanges(changes: SimpleChanges): void {
    this.load();
  }

  ngOnInit(): void {
    this.load();
  }

  public load() {
    if (!this.from || !this.to) {
      return;
    }

    this.API.GetVaStatuses(
      this.from,
      this.to,
      this.page,
      this.pageSize
    ).subscribe((r) => {
      r.items.forEach((i) => {
        if (moment(i.lastCall).isBefore(moment('2020-01-01'))) {
          i.lastCall = null;
        } else {
          const lastCall = moment.utc(i.lastCall).local();

          i._lastCallFormatted = lastCall.format(DATE_ONLY_FORMAT);
          i._lastCallTimeSince = lastCall.fromNow();
        }

        const lastCMnt = moment(i.lastCall || '2020-01-01').startOf('day');

        i._lastCallDifFromNowInMinutes = moment().diff(lastCMnt, 'minutes');

        i._lastCallDifFromNowInDays = moment().diff(lastCMnt, 'days');
      });
      r.items = orderBy(r.items, ['_lastCallDifFromNowInMinutes'], 'desc');
      this.smsDataSource = r.items;
      this.isLoading = false;
    });
  }

  sortData(sort: Sort) {
    this.smsDataSource = orderBy(
      this.smsDataSource,
      [sort.active],
      sort.direction === 'asc' ? 'asc' : 'desc'
    );
  }
}
