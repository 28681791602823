import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from 'src/app/api.service';
import {
  CustomerNote,
  GetCustomerNoteDTO
} from 'src/app/DTOS/CustomerNotes/CustomerNote';
import GenericList from 'src/app/DTOS/Generics/GenericList';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from 'src/app/_dialogs/confirm-dialog/confirm-dialog.component';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';

type CustomerNoteEntityType = {
  PatientRecord: 1;
  BookingsAppointment: 2;
  ConfirmationsAppointment: 3;
};

type ListItem = GetCustomerNoteDTO & {
  _formattedDate: string;
  _isEditing: boolean;
};

@Component({
  selector: 'app-customer-notes',
  templateUrl: './customer-notes.component.html',
  styleUrls: ['./customer-notes.component.scss']
})
export class CustomerNotesComponent implements OnInit {
  notesList: GenericList<ListItem>;

  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    public dialog: MatDialog
  ) {
    moment.locale('es');
    this.authenticationService.currentUser.subscribe((u) => {
      this.user = u;
    });
  }

  user: User;
  @Input() public entityType: number;
  @Input() public entityId: number;
  @Input() public clinicId: number;
  @Input() public showOnlyInputField: boolean = false;

  public page: number = 1;
  public quantity: number = 40;
  public newNoteContent: string;

  ngOnInit(): void {
    this.loadNotes();
  }

  @Output() customerNoteCreated = new EventEmitter<GetCustomerNoteDTO>();

  public DeleteNote(note: ListItem) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: new ConfirmDialogModel(
        '¿Estas seguro/a de querer eliminar esta nota?',
        'Esta acción no podrá ser revertida.',
        'Okay',
        'Cancelar'
      )
    });
    dialogRef.afterClosed().subscribe((response: Boolean) => {
      if (response) {
        this.API.DeleteCustomerNote(note.customerNoteId).subscribe((r) => {
          this.loadNotes();
        });
      }
    });
  }

  public UpdateCustomerNote(note: CustomerNote) {
    this.API.UpdateCustomerNote(note).subscribe((r) => {
      this.loadNotes();
    });
  }

  public loadNotes() {
    if (this.showOnlyInputField) {
      return;
    }
    const parameters = [this.quantity, this.entityId, this.entityType];
    if (!parameters.every((p) => p)) {
      return;
    }
    this.API.GetCustomerNotes(
      this.page,
      this.quantity,
      this.entityId,
      this.entityType
    ).subscribe((r) => {
      const list = {
        ...r,
        items: r.items.map((item) => ({
          ...item,
          _isEditing: false,
          _formattedDate: moment
            .utc(item.createDate)
            .local()
            .format('dddd DD MMMM yyyy[,] hh:mm a')
        }))
      };
      this.notesList = list;
      r.items;
    });
  }

  public CreateCustomerNote() {
    const dto = window.structuredClone({
      entityId: this.entityId,
      entityType: this.entityType,
      content: this.newNoteContent,
      clinicId: this.clinicId,
      isPinned: false
    });

    this.newNoteContent = '';

    this.API.CreateCustomerNote(dto).subscribe((r) => {
      this.loadNotes();
      if (this.customerNoteCreated) {
        this.customerNoteCreated.emit(r);
      }
    });
  }
}
