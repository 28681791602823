import { Component, Input, OnInit } from '@angular/core';
import { ApiService, GetUserPermissionDto } from '../api.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-user-permissions-table',
  templateUrl: './user-permissions-table.component.html',
  styleUrl: './user-permissions-table.component.scss'
})
export class UserPermissionsTableComponent implements OnInit {
  @Input() public userId: number;
  @Input() public userName: string;

  permissions: GetUserPermissionDto[];

  constructor(private API: ApiService) {}

  ngOnInit(): void {
    this.getItems();
  }

  public async getItems() {
    const permissions: GetUserPermissionDto[] = await firstValueFrom(
      this.API.getUserPermissions(this.userId)
    );
    this.permissions = permissions;
  }

  public onToggle = async (permissionId: number) => {
    try {
      await firstValueFrom(
        this.API.ToggleUserPermission(this.userId, permissionId)
      );
    } catch (error) {
      await this.getItems();
    }
  };
}
