// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-appointments-stats {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFkbWluLWFwcG9pbnRtZW50cy1zdGF0cy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtJQUFBLGtCQUFBO0lBQUEsbUJBQUE7SUFBQSxpQkFBQTtJQUFBO0FBQUEiLCJmaWxlIjoiYWRtaW4tYXBwb2ludG1lbnRzLXN0YXRzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmFkbWluLWFwcG9pbnRtZW50cy1zdGF0cyB7XG4gIEBhcHBseSBwbC04IHByLTggcHQtNCBwYi00O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/Components/admin-appointments-stats/admin-appointments-stats.component.scss"],"names":[],"mappings":"AACE;IAAA,kBAAA;IAAA,mBAAA;IAAA,iBAAA;IAAA;AAAA;AAKF,gaAAga","sourcesContent":[".admin-appointments-stats {\n  @apply pl-8 pr-8 pt-4 pb-4;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
