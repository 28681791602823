import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isEqual, sortBy, uniqWith } from 'lodash-es';
import * as moment from 'moment';
import { ApiService } from 'src/app/api.service';
import { AddAppointmentListItem } from 'src/app/DTOS/Appointments/AddAppointmentListItem';
import { Doctor } from 'src/app/DTOS/Doctors/DoctorDTO';
import {
  OcrResult,
  OCRReminderScheduleItem,
  OCRAppointment
} from 'src/app/DTOS/OCRResult/OcrResult';
import { ReminderSetDto } from 'src/app/DTOS/ReminderSets/ReminderSetDto';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import uniq from 'lodash-es/uniq';
import chunk from 'lodash/chunk';
import { CitamedPrinterEhr } from 'src/app/Constants/CitamedPrinterEhr';
import { firstValueFrom } from 'rxjs';

function hasMultipleAppointmentDates(appointments: OCRAppointment[]): boolean {
  const dates = uniq(
    appointments.map((a) =>
      moment(a.appointmentDate).startOf('day').format('YYYY-MM-DD')
    )
  );
  return dates.length > 1;
}

@Component({
  selector: 'app-ocr-appointment-list',
  templateUrl: './ocr-appointment-list.component.html',
  styleUrls: ['./ocr-appointment-list.component.scss']
})
export class OcrAppointmentListComponent implements OnInit {
  user: User;
  constructor(
    private API: ApiService,
    public dialogRef: MatDialogRef<OcrAppointmentListComponent>,
    private _snackBar: MatSnackBar,
    private authenticationService: AuthenticationService
  ) {}

  public allAppointments: (OCRAppointment &
    OCRReminderScheduleItem & { reminderSetId: number })[] = [];

  minSelectedDate: Date = new Date();

  public allDoctors: Doctor[] = [];
  public allReminderSets: ReminderSetDto[] = [];
  public isLoading: boolean = false;

  public showDateInput: boolean;
  public isMultiDate: boolean;
  public mustAskForAppointmentsDate: boolean;
  public allAppointmentsDate: Date;
  public today: Date = new Date();
  public allAppointmentsCallDate: Date;
  public searchTerm: string = '';

  @Input() public ocrResult: OcrResult;
  @Input() public clinicId: number;
  @Input() public ehr: number;

  public isInvalidAppointment(apt: OCRAppointment) {
    const appointment = structuredClone(apt);

    if (!appointment.patientName.trim()) {
      return true;
    }
    // Remove all non-numeric characters from the input string
    const numericStr = appointment.phoneNumber.replace(/[^0-9]/g, '');

    let isValid = numericStr.startsWith('787') || numericStr.startsWith('939');

    // Check if the resulting string starts with "787" or "939"

    return isValid === false;
  }

  public reminderScheduleEntries: { [key: number]: OCRReminderScheduleItem } =
    {};

  public removeAppointment(index: number) {
    const confirmResponse = window.confirm(
      '¿Estas segur@ de querer eliminar esta cita?'
    );
    if (!confirmResponse) return;
    this.allAppointments = this.allAppointments.filter((a, i) => i !== index);
  }

  public onClose(skipConfirmation: boolean) {
    if (skipConfirmation) {
      this.dialogRef.close();
      return;
    }
    const confirmation = window.confirm(
      '¿Estás segur@ de querer cancelar la subida de las citas? Estos registros serán eliminados.'
    );
    if (confirmation) {
      this.dialogRef.close();
    }
  }

  public hasMissingField = (appointment: OCRAppointment) => {
    return (
      !appointment.appointmentDate ||
      !appointment.patientName ||
      !appointment.phoneNumber
    );
  };

  public applyFilter() {}

  public addNewAppointment = () => {
    const pivot = structuredClone(this.allAppointments[0]);
    this.allAppointments = [
      {
        ...pivot,
        patientName: '',
        phoneNumber: ''
      },
      ...this.allAppointments
    ];
  };
  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.user = user;
    });
    Object.keys(this.ocrResult?.reminderSchedulesPerDay).forEach((date) => {
      const list = this.ocrResult.reminderSchedulesPerDay[date];
      list.forEach((item) => {
        this.reminderScheduleEntries[item.reminderScheduleId] = item;
      });
    });

    const mapped = this.ocrResult.appointments.map((currentAppointment) => {
      const remSchedule =
        this.reminderScheduleEntries[currentAppointment.reminderScheduleID];

      const aptDateOnly = moment(currentAppointment.appointmentDate).startOf(
        'day'
      );

      const callDateOnly = moment(remSchedule.callDate).startOf('day');

      let aptCallDateResult = moment(remSchedule.callDate);

      if (callDateOnly.isAfter(aptDateOnly)) {
        let appointmentDateBefore = moment(aptCallDateResult)
          .add('days', -1)
          .startOf('day');

        const startOfToday = moment().startOf('day');

        if (startOfToday.isAfter(appointmentDateBefore)) {
          aptCallDateResult = startOfToday;
        } else {
          aptCallDateResult = appointmentDateBefore;
        }
      }

      const aptRes = {
        ...currentAppointment,
        ...remSchedule,
        appointmentDate: moment(currentAppointment.appointmentDate).toDate(),
        callDate: aptCallDateResult.toDate(),
        appointmentTime: moment(currentAppointment.appointmentTime, [
          'h:mm A'
        ]).format('HH:mm')
      };
      return {
        ...aptRes
      };
    });

    this.allAppointments = sortBy(mapped, (apt) => {
      const value = this.isInvalidAppointment(apt);
      return value ? -1 : 1; // Sort true values before false values
    });
    this.allDoctors = uniqWith(
      this.allAppointments.map((a) => ({
        doctorId: a.doctorId,
        doctorName: a.doctorName
      })),
      isEqual
    );

    this.allReminderSets = uniqWith(
      this.allAppointments.map((a) => ({
        reminderSetName: a.reminderSetName,
        reminderSetId: a.reminderSetId
      })),
      isEqual
    ).map((remSetname) => {
      return {
        reminderSetId: Number(remSetname.reminderSetId),
        reminderSetName: remSetname.reminderSetName,
        audios: []
      };
    });

    this.showDateInput = hasMultipleAppointmentDates(
      this.ocrResult.appointments
    );
    this.isMultiDate = !!this.showDateInput;
    this.mustAskForAppointmentsDate = this.ehr === CitamedPrinterEhr.SecureEmr;
    if (
      this.mustAskForAppointmentsDate === false &&
      this.isMultiDate === false &&
      this.ocrResult?.appointments?.length
    ) {
      this.allAppointmentsDate = this.ocrResult.appointments[0].appointmentDate;
    }
  }

  public onAllAppointmentsDateChange = () => {
    const newDateFormatted = moment(this.allAppointmentsDate).format(
      'YYYY-MM-DD'
    );
    this.allAppointments.forEach((appointment) => {
      const formattedTime = moment(appointment.appointmentDate).format(
        'hh:mm A'
      );
      appointment.appointmentDate = moment(
        `${newDateFormatted} ${formattedTime}`
      ).toDate();
    });

    this.mustAskForAppointmentsDate = false;
  };

  public onAllAppointmentsCallDateChange = () => {
    const newDateFormatted = moment(
      moment(this.allAppointmentsCallDate).format('YYYY-MM-DD')
    ).toDate();
    this.allAppointments.forEach((apt) => {
      apt.callDate = newDateFormatted;
    });
  };

  public async saveAppointments() {
    const clinicId = this.clinicId || this.user.clinicId;
    this.isLoading = true;

    const appointmentsResult: AddAppointmentListItem[] =
      this.allAppointments.map((a) => {
        const dateOnly = moment(a.appointmentDate).format('YYYY-MM-DD');
        const allArgs = `${dateOnly} ${a.appointmentTime}`;
        const aptDateTime = moment(allArgs).format('YYYY-MM-DD hh:mm A');
        return {
          appointmentDate: aptDateTime,
          attempts: a.callAttempts,
          patientName: a.patientName,
          patientPhone: a.phoneNumber,
          doctorId: a.doctorId,
          reminderSetId: a.reminderSetId,
          reminderScheduleId: a.reminderScheduleId,
          callDate: moment(a.callDate).format('YYYY-MM-DD'),
          patientRecord: a.patientRecord
        };
      });

    // split the appointments into 50 items chunks to not send a huge request to the backend.
    const appointmentsListsChunks = chunk(appointmentsResult, 50);

    for (const chunkList of appointmentsListsChunks) {
      try {
        const req = this.API.AddAppointmentsList(clinicId, chunkList);
        await firstValueFrom(req);
      } catch (error) {
        this._snackBar.open(
          `Oops!, algunas de las citas no pudieron ser añadidas. `,
          'OK ',
          {
            duration: 10000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'error-dialog'
          }
        );
      }
    }

    this._snackBar.open(`¡Listo! `, 'OK ', {
      duration: 10000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      panelClass: 'success-dialog'
    });
    this.isLoading = false;
    this.onClose(true);
  }
}
