import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-html-only-dialog',
  templateUrl: './html-only-dialog.component.html',
  styleUrls: ['./html-only-dialog.component.scss']
})
export class HtmlOnlyDialogComponent implements AfterViewInit {
  @ViewChild('dialogElement') dialogElementRef: ElementRef;
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  ngAfterViewInit(): void {
    const dialogElement = this.dialogElementRef
      .nativeElement as HTMLDialogElement;
    if (dialogElement.showModal) {
      dialogElement.showModal();
    } else {
      console.error('The showModal method is not supported in this browser.');
    }

    dialogElement.addEventListener('close', () => {
      this.onClose.emit();
    });
  }

  closeDialog() {
    const dialogElement = this.dialogElementRef
      .nativeElement as HTMLDialogElement;
    dialogElement.close();
  }
}
