import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-http-error-snackbar',
  templateUrl: './http-error-snackbar.component.html',
  styleUrls: ['./http-error-snackbar.component.scss']
})
export class HttpErrorSnackbarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
