import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  AfterContentInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as introJs from 'intro.js/intro.js';
import * as moment from 'moment';
import { AdminViewAsClinicDTO } from '../../DTOS/AdminViewAsClinicDTO';
import {
  IAppointmentListItem,
  HomeViewModel,
  INoCallDate,
  ISummaryCallType,
  IStatusSummary
} from '../../DTOS/Appointments/AppointmentListItem';
import { CallStatusGET_DTO } from '../../DTOS/CallStatus/DTOS';
import { Doctor } from '../../DTOS/Doctors/DoctorDTO';
import {
  AddAppointmentDialogData,
  AddAppointmentsDialogComponent
} from '../../_dialogs/add-appointments-dialog/add-appointments-dialog.component';
import {
  AppointmentDetailsDialogComponent,
  AppointmentDetailsDialogData,
  AppointmentDetails
} from '../../_dialogs/appointment-details-dialog/appointment-details-dialog.component';
import {
  ReportsViewerComponent,
  reportsViewerData
} from '../../_dialogs/reports-viewer/reports-viewer.component';
import {
  UpdateAppointmentCallStatusDialogComponent,
  UpdateAppointmentCallStatusDialogData
} from '../../_dialogs/update-appointment-call-status/update-appointment-call-status.component';
import {
  UpdateAppointmentDialogComponent,
  UpdateAppointmentDialogData
} from '../../_dialogs/update-appointment/update-appointment.component';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';
import { ApiService, GetAppointmentsListParams } from 'src/app/api.service';
import { ClinicAppointmentType } from 'src/app/DTOS/Appointments-types/ClinicAppointmentType';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';
import { ClinicDoctorAssistanceStatusDTO } from 'src/app/DTOS/AssistanceStatus';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterUserViewAsSelectorComponent } from 'src/shared/Components/master-user-view-as-selector/master-user-view-as-selector.component';
import esLocale from '@fullcalendar/core/locales/es';
import enLocale from '@fullcalendar/core/locales/en-au';
import { Subscription } from 'rxjs';
import { RUN_TUTORIAL_SESSION_KEY } from 'src/app/app-component/app.component';
import { debounce, orderBy, isEmpty } from 'lodash-es';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from 'src/app/_dialogs/confirm-dialog/confirm-dialog.component';
import { AccountStatus } from 'src/app/DTOS/Enums/AccountStatus';
import { CompanyStatus } from 'src/app/DTOS/Appointments/GetLastSyncDateResponse';
import { Speciality } from 'src/app/DTOS/Speciality/Speciality';
import { LocationDTO } from 'src/app/DTOS/Locations/Location';
import {
  collapseAnimation,
  fadeInUpOnEnterAnimation,
  fadeOutDownAnimation
} from 'angular-animations';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Location } from '@angular/common';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,

  animations: [
    collapseAnimation(),
    fadeInUpOnEnterAnimation({
      anchor: 'enter',
      duration: 1000,
      delay: 70,
      translate: '30px'
    }),
    fadeOutDownAnimation({
      anchor: 'leave'
    }),
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class HomeComponent implements AfterContentInit, OnInit {
  appointmentSummarySubscription: Subscription;
  statusSummary: IStatusSummary[];
  summaryCallTypes: ISummaryCallType[];
  isLoadingSummaryCallType: boolean;
  LoadStatusSummariesSuscription: Subscription;
  GetAppointmentSummaryCallTypesSuscription: Subscription;
  selectedAppointmentsGroup: FormGroup;
  public chatOriginatorOverlayOrigin: any = null;
  public isChatOpened: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private API: ApiService,
    private permissionSlugsService: PermissionSlugsService,
    @Inject('BASE_URL') baseUrl: string,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private location: Location
  ) {
    this.baseUrl = baseUrl;
    moment.locale('es');
  }

  public showAppointmentAssistance: boolean = false;
  public userCanCreateConfirmationAppointments: boolean = true;
  public pageSize: number = 15;
  public currentPage: number = 1;
  public appointmentsFlipCardStatus = '';
  locales = [esLocale, enLocale];

  ngAfterContentInit(): void {
    this.authenticationService.currentUser.subscribe((User) => {
      this.User = User;
      this.clinicNameTitle = `${User.clinicId} - ${User.clinicName} (${User.companyId})`;

      setTimeout(() => {
        this.route.params.subscribe((c) => {
          const clinicID: number = Number(c.clinicID);
          const date = c.date;
          if (date) {
            this.selectedDate = moment(date).toDate();
          }
          if (clinicID) {
            this.API.GetClinics(null, clinicID).subscribe((clinicsRes) => {
              const selectedClinic = clinicsRes.find(
                (cc) => cc.clinicId == clinicID
              );
              this.onViewAsClinicIdChange(selectedClinic);
              this.viewAsSelector.selectValue(selectedClinic);
              this.ActivateView();
            });
          } else {
            this.ActivateView();
          }
        });
      }, 300);
    });
  }

  public specialities: Speciality[] = [];

  public GetSpecialitiesForClinics() {
    const clinicId = this.getClinicID();
    this.API.GetSpecialitiesForClinics(clinicId).subscribe((res) => {
      this.specialities = res;
    });
  }

  public selected_appointmentTypeId: number;
  baseUrl: string;
  ClinicAppointmentTypes: ClinicAppointmentType[] = [];
  public clinicAppointmentType: ClinicAppointmentType;
  public clinicDoctorAssistanceStatusDictionary: any;
  public canSeeDailyReports: boolean;

  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = 'Citas por página';
    this.paginator._intl.nextPageLabel = 'Siguiente';
    this.paginator._intl.previousPageLabel = 'Anterior';
    this.paginator._intl.lastPageLabel = 'Última página';
    this.paginator._intl.firstPageLabel = 'Primera página';

    this.permissionSlugsService.permissionsListener.subscribe((permissions) => {
      this.canSeeDailyReports = permissions.includes(
        PermissionSlugs.CAN_SEE_DAILY_REPORTS
      );
    });

    this.selectedAppointmentsGroup = this.formBuilder.group({
      appointmentIds: this.formBuilder.array([])
    });
  }

  @ViewChild('sms_panel') chatMenuTrigger: MatMenuTrigger;
  @ViewChild('doctorsFilter') doctorsFilterSelect: MatSelect;
  @ViewChild('locationsFilter') locationsFilterSelect: MatSelect;

  @ViewChild('specialitiesFilter') specialitiesFilterSelect: MatSelect;
  @ViewChild('statusFilter') estatusFilter: MatSelect;
  @ViewChild('viewAsSelector')
  viewAsSelector: MasterUserViewAsSelectorComponent;
  public has2wayTxtEnabled: Boolean = true;
  public view: HomeViewModel = {} as HomeViewModel;
  introJS = introJs();
  public chatAppointment: IAppointmentListItem = null;
  public selectedDoctorsFilter = new FormControl<Doctor[]>([]);
  public selectedSpecialitiesFilter = new FormControl<Speciality[]>([]);
  public selectedStatusFilter = new FormControl<CallStatusGET_DTO[]>([]);
  public selectedLocationsFilter = new FormControl<LocationDTO[]>([]);
  public showMoreAptsFilters: boolean = false;
  public isLinear: boolean = true;
  public isPreloading: boolean = true;
  expandedElement: IAppointmentListItem | null;
  public NoCallDates: INoCallDate[];
  public selectedSummaryCallType: ISummaryCallType;
  public selectedSummaryCallTypeId: number;
  public appointmentsTotal: number;
  public selectedSummaryCallTypeTabIndex: number = 0;
  public selectedDate: Date = new Date();
  public doctors: Doctor[] = new Array();
  public dateTitle: string = '';
  public lastSyncDate: string = '';
  public lastSyncForDate: string = '';
  public viewIsLoadingAppointments: boolean = true;
  public ViewAs: AdminViewAsClinicDTO;
  public AppointmentListItems: IAppointmentListItem[];
  public User: User;
  public showSmsColumn: boolean = false;
  public IsActivatedView: boolean = false;
  public sys_message_has_been_shown: boolean = false;
  public ShowRecordColumn: boolean = false;
  public callStatuses: CallStatusGET_DTO[];
  public allCallStatuses: CallStatusGET_DTO[];
  public clinicNameTitle: string = '';
  public showLocationColumn: boolean = false;
  selected = new UntypedFormControl(0);
  displayedColumns: string[] = [];
  assistanceData: ClinicDoctorAssistanceStatusDTO[] = [];
  public accountStatus: AccountStatus;
  public companyStatus: CompanyStatus;
  dataSource: MatTableDataSource<IAppointmentListItem>;
  loaderDataSource = new MatTableDataSource<IAppointmentListItem>(
    Array.from(Array(10).keys()).map(() => ({}) as any)
  );
  // references the #calendar in the template
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  appointmentsSearchTerm: string = '';
  public isLoadingDoctors: boolean = false;

  ngAfterViewInit() {
    this.authenticationService.currentUser.subscribe((u) => {
      this.getClinicAssistanceStatusesConfig();
      this.permissionSlugsService.permissionsListener.subscribe((r) => {
        this.userCanCreateConfirmationAppointments =
          u.isAdmin || !r.includes(PermissionSlugs.BOOKINGS);

        this.showAppointmentAssistance = r.includes(
          PermissionSlugs.APPOINTMENT_ASSISTANCE
        );
      });
      if (!sessionStorage.getItem('introJsFlag')) {
        this.snackBaTutorial();
        sessionStorage.setItem('introJsFlag', 'introJsFlag');
      }
    });
  }

  onSelectedAppointmentsChange(selectedOption: MatCheckboxChange) {
    const selectedAppts = (<FormArray>(
      this.selectedAppointmentsGroup.get('appointmentIds')
    )) as FormArray;

    if (selectedOption.checked) {
      selectedAppts.push(new FormControl(selectedOption.source.value));
    } else {
      const i = selectedAppts.controls.findIndex(
        (x) => x.value === selectedOption.source.value
      );
      selectedAppts.removeAt(i);
    }
  }

  public changeLogAppt: IAppointmentListItem;
  public openChangeLogModal(appointment: IAppointmentListItem) {
    this.changeLogAppt = structuredClone(appointment);
  }

  public openChat(appointment: IAppointmentListItem, element: any) {
    this.chatOriginatorOverlayOrigin = element;
    this.chatAppointment = appointment;
    this.isChatOpened = true;
  }

  public OnDateChanged() {
    let newRoute = '';

    const cId = this.ViewAs.clinicId;
    const cDate = moment(this.selectedDate).format('YYYY-MM-DD');

    if (this.ViewAs.clinicId) {
      newRoute = `/admin/clinic/${cId}/confirmations/${cDate}`;
      this.location.replaceState(newRoute);
    }
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    const cDate = moment(this.selectedDate).format('YYYY-MM-DD');
    const cId = this.ViewAs.clinicId;
    const newRoute = `/admin/clinic/${cId}/confirmations/${cDate}`;
    this.router.navigate([newRoute]);
    this.GetLastSyncDate(this.ViewAs.clinicId);
    this.getClinicAssistanceStatusesConfig();
    this.clinicNameTitle = `${this.ViewAs.clinicId} - ${this.ViewAs.clinicName} (${this.ViewAs.companyId})`;
  }

  public LoadClinicAppointmentTypes() {
    this.API.GetClinicAppointmentTypes(
      this.ViewAs?.clinicId || this.User.clinicId
    ).subscribe((r: ClinicAppointmentType[]) => {
      this.ClinicAppointmentTypes = r;
    });
  }

  areAllAppointmentsChecked() {
    if (!this.AppointmentListItems) {
      return false;
    }
    return (
      this.AppointmentListItems.length ===
      this.selectedAppointmentsGroup.value?.appointmentIds?.length
    );
  }

  selectAllVisibleAppointments(event: MatCheckboxChange) {
    if (event.checked) {
      const selectedAppts = (<FormArray>(
        this.selectedAppointmentsGroup.get('appointmentIds')
      )) as FormArray;

      this.AppointmentListItems.forEach((row) => {
        selectedAppts.push(new FormControl(row.appointmentId));
      });

      // console.log('checked here');
    } else {
      this.selectedAppointmentsGroup = this.formBuilder.group({
        appointmentIds: this.formBuilder.array([])
      });
    }
  }

  // selectedAppointmentsGroup.value?.appointmentIds
  public retryAppointmentLists() {
    const dto = this.selectedAppointmentsGroup.value;
    const a = confirm('Estas seguro de reenviar estos intentos?');
    if (a) {
      this.API.retryAppointments(dto).subscribe((r) => {
        this.selectedAppointmentsGroup = this.formBuilder.group({
          appointmentIds: this.formBuilder.array([])
        });
        this.LoadAppointments();
      });
    }
  }

  public getClinicID = () => {
    let clinicid = this.User.clinicId;
    if (this.ViewAs) clinicid = this.ViewAs.clinicId;
    return clinicid;
  };

  public FetchDoctors() {
    this.isLoadingDoctors = true;
    let clinicid = this.User.clinicId;
    if (this.ViewAs) clinicid = this.ViewAs.clinicId;

    this.API.GetAllDoctors(clinicid).subscribe(
      (result) => {
        var res = result as Doctor[];
        this.doctors = res;
        this.isLoadingDoctors = false;
      },
      (error) => console.error(error)
    );
  }

  DownloadAppointmentsReport(ExportType: string) {
    let userID = this.User.userId;
    if (this.ViewAs) userID = this.ViewAs.viewAsUserID;

    let link =
      this.baseUrl +
      `Reports/AppointmentsReport?StartDate=${moment(this.selectedDate).format(
        'YYYY-MM-DD'
      )}&EndTime=${moment(this.selectedDate).format('YYYY-MM-DD')}&CallTypeId=${
        this.selectedSummaryCallType.callTypeId
      }&ExportType=${ExportType}&userID=${userID}`;

    if (ExportType === 'PDF') {
      let data: reportsViewerData = {
        link: link
      };
      this.dialog
        .open(ReportsViewerComponent, {
          data: data,
          width: '80%',
          disableClose: true
        })
        .afterClosed()
        .subscribe((dialogResult) => {
          let result = dialogResult as boolean;
          if (result) this.LoadAppointments();
        });
    } else {
      window.open(link, '_blank');
    }
  }

  onCallTypesTabChange(TabChangeEvent: any) {
    let selected_summaryCallType = this.summaryCallTypes[TabChangeEvent.index];
    this.selectedSummaryCallType = selected_summaryCallType;
    this.selectedSummaryCallTypeId = selected_summaryCallType.callTypeId;
    this.currentPage = 0;
    this.pageSize = 30;
    if (this.IsActivatedView) {
      this.LoadAppointments();
    }
  }

  UpdateAppointmetCallStatus(apointment: IAppointmentListItem) {
    let data: UpdateAppointmentCallStatusDialogData = {
      appointment: apointment,
      callStatusID: apointment.callStatusId,
      callTypeId: this.view.callTypeId,
      clinicID: this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId,
      callStatuses: this.allCallStatuses
    };
    this.dialog
      .open(UpdateAppointmentCallStatusDialogComponent, {
        data: data,
        width: '35%',
        disableClose: true
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        let result = dialogResult as boolean;
        if (result) this.LoadAppointments();
      });
  }

  OpenAppointmentAddDialog() {
    let data: AddAppointmentDialogData = {
      clinicID: this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId,
      UserId: this.ViewAs ? this.ViewAs.viewAsUserID : this.User.userId,
      ClinicName: this.ViewAs ? this.ViewAs.clinicName : this.User.clinicName
    };
    this.dialog
      .open(AddAppointmentsDialogComponent, {
        data: data,
        maxHeight: '200.rem',
        maxWidth: '200.rem',
        hasBackdrop: true,
        disableClose: true
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        let result = dialogResult as boolean;
        if (result) {
          this.LoadAppointments();
        }
      });
  }

  OpenUpdateAppointmetDialog(apointment: IAppointmentListItem) {
    let data: UpdateAppointmentDialogData = {
      appointment: apointment,
      clinicID: this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId,
      callStatuses: this.allCallStatuses
    };
    this.dialog
      .open(UpdateAppointmentDialogComponent, {
        data: data,
        width: '35%',
        disableClose: true
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        let result = dialogResult as boolean;
        if (result) this.LoadAppointments();
      });
  }

  public OpenAppointmentsDetailDialog(apointment: IAppointmentListItem) {
    let data: AppointmentDetailsDialogData = { appointmentData: apointment };
    this.dialog
      .open(AppointmentDetailsDialogComponent, {
        data: data,
        width: '40%',
        disableClose: true
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        let result = dialogResult as boolean;
        if (result) this.LoadAppointments();
      });
  }

  public PauseConfirmations() {
    const clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    const date = moment(this.selectedDate).format('YYYY-MM-DD');

    this.API.NO_CALL_DATE_PauseAllForClinic(clinicId, date).subscribe(() =>
      this.LoadAppointments()
    );
  }

  public ResumeConfirmations() {
    const clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    const date = moment(this.selectedDate).format('YYYY-MM-DD');

    this.API.ResumeAllAppointmentsForClinic(clinicId, date).subscribe(() =>
      this.LoadAppointments()
    );
  }

  public LoadDetail(apointment: IAppointmentListItem) {
    apointment._isLoadingDetails = true;
    this.API.GetAppointmentDetails(
      apointment.clinicId,
      apointment.appointmentId
    ).subscribe(
      (result) => {
        let res = result as AppointmentDetails;

        apointment.author = res.appointmentDetails.author;
        res.details.forEach((e, idx) => {
          e._formatedDate = moment(e.callTime).format(
            'DD MMM [del] YYYY[,] hh:mm A'
          );
          e.attempt = idx + 1;
        });

        apointment.CallHistory = res.details;

        apointment._callDate = moment(res.attempts.callDate).format(
          'DD MMMM [del] YYYY'
        );

        apointment._createDate = moment(
          res.appointmentDetails.createDate
        ).format('DD MMMM [del] YYYY');

        apointment.createDate = res.appointmentDetails.createDate;

        apointment.timing = res.attempts.timings.filter((e) => {
          if (
            apointment.CallHistory.map((eee) => eee.attempt).indexOf(
              e.callOrder
            ) > -1
          )
            return false;
          else return true;
        });

        apointment.timing.forEach((t) => {
          t.callTimeUnformatted = t.callTime;
          t.callTime = moment(
            '2020-01-01 ' + t.callTime,
            'YYYY-MM-DD HH:mm:ss.SSSS'
          ).format('hh:mm a');
        });
        apointment._isLoadingDetails = false;
      },
      () => {}
    );
  }

  public isLastSyncDateLoading: boolean = false;
  private lastSyncDateSuscription: Subscription;
  public GetLastSyncDate(clinicId: number) {
    if (this.lastSyncDateSuscription && !this.lastSyncDateSuscription.closed) {
      this.lastSyncDateSuscription.unsubscribe();
    }
    this.isLastSyncDateLoading = true;
    this.accountStatus = null;
    this.lastSyncDateSuscription = this.API.GetLastSyncDate(clinicId).subscribe(
      (res) => {
        this.accountStatus = res.accountStatus;
        this.isLastSyncDateLoading = false;
        this.companyStatus = res.companyStatus;
        if (res.lastSyncDate) {
          this.lastSyncDate = moment(res.lastSyncDate)
            .locale('es')
            .format('DD MMM YYYY');
          this.lastSyncForDate = moment(res.appointmentDate)
            .locale('es')
            .format('DD MMM YYYY');
        } else {
          this.lastSyncDate = null;
          this.lastSyncForDate = null;
        }
      }
    );
  }

  public clearSelectedDoctorsFilter() {
    this.doctorsFilterSelect.options.forEach((o) => o.deselect());
    this.dataSource = new MatTableDataSource<IAppointmentListItem>(
      this.AppointmentListItems
    );
    this.dataSource.sort = this.sort;
  }

  public clearSelectedLocationsFilter() {
    this.locationsFilterSelect.options.forEach((o) => o.deselect());
    this.dataSource = new MatTableDataSource<IAppointmentListItem>(
      this.AppointmentListItems
    );
    this.dataSource.sort = this.sort;
  }

  public clearSelectedSpecialitiesFilter() {
    this.specialitiesFilterSelect.options.forEach((o) => o.deselect());
    this.dataSource = new MatTableDataSource<IAppointmentListItem>(
      this.AppointmentListItems
    );
    this.dataSource.sort = this.sort;
  }

  public debouncedRefetchAppointmentsList = debounce(
    () => this.refetchAppointmentsList(),
    1500,
    {}
  );

  public refetchAppointmentsList() {
    this.appointmentsFlipCardStatus = '';
    this.currentPage = 0;
    this.pageSize = 15;
    let { $date, CallTypeId, viewAsUserID, clinicId } =
      this.getLoadAppointmentsArguments();

    this.LoadAppointmentsSummary($date, CallTypeId, viewAsUserID, clinicId);
  }

  public clearStatusFilter() {
    this.estatusFilter.options.forEach((o) => o.deselect());
    this.dataSource = new MatTableDataSource<IAppointmentListItem>(
      this.AppointmentListItems
    );
  }

  LoadAppointments() {
    let { $date, CallTypeId, viewAsUserID, clinicId } =
      this.getLoadAppointmentsArguments();

    this.LoadAppointmentSummaryCallTypes(
      $date,
      CallTypeId,
      viewAsUserID,
      clinicId
    );
  }

  private getLoadAppointmentsArguments() {
    this.viewIsLoadingAppointments = true;
    let CallTypeId = 1; // default = confirmaciones;

    try {
      if (this.selectedSummaryCallType)
        CallTypeId = this.selectedSummaryCallType.callTypeId;
    } catch (e) {
      CallTypeId = 1; // default = confirmaciones;
    }
    let $date = moment(this.selectedDate).format('YYYY-MM-DD');
    let viewAsUserID = this.User.userId;
    let clinicId = this.User.clinicId;
    if (this.ViewAs) {
      viewAsUserID = this.ViewAs.viewAsUserID;
      clinicId = this.ViewAs.clinicId;
    }
    return { $date, CallTypeId, viewAsUserID, clinicId };
  }

  private LoadAppointmentsSummary(
    $date: string,
    CallTypeId: number,
    viewAsUserID: number,
    clinicId: number
  ) {
    if (
      this.appointmentSummarySubscription &&
      !this.appointmentSummarySubscription.closed
    ) {
      this.appointmentSummarySubscription.unsubscribe();
    }

    function getItemsOrUndefined<T, K extends keyof T>(
      val: T[],
      key: K
    ): T[K][] {
      if (isEmpty(val)) {
        return undefined;
      } else {
        const itemsFilteredAndMapped = val
          .filter((item) => item[key])
          .map((item) => item[key]);
        return isEmpty(itemsFilteredAndMapped)
          ? undefined
          : itemsFilteredAndMapped;
      }
    }

    const selectedDoctors: number[] = getItemsOrUndefined(
      this.selectedDoctorsFilter?.value,
      'doctorId'
    );

    const selectedCallStatus: string[] = getItemsOrUndefined(
      this.selectedStatusFilter?.value,
      'callStatusId'
    );

    const selectedSpecialities: number[] = getItemsOrUndefined(
      this.selectedSpecialitiesFilter?.value,
      'specialtyId'
    );

    const selectedLocations: number[] = getItemsOrUndefined(
      this.selectedLocationsFilter.value,
      'locationId'
    );

    const parameters: GetAppointmentsListParams = {
      date: $date,
      CallTypeId: CallTypeId,
      UserId: viewAsUserID,
      appointmentTypeID: this.selected_appointmentTypeId,
      page: this.currentPage,
      quantity: this.pageSize,
      clinicId: clinicId,
      sortColumn: 'AppointmentDate',
      sortOrder: 'ASC',
      doctorsIds: selectedDoctors,
      searchTerm: this.appointmentsSearchTerm,
      callStatusId: selectedCallStatus,
      specialitiesIds: selectedSpecialities,
      locationsIds: selectedLocations
    };

    this.appointmentSummarySubscription = this.API.GetAppointmentsSummary(
      structuredClone(parameters)
    ).subscribe(
      (result) => {
        let res = result as HomeViewModel;
        this.IsActivatedView = true;

        this.NoCallDates = res.nocallDates;

        const _appointmentsMapped = res.appointments.map((e, index) => {
          const lastCallDate = e.lastCall
            ? moment(e.lastCall).locale('es').format('DD MMMM YYYY')
            : null;

          const lastCallHour = e.lastCall
            ? moment(e.lastCall).locale('es').format('hh:mm A')
            : null;

          e._relativeDate = e.lastCall
            ? moment(e.lastCall).locale('es').fromNow()
            : null;

          return {
            ...e,
            _appointmentDateUnformatted: moment(e.appointmentDate).toDate(),
            _lastCallUnformatted: e.lastCall
              ? moment(e.lastCall).toDate()
              : null,
            appointmentDate: moment(e.appointmentDate).format('hh:mm A'),
            lastCall: e.lastCall
              ? moment(e.lastCall).locale('es').format('DD MMM YYYY hh:mm A')
              : null,
            is_gray: index % 2 == 0,
            lastCallDate,
            lastCallHour,
            ...(e as any).callScheduleData
          };
        });

        if (res.appointments.some((c) => c.unreadSmsResponsesCount > 0)) {
          const unreadApts = _appointmentsMapped.filter(
            (aa) => aa.unreadSmsResponsesCount > 0
          );

          const nonUnreadApts = _appointmentsMapped.filter(
            (aa) => !aa.unreadSmsResponsesCount
          );

          res.appointments = [
            ...orderBy(unreadApts, ['unreadSmsResponsesCount'], 'desc'),
            ...nonUnreadApts
          ];
        } else {
          res.appointments = _appointmentsMapped;
        }

        this.dateTitle = moment($date)
          .locale('es')
          .format('dddd DD [de] MMMM [del] YYYY');

        this.view = res;

        this.AppointmentListItems = res.appointments;

        this.dataSource = new MatTableDataSource<IAppointmentListItem>(
          this.AppointmentListItems
        );

        this.dataSource.sort = this.sort;
        this.viewIsLoadingAppointments = false;
        this.showSmsColumn = this.view.appointments.some((e) => e.smsResponse);
        this.ShowRecordColumn = this.view.appointments.some(
          (e) => e.patientRecord
        );
        this.showLocationColumn = this.view.appointments.some(
          (e) => e.locationName
        );
        this.isPreloading = false;
        this.SetTutorialSteps();
      },
      (error) => console.error(error)
    );
  }

  public GetAppointmentsStatusSummaryLoading: boolean = false;
  private LoadStatusSummaries(
    $date: string,
    CallTypeId: number,
    viewAsUserID: number
  ) {
    if (
      this.LoadStatusSummariesSuscription &&
      !this.LoadStatusSummariesSuscription.closed
    ) {
      this.LoadStatusSummariesSuscription.unsubscribe();
    }
    this.GetAppointmentsStatusSummaryLoading = true;
    this.LoadStatusSummariesSuscription = this.API.GetAppointmentsStatusSummary(
      $date,
      CallTypeId,
      viewAsUserID,
      this.selected_appointmentTypeId
    ).subscribe(
      (res) => {
        this.statusSummary = res.statusSummary;
        this.callStatuses = res.statusSummary.map((s) => {
          return {
            callStatusId: s.callStatusId,
            callStatusName: s.callStatusName,
            smallIconFilename: s.smallIconFilename
          };
        });
        this.appointmentsTotal = this.statusSummary
          .map((e) => e.statusCount)
          .reduce((a, b) => a + b, 0);
        this.GetAppointmentsStatusSummaryLoading = false;
      },
      (e) => {
        this.GetAppointmentsStatusSummaryLoading = false;
      }
    );
  }

  private LoadAppointmentSummaryCallTypes(
    $date: string,
    CallTypeId: number,
    viewAsUserID: number,
    clinicId: number
  ) {
    if (
      this.GetAppointmentSummaryCallTypesSuscription &&
      !this.GetAppointmentSummaryCallTypesSuscription.closed
    ) {
      this.GetAppointmentSummaryCallTypesSuscription.unsubscribe();
    }
    this.isLoadingSummaryCallType = true;
    this.GetAppointmentSummaryCallTypesSuscription =
      this.API.GetAppointmentSummaryCallTypes(
        $date,
        CallTypeId,
        viewAsUserID,
        this.selected_appointmentTypeId
      ).subscribe((res) => {
        this.summaryCallTypes = orderBy(
          res.summaryCallTypes,
          (c) => c.callTypeId,
          'asc'
        );
        this.selectedSummaryCallType = res.summaryCallTypes.find(
          (c) => c.callTypeId == CallTypeId
        );

        if (res.summaryCallTypes.length && !this.selectedSummaryCallType) {
          this.selectedSummaryCallType = res.summaryCallTypes[0];
          this.selectedSummaryCallTypeId =
            this.selectedSummaryCallType.callTypeId;
        } else {
          if (this.selectedSummaryCallTypeId) {
            this.selectedSummaryCallTypeTabIndex =
              this.summaryCallTypes.indexOf(this.selectedSummaryCallType);
          }

          let selected_summaryCallType = this.summaryCallTypes.filter(
            (eee) => eee.callTypeId == CallTypeId
          )[0];

          selected_summaryCallType = selected_summaryCallType || {
            callTypeId: 1,
            callTypeName: 'Confirmation',
            callTypeAlias: 'Confirmación',
            description: 'Ver resumen de estatus de llamadas de confirmación.'
          };

          this.selectedSummaryCallType = selected_summaryCallType;
          this.selectedSummaryCallTypeId = selected_summaryCallType.callTypeId;
        }

        this.isLoadingSummaryCallType = false;

        this.LoadStatusSummaries(
          $date,
          this.selectedSummaryCallType.callTypeId,
          viewAsUserID
        );
        this.LoadAppointmentsSummary(
          $date,
          this.selectedSummaryCallType.callTypeId,
          viewAsUserID,
          clinicId
        );
      });
  }
  public handlePage(e: PageEvent) {
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    let { $date, CallTypeId, viewAsUserID, clinicId } =
      this.getLoadAppointmentsArguments();

    this.LoadAppointmentsSummary($date, CallTypeId, viewAsUserID, clinicId);
  }
  getDisplayedColumns() {
    let columns = [
      'patientName',
      'Telefono',
      'reminderSetName',
      this.showLocationColumn ? 'location' : null,
      'appointmentDate',
      this.showSmsColumn ? 'smsResponse' : null,
      'callStatusName',
      this.showAppointmentAssistance ? 'assistanceStatusId' : null,
      'Acciones'
    ].filter((e) => e);

    return columns;
  }

  LoadCallStatuses() {
    let clinicID = this.User.clinicId;
    if (this.ViewAs) clinicID = this.ViewAs.clinicId;
    this.API.GetAllCallStatus(clinicID).subscribe((result) => {
      this.allCallStatuses = result as CallStatusGET_DTO[];
    });
  }

  StartDemo() {
    this.introJS.start();
  }

  SetTutorialSteps() {
    const stepsWhenTheUserHasAppointments = [
      {
        element: '#step4',
        intro:
          'Aquí puedes filtar por el tipo de llamada (Confirmacion de Citas, llamada especial, Recordatorios etc...)',
        position: 'right'
      },
      {
        element: '#step5',
        intro:
          'Si necesitas descargar el reporte de citas, haz clic en este botón y selecciona el formato deseado',
        position: 'right'
      },
      {
        element: '#intro-step-search-appointment',
        intro:
          'Si quieres buscar una cita en especifico puedes filtrarla en este campo, utilizando el nombre del paciente, su teléfono, número de record o nombre del doctor asignado a la cita.',
        position: 'right'
      },
      {
        element: '#intro-step-filter-provider',
        intro:
          'Puedes filtrar las citas por el libro de citas (proveedor/doctor).',
        position: 'right'
      },
      {
        element: '#intro-step-filter-estatus',
        intro:
          'Puedes filtrar las citas por el estatus de la cita (confirmadas, canceladas...)',
        position: 'right'
      },
      {
        element: '#step11',
        intro: 'Haz clic aquí para añadir una nueva cita.',
        position: 'right'
      },
      {
        element: '#intro-step-refresh',
        intro: 'Haz clic aquí para volver a cargar el listado de citas.',
        position: 'right'
      },
      {
        element: '#intro-step-tutorial',
        intro:
          'Puedes volver a correr este tutorial, haciendo clic en este botón.',
        position: 'right'
      },

      {
        element: '#step6',
        intro:
          'Aquí podrás ver el listado de pacientes para fecha y tipo de llamada seleccionadas.',
        position: 'right'
      },
      {
        element: '#step8',
        intro:
          'Haz clic en el icono del estatus en esta columna para editar el estatus de la cita (confirmarla/cancelarla, etc...)',
        position: 'right'
      },
      {
        element: '#step9',
        intro: 'Haz clic en el lápiz de una cita para editarla.',
        position: 'right'
      },
      {
        element: '#intro-step-details',
        intro:
          'Para ver el detalle de intentos, haz clic en este botón para expandir el detalle.',
        position: 'right'
      },
      {
        element: '#step10',
        intro:
          'Haz clic en la cabecera de la columna para organizar las citas por ellas.',
        position: 'right'
      }
    ];
    const stepsWhenTheUserDOESNOTHaveAppointments = [
      {
        element: '#intro-step-no-appointments-add-new-button',
        intro:
          'Como no tienes citas agendadas, puedes registrar una nueva cita haciendo clic en este botón',
        position: 'bottom'
      },
      {
        element: '#intro-step-no-appointments-refresh-button',
        intro: 'Puedes volver a cargar el listado, haciendo clic en este botón',
        position: 'bottom'
      },
      {
        element: '#intro-step-no-appointments-nocalldate-button',
        intro:
          'Con este botón, puedes controlar si las citas son enviadas o no para el día seleccionado.',
        position: 'bottom'
      },
      {
        element: '#v2-go-back-button',
        intro:
          'Si quieres volver a la versión anterior de citamed, puedes hacer clic en este botón.',
        position: 'bottom'
      }
    ];

    const additionalSteps = this.AppointmentListItems?.length
      ? stepsWhenTheUserHasAppointments
      : stepsWhenTheUserDOESNOTHaveAppointments;

    this.introJS.setOptions({
      steps: [
        {
          element: '#navbarLogo',
          intro:
            '¡Citamed tiene una nueva imagen! más amigable y fácil de usar, adaptable a laptops, tablets y móviles.',
          position: 'bottom'
        },
        {
          element: '#intro-step-sidebar',
          intro:
            'Puedes acceder a las funcionalidades del sistema desde nuestro nuevo menú',
          position: 'bottom'
        },
        {
          element: '#intro-step-confirmation-ribbon',
          intro:
            'Para navegar en los listados de día de confirmaciones, puedes utilizar esta barra de navegación.',
          position: 'right'
        },
        {
          element: '#date-selection-ribbon-prevBtn',
          intro: 'Haz click aquí, para ir al día anterior.',
          position: 'right'
        },
        {
          element: '#date-selection-ribbon-date-calendar',
          intro:
            'Aquí mostramos el día actual, si haces clic acá, se mostrará un calendario en el cual podrás navegar a un día especifico.',
          position: 'right'
        },
        {
          element: '#date-selection-ribbon-nextBtn',
          intro: 'Haz clic aquí, para ir al día siguiente.',
          position: 'right'
        },
        ...additionalSteps,
        {
          element: '.chat',
          intro:
            'Recuerda, si necesitas ayuda puedes escribirnos en el recuadro verde al fondo de la pantalla llamado "Soporte Técnico".',
          position: 'bottom'
        }
      ],
      doneLabel: 'Listo',
      skipLabel: 'Saltar',
      prevLabel: 'Atrás',
      nextLabel: 'Siguiente',
      showProgress: true
    });

    if (window.sessionStorage.getItem(RUN_TUTORIAL_SESSION_KEY) === 'true') {
      window.sessionStorage.removeItem(RUN_TUTORIAL_SESSION_KEY);
      setTimeout(() => {
        this.StartDemo();
      }, 3000);
    }
  }

  public locations: LocationDTO[] = [];

  public loadClinicLocations() {
    this.API.GetClinicLocations(this.getClinicID(), 1, 1000).subscribe(
      (locations) => {
        this.locations = locations.items;
      }
    );
  }
  ActivateView() {
    this.viewIsLoadingAppointments = true;

    this.LoadAppointments();
    this.LoadCallStatuses();

    this.loadClinicLocations();

    this.viewIsLoadingAppointments = false;
    this.FetchDoctors();
    this.LoadClinicAppointmentTypes();
    this.GetSpecialitiesForClinics();
    let viewAsUserID = this.User.userId;
    let viewAsClinicID = this.User.clinicId;
    if (this.ViewAs) {
      viewAsUserID = this.ViewAs.viewAsUserID;
      viewAsClinicID = this.ViewAs.clinicId;
    }

    this.GetLastSyncDate(viewAsClinicID);
  }
  snackBaTutorial() {
    let snackBar = this._snackBar.open(
      '¡Bienvenido a Citamed!, haz click en este botón para aprender a utilizar el sistema',
      'Ver tutorial ',
      {
        duration: 5000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'success-dialog'
      }
    );

    snackBar.onAction().subscribe(() => {
      this.StartDemo();
    });
  }

  public deleteAppointment(appointmentId: number) {
    let confirmed = window.confirm(
      '¿Estás segur@ de querer eliminar esta entrada?'
    );
    if (confirmed) {
      this.isPreloading = true;

      try {
        this.API.DeleteAppointmentCallSchedule(
          appointmentId,
          this.selectedSummaryCallType.callTypeId
        ).subscribe(() => {
          this.isPreloading = false;
          this.LoadAppointments();
        });
      } catch (e) {
        this.isPreloading = false;
      }
    }
  }

  public getClinicAssistanceStatusesConfig() {
    const clinicid = this.getClinicID();

    this.API.GetClinicAssistanceConfig(clinicid).subscribe(
      (data: ClinicDoctorAssistanceStatusDTO[]) => {
        this.assistanceData = data;
        const dictionary = {};

        data.forEach(
          (d) => (dictionary[`${d.assistanceStatusID}-${d.doctorId}`] = d)
        );

        this.clinicDoctorAssistanceStatusDictionary = dictionary;
      }
    );
  }

  public openJson(jsonString: string) {
    this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogModel(
        'PROVIDER API RESPONSE',
        null,
        null,
        'cerrar',
        '',
        `<pre>${JSON.stringify(JSON.parse(jsonString), null, 2)}</pre>`
      )
    });
  }
  public updateAppointmentAssistanceStatus(
    appointment: IAppointmentListItem,
    statusId: number
  ) {
    const onError = () => {
      alert('An error has occurred.');
    };
    this.API.UpdateAppointmentAssistanceStatus(
      statusId,
      appointment.appointmentId,
      appointment.clinicId
    ).subscribe(() => {
      const message = this.assistanceData.find(
        (c) =>
          c.doctorId == appointment.doctorId && c.assistanceStatusID == statusId
      );
      if (message.messageTemplate?.length) {
        this.API.SendSms({
          AppointmentId: appointment.appointmentId,
          ToNumber: appointment.patientPhoneNumber,
          MessageContent: message.messageTemplate
        }).subscribe(() => {
          this._snackBar.open(
            `Mensaje de texto enviado a ${appointment.patientName} con el contenido "${message.messageTemplate}"`,
            null,
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog'
            }
          );
        }, onError);
      }
    }, onError);
  }

  lastSync() {
    if (this.lastSyncDate) {
      return `Fecha de Última sincronización: ${this.lastSyncDate} para las citas del ${this.lastSyncForDate}.`;
    } else {
      return `Fecha de Última sincronización: NA.`;
    }
  }

  filterAppointmentsListByFlipCard(callStatusId: string) {
    if (callStatusId === this.appointmentsFlipCardStatus) {
      this.appointmentsFlipCardStatus = null;
    } else {
      this.appointmentsFlipCardStatus = callStatusId;
    }

    this.selectedStatusFilter.setValue(
      !this.appointmentsFlipCardStatus
        ? []
        : [this.callStatuses.find((c) => c.callStatusId === callStatusId)]
    );
    this.showMoreAptsFilters = true;
    this.currentPage = 0;
    this.pageSize = 15;

    let { $date, CallTypeId, viewAsUserID, clinicId } =
      this.getLoadAppointmentsArguments();

    this.LoadAppointmentsSummary($date, CallTypeId, viewAsUserID, clinicId);
  }
}
