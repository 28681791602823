// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `code {
  background-color: #fff7f0;
  padding-top: 5px;
  border-radius: 6px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: solid 1.5px #e7c29b;
  color: #8a4e0e;
  font-weight: bold;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNsaW5pYy10cmFuc2l0aW9uYWwtY2FyZS1ob3NwaXRhbGl6ZWMtdGFiLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UseUJBQUE7RUFDQSxnQkFBQTtFQUNBLGtCQUFBO0VBQ0EsbUJBQUE7RUFDQSxrQkFBQTtFQUNBLG1CQUFBO0VBQ0EsMkJBQUE7RUFDQSxjQUFBO0VBQ0EsaUJBQUE7QUFDRiIsImZpbGUiOiJjbGluaWMtdHJhbnNpdGlvbmFsLWNhcmUtaG9zcGl0YWxpemVjLXRhYi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImNvZGUge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZmZmN2YwO1xuICBwYWRkaW5nLXRvcDogNXB4O1xuICBib3JkZXItcmFkaXVzOiA2cHg7XG4gIHBhZGRpbmctYm90dG9tOiA1cHg7XG4gIHBhZGRpbmctbGVmdDogMTBweDtcbiAgcGFkZGluZy1yaWdodDogMTBweDtcbiAgYm9yZGVyOiBzb2xpZCAxLjVweCAjZTdjMjliO1xuICBjb2xvcjogIzhhNGUwZTtcbiAgZm9udC13ZWlnaHQ6IGJvbGQ7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/Pages/clinic-transitional-care-hospitalizec-tab/clinic-transitional-care-hospitalizec-tab.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,2BAAA;EACA,cAAA;EACA,iBAAA;AACF;AACA,4vBAA4vB","sourcesContent":["code {\n  background-color: #fff7f0;\n  padding-top: 5px;\n  border-radius: 6px;\n  padding-bottom: 5px;\n  padding-left: 10px;\n  padding-right: 10px;\n  border: solid 1.5px #e7c29b;\n  color: #8a4e0e;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
