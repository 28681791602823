import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ApiService } from '../api.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { VaConversationMessage } from '../DTOS/VaConversation/VaConversationMessage';
import GenericList from '../DTOS/Generics/GenericList';
import groupBy from 'lodash-es/groupBy';
import moment from 'moment';
import { Dictionary } from 'lodash';
import {
  bounceOutDownOnLeaveAnimation,
  fadeInUpOnEnterAnimation
} from 'angular-animations';
import { VaConversation } from '../DTOS/VaConversation/va-conversation';

@Component({
  selector: 'app-ai-bot-chat',
  templateUrl: './ai-bot-chat.component.html',
  styleUrl: './ai-bot-chat.component.scss',
  animations: [
    fadeInUpOnEnterAnimation({
      anchor: 'enter',
      duration: 1000,
      delay: 70,
      translate: '30px'
    }),
    bounceOutDownOnLeaveAnimation({
      anchor: 'leave',
      duration: 500,
      delay: 200,
      translate: '40px'
    })
  ]
})
export class AiBotChatComponent implements OnInit, OnChanges, AfterViewChecked {
  constructor(public api: ApiService) {}

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  public chats: GenericList<VaConversationMessage>;
  public grouppedMessages: Dictionary<VaConversationMessage[]> = {};

  public messagesObs: BehaviorSubject<VaConversationMessage[]> =
    new BehaviorSubject<VaConversationMessage[]>([]);

  public grouppedKeys: string[] = [];
  userScrolled: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    this.scrolled = false;
    this.loadChats();
  }

  ngOnInit(): void {
    this.messagesObs.subscribe((val) => {
      const grouped = groupBy(val, (c) =>
        moment.utc(c.createDate).local().format('DD MMMM YYYY')
      );
      this.grouppedKeys = Object.keys(grouped);
      this.grouppedMessages = grouped;

      console.log('this.grouppedMessages', this.grouppedMessages);
    });

    this.loadChats();
  }
  @Input() conversation: VaConversation;
  @Input() accountId: number;

  public async loadChats() {
    const chats = await firstValueFrom(
      this.api.GetVaConversationMessages(
        this.accountId,
        this.conversation.conversationId,
        1,
        1000
      )
    );

    chats.items.forEach((chat) => {
      chat._timeSince = moment.utc(chat.createDate).local().fromNow();
    });

    chats.items[chats.items.length - 1]._isLast = true;
    this.chats = chats;

    this.messagesObs.next(this.chats.items);

    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
  }

  public scrolled: Boolean = false;

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  public scrollToBottom(): void {
    console.log('scrollToBottom');
    if (this.scrolled || !this.chats.items.length) {
      return;
    }
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
      this.scrolled = true;
    } catch (err) {}
  }
}
