import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { User } from '../../_models';
import { AuthenticationService } from '../../_services/authentication.service';
import { AdminViewAsClinicDTO } from '../../DTOS/AdminViewAsClinicDTO';

@Component({
  selector: 'app-bulk-message-create',
  templateUrl: './bulk-message-create.component.html',
  styleUrls: ['./bulk-message-create.component.scss']
})
export class BulkMessageCreateComponent implements AfterViewInit {
  uploadedFiles: any[] = [];
  public User: User;
  public ViewAs: AdminViewAsClinicDTO;
  isLoading: boolean;
  showCancelButton = false;
  public isEditing = false;
  public messageContent = '';
  public date: Date = new Date();
  public time: Date;

  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {}

  public done() {
    alert('Hemos recibido su solicitud, estaremos trabajando en la misma.');
    location.replace('/');
  }

  public getUrl() {
    if (!this.date || !this.messageContent || !this.time) {
      return;
    }

    return `/Campaigns/ScheduleCampaign?scheduleDate=${moment(this.date).format(
      'YYYY-MM-DD'
    )}&scheduleTime=${moment(this.time).format('hh:mm a')}&message_content=${
      this.messageContent
    }`;
  }
  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
  }
  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
  }

  onUpload(event) {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  onBeforeUpload() {
    return false;
  }
}
