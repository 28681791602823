export enum CitamedPrinterEhr {
  PractiseFusion = 1,
  OncoEMR = 2,
  PayDc = 3,
  SoapWare = 4,
  EHRz = 5,
  Prognocis = 6,
  Medicus = 7,
  Neomed = 8,
  Assertus = 9,
  Atlas = 10,
  PracticePerfect = 11,
  PrognocisScheduleReport = 12,
  SecureEmr = 13,
  AestheticRecord = 14,
  OrtoWare = 15
}
