import { Component } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-virtual-phones-list',
  templateUrl: './virtual-phones-list.component.html',
  styleUrls: ['./virtual-phones-list.component.scss']
})
export class VirtualPhonesListComponent {
  tableObsevable: any;
  public phonesBatch: string;
  public isLoading: boolean;

  constructor(private API: ApiService) {
    this.tableObsevable = API.GetVirtualPhones();
  }

  public async createPhonesBatch() {
    try {
      this.isLoading = true;
      await firstValueFrom(
        this.API.CreateVirtualPhonesInBatch(this.phonesBatch)
      );
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      alert('Error' + JSON.stringify(error));
    }
  }
}
