// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.import-side-bar.container {
  margin-left: 20px;
}
.import-side-bar.footer {
  margin-top: 50px;
}
.import-side-bar.cancel-btn {
  margin-right: 10px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRyYW5zaXRpb25hbC1jYXJlLWFkbWlzaW9ucy1hbmQtZGlzY2hhcmdlcy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLGlCQUFBO0FBQUo7QUFFRTtFQUNFLGdCQUFBO0FBQUo7QUFHRTtFQUNFLGtCQUFBO0FBREoiLCJmaWxlIjoidHJhbnNpdGlvbmFsLWNhcmUtYWRtaXNpb25zLWFuZC1kaXNjaGFyZ2VzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmltcG9ydC1zaWRlLWJhciB7XG4gICYuY29udGFpbmVyIHtcbiAgICBtYXJnaW4tbGVmdDogMjBweDtcbiAgfVxuICAmLmZvb3RlciB7XG4gICAgbWFyZ2luLXRvcDogNTBweDtcbiAgfVxuXG4gICYuY2FuY2VsLWJ0biB7XG4gICAgbWFyZ2luLXJpZ2h0OiAxMHB4O1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/Pages/transitional-care-admisions-and-discharges/transitional-care-admisions-and-discharges.component.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ;AAGE;EACE,kBAAA;AADJ;AACA,wmBAAwmB","sourcesContent":[".import-side-bar {\n  &.container {\n    margin-left: 20px;\n  }\n  &.footer {\n    margin-top: 50px;\n  }\n\n  &.cancel-btn {\n    margin-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
