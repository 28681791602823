import { AuthGuard, AuthGuardAdmin } from './_helpers';
import { AccountDetailsComponent } from './Components/account-details/account-details.component';
import { AccountsComponentComponent } from './Pages/va-accounts/va-accounts.component';
import { AddAppointmentsComponent } from './Pages/add-appointments/add-appointments.component';
import { AddRemindersComponent } from '../admin/Pages/add-reminders/add-reminders.component';
import { AddUserComponent } from '../shared/Components/add-user/add-user.component';
import { AppointmentsRequestListComponent } from './Pages/appointments-request-list/appointments-request-list.component';
import { BookingsNewBookingComponent } from './Pages/bookings-new-booking/bookings-new-booking.component';
import { BulkMessageCreateComponent } from './Pages/bulk-message-create/bulk-message-create.component';
import { CallAgainSchedulesComponent } from './Pages/call-again-schedules/call-again-schedules.component';
import { ClinicAppointmentTypesComponent } from './Pages/clinic-appointment-types/clinic-appointment-types.component';
import { ClinicDetailsComponent } from '../admin/Pages/clinic-details/clinic-details.component';
import { CompanyDetailsComponent } from './Pages/company-details/company-details.component';
import { DigitalWaitingListComponent } from './Pages/digital-waiting-list/digital-waiting-list.component';
import { DoctorsListComponent } from '../shared/Components/doctors-list/doctors-list.component';
import { EngineSettingsComponent } from './Pages/engine-settings/engine-settings.component';
import { HomeComponent } from './Pages/home/home.component';
import { LoginComponent } from './Pages/login/login.component';
import { MissedCallsComponent } from './Pages/missed-calls/missed-calls.component';
import { NoCallDateComponent } from './Pages/no-call-date/no-call-date.component';
import { PasswordRecoverComponent } from './Pages/password-recover/password-recover.component';
import { PasswordRecoveryComponent } from './Pages/password-recovery/password-recovery.component';
import { RemindersComponent } from './Pages/reminders/reminders.component';
import { RemindersetRemindersetComponent } from './Pages/reminderset-reminderset/reminderset-reminderset.component';
import { Route } from '@angular/router';
import { SpecialAnnouncementComponent } from './Pages/special-announcement/special-announcement.component';
import { TransitionalCareAdmisionsAndDischargesComponent } from './Pages/transitional-care-admisions-and-discharges/transitional-care-admisions-and-discharges.component';
import { TwilioCallerIdActivatorComponent } from './Pages/twilio-caller-id-activator/twilio-caller-id-activator.component';
import { UsersComponent } from '../shared/Components/users/users.component';
import { VaConfigurationComponent } from './Pages/va-configuration/va-configuration.component';
import { LandingComponent } from './landing/landing.component';
import { AdminDashboardComponent } from '../admin/Pages/admin-dashboard/admin-dashboard.component';
import { ConversationsComponent } from './Pages/conversations/conversations.component';
import { CallStatusesComponent } from './Pages/call-statuses/call-statuses.component';
import { CallStatusesCreateComponent } from './Pages/call-statuses-create/call-statuses-create.component';
import { QuickRepliesComponent } from './Pages/quick-replies/quick-replies.component';
import { PhoneNumberAppointmentsHistoryComponent } from './Pages/phone-number-appointments-history/phone-number-appointments-history.component';
import { VirtualPhonesListComponent } from './Pages/virtual-phones-list/virtual-phones-list.component';
import { ReportsPageComponent } from './Pages/reports-page/reports-page.component';
import { LocationsComponent } from './Pages/locations/locations.component';
import { LocationsDetailsComponent } from './Pages/locations-details/locations-details.component';
import { BillingAccountsComponent } from './Pages/billing-accounts/billing-accounts.component';
import { VaIntakeFormsDetailsComponent } from './Pages/va-intake-forms-details/va-intake-forms-details.component';
import { SmsLogsComponent } from './sms-logs/sms-logs.component';
import { RemindersetComponent } from './Pages/reminderset/reminderset.component';

interface CitamedRoute extends Route {
  routeName?: string;
  hideNavigationBar?: boolean;
}

export type AppRoutesInfo = {
  routeName: string;
  path: string;
  hideNavigationBar?: boolean;
};

export const applicationRoutes = {
  ConfirmationsPage: 'confirmaciones',
  chatPage: 'chat'
};

const appRoutes: CitamedRoute[] = [
  {
    path: '',
    component: LandingComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    routeName: 'Inicio'
  },
  {
    path: applicationRoutes.ConfirmationsPage,
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    routeName: 'Inicio'
  },

  {
    path: applicationRoutes.ConfirmationsPage + '/:clinicID/:date',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    routeName: 'Inicio'
  },

  // {
  //   path: 'admin-view-as/:clinicID',
  //   component: HomeComponent,
  //   canActivate: [AuthGuardAdmin],
  //   routeName: 'Inicio',
  // },
  {
    path: 'admin/clinic/:clinicID/confirmations/:date',
    component: HomeComponent,
    canActivate: [AuthGuardAdmin],
    routeName: 'Inicio'
  },
  {
    path: 'login',
    component: LoginComponent,
    routeName: 'Citamed - iniciar sesión'
  },
  {
    path: 'RecoverPassword',
    component: PasswordRecoveryComponent,
    routeName: 'Recuperar contraseña'
  },
  {
    path: 'ResetPassword',
    component: PasswordRecoverComponent,
    routeName: 'Reestablecer contraseña'
  },
  {
    path: 'add-appointments',
    component: AddAppointmentsComponent,
    canActivate: [AuthGuard],
    routeName: 'Añadir Citas'
  },
  {
    path: 'no-call-dates-add-new',
    component: NoCallDateComponent,
    canActivate: [AuthGuard],
    routeName: 'Pausar envio de mensajes'
  },
  {
    path: 'special-Announcement',
    component: SpecialAnnouncementComponent,
    canActivate: [AuthGuard],
    routeName: 'Llamada Especial'
  },
  {
    path: 'Users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    routeName: 'Usuarios'
  },
  {
    path: 'Add-User',
    component: AddUserComponent,
    canActivate: [AuthGuard],
    routeName: 'Añadir Usuario'
  },
  {
    path: 'Doctors-list',
    component: DoctorsListComponent,
    canActivate: [AuthGuard],
    routeName: 'Doctores'
  },
  {
    path: 'my-clinic-appointment-types',
    component: ClinicAppointmentTypesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'account-details',
    component: AccountDetailsComponent,
    canActivate: [AuthGuard],
    routeName: 'Detalles y perfil'
  },
  {
    path: 'waiting-list',
    component: DigitalWaitingListComponent,
    canActivate: [AuthGuard],
    hideNavigationBar: true
  },
  {
    path: 'reminders',
    component: RemindersComponent,
    canActivate: [AuthGuardAdmin],
    hideNavigationBar: true
  },
  {
    path: 'add-reminders',
    component: AddRemindersComponent,
    canActivate: [AuthGuardAdmin],
    routeName: 'Añadir mensaje'
  },
  {
    path: 'reminderSet-reminderSet',
    component: RemindersetRemindersetComponent,
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'reminderSet-list',
    component: RemindersetComponent,
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'twilio-caller-id-activator',
    component: TwilioCallerIdActivatorComponent,
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'call-again-schedules',
    component: CallAgainSchedulesComponent,
    canActivate: [AuthGuardAdmin]
  },
  // {
  //   path: 'clinic/:clinicId',
  //   component: ClinicDetailsComponent,
  //   canActivate: [AuthGuardAdmin],
  // },
  {
    path: 'admin/clinic/New',
    component: ClinicDetailsComponent,
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'VA/accounts',
    component: AccountsComponentComponent,
    routeName: 'Asistentes Virtuales',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'asistente-virtual/llamadas-perdidas',
    component: MissedCallsComponent,
    routeName: 'Llamadas Perdidas',
    canActivate: [AuthGuard]
  },

  {
    path: 'asistente-virtual/llamadas-perdidas/:accountID',
    component: MissedCallsComponent,
    routeName: 'Llamadas Perdidas',
    canActivate: [AuthGuard]
  },

  {
    path: 'solicitudes-de-citas',
    component: AppointmentsRequestListComponent,
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'asistente-virtual/Configuracion',
    component: VaConfigurationComponent,
    routeName: 'Configuración de asistente virtual',
    canActivate: [AuthGuard]
  },
  {
    path: 'asistente-virtual/Configuracion/:accountID',
    component: VaConfigurationComponent,
    routeName: 'Configuración de asistente virtual',
    canActivate: [AuthGuard]
  },
  {
    path: 'asistente-virtual/Configuracion/:accountID/questionario/:intakeFormId',
    component: VaIntakeFormsDetailsComponent,
    routeName: 'Cuestionario de asistente virtual',
    canActivate: [AuthGuard]
  },
  ,
  {
    path: 'asistente-virtual/Configuracion/:accountID/nuevo-questionario',
    component: VaIntakeFormsDetailsComponent,
    routeName: 'Cuestionario de asistente virtual',
    canActivate: [AuthGuard]
  },
  {
    path: 'campañas/crear',
    component: BulkMessageCreateComponent,
    routeName: 'Crear nueva campaña',
    canActivate: [AuthGuard]
  },
  {
    path: 'Transitional-Care-admisions-and-discharges',
    component: TransitionalCareAdmisionsAndDischargesComponent,
    hideNavigationBar: true,
    canActivate: [AuthGuard]
  },
  {
    path: 'Agendamiento/Nuevo',
    component: BookingsNewBookingComponent,
    routeName: 'Registrar nueva cita',
    canActivate: [AuthGuard]
  },
  {
    path: 'BM',
    loadChildren: () =>
      import('../bookings/bookings.module').then((m) => m.BookingsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'administrator',
    loadChildren: () =>
      import('../admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'plugins/stored-procedures',
    loadChildren: () =>
      import('../plugin-modules/sproc-editor/sproc-editor.module').then(
        (m) => m.StoredProcedureEditorModule
      )
  },
  {
    path: 'company/:companyId',
    component: CompanyDetailsComponent,
    canActivate: [AuthGuardAdmin],
    routeName: 'Cuenta de facturación'
  },
  {
    path: 'admin/company/New',
    component: CompanyDetailsComponent,
    canActivate: [AuthGuardAdmin],
    routeName: 'Cuentas de Facturación'
  },
  {
    path: 'engine-setting',
    component: EngineSettingsComponent,
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'admin/dashboard',
    component: AdminDashboardComponent,
    canActivate: [AuthGuardAdmin],
    routeName: 'Dashboard de administradores'
  },
  {
    path: 'admin/call-statuses',
    component: CallStatusesComponent,
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'admin/call-statuses/create',
    component: CallStatusesCreateComponent,
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'admin/call-statuses/details/:callStatusId',
    component: CallStatusesCreateComponent,
    canActivate: [AuthGuardAdmin]
  },
  {
    path: applicationRoutes.chatPage,
    component: ConversationsComponent,
    canActivate: [AuthGuard],
    hideNavigationBar: true
  },
  {
    path: `admin/clinic/:clinicId/` + applicationRoutes.chatPage,
    component: ConversationsComponent,
    canActivate: [AuthGuard],
    hideNavigationBar: true
  },
  {
    path: `admin/clinic/:clinicId/${applicationRoutes.chatPage}/:focusedChatType`,
    component: ConversationsComponent,
    canActivate: [AuthGuard],
    hideNavigationBar: true
  },
  {
    path: 'respuestas-rapidas',
    component: QuickRepliesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'historial/:clinicId/:phoneNumberId',
    component: PhoneNumberAppointmentsHistoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/virtual-phones-list',
    component: VirtualPhonesListComponent,
    canActivate: [AuthGuardAdmin]
  },

  {
    path: 'admin/locations',
    component: LocationsComponent,
    routeName: 'Localidades',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'admin/locations/details/:locationId/:clinicId',
    component: LocationsDetailsComponent,
    routeName: 'Localidad',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'admin/locations/create/:clinicId',
    component: LocationsDetailsComponent,
    routeName: 'Localidad',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'reportes',
    component: ReportsPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cuentas-de-facturacion/:companyId/cancelar',
    component: BillingAccountsComponent,
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'historial-de-mensajes',
    component: SmsLogsComponent,
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

const appRoutesNames: AppRoutesInfo[] = appRoutes.map((a) => {
  const route = a as CitamedRoute;
  return {
    routeName: route.routeName,
    path: (route as any).path,
    hideNavigationBar: route.hideNavigationBar
  };
});

export { appRoutes, appRoutesNames };
