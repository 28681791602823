import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import moment from 'moment';
import { LONG_DATE_FORMAT } from 'src/app/Constants/constants';
import { ChangeLogRoot } from 'src/app/DTOS/ChangeLogs/changeLog';
import { CitamedEntity } from 'src/app/DTOS/commont';
import { ApiService } from 'src/app/api.service';
import { snakeCaseToNormal } from 'src/shared/helpers/helper-functions';

@Component({
  selector: 'app-change-logs',
  templateUrl: './change-logs.component.html',
  styleUrls: ['./change-logs.component.scss']
})
export class ChangeLogsComponent implements OnInit {
  @Input() public entityType: CitamedEntity;
  @Input() public entityId: number;

  constructor(private API: ApiService) {}

  // ngOnChanges(changes: SimpleChanges): void {
  //   this.loadHistory();
  // }

  ngOnInit(): void {
    this.loadHistory();
  }
  histories: ChangeLogRoot[];

  public getKeyValArray = (obj: any) => {
    const keys = Object.keys(obj);

    const arr = keys.map((key) => {
      const formattedKey = snakeCaseToNormal(key);

      return { key: formattedKey, value: obj[key] };
    });

    return arr;
  };
  public loadHistory = () => {
    this.API.getChangeLogs(this.entityType, this.entityId).subscribe((r) => {
      this.histories = r.map((v) => ({
        ...v,

        log: {
          ...v.log,
          original: this.getKeyValArray(JSON.parse(v.log.original)),
          updated: this.getKeyValArray(JSON.parse(v.log.updated)),
          date: moment.utc(v.log.date).local().format(LONG_DATE_FORMAT)
        }
      }));
    });
  };
}
