import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services';
import { ApiService, SmsLogGet } from '../api.service';
import { firstValueFrom } from 'rxjs';
import GenericList from '../DTOS/Generics/GenericList';
import { User } from '../_models';
import { AdminViewAsClinicDTO } from '../DTOS/AdminViewAsClinicDTO';
import moment from 'moment';

@Component({
  selector: 'app-sms-logs',
  templateUrl: './sms-logs.component.html',
  styleUrl: './sms-logs.component.scss'
})
export class SmsLogsComponent implements OnInit {
  user: User;
  clinicId: number;
  public direction: string = '';
  public from: string = '';
  public to: string = '';

  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService
  ) {}

  public pageData: GenericList<SmsLogGet>;
  public statuses: string[] = [
    'delivered',
    'failed',
    'message-delivered',
    'message-failed',
    'queued',
    'received',
    'sent',
    'undelivered'
  ];

  public selectedStatuses: string[] = [];

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((u) => {
      this.user = u;
    });
    this.paginate();
  }

  public onViewAsClinicIdChange(u: AdminViewAsClinicDTO) {
    this.clinicId = u.clinicId;
  }

  public currentPage: number = 0;
  public pageSize: number = 30;

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.paginate();
  }

  public async paginate() {
    const items = await firstValueFrom(
      this.API.GetPaginatedSmsLogs(
        this.clinicId,
        this.currentPage + 1,
        this.pageSize,
        this.direction,
        this.from,
        this.to,
        this.selectedStatuses
      )
    );

    items.items.forEach((item) => {
      item._formatedCreateDate = moment
        .utc(item.createDate)
        .local()
        .format('DD-MM-yyyy');

      item._formatedHour = moment
        .utc(item.createDate)
        .local()
        .format('hh:mm a');
    });

    this.pageData = items;
  }

  public cleanFilters() {
    this.clinicId = null;
    this.selectedStatuses = [];
    this.from = '';
    this.to = '';
    this.direction = '';
  }
}
