export const isDevelopmentEnv = () => {
  return (
    window.location.hostname.includes('dev-env.citamed-secure.com') ||
    window.location.hostname.includes('localhost')
  );
};

export const removeFalsyValues = (object: any) => {
  let output = {};

  Object.keys(object).forEach((k) => {
    if (object[k]) {
      output[k] = object[k];
    }
  });

  return output;
};
