export default {
  DIGITAL_WAITING_LIST: 'Features.DWL',
  VIRTUAL_ASSISTANT: 'Features.VA',
  CAMPAIGNS: 'Features.BM',
  TRANSITIONAL_CARE: 'Features.TC',
  APPOINTMENT_ASSISTANCE: 'Features.AS',
  GOOGLE_CALENDAR: 'Features.GooglCal',
  PATIENT_MANAGEMENT: 'Features.PatientManagement',
  HOME_V2: 'Features.HomeSummary.v2',
  BOOKINGS: 'Features.Bookings',
  CONVERSATIONS: 'Features.Conversations',
  BOOKINGS_AGENDA_AS_PRIMARY_VIEW: 'Bookings.AgendaAsPrimary',
  BLOCK_APPOINTMENT_MANAGEMENT: 'AntiPerm.AppointmentManagement',
  HIDE_MONTH_VIEW: 'AntiPerm.Appointments.NoMonthView',
  CREATE_USERS: 'System.CreateUser',
  CAN_SEE_DAILY_REPORTS: 'Confirmations.SeeDailyReport',
  AI_CHAT_BOT: 'Features.AIAssistantMessages'
};
