import { Component, OnInit } from '@angular/core';
import {
  bounceAnimation,
  bounceOutDownOnLeaveAnimation,
  fadeInUpOnEnterAnimation
} from 'angular-animations';
import { Observable } from 'rxjs';
import { AdminViewAsClinicDTO } from 'src/app/DTOS/AdminViewAsClinicDTO';
import { QuickReply } from 'src/app/DTOS/QuickReplies/quickReply';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-quick-replies',
  templateUrl: './quick-replies.component.html',
  styleUrls: ['./quick-replies.component.scss'],
  animations: [
    bounceAnimation({ duration: 2000 }),
    fadeInUpOnEnterAnimation({
      anchor: 'enter',
      duration: 1000,
      delay: 100,
      translate: '30px'
    }),
    bounceOutDownOnLeaveAnimation({
      anchor: 'leave',
      duration: 500,
      delay: 200,
      translate: '40px'
    })
  ]
})
export class QuickRepliesComponent implements OnInit {
  public quickReplies: QuickReply[] = [];

  public getClinicId = () => {
    let clinicid = this.User.clinicId;
    if (this.viewAs) clinicid = this.viewAs.clinicId;
    return clinicid;
  };

  public User: User;
  private viewAs: AdminViewAsClinicDTO;
  public isSlidePanelOpened: boolean = false;
  public quickReplyForm: QuickReply;
  public isSubmitting: boolean = false;

  constructor(
    private API: ApiService,
    private authenticationService: AuthenticationService
  ) {}

  onViewAsClinicIdChange($event: AdminViewAsClinicDTO) {
    this.viewAs = $event;
    this.loadQuickReplies();
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((User) => {
      this.User = User;
      this.loadQuickReplies();
    });
  }

  public loadQuickReplies = () => {
    this.API.GetAllQuickReplies(this.getClinicId()).subscribe(
      (allQuickReplies) => {
        this.quickReplies = allQuickReplies;
      }
    );
  };

  public closeSidePanel = () => {
    this.quickReplyForm = undefined;
    this.isSlidePanelOpened = false;
  };

  public openSidePanel = (quickReplyData: QuickReply) => {
    if (!quickReplyData) {
      this.quickReplyForm = {
        quickReplyId: undefined,
        content: '',
        shortcut: ''
      };
    } else {
      this.quickReplyForm = structuredClone(quickReplyData);
    }

    this.isSlidePanelOpened = true;
  };

  public save = () => {
    this.isSubmitting = true;
    let sub: Observable<Object>;

    const callback = () => {
      this.closeSidePanel();
      this.isSubmitting = false;
    };
    const dto: QuickReply = structuredClone({
      ...this.quickReplyForm,
      clinicId: this.getClinicId()
    });

    if (this.quickReplyForm.quickReplyId) {
      this.API.UpdateQuickReply(dto).subscribe((response) => {
        const idx = this.quickReplies.findIndex(
          (q) => q.quickReplyId === response.quickReplyId
        );
        if (idx > -1) {
          this.quickReplies[idx] = response;
        }

        callback();
      });
    } else {
      this.API.CreateQuickReply(dto).subscribe((response) => {
        this.quickReplies.push(response);
        callback();
      });
    }

    // sub.subscribe();
  };

  public deleteQuickReply = (qr: QuickReply) => {
    this.API.deleteQuickReply(qr.quickReplyId).subscribe(() => {
      const index = this.quickReplies.findIndex(
        (q) => q.quickReplyId === qr.quickReplyId
      );

      if (index > -1) {
        this.quickReplies.splice(index, 1);
      }

      this.closeSidePanel();
    });
  };

  public isFormValid = () => {
    if (!this.quickReplyForm) {
      return false;
    }
    return this.quickReplyForm.content && this.quickReplyForm.shortcut;
  };
}
