import { Component, Input, OnInit } from '@angular/core';
import { IAppointmentListItem } from 'src/app/DTOS/Appointments/AppointmentListItem';
import { ChangeLogRoot } from 'src/app/DTOS/ChangeLogs/changeLog';
import { CitamedEntity } from 'src/app/DTOS/commont';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-appointment-change-log',
  templateUrl: './appointment-change-log.component.html',
  styleUrls: ['./appointment-change-log.component.scss']
})
export class AppointmentChangeLogComponent implements OnInit {
  constructor(private API: ApiService) {}

  public callScheduleIds: number[];

  public entityType = CitamedEntity.ConfirmationsAppointment;
  public callscheduleEntityType = CitamedEntity.CallSchedule;

  @Input() public appointment: IAppointmentListItem;

  ngOnInit(): void {
    this.API.getAppointmentCallScheduleIds(
      this.appointment.appointmentId
    ).subscribe((r) => {
      this.callScheduleIds = r;
    });
  }
}
