import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ApiService } from '../api.service';
import { firstValueFrom } from 'rxjs';
import GenericList from '../DTOS/Generics/GenericList';
import {
  VaContact,
  VaConversation
} from '../DTOS/VaConversation/va-conversation';
import moment from 'moment';

@Component({
  selector: 'app-ai-bot-chats-list',
  templateUrl: './ai-bot-chats-list.component.html',
  styleUrl: './ai-bot-chats-list.component.scss'
})
export class AiBotChatsListComponent implements OnInit, OnChanges {
  @Input() accountId: number;
  /**
   *
   */
  constructor(private API: ApiService) {}
  public chats: GenericList<VaConversation>;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.accountId &&
      Number(changes.accountId.currentValue) !== Number(this.accountId)
    ) {
      this.loadList();
    }
  }

  @Input() onChatSelected: (chat: VaConversation) => void;

  public getName(contact: VaContact) {
    if (!contact || (!contact.name && !contact.lastName)) {
      return contact.phone || 'Desconocido';
    }
    return `${contact.name || ''} ${contact.lastName || ''}`.trim();
  }

  public selectedChatId: number;
  public onChatClicked(chat: VaConversation) {
    this.onChatSelected(chat);
    this.selectedChatId = chat.conversationId;
  }

  public loadList = async () => {
    const chats = await firstValueFrom(
      this.API.GetVaConversations(this.accountId, 1, 10)
    );
    this.chats = chats;
  };

  public formatLastDate(date: Date) {
    if (!date) {
      return '';
    }
    const nowFormatted = moment().format('DD-MM-YYYY');

    const dMoment = moment.utc(date).local();
    const dateFormatted = dMoment.format('DD-MM-YYYY');
    if (dateFormatted === moment().add(-1, 'days').format('DD-MM-YYYY')) {
      return 'Ayer';
    } else if (dateFormatted === nowFormatted) {
      return dMoment.format('hh:mm a');
    } else {
      return dateFormatted;
    }
  }

  ngOnInit(): void {
    this.loadList();
  }
}
