export interface CustomerNote {
  customerNoteId?: number;
  entityId: number;
  entityType: CustomerNoteEntityType;
  isPinned: boolean;
  content: string;
  clinicId: number;
}
export interface GetCustomerNoteDTO {
  customerNoteId: number;
  isPinned: boolean;
  content: string;
  author: Author;
  createDate: Date;
}
export interface Author {
  userId: number;
  clinicId: number;
  username: string;
}

export enum CustomerNoteEntityType {
  PatientRecord = 1,
  BookingsAppointment = 2,
  ConfirmationsAppointment = 3,
  VA_Call = 4,
  ChatCustomerNote = 5,
  Chat_Appointment = 6
}

export enum ConversationChangeAction {
  ARCHIVE = 1,
  UNARCHIVE = 2,
  SEEN = 3
}

export interface GetConversationsChangeLogItem {
  createDate: string;
  action: ConversationChangeAction;
  author: Author;
  conversationChangeLogId: number;
  _formattedDate: string;
}
