import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import groupBy from 'lodash-es/groupBy';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ApiService } from '../../api.service';
import {
  SmsStats,
  SmsStatsInRange,
  StatPerClinicGrouper,
  StatsPerClinic
} from '../../DTOS/System/SmsStats';

type SmsTableDataSource = StatPerClinicGrouper & {
  inboundCount: number;
  outboundCount: number;
};
export interface Dataset {
  data: number[];
}

export interface Data {
  datasets: Dataset[];
  labels: string[];
}

export interface RootObject {
  barChartOptions: ChartOptions;
  data: Data;
}

@Component({
  selector: 'admin-sms-stats',
  templateUrl: './admin-sms-stats.component.html',
  styleUrls: ['./admin-sms-stats.component.scss']
})
export class AdminSmsStatsComponent implements OnInit, OnChanges {
  constructor(private API: ApiService) {
    this.date.next(new Date());
  }

  smsStatsObservable: Observable<SmsStats>;

  public date: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());

  public fromDate: Date;
  public toDate: Date;

  @Input() public selectedDate: Date;
  subscription: Subscription;
  public smsStats: SmsStats;
  public isLoading: boolean = false;

  smsDataSource: SmsTableDataSource[];
  displayedColumns: string[] = [
    'Clinica',
    'Mensajes Enviados',
    'Mensajes Recibidos',
    'Via'
  ];
  //////////
  public smsProviderChartLabels: string[] = [''];
  public smsProviderChartDatasets: ChartConfiguration<'doughnut'>['data']['datasets'] =
    [{ data: [] }];
  smsStatsInRange: SmsStatsInRange[];
  //////////

  public doughnutChartColors: any[] = [];
  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'Twilio',
        backgroundColor: 'rgba(242,52,74,0.2)',
        borderColor: '#f2344a',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin'
      },
      {
        data: [],
        label: 'Bandwith',
        backgroundColor: 'rgba(8,156,238,0.2)',
        borderColor: '#089cee',
        pointBackgroundColor: 'rgba(77,83,96,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(77,83,96,1)',
        fill: 'origin'
      }
    ],
    labels: []
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5
      }
    },

    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      y: {
        position: 'left'
      },
      y1: {
        position: 'right',
        grid: {
          color: 'rgba(255,0,0,0.3)'
        },
        ticks: {
          color: 'red'
        }
      }
    },

    plugins: {
      legend: { display: true }
    }
  };

  public onSelectedDateChange(date: Date) {
    this.date.next(date);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selectedDate) {
      this.onSelectedDateChange(changes?.selectedDate.currentValue);
      this.selectedDate = changes?.selectedDate.currentValue;
    }
  }

  public getThelephonyProviderLogo(telephonyId: number) {
    if (telephonyId === 2) {
      return '/Images/partners/logos/' + '2.png';
    } else if (telephonyId === 3) {
      return '/Images/partners/logos/' + '3.png';
    } else {
      return '/Images/partners/logos/' + '0.png';
    }
  }

  public monthDateFormat(date: Date) {
    return moment(date).format('MMM DD');
  }
  ngOnInit(): void {
    this.smsStatsObservable = this.date.pipe(
      switchMap((d) => {
        // cancels the query
        if (this.subscription && !this.subscription.closed) {
          this.subscription.unsubscribe();
        }
        this.isLoading = true;
        this.selectedDate = d;
        const subscription = this.API.GetSmsStats(d);
        this.subscription = subscription.subscribe();

        this.fromDate = moment(d).startOf('month').toDate();
        this.toDate = moment(d).endOf('month').toDate();

        this.API.GetSmsStatsInRange(this.fromDate, this.toDate).subscribe(
          (r) => {
            this.smsStatsInRange = r;
            const distinctDates = [
              ...new Set(r.map((ee) => moment(ee.date).format('MMM DD')))
            ];

            const allTwilio = r.filter((t) => t.provider === 2);

            this.lineChartData.datasets[0].data = distinctDates.map(
              (dateFormatted) => {
                return (
                  allTwilio.find(
                    (f) => moment(f.date).format('MMM DD') === dateFormatted
                  )?.count || 0
                );
              }
            );
            const allBandwidth = r.filter((T) => T.provider === 3);

            this.lineChartData.datasets[1].data = distinctDates.map(
              (dateFormatted) => {
                return (
                  allBandwidth.find(
                    (f) => moment(f.date).format('MMM DD') === dateFormatted
                  )?.count || 0
                );
              }
            );

            this.lineChartData.labels = distinctDates;
          }
        );

        return subscription;
      })
    );

    this.smsStatsObservable.subscribe((data) => {
      this.smsStats = data;
      const colors: { backgroundcolor: string; hoverColor: string }[] = [];
      this.smsProviderChartDatasets = [
        {
          data: data.statsPerProvider.map((dd) => {
            if (dd.provider?.telephonyProviderId === 2) {
              colors.push({
                backgroundcolor: 'rgba(242,52,74)',
                hoverColor: 'rgba(242,52,74,0.5)'
              });
            } else if (dd.provider?.telephonyProviderId === 3) {
              colors.push({
                backgroundcolor: 'rgba(8,156,238)',
                hoverColor: 'rgba(8,156,238,0.5)'
              });
            } else {
              colors.push({
                backgroundcolor: 'gray',
                hoverColor: 'gray'
              });
            }
            return dd.count;
          }),
          backgroundColor: colors.map((c) => c.backgroundcolor),
          hoverBackgroundColor: colors.map((c) => c.hoverColor)
        }
      ];

      this.smsProviderChartLabels = data.statsPerProvider.map(
        (d) => d?.provider?.telephonyProviderName || 'Desconocido'
      );

      const aa = groupBy(data.statsPerClinic, (a) => {
        return JSON.stringify({
          telephonyProvider: a.telephonyProvider,
          clinic: a.clinic
        });
      });

      try {
        this.smsDataSource = Object.keys(aa).map((val) => {
          const parsedVal: StatPerClinicGrouper = JSON.parse(val);
          const items: StatsPerClinic[] = aa[val];

          const resultLine: SmsTableDataSource = {
            ...parsedVal,
            inboundCount: items.find((d) => d.direction?.toLowerCase() === 'i')
              ?.count,
            outboundCount: items.find((d) => d.direction?.toLowerCase() === 'o')
              ?.count
          };

          return resultLine;
        });
      } catch (error) {
        alert(
          'Ha ocurrido un error cargando el detalle de sms enviados por clinica.'
        );
        this.smsDataSource = [];
      }
      this.isLoading = false;
    });
  }

  public check() {
    this.date.next(new Date());
  }
}
