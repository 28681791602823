import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SystemMessage } from '../../DTOS/Appointments/AppointmentListItem';

@Component({
  selector: 'app-balance-due',
  templateUrl: './balance-due.component.html',
  styleUrls: ['./balance-due.component.scss']
})
/** balance-due component*/
export class BalanceDueComponent {
  /** balance-due ctor */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SystemMessage,
    public dialogRef: MatDialogRef<BalanceDueComponent>
  ) {
    this.sys_message = data;
  }
  public sys_message: SystemMessage;
  public onDismiss() {
    this.dialogRef.close(true);
  }
}
