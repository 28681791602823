// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-appointments-stats-in-range-card {
    max-height: 24rem
}
.admin-appointments-stats-in-range-card canvas {
    max-height: 20rem !important;
    width: 100% !important
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFkbWluLWFwcG9pbnRtZW50cy1zdGF0cy1pbi1yYW5nZS1jYXJkLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0lBQUE7QUFBQTtBQUVFO0lBQUEsNEJBQUE7SUFBQTtBQUFBIiwiZmlsZSI6ImFkbWluLWFwcG9pbnRtZW50cy1zdGF0cy1pbi1yYW5nZS1jYXJkLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmFkbWluLWFwcG9pbnRtZW50cy1zdGF0cy1pbi1yYW5nZS1jYXJkIHtcbiAgQGFwcGx5IG1heC1oLTk2O1xuICBjYW52YXMge1xuICAgIEBhcHBseSBtYXgtaC04MCB3LWZ1bGwgI3shaW1wb3J0YW50fTtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/Components/admin-appointments-stats-in-range-card/admin-appointments-stats-in-range-card.component.scss"],"names":[],"mappings":"AACE;IAAA;AAAA;AAEE;IAAA,4BAAA;IAAA;AAAA;AAIJ,ghBAAghB","sourcesContent":[".admin-appointments-stats-in-range-card {\n  @apply max-h-96;\n  canvas {\n    @apply max-h-80 w-full #{!important};\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
