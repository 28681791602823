// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-selector {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))
}
.chat-selector .avatar {
    margin-right: 0.625rem;
    max-width: 40px;
    border-radius: 10px
}
.chat-selector .lastMessageContent {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoYXQtc2VsZWN0b3IuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7SUFBQSxhQUFBO0lBQUEsZUFBQTtJQUFBLG1CQUFBO0lBQUEsbUJBQUE7SUFBQSxhQUFBO0lBQUEsb0JBQUE7SUFBQTtBQUFBO0FBRUU7SUFBQSxzQkFBQTtJQUFBLGVBQUE7SUFBQTtBQUFBO0FBR0E7SUFBQSxZQUFBO0lBQUEsZ0JBQUE7SUFBQSx1QkFBQTtJQUFBO0FBQUEiLCJmaWxlIjoiY2hhdC1zZWxlY3Rvci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jaGF0LXNlbGVjdG9yIHtcbiAgQGFwcGx5IGN1cnNvci1wb2ludGVyIGZsZXggZmxleC1yb3cgdGV4dC1bYmxhY2tdIGl0ZW1zLWNlbnRlciBwLVsxNXB4XTtcbiAgLmF2YXRhciB7XG4gICAgQGFwcGx5IG1heC13LVs0MHB4XSBtci0yLjUgcm91bmRlZC1bMTBweF07XG4gIH1cbiAgLmxhc3RNZXNzYWdlQ29udGVudCB7XG4gICAgQGFwcGx5IHdoaXRlc3BhY2Utbm93cmFwIG92ZXJmbG93LWhpZGRlbiB0ZXh0LWVsbGlwc2lzIHctWzE1MHB4XTtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/Components/chat-selector/chat-selector.component.scss"],"names":[],"mappings":"AACE;IAAA,aAAA;IAAA,eAAA;IAAA,mBAAA;IAAA,mBAAA;IAAA,aAAA;IAAA,oBAAA;IAAA;AAAA;AAEE;IAAA,sBAAA;IAAA,eAAA;IAAA;AAAA;AAGA;IAAA,YAAA;IAAA,gBAAA;IAAA,uBAAA;IAAA;AAAA;AAcJ,oyBAAoyB","sourcesContent":[".chat-selector {\n  @apply cursor-pointer flex flex-row text-[black] items-center p-[15px];\n  .avatar {\n    @apply max-w-[40px] mr-2.5 rounded-[10px];\n  }\n  .lastMessageContent {\n    @apply whitespace-nowrap overflow-hidden text-ellipsis w-[150px];\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
