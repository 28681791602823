import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import cloneDeep from 'lodash-es/cloneDeep';
import some from 'lodash-es/some';
import { ConfirmationService } from 'primeng/api';
import { IntakeFormDTO } from 'src/app/DTOS/VA_Configurations/IntakeForm.DTO';
import { InitialQuestions } from 'src/app/DTOS/VA_Configurations/VA_Configurations';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-va-intake-forms-details',
  templateUrl: './va-intake-forms-details.component.html',
  styleUrl: './va-intake-forms-details.component.scss'
})
export class VaIntakeFormsDetailsComponent implements OnInit {
  isLoading: boolean = false;
  isEditing: boolean = true;
  slidePanelOpen: boolean = false;
  addNewQuestions: boolean = true;
  intakeForm: IntakeFormDTO = {} as IntakeFormDTO;
  intakeFormUpdateInsert = {} as IntakeFormDTO;
  accountID: number = 0;
  intakeFormId: number = 0;
  public initialQuestions: InitialQuestions = {} as InitialQuestions;
  public initialQuestionsEdit: InitialQuestions = {} as InitialQuestions;
  public listInitialQuestions = [] as InitialQuestions[];
  public User: User;
  public intakeFormName: string = '';

  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
      this.route.params.subscribe((params) => {
        this.intakeFormId = Number(params.intakeFormId);
        this.accountID = Number(params.accountID);
        this.intakeForm.questions = [] as InitialQuestions[];
        if (this.intakeFormId && this.accountID) {
          this.isEditing = false;
          this.GetIntakeForm(this.accountID, this.intakeFormId);
        }
      });
      this.isLoading = false;
    });
  }
  public DeleteBotQuestion(event: Event, initialQuestion: InitialQuestions) {
    if (this.User?.isAdmin === false) {
      return;
    }
    const botQuestionId = initialQuestion.botQuestionId;
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer eliminar este registro?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, eliminar',
      rejectLabel: 'Cancelar',
      accept: () =>
        this.API.DeleteBotQuestion(this.accountID, botQuestionId).subscribe(
          (r) => {
            this.clear();
            this.GetIntakeForm(this.accountID, this.intakeFormId);
            this._snackBar.open(
              `¡Listo!,  Registro eliminado  correctamente. `,
              'OK ',
              {
                duration: 70000,
                horizontalPosition: 'left',
                verticalPosition: 'bottom',
                panelClass: 'success-dialog'
              }
            );
          }
        ),
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  async UpdateIntakeForm(botQuestionId: number) {
    await this.API.UpsertQuestionsntakeForm(
      this.accountID,
      this.intakeForm.intakeFormId,
      this.intakeForm
    ).subscribe(() => {
      this.isLoading = false;
      this.isEditing = true;
      this.GetIntakeForm(this.accountID, this.intakeFormId);
      this._snackBar.open(
        `¡Listo!, la registro actualizado correctamente. `,
        'OK ',
        {
          duration: 70000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog'
        }
      );
    });
  }

  public Save() {
    this.API.CreateIntakeForm(this.accountID, this.intakeForm).subscribe(
      (result) => {
        let intakeFormId = result;
        this.router.navigate([
          `asistente-virtual/Configuracion/${this.accountID}/questionario`,
          intakeFormId
        ]);
        this._snackBar.open(
          `¡Listo!, la cuestionario creado correctamente. `,
          'OK ',
          {
            duration: 70000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog'
          }
        );
      }
    );
  }

  public Delete(event: Event, IntakeForm: IntakeFormDTO) {
    if (this.User?.isAdmin === false) {
      return;
    }
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer eliminar este registro?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, eliminar',
      rejectLabel: 'Cancelar',
      accept: () =>
        this.API.DeleteIntakeFormVirtualAssistant(
          this.accountID,
          IntakeForm.intakeFormId
        ).subscribe((r) => {
          this.GetIntakeForm(this.accountID, this.intakeFormId);
        }),
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  public GetIntakeForm(accountId: number, intakeFormId: number) {
    this.isLoading = true;
    this.API.GetIntakeForm(accountId, intakeFormId).subscribe((result) => {
      this.isLoading = false;
      this.intakeForm = result;
      this.intakeFormName = this.intakeForm.intakeFormName;
      this.initialQuestions = {} as InitialQuestions;
      this.closeAddNewSidePanel();
    });
  }
  public onDrop(): void {
    this.intakeForm.questions.forEach((item, index) => {
      item.sortOrder = index;
    });
  }

  clear() {
    this.isLoading = false;
    this.isEditing = false;
    this.initialQuestions = {} as InitialQuestions;
  }

  SetItemEdit(question: InitialQuestions) {
    this.initialQuestions = question;
    this.isEditing = true;
  }

  public Add(initialQuestions: InitialQuestions) {
    let exists: boolean;
    this.intakeForm.accountId = this.accountID;
    if (this.intakeForm.questions?.length > 0) {
      exists = this.intakeForm.questions.some(
        (item) =>
          item.prompt.toLowerCase() === initialQuestions.prompt.toLowerCase() &&
          item.label.toLowerCase() === initialQuestions.label.toLowerCase()
      );
    }
    if (!exists) {
      initialQuestions.sortOrder =
        this.intakeForm.questions?.length === null
          ? 0
          : (initialQuestions.sortOrder = this.intakeForm.questions?.length);
      this.intakeForm.questions = [
        ...this.intakeForm.questions,
        initialQuestions
      ];
      this.initialQuestions = {} as InitialQuestions;
    } else {
      this._snackBar.open(
        `Atención!, Existe una pregunta similar en este cuestionario `,
        'OK ',
        {
          duration: 70000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog'
        }
      );
    }
  }
  public Remove(prompt: string, label: string) {
    this.intakeForm.questions = this.intakeForm.questions.filter(
      (item) => !(item.prompt === prompt && item.label === label)
    );
  }

  public openEditSidePanel(item) {
    this.slidePanelOpen = true;
    this.initialQuestionsEdit = item;
  }
  public closeAddNewSidePanel() {
    this.slidePanelOpen = false;
    this.initialQuestionsEdit = {} as InitialQuestions;
  }
}
