// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clinic-appointment-types__sidePanel--Container {
  margin-left: 20px;
}
.clinic-appointment-types__sidePanel--Container .input-row {
  margin-top: 10px;
}
.clinic-appointment-types__sidePanel--Container .title {
  font-weight: 700;
}
.clinic-appointment-types__sidePanel--footer {
  margin-top: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNsaW5pYy1hcHBvaW50bWVudC10eXBlcy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFSTtFQUNFLGlCQUFBO0FBRE47QUFFTTtFQUNFLGdCQUFBO0FBQVI7QUFFTTtFQUNFLGdCQUFBO0FBQVI7QUFJSTtFQUNFLGdCQUFBO0FBRk4iLCJmaWxlIjoiY2xpbmljLWFwcG9pbnRtZW50LXR5cGVzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsaW5pYy1hcHBvaW50bWVudC10eXBlcyB7XG4gICZfX3NpZGVQYW5lbCB7XG4gICAgJi0tQ29udGFpbmVyIHtcbiAgICAgIG1hcmdpbi1sZWZ0OiAyMHB4O1xuICAgICAgLmlucHV0LXJvdyB7XG4gICAgICAgIG1hcmdpbi10b3A6IDEwcHg7XG4gICAgICB9XG4gICAgICAudGl0bGUge1xuICAgICAgICBmb250LXdlaWdodDogNzAwO1xuICAgICAgfVxuICAgIH1cblxuICAgICYtLWZvb3RlciB7XG4gICAgICBtYXJnaW4tdG9wOiAyMHB4O1xuICAgIH1cbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/Pages/clinic-appointment-types/clinic-appointment-types.component.scss"],"names":[],"mappings":"AAEI;EACE,iBAAA;AADN;AAEM;EACE,gBAAA;AAAR;AAEM;EACE,gBAAA;AAAR;AAII;EACE,gBAAA;AAFN;AACA,guBAAguB","sourcesContent":[".clinic-appointment-types {\n  &__sidePanel {\n    &--Container {\n      margin-left: 20px;\n      .input-row {\n        margin-top: 10px;\n      }\n      .title {\n        font-weight: 700;\n      }\n    }\n\n    &--footer {\n      margin-top: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
