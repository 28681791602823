import { Injectable } from '@angular/core';
import { User } from '../_models/user';

@Injectable({ providedIn: 'root' })
export class TawkToService {
  private Tawk_API: any;
  public Tawk_LoadStart: Date;
  constructor() {}

  isActive = false;

  public StartChat(user: User) {
    if (!(user && user.clinicId)) {
      return;
    }
    window['Tawk_API'] = window['Tawk_API'] || {};
    window['Tawk_LoadStart'] = new Date();
    (function () {
      const s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = false;
      s1.src = 'https://embed.tawk.to/5e0fcbe97e39ea1242a2f4ef/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
      s1.onload = () => {
        const atr = {
          name: `${user.clinicId} - ${user.clinicName}`,
          email: user.clinicEmailAddress,
          hash: user.hashHmac,
          companyId: user.companyId,
          UserEmailAddress: user.emailAddress,
          username: user.username,
          userId: user.userId,
          isAdmin: user.isAdmin
        };
        window['Tawk_API'] &&
          window['Tawk_API'].setAttributes &&
          window['Tawk_API'].setAttributes(atr, function (error) {
            console.error('ERRIR', error);
          });
      };
    })();
  }
  public endChat() {
    if (this.isActive) {
      this.Tawk_API.endChat();
    }
  }
}
