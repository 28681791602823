import { Component } from '@angular/core';
import { DialogSystemMessagesComponent } from '../../../app/Components/dialog-system-messages/dialog-system-messages.component';
import {
  SystemAnnouncementDetail,
  SystemMessagesConfig
} from '../../../app/DTOS/SystemMessages/System-MesagesDto';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent {
  constructor(public dialog: MatDialog) {}

  public selectedDate: Date;

  public showSmsStats: boolean = true;
  public showAppointmentsStats: boolean = false;
  loadingRefMessage: MatDialogRef<DialogSystemMessagesComponent>;
  public systemMessagesConfig: SystemMessagesConfig =
    {} as SystemMessagesConfig;
  public systemMessagesConfigHeader: SystemMessagesConfig =
    {} as SystemMessagesConfig;
  public showVAStats: boolean = false;
  public currIdx: number = 0;

  public onTabChange(index: number) {
    this.currIdx = index;

    if (index === 1) {
      this.showAppointmentsStats = true;
      return;
    }
    if (index === 2) {
      this.showVAStats = true;
      return;
    }
  }

  ShowDialogSystemMessages(message: string, type: string, title: string) {
    this.systemMessagesConfig = new SystemMessagesConfig(
      title,
      message,
      type, //CSS style =>info, error, warn
      false
    );
    const loading_dialog = this.dialog.open(DialogSystemMessagesComponent, {
      maxWidth: '900px',
      maxHeight: '400px',
      panelClass: 'app-full-dialog',
      data: this.systemMessagesConfig
    });
    loading_dialog.disableClose = true;
    this.loadingRefMessage = loading_dialog;
  }

  ShowSystemMessagessHeader(message: string, type: string, title: string) {
    let anouncementDetail = {} as SystemAnnouncementDetail;
    anouncementDetail.showHeaderMessage = true;
    this.systemMessagesConfigHeader = new SystemMessagesConfig(
      (anouncementDetail.title = title),
      (anouncementDetail.message = message),
      (anouncementDetail.announcementType = type),
      (anouncementDetail.showHeaderMessage = true)
    );
  }
}
