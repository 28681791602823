import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorized-message',
  templateUrl: './unauthorizedMessage.component.html',
  styleUrls: ['./unauthorizedMessage.component.scss']
})
export class UnauthorizedMessageComponent {
  constructor() {}
}
