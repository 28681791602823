import { AccountStatus } from '../Enums/AccountStatus';

export interface ClinicDetails {
  clinicId: number;
  companyId: number;
  clinicName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  mainContact: string;
  phoneNumber: string;
  faxNumber: string;
  emailAddress: string;
  viewAsUserID: number;
  electronicCalendarId: number;
  accountStatus: AccountStatus;
}

export class ClinicConfigDTO {
  ClinicID: number;
  DefaultElectronicCalendarId: number;
}

export enum TelephonyProvider {
  MESSAGE_BIRD = 1,
  TWILIO = 2,
  BANDWIDTH = 3
}
export class ClinicProviders {
  clinicId: number;
  smsProvider: number;
  voiceProvider: number;
}

export class TelephonyProviderDTO {
  name: string;
  value: number;
  constructor(name: string, value: number) {
    this.name = name;
    this.value = value;
  }
}
