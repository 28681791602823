import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ClinicProviders,
  TelephonyProvider,
  TelephonyProviderDTO
} from 'src/app/DTOS/Clinic/clinic-details';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-telephony-provider',
  templateUrl: './telephony-provider.component.html',
  styleUrls: ['./telephony-provider.component.scss']
})
export class TelephonyProviderComponent implements OnInit {
  @Input() public clinicID: number;
  public Provider: ClinicProviders = {} as ClinicProviders;
  @ViewChild('ProviderForm', { static: false }) public clinicForm: NgForm;
  public User: User;
  public ListTelephonyProvider = [] as TelephonyProviderDTO[];
  public telephonyProviders = Object.values(TelephonyProvider);
  public isLoading = false;

  constructor(
    private API: ApiService,
    private _snackBar: MatSnackBar,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe((u) => (this.User = u));
  }

  ngOnInit(): void {
    this.GetProviderForClinicAsync();
    this.EnumToList();
  }

  public GetProviderForClinicAsync() {
    this.API.GetProviderForClinicAsync(this.clinicID).subscribe((r) => {
      this.Provider = r as ClinicProviders;
    });
  }

  EnumToList() {
    for (const value of Object.values(TelephonyProvider)) {
      let dto: TelephonyProviderDTO = new TelephonyProviderDTO(
        value.toString(),
        TelephonyProvider[value]
      );
      const exists = this.ListTelephonyProvider.some(
        (p: TelephonyProviderDTO) => p.value === value
      );
      if (!exists) {
        this.ListTelephonyProvider.push(dto);
      }
    }
  }
  SetClinicProviders() {
    this.isLoading = true;
    this.Provider.clinicId = this.clinicID;
    this.API.SetClinicProviders(this.Provider).subscribe(
      (clinicId) => {
        this.isLoading = false;
        this._snackBar.open(
          `¡Listo!,  Proveedor de telefonia configurados. `,
          'OK ',
          {
            duration: 70000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog'
          }
        );
      },
      (e) => {
        this.isLoading = false;
      }
    );
  }
}
