import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { snakeCaseToNormal } from 'src/shared/helpers/helper-functions';
import { LONG_DATE_FORMAT } from '../Constants/constants';
import { omit } from 'lodash';

function isValidIsoDateTime(str) {
  const regex = /^\d{4}-\d{2}-\d{2}/;
  return regex.test(str);
}

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent implements OnInit {
  @Input() dataArrayObservable: Observable<any[]>;
  @Input() omitCols: string[];

  dataArray: any[];
  isLoading = true;

  ngOnInit() {
    this.dataArrayObservable.subscribe((data) => {
      if (this.omitCols?.length) {
        this.dataArray = data.map((d) => omit(d, this.omitCols));
      } else {
        this.dataArray = data;
      }
      this.isLoading = false;
    });
  }

  formatIfDate(value: any): any {
    if (isValidIsoDateTime(value)) {
      return moment(value).format(LONG_DATE_FORMAT);
    }
    return value;
  }

  getKeys(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }

  snakeCaseToNormal = snakeCaseToNormal;
}
