// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recovery {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  background-color: white;
}

.invalid-feedback {
  font-style: italic;
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.logo {
  max-width: 15.625rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBhc3N3b3JkLXJlY292ZXJ5LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsWUFBQTtFQUNBLGlCQUFBO0VBQ0EsV0FBQTtFQUNBLG1CQUFBO0VBQ0Esc0JBQUE7RUFDQSxhQUFBO0VBQ0EscUJBQUE7RUFDQSxtQkFBQTtFQUNBLHVCQUFBO0FBQ0Y7O0FBRUU7RUFBQSxrQkFBQTtFQUFBLG9CQUFBO0VBQUE7QUFBQTs7QUFHQTtFQUFBO0FBQUEiLCJmaWxlIjoicGFzc3dvcmQtcmVjb3ZlcnkuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIucmVjb3Zlcnkge1xuICBoZWlnaHQ6IDEwMCU7XG4gIG1pbi1oZWlnaHQ6IDEwMHZoO1xuICB3aWR0aDogMTAwJTtcbiAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgYm94LXNpemluZzogYm9yZGVyLWJveDtcbiAgZGlzcGxheTogZmxleDtcbiAgcGxhY2UtY29udGVudDogY2VudGVyO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbn1cbi5pbnZhbGlkLWZlZWRiYWNrIHtcbiAgQGFwcGx5IHRleHQtcmVkLTYwMCBpdGFsaWM7XG59XG4ubG9nbyB7XG4gIEBhcHBseSBtYXgtdy1bMTUuNjI1cmVtXTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/Pages/password-recovery/password-recovery.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;EACA,WAAA;EACA,mBAAA;EACA,sBAAA;EACA,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEE;EAAA,kBAAA;EAAA,oBAAA;EAAA,8CAAA;AAAA;;AAGA;EAAA,oBAAA;AAAA;AAMF,o3BAAo3B","sourcesContent":[".recovery {\n  height: 100%;\n  min-height: 100vh;\n  width: 100%;\n  flex-direction: row;\n  box-sizing: border-box;\n  display: flex;\n  place-content: center;\n  align-items: center;\n  background-color: white;\n}\n.invalid-feedback {\n  @apply text-red-600 italic;\n}\n.logo {\n  @apply max-w-[15.625rem];\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
