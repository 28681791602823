import { ReminderSetType } from '../ReminderSets/ReminderSetDto';
import { ClinicDto } from '../SystemMessages/System-MesagesDto';

export class ReminderSequenceCreationDTO {
  ReminderSequenceId: number;
  ReminderSetId: number;
  ReminderId: number;
  step: number;
  actionType: string;
  gatherNextStep: string;
  callStatusId: string;
  reminderType: string;
  messageOrPath: string;
  checked: boolean;
}

export class ConfirmationTextMessageResponses {
  callStatusId: string;
  message: string;
  confirmationTextMessageResponseId: number;
}

export interface ReminderDetail {
  reminderSetId: number;
  reminderSetType: ReminderSetType;
  reminderSetName: string;
  createDateView: string;
  createDate: Date;
  clinicId: number;
  clinic: ClinicDto;
  reminderScheduleCount: number;
}

export interface ReminderList {
  totalCount: number;
  pageSize: number;
  pageIndex: number;
  items: ReminderDetail[];
}
