import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { JwtInterceptor } from './_helpers';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  ImportAppointmentsDialogComponent,
  ImportResultDialogComponent
} from './_dialogs/import-appointments-dialog/import-appointments-dialog.component';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { AccountDetailsComponent } from './Components/account-details/account-details.component';
import { AccountsComponentComponent } from './Pages/va-accounts/va-accounts.component';
import { AddAppointmentsComponent } from './Pages/add-appointments/add-appointments.component';
import { AddAppointmentsDialogComponent } from './_dialogs/add-appointments-dialog/add-appointments-dialog.component';
import { AddEhrDialogComponent } from './_dialogs/add-ehr-dialog/add-ehr-dialog.component';
import { AppComponent } from './app-component/app.component';
import { AppointmentDetailsDialogComponent } from './_dialogs/appointment-details-dialog/appointment-details-dialog.component';
import { AppointmentsRequestListComponent } from './Pages/appointments-request-list/appointments-request-list.component';
import { BalanceDueComponent } from './_dialogs/balance-due/balance-due.component';
import { BookingsConfigurationDialogComponent } from './_dialogs/bookings-configuration-dialog/bookings-configuration-dialog.component';
import { BookingsNewBookingComponent } from './Pages/bookings-new-booking/bookings-new-booking.component';
import { BrowserModule } from '@angular/platform-browser';
import { BulkMessageCreateComponent } from './Pages/bulk-message-create/bulk-message-create.component';
import { CSVImportDialogComponent } from './_dialogs/csv-import-dialog/csv-import-dialog';
import { CallAgainSchedulesComponent } from './Pages/call-again-schedules/call-again-schedules.component';
import { ChatComponent } from './Components/chat/chat.component';
import { ClinicAppointmentTypesComponent } from './Pages/clinic-appointment-types/clinic-appointment-types.component';
import { ClinicNewWhitelistedPhoneDialogComponent } from './_dialogs/clinic-new-whitelisted-phone-dialog/clinic-new-whitelisted-phone-dialog.component';
import { ClinicTransitionalCareHospitalizecTabComponent } from './Pages/clinic-transitional-care-hospitalizec-tab/clinic-transitional-care-hospitalizec-tab.component';
import { ClinicTransitionalCareWithAppointmentTabComponent } from './Pages/clinic-transitional-care-with-appointment-tab/clinic-transitional-care-with-appointment-tab.component';
import { ClinicTransitionalCareWithoutAppointmentTabComponent } from './Pages/clinic-transitional-care-without-appointment-tab/clinic-transitional-care-without-appointment-tab.component';
import { CompanyDetailsComponent } from './Pages/company-details/company-details.component';
import { CompanyDialogComponent } from './_dialogs/company-dialog/company-dialog.component';
import { ConfirmationService } from 'primeng/api';
import { DigitalWaitingListComponent } from './Pages/digital-waiting-list/digital-waiting-list.component';
import { EngineSettingsComponent } from './Pages/engine-settings/engine-settings.component';
import { HomeComponent } from './Pages/home/home.component';
import { InsurancePlanDialogComponent } from './_dialogs/insurance-plan-dialog/insurance-plan-dialog.component';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { LoginComponent } from './Pages/login/login.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MissedCallsComponent } from './Pages/missed-calls/missed-calls.component';
import { NavigationBarComponent } from './Components/navigation-bar/navigation-bar.component';
import { NoCallDateComponent } from './Pages/no-call-date/no-call-date.component';
import { PasswordRecoverComponent } from './Pages/password-recover/password-recover.component';
import { PasswordRecoveryComponent } from './Pages/password-recovery/password-recovery.component';
import { PermissionDialogComponent } from './_dialogs/permission-dialog/permission-dialog.component';
import { PermissionSlugsService } from './Services/PermissionSlugsService';
import { RemindersComponent } from './Pages/reminders/reminders.component';
import { RemindersetRemindersetComponent } from './Pages/reminderset-reminderset/reminderset-reminderset.component';
import { ReportsViewerComponent } from './_dialogs/reports-viewer/reports-viewer.component';
import { RouterModule } from '@angular/router';
import { SafePipe } from './_services/SafePipe ';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SidebarMenuComponent } from './Components/sidebar-menu/sidebar-menu.component';
import { SpecialAnnouncementComponent } from './Pages/special-announcement/special-announcement.component';
import { SupportChannelsDialogComponent } from './_dialogs/support-channels-dialog/support-channels-dialog.component';
import { SystemMessagesComponent } from './system-messages/system-messages.component';
import { TransitionalCareAdmisionsAndDischargesComponent } from './Pages/transitional-care-admisions-and-discharges/transitional-care-admisions-and-discharges.component';
import { TransitionalCareResumenTabComponent } from './transitional-care-resumen-tab/transitional-care-resumen-tab.component';
import { TransitionalCareTableComponent } from './Pages/transitional-care-table/transitional-care-table.component';
import { TwilioCallerIdActivatorComponent } from './Pages/twilio-caller-id-activator/twilio-caller-id-activator.component';
import { UnauthorizedMessageComponent } from './Pages/unauthorizedMessage/unauthorizedMessage.component';
import { UpdateAppointmentCallStatusDialogComponent } from './_dialogs/update-appointment-call-status/update-appointment-call-status.component';
import { UpdateAppointmentDialogComponent } from './_dialogs/update-appointment/update-appointment.component';
import { VaConfigurationComponent } from './Pages/va-configuration/va-configuration.component';
import { VaDoctorTagsComponent } from './Pages/va-doctor-tags/va-doctor-tags.component';
import { VaDoctorsComponent } from './Pages/va-doctors/va-doctors.component';
import { VaServiceTagsComponent } from './Pages/va-service-tags/va-service-tags.component';
import { VaServicesComponent } from './Pages/va-services/va-services.component';
import { appRoutes } from './app.routes';
import { CustomIntentsComponent } from './custom-intents/custom-intents.component';
import { LandingComponent } from './landing/landing.component';
import { AdminSmsStatsComponent } from './Components/admin-sms-stats/admin-sms-stats.component';
import { AdminDashboardComponent } from '../admin/Pages/admin-dashboard/admin-dashboard.component';
import { AccountComponent } from './Pages/va-account/va-account.component';
import { AdminAppointmentsStatsComponent } from './Components/admin-appointments-stats/admin-appointments-stats.component';
import { AdminAppointmentsStatsInRangeCardComponent } from './Components/admin-appointments-stats-in-range-card/admin-appointments-stats-in-range-card.component';
import { OcrAppointmentListComponent } from './Components/ocr-appointment-list/ocr-appointment-list.component';
import { ConversationsComponent } from './Pages/conversations/conversations.component';
import { LongPressDirective } from './Directives/LongPressDirective';
import { SharedModule } from 'src/shared/shared.module';
import { DialogSystemMessagesComponent } from './Components/dialog-system-messages/dialog-system-messages.component';
import { AdminVaStatsComponent } from './Components/admin-va-stats/admin-va-stats.component';
import { AppointmentDetailsComponent } from './Components/appointment-details/appointment-details.component';
import { ConversationFormComponent } from './conversation-form/conversation-form.component';
import { provideNgxMask } from 'ngx-mask';
import { CallStatusesComponent } from './Pages/call-statuses/call-statuses.component';
import { CallStatusesCreateComponent } from './Pages/call-statuses-create/call-statuses-create.component';
import { ExpansibleContainerComponent } from './Components/expansible-container/expansible-container.component';
import { QuickRepliesComponent } from './Pages/quick-replies/quick-replies.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GenericTableComponent } from './generic-table/generic-table.component';
import { HtmlOnlyDialogComponent } from './html-only-dialog/html-only-dialog.component';
import { PhoneNumberAppointmentsHistoryComponent } from './Pages/phone-number-appointments-history/phone-number-appointments-history.component';
import { VirtualPhonesListComponent } from './Pages/virtual-phones-list/virtual-phones-list.component';
import { ChangeLogsComponent } from './Components/change-logs/change-logs.component';
import { AppointmentChangeLogComponent } from './Components/appointment-change-log/appointment-change-log.component';
import { SystemMessagesContainerComponent } from './system-messages-container/system-messages-container.component';
import { ReportsPageComponent } from './Pages/reports-page/reports-page.component';
import { VaStatsComponent } from './Components/va-stats/va-stats.component';
import { LocationsComponent } from './Pages/locations/locations.component';
import { LocationsDetailsComponent } from './Pages/locations-details/locations-details.component';
import { BillingAccountsComponent } from './Pages/billing-accounts/billing-accounts.component';
import { VaInitialQuestionsComponent } from './Pages/va-initial-questions/va-initial-questions.component';
import { VaIntakeFormsComponent } from './Pages/va-intake-forms/va-intake-forms.component';
import { VaIntakeFormsDetailsComponent } from './Pages/va-intake-forms-details/va-intake-forms-details.component';
import { AlphanumericsDirective } from './Directives/app-alphanumeric.directive';
import { TimelineModule } from 'primeng/timeline';
import { CacheService } from './Services/CacheService';
import { ConfigurandoClinicsComponent } from 'src/admin/Components/configurando-clinics/configurando-clinics.component';
import { SmsLogsComponent } from './sms-logs/sms-logs.component';
import { RemindersetComponent } from './Pages/reminderset/reminderset.component';
import { ChatSelectorComponent } from './Components/chat-selector/chat-selector.component';
import { AiBotChatsListComponent } from './ai-bot-chats-list/ai-bot-chats-list.component';
import { AiBotChatComponent } from './ai-bot-chat/ai-bot-chat.component';
import {
  provideLottieOptions,
  provideCacheableAnimationLoader
} from 'ngx-lottie';
import { AiIconComponent } from './ai-icon/ai-icon.component';
import { LottieComponent } from 'ngx-lottie';
@NgModule({
  declarations: [
    AppComponent,
    PasswordRecoveryComponent,
    LoadingDialogComponent,
    PasswordRecoverComponent,
    HomeComponent,
    AddAppointmentsComponent,
    NoCallDateComponent,
    SpecialAnnouncementComponent,
    LoginComponent,
    UpdateAppointmentCallStatusDialogComponent,
    UpdateAppointmentDialogComponent,
    AccountDetailsComponent,
    AddAppointmentsDialogComponent,
    ReportsViewerComponent,
    RemindersComponent,
    SafePipe,
    CompanyDialogComponent,
    ClinicAppointmentTypesComponent,
    RemindersetRemindersetComponent,
    SupportChannelsDialogComponent,
    AppointmentDetailsDialogComponent,
    BalanceDueComponent,
    SystemMessagesComponent,
    DigitalWaitingListComponent,
    ChatComponent,
    TwilioCallerIdActivatorComponent,
    CallAgainSchedulesComponent,
    MissedCallsComponent,
    AppointmentsRequestListComponent,
    PermissionDialogComponent,
    VaConfigurationComponent,
    AccountComponent,
    VaDoctorsComponent,
    VaServicesComponent,
    VaDoctorTagsComponent,
    VaServiceTagsComponent,
    BulkMessageCreateComponent,
    UnauthorizedMessageComponent,
    TransitionalCareAdmisionsAndDischargesComponent,
    TransitionalCareTableComponent,
    TransitionalCareResumenTabComponent,
    ClinicTransitionalCareHospitalizecTabComponent,
    ClinicTransitionalCareWithAppointmentTabComponent,
    ClinicTransitionalCareWithoutAppointmentTabComponent,
    ImportAppointmentsDialogComponent,
    ImportResultDialogComponent,
    CSVImportDialogComponent,
    SidebarMenuComponent,
    AccountsComponentComponent,
    InsurancePlanDialogComponent,
    BookingsConfigurationDialogComponent,
    BookingsNewBookingComponent,
    ClinicNewWhitelistedPhoneDialogComponent,
    CompanyDetailsComponent,
    EngineSettingsComponent,
    AddEhrDialogComponent,
    NavigationBarComponent,
    CustomIntentsComponent,
    LandingComponent,
    AdminSmsStatsComponent,
    AdminDashboardComponent,
    AdminAppointmentsStatsComponent,
    AdminAppointmentsStatsInRangeCardComponent,
    OcrAppointmentListComponent,
    ConversationsComponent,
    LongPressDirective,
    AlphanumericsDirective,
    DialogSystemMessagesComponent,
    ConfigurandoClinicsComponent,
    SmsLogsComponent,
    AppointmentDetailsComponent,
    AdminVaStatsComponent,
    ConversationFormComponent,
    CallStatusesComponent,
    CallStatusesCreateComponent,
    ExpansibleContainerComponent,
    QuickRepliesComponent,
    GenericTableComponent,
    HtmlOnlyDialogComponent,
    PhoneNumberAppointmentsHistoryComponent,
    VirtualPhonesListComponent,
    ChangeLogsComponent,
    AppointmentChangeLogComponent,
    SystemMessagesContainerComponent,
    ReportsPageComponent,
    VaStatsComponent,
    LocationsComponent,
    LocationsDetailsComponent,
    BillingAccountsComponent,
    VaInitialQuestionsComponent,
    VaIntakeFormsComponent,
    VaIntakeFormsDetailsComponent,
    RemindersetComponent,
    ChatSelectorComponent,
    AiBotChatsListComponent,
    AiBotChatComponent,
    AiIconComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    SharedModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: true }),
    TimelineModule,
    LottieComponent
  ],
  providers: [
    MatNativeDateModule,
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },

    // { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    CacheService,
    PermissionSlugsService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    },
    provideNgxMask(),
    provideLottieOptions({
      player: () => import('lottie-web').then((r) => r)
    }),
    provideCacheableAnimationLoader()
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
