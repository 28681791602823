import {
  AddAppointmentDialogData,
  AddAppointmentsDialogComponent
} from 'src/app/_dialogs/add-appointments-dialog/add-appointments-dialog.component';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  menuHideShow as MenuHideShow,
  MenuNode
} from 'src/app/DTOS/sidebar-menu/menu';

import { ApiService } from 'src/app/api.service';
import { AuthenticationService } from 'src/app/_services';
import { ImportAppointmentsDialogComponent } from 'src/app/_dialogs/import-appointments-dialog/import-appointments-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';
import { Router } from '@angular/router';
import { TawkToService } from 'src/app/_services/TawkToService';
import { User } from 'src/app/_models';
import { getCurrentScreen } from 'src/app/Helpers/viewportHelpers';
import { applicationRoutes } from 'src/app/app.routes';
import { MatAccordion } from '@angular/material/expansion';

const NEW_FEATURE_SESSION_K = 'cmd-cht-bnr-21201331';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {
  menuHideShow: MenuHideShow = {} as MenuHideShow;
  hasBookingPermission: boolean;
  currentUser: User = {} as User;
  TREE_DATA: MenuNode[] = [] as MenuNode[];
  @Output() menuExpandedChange = new EventEmitter<boolean>();
  screen: string;
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    public router: Router,
    private tawkToService: TawkToService,
    private permissionSlugsService: PermissionSlugsService,
    private API: ApiService,
    private cdr: ChangeDetectorRef
  ) {
    this.menuHideShow = {} as MenuHideShow;
  }

  public showChatNewFeatureBubble: boolean = false;
  public disableNewFeatureBubbles: boolean = false;
  @Input() public menuExpanded: boolean;
  @ViewChild('mainAcordion') accordion: MatAccordion;

  public rootClass: string = '';

  public screenSizes = {
    height: window.innerHeight,
    width: window.innerWidth
  };

  ngOnInit(): void {
    window.onresize = () => {
      this.screenSizes = {
        height: window.innerHeight,
        width: window.innerWidth
      };
    };

    this.authenticationService.currentUser.subscribe((user) => {
      if (!user) {
        return;
      }
      if (user?.isAdmin) {
        this.menuHideShow.isPrinter = user.isAdmin;
        this.InitializeData();
      } else {
        this.API.IsUserPrinter(user.clinicId).subscribe(
          (response: boolean) => {
            this.menuHideShow.isPrinter = response;
            this.InitializeData();
          },
          (error) => {
            console.error(error);
          }
        );
      }
    });
  }
  openLink(link) {
    window.open(link, '_blank');
  }

  public closeNewFeatureBubble() {
    this.disableNewFeatureBubbles = true;
    this.showChatNewFeatureBubble = undefined;
    this.menuExpanded = undefined;
    localStorage.setItem(NEW_FEATURE_SESSION_K, String(true));
    this.cdr.detectChanges();
  }

  public onNodeClicked(node: MenuNode) {
    if (node.url) {
      this.navigateTo(node.url);
    } else if (node.onClick) {
      node.onClick();
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.onSidenavClose();
  }

  public onLogoClicked() {
    if (this.currentUser.landingRoute)
      this.router.navigate([this.currentUser.landingRoute]);
  }

  public getRootClass() {
    const screen = getCurrentScreen();
    this.screen = screen;
    if (['sm', 'md'].includes(screen) && this.menuExpanded) {
      return 'citamed-sidebar mobileExpanded expandedmenu';
    }
    if (screen === 'sm' && !this.menuExpanded) {
      return 'citamed-sidebar';
    }
    if (screen === '3xl' || this.menuExpanded) {
      return 'citamed-sidebar expandedmenu';
    } else {
      return 'citamed-sidebar';
    }
  }

  public InitializeData() {
    this.authenticationService.currentUser.subscribe((currentUserValue) => {
      if (!currentUserValue?.clinicId) {
        this.currentUser = null;
        return;
      }
      this.currentUser = currentUserValue;

      this.tawkToService.StartChat(this.currentUser);

      this.permissionSlugsService.permissionsListener.subscribe(
        (permissions) => {
          this.menuHideShow.showDWL = permissions.includes(
            PermissionSlugs.DIGITAL_WAITING_LIST
          );
          this.menuHideShow.hasBookingPermission = permissions.includes(
            PermissionSlugs.BOOKINGS
          );

          this.menuHideShow.hideAppointmentManagement = permissions.includes(
            PermissionSlugs.BLOCK_APPOINTMENT_MANAGEMENT
          );

          this.menuHideShow.showVirtualAssistant = permissions.includes(
            PermissionSlugs.VIRTUAL_ASSISTANT
          );

          this.menuHideShow.showCampaigns = permissions.includes(
            PermissionSlugs.CAMPAIGNS
          );

          this.menuHideShow.showTransitionalCare = permissions.includes(
            PermissionSlugs.TRANSITIONAL_CARE
          );

          this.menuHideShow.patientManagement = permissions.includes(
            PermissionSlugs.PATIENT_MANAGEMENT
          );
          this.menuHideShow.conversations = permissions.includes(
            PermissionSlugs.CONVERSATIONS
          );

          this.setmenuData();
        }
      );
    });

    // Transitional Care Management Users
    if (
      this.menuHideShow.hideAppointmentManagement === true &&
      this.menuHideShow.showTransitionalCare === true
    ) {
      this.router.navigate(['/Transitional-Care-admisions-and-discharges']);
    } else if (
      this.menuHideShow.hideAppointmentManagement === true &&
      this.menuHideShow.showCampaigns === true
    ) {
      this.router.navigate(['/campañas/crear']);
    } else if (
      this.menuHideShow.hideAppointmentManagement === true &&
      this.menuHideShow.showVirtualAssistant === true &&
      this.menuHideShow.showTransitionalCare === false
    ) {
      this.router.navigate(['/llamadasPerdidas']);
    }
  }
  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  setmenuData() {
    this.authenticationService.currentUser.subscribe((user) => {
      const userCanCreateConfirmationAppointments =
        this.menuHideShow.hasBookingPermission === false || user.isAdmin;

      const menuOptions = [
        {
          name: 'Inicio',
          ngif: true,
          url: '/',
          icon: 'home'
        },

        {
          name: 'Chat',
          url: '/chat',
          ngif: this.menuHideShow.conversations || this.currentUser.isAdmin,
          icon: 'forum'
        },
        {
          name: `Confirmaciones`,
          icon: 'event',
          ngif: !this.menuHideShow.hideAppointmentManagement,
          url: '',
          children: [
            user.landingRoute
              ? {
                  name: 'Confirmaciones',
                  ngif: true,
                  url: applicationRoutes.ConfirmationsPage,
                  icon: 'check_circle'
                }
              : null,

            userCanCreateConfirmationAppointments
              ? {
                  name: 'Añadir Cita',
                  ngif: true,
                  icon: 'event',
                  isClic: true,
                  onClick: this.OpenAppointmentAddDialog.bind(this)
                }
              : null,

            userCanCreateConfirmationAppointments
              ? {
                  name: 'Añadir Multiples Citas',
                  ngif: true,
                  icon: 'list_alt',
                  url: '/add-appointments'
                }
              : null,
            userCanCreateConfirmationAppointments
              ? {
                  name: 'Importar Citas ',
                  isClic: true,
                  icon: 'upload_file',
                  ngif: this.menuHideShow.isPrinter,
                  onClick: this.OpenImportAppointmentDialog.bind(this)
                }
              : null,
            {
              name: 'Pausar Envios',
              ngif: true,
              icon: 'pause_circle',
              url: '/no-call-dates-add-new'
            },
            {
              name: 'Llamada Especial',
              ngif: true,
              icon: 'phone_forwarded',
              url: '/special-Announcement'
            }
          ].filter((opt) => !!opt)
        },
        {
          name: 'Pacientes',
          icon: 'person',
          ngif: this.menuHideShow.patientManagement,
          url: '',
          children: [
            {
              name: 'Registrar Nuevo',
              ngif: true,
              url: `BM/paciente-nuevo/${this.currentUser?.clinicId}`
            },
            {
              name: 'Listado de Pacientes',
              ngif: true,
              url: 'BM/listado-de-pacientes'
            },
            {
              name: 'Planes de Seguros',
              ngif: true,
              url: 'BM/planes-de-seguros'
            }
          ]
        },
        {
          name: 'Agenda',
          icon: 'settings_applications',
          ngif: this.menuHideShow.hasBookingPermission,
          url: '',
          children: [
            {
              name: 'Agenda',
              ngif: true,
              url: `BM/agendamiento`
            },

            {
              name: 'Configuración',
              ngif: true,
              url: 'BM/agendamiento/configuracion'
            }
          ]
        },
        {
          name: 'Asistencia Virtual',
          icon: 'smart_toy',
          ngif: this.menuHideShow.showVirtualAssistant,
          url: '',
          children: [
            {
              name: 'Llamadas perdidas',
              ngif: true,
              url: 'asistente-virtual/llamadas-perdidas',
              icon: 'call'
            },
            {
              name: 'Configuración',
              ngif: true,
              url: `asistente-virtual/Configuracion/${this.currentUser?.clinicId}`
            },
            {
              name: 'Cuentas',
              ngif: this.currentUser?.isAdmin,
              url: 'VA/accounts'
            }
          ]
        },
        {
          name: 'Estatus de Turno',
          icon: 'view_column',
          ngif: this.menuHideShow.showDWL,
          url: '',
          children: [
            {
              name: 'Tablero',
              ngif: true,
              url: 'waiting-list'
            }
          ]
        },

        {
          name: 'Reportes',
          url: '/reportes',
          icon: 'bar_chart',
          ngif: false
        },

        {
          name: 'Comunicaciones',
          icon: 'dialpad',
          ngif: this.menuHideShow.showCampaigns,
          url: '',
          children: [
            {
              name: 'Crear Campaña',
              ngif: this.menuHideShow.showCampaigns,
              url: '/campañas/crear'
            }
          ]
        },
        {
          name: 'Transitional Care',
          icon: 'dashboard',
          ngif: this.menuHideShow.showTransitionalCare,
          url: '',
          children: [
            {
              name: 'Admisiones y Altas',
              ngif: true,
              url: '/Transitional-Care-admisions-and-discharges'
            },
            {
              name: 'Confirmaciones de citas',
              ngif: false,
              url: '/Transitional-Care-confirmations'
            }
          ]
        },

        {
          name: 'Mi Cuenta',
          icon: 'event',
          ngif: true,
          url: '',
          children: [
            {
              name: 'Detalles y perfil',
              ngif: true,
              icon: 'business',
              url: 'account-details'
            },
            {
              name: 'Doctores/Proveedores',
              ngif: true,
              icon: 'group',
              url: 'Doctors-list'
            },
            {
              name: 'Usuarios',
              ngif: true,
              url: '/Users',
              icon: 'supervised_user_circle'
            },
            {
              name: 'Tipos de Citas',
              ngif: !!this.currentUser?.isAdmin,
              url: '/my-clinic-appointment-types'
            },
            this.currentUser.isAdmin == false
              ? {
                  name: 'Historial de Sms',
                  ngif: true,
                  url: '/historial-de-mensajes'
                }
              : null
          ].filter((f) => f)
        },
        {
          name: 'Administradores',
          icon: 'settings',
          ngif: this.currentUser?.isAdmin,
          url: '',
          children: [
            {
              name: 'Cuentas',
              ngif: this.currentUser?.isAdmin,
              url: '',
              icon: 'business',
              children: [
                {
                  name: 'Cuentas de Facturación',
                  ngif: true,
                  url: 'administrator/company'
                },
                {
                  name: 'Clínicas',
                  ngif: true,
                  url: 'administrator/clinics'
                },
                {
                  name: 'Reporte de Estados',
                  ngif: true,
                  url: 'administrator/accounts-stats'
                }
              ]
            },
            {
              name: 'Calendarios',
              icon: 'calendar_month',
              ngif: this.currentUser?.isAdmin,
              url: '',
              children: [
                {
                  name: 'Calendarios de Llamadas',
                  ngif: true,

                  url: 'administrator/reminder-schedules'
                },
                {
                  name: 'Horarios/Frecuencias',
                  ngif: true,

                  url: 'administrator/call-timings'
                },
                {
                  name: ' Re-calls',
                  ngif: true,
                  url: '/call-again-schedules'
                },
                {
                  name: 'Creación de Mensajes',
                  ngif: true,
                  url: 'administrator/reminders/new'
                },
                {
                  name: 'Listado de Mensajes',
                  ngif: true,
                  url: 'reminderSet-list'
                }
              ]
            },
            {
              name: 'Mantenimientos',
              ngif: true,
              url: '/administrator/mantenimientos'
              // children: [
              //   {
              //     name: 'Récords Electrónicos',
              //     ngif: true,
              //     url: 'administrator/ehrs',
              //   },
              //   {
              //     name: 'Planes',
              //     ngif: true,
              //     url: 'administrator/rate-plans',
              //   },
              //   {
              //     name: 'Estatus de Asistencia',
              //     ngif: true,
              //     url: 'administrator/status-assistance',
              //   },
              //   {
              //     name: 'Tipos de Citas',
              //     ngif: true,
              //     url: 'administrator/appointment-types',
              //   },
              //   {
              //     name: 'Permisos',
              //     ngif: true,
              //     url: 'administrator/permissions',
              //   },
              //   {
              //     name: 'Sprocs',
              //     ngif: true,
              //     url: 'administrator/stored-procedures',
              //   },
              //   {
              //     name: 'Anuncios del Sistema',
              //     ngif: true,
              //     url: 'administrator/system-announcements',
              //   },
              //   {
              //     name: 'Usuarios API',
              //     ngif: true,
              //     url: 'administrator/api-users',
              //   },
              //   {
              //     name: 'Virtual Phones',
              //     ngif: true,
              //     url: '/admin/virtual-phones-list',
              //   },
              //   {
              //     name: 'Estados de Llamadas',
              //     ngif: true,
              //     url: '/admin/call-statuses',
              //   },
              //   {
              //     name: 'Localidades',
              //     ngif: this.currentUser?.isAdmin,
              //     url: '/admin/locations',
              //   },
              // ],
            },
            // {
            //   name: 'Twilio Caller ID',
            //   ngif: true,
            //   url: '/twilio-caller-id-activator',
            // },
            {
              name: 'Admin Console',
              ngif: true,
              isLink: true,
              url: 'https://console.citamed-secure.com/'
            },
            {
              name: 'Historial de Sms',
              ngif: true,
              url: '/historial-de-mensajes'
            }
          ]
        }
      ].filter((opt) => opt && opt.ngif);

      if (menuOptions.length === 3) {
        let opts = [];
        menuOptions.forEach((option) => {
          opts = [...opts, ...(option.children || [])];
          if (!option.children?.length) {
            opts.push(option);
          }
        });
        this.TREE_DATA = opts;
      } else {
        this.TREE_DATA = menuOptions;
      }

      if (this.menuHideShow?.conversations) {
        if (!localStorage.getItem(NEW_FEATURE_SESSION_K)) {
          setTimeout(() => this.showNewFeatureBubble(), 1000 * 8);
        } else {
          this.showChatNewFeatureBubble = false;
          this.disableNewFeatureBubbles = true;
        }
      }
    });
  }

  public showNewFeatureBubble() {
    return;
  }

  OpenAppointmentAddDialog() {
    const data: AddAppointmentDialogData = {
      clinicID: this.currentUser.clinicId,
      UserId: this.currentUser.userId,
      ClinicName: this.currentUser.clinicName
    };
    this.dialog
      .open(AddAppointmentsDialogComponent, {
        data,
        maxWidth: '95vw',
        width: '39.5rem',
        maxHeight: '42rem',
        panelClass: 'full-with-dialog',
        disableClose: false,
        hasBackdrop: true,
        autoFocus: true
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this._snackBar.open(
            '¡Listo! La cita ha sido añadida correctamente.',
            'ok ',
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog'
            }
          );
        }
      });
  }

  hasChild = (n: number, node: MenuNode) => {
    if (!!node.children && node.children.length > 0) {
      let { children } = node;
      let childrenFilter = children.filter((item) => item.ngif == true);
      node.children = childrenFilter;
      return node;
    }
  };

  logout() {
    this.authenticationService.logout();
  }

  OpenImportAppointmentDialog() {
    this.dialog
      .open(ImportAppointmentsDialogComponent, {
        panelClass: ['full-w-dialog', '!max-w-[90vw]'],
        disableClose: false,
        hasBackdrop: true,
        autoFocus: true
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this._snackBar.open(
            '¡Listo! Las citas han sido importadas correctamente.',
            'ok ',
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog'
            }
          );
        }
      });
  }
  onSidenavClose = () => {
    this.onMenuItemClick();
    if (typeof this.accordion?.closeAll === 'function') {
      this.accordion?.closeAll();
    }
  };

  onMenuItemClick() {
    this.menuExpandedChange.emit(false);
  }
}
