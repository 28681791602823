import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemMessagesConfig } from '../../DTOS/SystemMessages/System-MesagesDto';

@Component({
  selector: 'app-dialog-system-messages',
  templateUrl: './dialog-system-messages.component.html',
  styleUrls: ['./dialog-system-messages.component.scss']
})
export class DialogSystemMessagesComponent {
  title: string;
  message: string;
  type: string;
  yesBtnLabel = 'Ok';
  noBtnLabel = 'Cancelar';
  public textColor: string;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogSystemMessagesComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const modalData = data as SystemMessagesConfig;
    this.title = modalData.title;
    this.message = modalData.message;
    this.type = modalData.type;
    this.textColor = this.type === 'warn' ? '#000' : '#fff';
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
