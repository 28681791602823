/**
 * The status of an account or clinic.
 */
export enum AccountStatus {
  /**
   * The account or clinic can perform calls and text messages to all numbers.
   */
  Normal = 1,
  /**
   * The account or clinic can only initiate calls or text messages to numbers configured
   * in the white list phone number table.
   */
  SettingUp = 2
}
