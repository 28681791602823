import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import AppointmentTypeDto from 'src/app/DTOS/Appointments-types/appointmentType';
import { AppointmentDetails } from './_dialogs/appointment-details-dialog/appointment-details-dialog.component';
import { AdminViewAsClinicDTO } from './DTOS/AdminViewAsClinicDTO';
import { ApiUserDTO, IApiUserList } from './DTOS/ApiUsers/ApiUsersDTO';
import {
  ClinicAssistanceViewModel,
  HomeViewModel,
  IStatusSummary,
  ISummaryCallType,
  SystemMessage
} from './DTOS/Appointments/AppointmentListItem';
import { ClinicDoctorAssistanceStatusDTO } from './DTOS/AssistanceStatus';
import { CallStatusGET_DTO, CallStatusViewModel } from './DTOS/CallStatus/DTOS';
import { CallTimingSetGET } from './DTOS/CallTiming/CallTiming';
import { CalltimingSet } from './DTOS/CalltimingSet/CallTimingSetGetDTO';
import { CallTimingSprocDTO } from './DTOS/CallTimingSproc/CallTimingSproc';
import { GetCallTimingSprocDTO } from './DTOS/CallTimingSproc/GetCallTimingSprocDTO';
import { CallType } from './DTOS/CallType/CallType';
import {
  ClinicConfigDTO,
  ClinicDetails,
  ClinicProviders
} from './DTOS/Clinic/clinic-details';
import { Doctor, GoogleTokenId } from './DTOS/Doctors/DoctorDTO';
import { GoogleCalendarDto } from './DTOS/GoogleCalendars/GoogleCalendarDto';
import { InsurancePlansDTO } from './DTOS/InsurancePlans/InsurancePlansDTO';
import {
  NoCallDateCreateDTO,
  NoCallDateGetDTO
} from './DTOS/NoCallDate/NoCallDateCreateDTO';
import {
  PatientDetail,
  PatientPhoneNumber
} from './DTOS/Patient/Patient-Detail';
import {
  AddPermissionDTO,
  ClinicPermissionDTO
} from './DTOS/Permission/PermissionDTO';
import { RatePlan } from './DTOS/RatePlans/RatePlan';
import { ReminderScheduleGet } from './DTOS/ReminderSchedule/ReminderScheduleGet';
import { ReminderSetDto } from './DTOS/ReminderSets/ReminderSetDto';
import { ClinicStatByStatus } from './DTOS/Reports/ClinicStatByStatus';
import { SpecialAnnouncementCreateDTO } from './DTOS/SpecialAnnouncement/SpecialAnnouncement';
import { Speciality } from './DTOS/Speciality/Speciality';
import {
  TCMDetail,
  TransitionalCareConfigMessageDTO
} from './DTOS/TransitionalCareAdmisionsAndDischarges/TransitionalCareAdmisionsAndDischarges';
import {
  PasswordChangeDTO,
  UserCreateDTO,
  UserEditDTO,
  UserGetDTO
} from './DTOS/Users/Users';
import { GetAccountWithClinicInfo } from './DTOS/VA_Configurations/GetAccountWithClinicInfo';
import {
  IntentType,
  SpeechVoicesList,
  VirtualAssistantAccountDTO,
  VirtualAssistantDoctorsDTO,
  VirtualAssistantDoctorTagsDTO,
  VirtualAssistantServicesDTO,
  VirtualAssistantServiceTagsDTO,
  InitialQuestions,
  IntakeFormSelect
} from './DTOS/VA_Configurations/VA_Configurations';
import * as moment from 'moment';
import { BookingService } from './DTOS/Bookings/BookingServiceDTO';
import {
  BookingAppointments,
  BookingAppointmentsList,
  BookingPatient
} from './DTOS/Bookings/BookingAppointmentsDTO';
import { GetBookingsRequest } from './DTOS/Bookings/GetBookingsRequest';
import { BookingModel } from './DTOS/Bookings/BookingDTO';
import {
  WhiteListedPhoneNumbers,
  WhiteListedPhoneNumbersList
} from './DTOS/Clinic/WhiteListedPhoneNumbersDTO';
import { AddCompanyDTO } from './DTOS/Company/AddCompanyDTO';
import {
  EngineSetting,
  IEngineSettingsList
} from './DTOS/Admin/AdminViewAsClinic';
import { ElectronicCalendar } from './DTOS/Ehrs/EhrGetDTO';
import { removeFalsyValues } from './Helpers/helper-functions';
import { AvailableVirtualPhoneNumber } from './DTOS/AvailableVirtualPhoneNumbers/AvailableVirtualPhoneNumber';
import {
  BotActionDto,
  CustomIntent
} from './DTOS/VA_Configurations/CustomIntent';
import GenericList from './DTOS/Generics/GenericList';
import { SmsStats, SmsStatsInRange } from './DTOS/System/SmsStats';
import { map } from 'rxjs/operators';
import orderBy from 'lodash-es/orderBy';
import { IntentConfiguration } from './DTOS/VA_Configurations/IntentConfiguration';
import { AppointmentStatsPerRangeItem } from './DTOS/System/AppointmentsStats';
import {
  CustomerNote,
  GetConversationsChangeLogItem,
  GetCustomerNoteDTO
} from './DTOS/CustomerNotes/CustomerNote';
import { AddAppointmentListItem } from './DTOS/Appointments/AddAppointmentListItem';
import {
  Conversation,
  ConversationStatus,
  ConversationStatusCount,
  CreateConversationDto,
  SendConversationMessage
} from './DTOS/Conversations/Conversation';
import { GetLastSyncDateResponse } from './DTOS/Appointments/GetLastSyncDateResponse';
import { VaCallsSummary } from './DTOS/Admin/VaCallsSummary';
import { EnableBookingConfirmationResult } from './DTOS/Bookings/EnableBookingsConfirmationResult';
import { SystemAnnouncementDetail } from './DTOS/SystemMessages/System-MesagesDto';
import { UserDefinedCallStatus } from './DTOS/IncomingCall/UserDefinedCallStatus';
import { LocationDTO } from './DTOS/Locations/Location';
import { QuickReply } from './DTOS/QuickReplies/quickReply';
import { Board } from './DTOS/Boards/Board';
import { WaitingListPatient } from './DTOS/Boards/WaitingListPatient';
import { BoardTransitions } from './DTOS/Boards/BoardTransaction';
import { CitamedEntity } from './DTOS/commont';
import { ChangeLogRoot } from './DTOS/ChangeLogs/changeLog';
import { DesactivateBillingDto } from './DTOS/billing-accounts/billing-accountsDto';
import { IncomingCallsResultDTO } from './DTOS/IncomingCall/IncomingCallsDTO';
import {
  IntakeFormDTO,
  IntakeFormDTOResultDTO
} from './DTOS/VA_Configurations/IntakeForm.DTO';
import { Subscription, lastValueFrom } from 'rxjs';
import { GetNotesAssociatedByPhonesResponse } from './DTOS/CustomerNotes/AssociatedToPhone';
import { BookingStatusesDTO } from './DTOS/Bookings/BookingStatusesDTO';
import { ShortenedLinkGet } from './DTOS/URL-Shortener/ShortenedLinks';
import { AccountStatus } from './DTOS/Enums/AccountStatus';
import { ReminderList } from './DTOS/Reminders/ReminderSequence';
import { VaConversation } from './DTOS/VaConversation/va-conversation';
import { VaConversationMessage } from './DTOS/VaConversation/VaConversationMessage';

export type GetAppointmentsListParams = {
  date: string;
  CallTypeId: number;
  UserId: number;
  appointmentTypeID: number;
  page: number;
  quantity: number;
  clinicId: number;
  sortColumn: 'AppointmentDate' | 'DoctorId' | 'PatientName';
  sortOrder: 'ASC' | 'DESC';
  doctorsIds?: number[];
  searchTerm?: string;
  callStatusId?: string[];
  locationsIds?: number[];
  specialitiesIds?: number[];
};

const localToUtcIsoString = (localDate: Date): string => {
  return moment(localDate).utc().toISOString();
};
const utcToLocalDate = (utcDate: Date): Date => {
  return moment.utc(utcDate).local().toDate();
};

const toDateOnlyFormat = (date: Date) => {
  return moment(date).format('YYYY-MM-DD');
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  httpClient: HttpClient;
  baseUrl: string;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }

  public GET_BASE_URL() {
    const base = this.baseUrl;
    return base;
  }

  public CreateAppointmentType(dto: AppointmentTypeDto) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'AppointmentTypes/CreateAppointmentType',
      dto
    );
  }

  public UpdateAppointmentType(dto: AppointmentTypeDto) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'AppointmentTypes/UpdateAppointmentType',
      dto
    );
  }

  public GetAppointmentTypes() {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'AppointmentTypes/GetAppointmentTypes'
    );
  }

  public GetClinicAppointmentTypes(clinicid: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        'AppointmentTypes/GetClinicAppointmentTypes?clinicid=' +
        clinicid
    );
  }

  public ToggleAppointmentTypeForClinic(
    Clinicid: number,
    AppointmentTypeId: number,
    onOrOff: boolean
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() +
        `AppointmentTypes/ToggleAppointmentTypeForClinic?clinicid=${Clinicid}&AppointmentTypeId=${AppointmentTypeId}&onOrOff=${onOrOff}`,
      {}
    );
  }
  public FetchDoctors(ClinicID: number) {
    return this.httpClient.get<Doctor[]>(
      this.GET_BASE_URL() + 'Doctors/GetAll?ClinicID=' + ClinicID
    );
  }
  public FetchNoCallDates(ClinicID: number) {
    return this.httpClient.get<NoCallDateGetDTO[]>(
      this.GET_BASE_URL() + 'nocalldates/GetNoCallDates?ClinicID=' + ClinicID
    );
  }
  public CreateNoCallDate(noCallDateDTO: NoCallDateCreateDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'NoCallDates/SaveNoCallDate',
      noCallDateDTO
    );
  }

  public DeleteNoCallDate(NoCallDateId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        'nocalldates/DeleteNoCallDate?NoCallDateID=' +
        NoCallDateId
    );
  }

  public RemoveNoCallDate(nocalldate: NoCallDateCreateDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'NoCallDates/RemoveNoCallDate',
      nocalldate
    );
  }

  public Login({ username, Password }: { username: string; Password: string }) {
    return this.httpClient.post<any>(this.GET_BASE_URL() + 'Auth/Login', {
      Identifier: username,
      Password
    });
  }

  public GetAllUsers(clinicId: number) {
    return this.httpClient.get<UserGetDTO[]>(
      this.GET_BASE_URL() + 'users/getall?clinicid=' + clinicId
    );
  }

  public ChangePassword(user: UserEditDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'users/ChangePassword',
      user
    );
  }
  public EditUser(user: UserEditDTO) {
    return this.httpClient.post(this.GET_BASE_URL() + 'users/EditUser', user);
  }
  public CreateUser(user: UserCreateDTO, clinicId: number) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `users/Create?ClinicID=${clinicId}`,
      user
    );
  }
  public GetAllAppointments(clinicId: number, _date: string) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `Appointments/GetAppointments?clinicId=${clinicId}&AppointmentDate=${_date}`
    );
  }
  public GetAllDoctors(clinicId: number) {
    return this.httpClient.get<Doctor[]>(
      this.GET_BASE_URL() + `Doctors/GetAll?clinicId=${clinicId}`
    );
  }

  public GetAllReminderSet(clinicId: number) {
    return this.httpClient.get<ReminderSetDto[]>(
      this.GET_BASE_URL() + `ReminderSet/GetAll?clinicId=${clinicId}`
    );
  }

  public SearchReminders(clinicId: number) {
    return this.httpClient.get<ReminderSetDto[]>(
      this.GET_BASE_URL() + `ReminderSet/SearchReminders?ClinicID=${clinicId}`
    );
  }

  public DeleteReminder(reminderSetId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        `ReminderSet/DeleteReminder?reminderSetId=${reminderSetId}`
    );
  }

  public GetListlReminderSet(
    currentPage: number,
    pageSize: number,
    clinicId: number,
    searchTerm: string,
    messageType: number | null
  ) {
    return this.httpClient.get<ReminderList>(
      this.GET_BASE_URL() +
        `ReminderSet/GetListReminders?searchTerm=${searchTerm}&page=${currentPage}&pageSize=${pageSize}${
          clinicId ? `&clinicId=${clinicId}` : ''
        }${messageType !== null ? `&messageType=${messageType}` : ''}`
    );
  }

  public SendSpecialAnnouncement(dto: SpecialAnnouncementCreateDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'SpecialAnnouncement/SendSpecialAnnouncement',
      dto
    );
  }

  public GetReminderSetReminderSet(clinicId: number) {
    const route = 'reminderset/GetReminderSetReminderSet?clinicid=' + clinicId;
    return this.httpClient.get(this.GET_BASE_URL() + route);
  }

  public DeleteReminderSetReminderSet(reminderSetReminderSetId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `ReminderSet/DeleteReminderSetReminderSet?ReminderSet_ReminderSetID=` +
        reminderSetReminderSetId
    );
  }

  public CreateReminderSetReminderSet(dto: any) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'ReminderSet/CreateReminderSetReminderSet',
      dto
    );
  }

  public UpdateReminderSchedule(e: ReminderScheduleGet) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'ReminderSchedule/UpdateReminderSchedule',
      e
    );
  }

  public AddReminderSchedule(e: ReminderScheduleGet) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'ReminderSchedule/AddReminderSchedule',
      e
    );
  }

  public DeleteReminderSchedule(reminderScheduleId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        `ReminderSchedule/DeleteReminderSchedule?reminderScheduleId=` +
        reminderScheduleId
    );
  }

  public GetAllCallTypes() {
    return this.httpClient.get<CallType[]>(
      this.GET_BASE_URL() + 'calltypes/GetAll'
    );
  }

  public FetchCallTimingSets(ClinicID: number) {
    return this.httpClient.get<CallTimingSetGET[]>(
      this.GET_BASE_URL() + `Calltiming/GetAll?ClinicID=` + ClinicID
    );
  }

  public GetReminderSchedules(ClinicID: number) {
    return this.httpClient.get<ReminderScheduleGet[]>(
      this.GET_BASE_URL() +
        `reminderschedule/GetReminderSchedules?ClinicID=${ClinicID}`
    );
  }

  public CreatePasswordResetToken(Identifier: string) {
    return this.httpClient.post('PasswordReset/CreatePasswordResetToken', {
      Identifier
    });
  }

  public NO_CALL_DATE_PauseAllForClinic(clinicId: number, date: string) {
    return this.httpClient.post(
      this.GET_BASE_URL() +
        `nocalldates/PauseAllForClinic?clinicId=${clinicId}&date=${date}`,
      {}
    );
  }
  public ResumeAllAppointmentsForClinic(clinicId: number, date: string) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        `nocalldates/ResumeAllAppointmentsForClinic?clinicId=${clinicId}&date=${date}`,
      {}
    );
  }

  public GetAppointmentDetails(clinicId: number, appointmentId: number) {
    return this.httpClient.get<AppointmentDetails>(
      this.GET_BASE_URL() +
        `appointments/GetAppointmentDetails?clinicid=${clinicId}&appointmentid=${appointmentId}`
    );
  }

  public GetAppointmentsSummary(parameters: GetAppointmentsListParams) {
    return this.httpClient.post<HomeViewModel>(
      this.GET_BASE_URL() + `Appointments/get-appointments-list`,
      removeFalsyValues(structuredClone(parameters))
    );
  }
  public GetAppointmentsStatusSummary(
    date: string,
    CallTypeId: number,
    UserId: number,
    appointmentTypeID: number
  ) {
    return this.httpClient.get<{ statusSummary: IStatusSummary[] }>(
      this.GET_BASE_URL() +
        `Appointments/GetAppointmentsStatusSummary?date=${date}&CallTypeId=${CallTypeId}&UserId=${UserId}${
          appointmentTypeID ? '&appointmentTypeId=' + appointmentTypeID : ''
        }`
    );
  }

  public GetAppointmentSummaryCallTypes(
    date: string,
    CallTypeId: number,
    UserId: number,
    appointmentTypeID: number
  ) {
    return this.httpClient.get<{ summaryCallTypes: ISummaryCallType[] }>(
      this.GET_BASE_URL() +
        `Appointments/GetAppointmentSummaryCallTypes?date=${date}&CallTypeId=${CallTypeId}&UserId=${UserId}${
          appointmentTypeID ? '&appointmentTypeId=' + appointmentTypeID : ''
        }`
    );
  }

  public CreateNewViewLogSystemMessage(
    UserId: number,
    SystemAnnouncementId: number
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'SystemMessages/CreateNewViewLog',
      {
        UserId,
        SystemAnnouncementId
      }
    );
  }
  public GetAllSystemAnouncementsList(
    page: number,
    pageSize: number,
    clinicId: number | null,
    searchTerm: string | ''
  ) {
    return this.httpClient.get<GenericList<SystemAnnouncementDetail>>(
      this.GET_BASE_URL() +
        `SystemMessages/get-list?searchTerm=${searchTerm}&page=${page}&pageSize=${pageSize}${
          clinicId ? `&clinicId=${clinicId}` : ''
        }`
    );
  }

  public CreatemAnouncement(newAnnouncement: SystemAnnouncementDetail) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'SystemMessages/CreateNew',
      newAnnouncement
    );
  }
  public UpdatemAnouncement(newAnnouncement: SystemAnnouncementDetail) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'SystemMessages/Update',
      newAnnouncement
    );
  }
  public DeleteAnouncement(systemAnnouncementId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        `SystemMessages/DeleteSystemAnnouncement?systemAnnouncementId=${systemAnnouncementId}`
    );
  }

  public GetSystemMessageDetails(systemAnnouncementId: number) {
    return this.httpClient.get<SystemAnnouncementDetail>(
      this.GET_BASE_URL() +
        `SystemMessages/GetSystemMessageDetails?systemAnnouncementId=${systemAnnouncementId}`
    );
  }

  public GetAllCallStatus(clinicID: number) {
    return this.httpClient.get<CallStatusGET_DTO[]>(
      this.GET_BASE_URL() + `CallStatus/GetAll?ClinicID=${clinicID}`
    );
  }
  public GetAllCallStatusList(
    page: number,
    pageSize: number,
    searchTearm: string
  ) {
    return this.httpClient.get<GenericList<CallStatusViewModel>>(
      this.GET_BASE_URL() +
        `CallStatus/get-list?page=${page}&pageSize=${pageSize}&searchTerm=${searchTearm}`
    );
  }

  public GetAllCallStatuDetails(callStatusId: string) {
    return this.httpClient.get<CallStatusViewModel>(
      this.GET_BASE_URL() +
        `CallStatus/GetAllCallStatuDetails?callStatusId=${callStatusId}`
    );
  }

  public UpdateCallStatusView(callStatus: CallStatusViewModel) {
    return this.httpClient.put<CallStatusViewModel>(
      this.GET_BASE_URL() + `CallStatus/UpdateCallStatusView`,
      callStatus
    );
  }
  public CreateCallStatusView(callStatus: CallStatusViewModel) {
    return this.httpClient.post<CallStatusViewModel>(
      this.GET_BASE_URL() + 'CallStatus/CreateNew',
      callStatus
    );
  }

  public DeleteAppointmentCallSchedule(
    appointmentId: number,
    callTypeId: number
  ) {
    const route = `Appointments/DeleteAppointmentCallSchedule?AppointmentID=${appointmentId}&CallTypeID=${callTypeId}`;
    return this.httpClient.delete(this.GET_BASE_URL() + route);
  }

  public GetAllEhrs() {
    return this.httpClient.get(this.GET_BASE_URL() + 'EHRs/GetAll');
  }

  public GetEHRPagedList(
    currentPage: number,
    pageSize: number,
    searchTerm: string
  ) {
    const route = `EHRs/GetAllFilter?page=${currentPage}&pageSize=${pageSize}&searchTerm=${searchTerm}`;
    return this.httpClient.get(this.GET_BASE_URL() + route);
  }

  public CreateElectronicCalendar(electronicCalendar: ElectronicCalendar) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'EHRs/AddElectronicCalendar',
      electronicCalendar
    );
  }

  public UpdateElectronicCalendar(electronicCalendar: ElectronicCalendar) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'EHRs/UpdateElectronicCalendar',
      electronicCalendar
    );
  }

  public DeleteElectronicCalendar(electronicCalendarId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        'EHRs/DeleteElectronicCalendar?ElectronicCalendarId=' +
        electronicCalendarId
    );
  }

  public GetAllCalltimings(clinicId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'Calltiming/GetCallTimingSets?clinicId=' + clinicId
    );
  }

  public UpdateCallTimingSet(calltimingSet: CalltimingSet) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'Calltiming/UpdateCallTimingSet',
      calltimingSet
    );
  }

  public CreateCallTimingSet(calltimingSet: CalltimingSet) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'Calltiming/CreateCallTimingSet',
      calltimingSet
    );
  }

  public DeleteCallTimingSet(CalltimingSetID: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        'Calltiming/DeleteCallTimingSet?CalltimingSetID=' +
        CalltimingSetID
    );
  }

  public GetAllRatePlans() {
    return this.httpClient.get<RatePlan[]>(
      this.GET_BASE_URL() + 'rateplan/GetAall'
    );
  }

  public CreateRatePlan(newPlan: RatePlan) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'rateplan/Create',
      newPlan
    );
  }

  public DeleteRatePlan = (RateplanID: number) =>
    this.httpClient.delete(
      this.GET_BASE_URL() + 'rateplan/Delete?RateplanID=' + RateplanID
    );

  public GetSpecialities() {
    return this.httpClient.get<Speciality[]>(
      this.GET_BASE_URL() + 'speciality/GetAll'
    );
  }

  public CreateDoctor(doctor: {
    ClinicId: number;
    specialtyId: number | null;
    doctorName: string;
    doctorInternalIdentifier: string | null;
  }) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Doctors/CreateDoctor',
      doctor
    );
  }

  public UpdateDoctor(doctor: {
    ClinicId: number;
    doctorId: number;
    ClinicDoctorId: number;
    specialtyId: number | null;
    doctorName: string;
    doctorInternalIdentifier: string | null;
  }) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Doctors/EditDoctor',
      doctor
    );
  }

  public DeleteDoctor(doctorId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() + 'Doctors/DeleteDoctor?doctorId=' + doctorId
    );
  }

  public GetClinicPhoneNumber(ClinicID: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'clinics/GetClinicPhoneNumber?ClinicID=' + ClinicID
    );
  }

  public ActivateT_CallerID(ClinicID: number, extension: string) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `clinics/ActivateT_CallerID?ClinicID=${ClinicID}${
          extension ? `&extension=${extension}` : ''
        }`
    );
  }

  public GetClinic(ClinicID: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'clinics/GetClinic?ClinicID=' + ClinicID
    );
  }
  public GetClinicByCompany(CompanyId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'clinics/GetClinicByCompany?CompanyId=' + CompanyId
    );
  }

  public GetClinicConfiguration(clinicID: number) {
    const route =
      this.GET_BASE_URL() + `Clinics/GetConfig?ClinicId=${clinicID}`;

    return this.httpClient.get(route);
  }

  public UpdateEHR(clinicConfig: ClinicConfigDTO) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'clinics/UpdateConfig',
      clinicConfig
    );
  }

  public UpdateClinic(clinic: ClinicDetails) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'clinics/UpdateClinic',
      clinic
    );
  }

  public CreateClinic(clinic: ClinicDetails) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'clinics/CreateClinic',
      clinic
    );
  }

  public HasTwoWayTextingEnabled(clinicid: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        'clinics/HasTwoWayTextingEnabled?clinicid=' +
        clinicid
    );
  }

  public SearchCompany(searchTerm: string) {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'company/SearchCompany?searchTerm=' + searchTerm
    );
  }
  public CreateCompany(companyDTO: AddCompanyDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Company/CreateCompany',
      companyDTO
    );
  }
  public UpdateCompany(companyDTO: AddCompanyDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Company/UpdateCompany',
      companyDTO
    );
  }
  public GetCompany() {
    return this.httpClient.get(this.GET_BASE_URL() + 'Company/GetCompany');
  }
  public GetCompanyById(companyId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + `Company/GetCompanyById?companyId=${companyId}`
    );
  }
  public GetVerticalTypes() {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'Vertical/GetVerticalTypes'
    );
  }
  public DesactivateCompany(company: DesactivateBillingDto) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `Company/deactivate-company`,
      company
    );
  }
  public FetchAssociatedProducts(companyId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + `Company/${companyId}/get-associated-products`
    );
  }

  public GetClinicCallAgainSchedules(clinicId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'CallAgainSchedule/GetByClinic?clinicid=' + clinicId
    );
  }

  public GetAccountStats() {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'accounts/GetAccountStats'
    );
  }

  public GetMissedCalls(
    clinicID: number,
    currentPage: number,
    pageSize: number,
    fromFormat: string | null,
    toFormat: string | null,
    searchTearm: string,
    selectedGenericIntentTypes: number[] | null,
    selectedCustomIntentTypes: number[] | null,
    selectedStatuses: number[] | null
  ) {
    let route = `VA/${clinicID}/IncomingCalls/list?accountId=${clinicID}&page=${currentPage}&pagesize=${pageSize}&from=${fromFormat}&To=${toFormat}&searchTerm=${searchTearm}`;

    if (selectedGenericIntentTypes && selectedGenericIntentTypes.length) {
      route =
        route + selectedGenericIntentTypes.map((ci) => `&si=${ci}`).join('');
    }

    if (selectedCustomIntentTypes && selectedCustomIntentTypes.length) {
      route =
        route + selectedCustomIntentTypes.map((ci) => `&ci=${ci}`).join('');
    }

    if (selectedStatuses && selectedStatuses.length) {
      route = route + selectedStatuses.map((ci) => `&uS=${ci}`).join('');
    }
    return this.httpClient.get<IncomingCallsResultDTO>(
      this.GET_BASE_URL() + route
    );
  }

  public DeleteMissedCall(clinicID: number, CallIds: number[]) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `VA/${clinicID}/IncomingCalls`,
      CallIds
    );
  }

  public GetAppointmentsRequest(
    clinicID: number,
    currentPage: number,
    pageSize: number,
    fromFormat: string | null,
    toFormat: string | null,
    searchTearm: string
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `VA/${clinicID}/AppointmentRequests/GetList?accountId=${clinicID}&page=${currentPage}&pagesize=${pageSize}&from=${fromFormat}&To=${toFormat}&searchTerm=${searchTearm}`
    );
  }

  public GetAllPermissions(HasValue: boolean) {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'Permission/GetAll?HasValue=' + HasValue
    );
  }
  public CreatePermission(permission: AddPermissionDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Permission/CreatePermission',
      permission
    );
  }
  public UpdatePermission(permission: AddPermissionDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Permission/EditPermission',
      permission
    );
  }

  public GetAllClinicPermissions(clinicid: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'ClinicPermissions/GetAll?ClinicID=' + clinicid
    );
  }

  public CreateClinicPermission(clicPermissionDTO: ClinicPermissionDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'ClinicPermissions/CreateClinicPermission',
      clicPermissionDTO
    );
  }

  public DeleteClinicPermissions(clinicPermissionId: number) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `ClinicPermissions/DeleteClinicPermission`,
      clinicPermissionId
    );
  }
  public async PasswordChange(passwordChageDTO: PasswordChangeDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Auth/PasswordChange',
      passwordChageDTO
    );
  }

  public LoadAccount(accountId: number) {
    return this.httpClient.get(this.GET_BASE_URL() + `VA/${accountId}/Config`);
  }
  public AddAccount(accountId: number, account: VirtualAssistantAccountDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountId}/Config`,
      account
    );
  }
  public UpdateAccount(accountID: number, account: VirtualAssistantAccountDTO) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `VA/${accountID}/Config`,
      account
    );
  }

  public CreateDoctorVirtualAssistant(
    accountId: number,
    doctor: VirtualAssistantDoctorsDTO
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountId}/Doctors/CreateDoctor`,
      doctor
    );
  }

  public LoadListDoctorVirtualAssistant(
    clinicID: number,
    currentPage: number,
    pageSize: number,
    searchTearm: string
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `VA/${clinicID}/Doctors/GetList?accountId=${clinicID}&page=${currentPage}&pagesize=${pageSize}&searchTerm=${searchTearm}`
    );
  }

  public UpdateDoctorVirtualAssistant(
    accountID: number,
    doctor: VirtualAssistantDoctorsDTO
  ) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `VA/${accountID}/Doctors`,
      doctor
    );
  }
  public DeleteDoctorVirtualAssistant(accountID: number, doctorID: number) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountID}/Doctors/DeleteDoctor`,
      doctorID
    );
  }

  public CreateServiceVirtualAssistant(
    accountId: number,
    doctor: VirtualAssistantServicesDTO
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountId}/Services`,
      doctor
    );
  }

  public UpsertQuestions(
    accountID: number,
    InitialQuestions: InitialQuestions[]
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountID}/BotQuestions/upsert`,
      InitialQuestions
    );
  }

  public GetInitialQuestions(accountID: number) {
    return this.httpClient.get<InitialQuestions[]>(
      this.GET_BASE_URL() + `VA/${accountID}/BotQuestions/get-initial-questions`
    );
  }

  public DeleteBotQuestion(accountID: number, botQuestionId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        `VA/${accountID}/BotQuestions/delete?botQuestionId=${botQuestionId}`
    );
  }

  public LoadListServicesVirtualAssistant(
    clinicID: number,
    currentPage: number,
    pageSize: number,
    searchTearm: string
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `VA/${clinicID}/Services/GetList?accountId=${clinicID}&page=${currentPage}&pagesize=${pageSize}&searchTerm=${searchTearm}`
    );
  }

  public UpdateServiceVirtualAssistant(
    accountID: number,
    sevices: VirtualAssistantServicesDTO
  ) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `VA/${accountID}/Services`,
      sevices
    );
  }
  public DeleteServiceVirtualAssistant(accountID: number, serviceID: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() + `VA/${accountID}/Services?serviceID=${serviceID}`,
      {}
    );
  }

  public CreateDoctorTagsVirtualAssistant(
    accountId: number,
    DoctorTag: VirtualAssistantDoctorTagsDTO
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountId}/DoctorsTags/CreateDoctorTag`,
      DoctorTag
    );
  }

  public GetListDoctorTagsVirtualAssistant(
    accountId: number,
    doctorID: number,
    currentPage: number,
    pageSize: number,
    searchTearm: string
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `VA/${accountId}/DoctorsTags/GetListDoctorTags?doctorID=${doctorID}&page=${currentPage}&pagesize=${pageSize}&searchTerm=${searchTearm}`
    );
  }

  public DeleteSDoctorTagsVirtualAssistant(
    accountID: number,
    doctorTagID: number
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountID}/DoctorsTags/DeleteDoctorTags`,
      doctorTagID
    );
  }

  public CreateServiceTagsVirtualAssistant(
    accountId: number,
    ServiceTag: VirtualAssistantServiceTagsDTO
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountId}/ServicesTags/CreateServiceTag`,
      ServiceTag
    );
  }

  public GetListVoicesVirtualAssistant(
    accountId: number,
    currentPage: number,
    pageSize: number
  ) {
    return this.httpClient.get<SpeechVoicesList>(
      this.GET_BASE_URL() +
        `VA/${accountId}/Voices/list?page=${currentPage}&pagesize=${pageSize}`
    );
  }

  public GetListServiceTagsVirtualAssistant(
    accountId: number,
    serviceID: number,
    currentPage: number,
    pageSize: number,
    searchTearm: string
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `VA/${accountId}/ServicesTags/GetListServiceTags?serviceID=${serviceID}&page=${currentPage}&pagesize=${pageSize}&searchTerm=${searchTearm}`
    );
  }

  public DeleteServiceTagsVirtualAssistant(
    accountID: number,
    serviceTagID: number
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountID}/ServicesTags/DeleteServiceTags`,
      serviceTagID
    );
  }

  public GetIntentTypes(accountID: number) {
    return this.httpClient.get<IntentType[]>(
      this.GET_BASE_URL() + `VA/${accountID}/Config/GetIntentTypes`
    );
  }

  public GetCustomIntentTypes(accountID: number) {
    return this.httpClient.get<CustomIntent[]>(
      this.GET_BASE_URL() +
        `VA/${accountID}/Config/GetCustomIntents?accountID=${accountID}`
    );
  }

  public GetLastSyncDate(clinicId: number) {
    return this.httpClient.get<GetLastSyncDateResponse>(
      this.GET_BASE_URL() + `Appointments/GetLastSyncDate?clinicId=${clinicId}`
    );
  }

  public GetSystemMessage(userid: number) {
    return this.httpClient.get<SystemMessage[]>(
      this.GET_BASE_URL() + `SystemMessages/GetSystemMessage?userid=${userid}`
    );
  }

  public GetPermissions() {
    return this.httpClient.get<string[]>(
      this.GET_BASE_URL() + 'auth/GetPermissions'
    );
  }

  public GetTransitionalCareRecords(
    clinicID: number,
    AppointmentType: number,
    fromDate: string | null,
    toDate: string | null,
    page: number,
    quantity: number,
    sortByColumn?: string | null,
    sortByDirection?: string | null,
    searchTerm?: string | null
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `TC/TransitionalCareRecords/GetListRecords?clinicID=${clinicID}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&quantity=${quantity}&sortByColumn=${
          sortByColumn || 'admitionDate'
        }&sortByDirection=${sortByDirection || 'desc'}${
          searchTerm ? `&searchTerm=${searchTerm}` : ''
        }${AppointmentType ? `&appointmentTypeID=${AppointmentType}` : ''}`
    );
  }
  public GetResumenTransitionalCare(
    clinicID: number,
    fromDate: string,
    toDate: string
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `TC/TransitionalCareRecords/GetResumenTransitionalCare?clinicID=${clinicID}&fromDate=${fromDate}&toDate=${toDate}`
    );
  }

  public CreateTransitionalCareMessage(
    transitionalCareConfigMessageDTO: TransitionalCareConfigMessageDTO
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `TC/TransitionalCareConfigMessages/CreateMessage`,
      transitionalCareConfigMessageDTO
    );
  }

  public UpdateTransitionalCareMessage(
    transitionalCareConfigMessage: TransitionalCareConfigMessageDTO
  ) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `TC/TransitionalCareConfigMessages/UpdateMessage`,
      transitionalCareConfigMessage
    );
  }

  public GetTransitionalCareMessage(
    clinicID: number,
    appointmentTypeID: number
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `TC/TransitionalCareConfigMessages/GetListRecords?clinicID=${clinicID}&appointmentTypeID=${appointmentTypeID}`
    );
  }

  public DeleteTransitionalCareMessageTemplaste(
    clinicID: number,
    MessageTemplateId: number
  ) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        `TC/TransitionalCareConfigMessages/DeleteMessage?clinicID=${clinicID}&MessageTemplateId=${MessageTemplateId}`
    );
  }

  public ActiveTransitionalCareMessageTemplaste(
    clinicID: number,
    MessageTemplateId: number
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() +
        `TC/TransitionalCareConfigMessages/ActiveMessage?clinicID=${clinicID}&MessageTemplateId=${MessageTemplateId}`,
      {}
    );
  }

  public GetClinicAssistanceConfig(clinicID: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + `Assistance/GetConfig?ClinicID=${clinicID}`
    );
  }

  public GetAssistanceStatuses() {
    const route = this.GET_BASE_URL() + `Assistance/GetAssistanceStatuses`;
    return this.httpClient.get(route);
  }

  public DeleteAssistanceStatusConfig(clinicDoctorAssistanceStatusId: number) {
    const route =
      this.GET_BASE_URL() +
      `Assistance/DeleteAssistanceStatusConfig?ClinicDoctorAssistanceStatusId=${clinicDoctorAssistanceStatusId}`;
    return this.httpClient.delete(route);
  }

  public CreateNewAssistanceStatusConfig(
    ClinicDoctorAssistanceStatusDTO: ClinicDoctorAssistanceStatusDTO
  ) {
    const route =
      this.GET_BASE_URL() + `Assistance/CreateNewAssistanceStatusConfig`;
    return this.httpClient.post(route, ClinicDoctorAssistanceStatusDTO);
  }
  public CreateNewAssistanceStatus(payload: ClinicAssistanceViewModel) {
    const route = this.GET_BASE_URL() + `Assistance/CreateNewAssistanceStatus`;
    return this.httpClient.post(route, payload);
  }

  public UpdateAssistanceStatus(payload: ClinicAssistanceViewModel) {
    const route = this.GET_BASE_URL() + `Assistance/UpdateNewAssistanceStatus`;
    return this.httpClient.put(route, payload);
  }

  public UpdateAppointmentAssistanceStatus(
    assistanceStatusID: number,
    appointmentId: number,
    clinicId: number
  ) {
    const route =
      this.GET_BASE_URL() +
      `Assistance/UpdateAppointmentAssistanceStatus?assistanceStatusID=${assistanceStatusID}&appointmentId=${appointmentId}&clinicId=${clinicId}`;
    return this.httpClient.put(route, {});
  }

  public GetTcmDetail(clinicID: number, appointmentid: number) {
    return this.httpClient.get<TCMDetail>(
      this.GET_BASE_URL() +
        `TC/TransitionalCareRecords/GetTcmDetail?clinicID=${clinicID}&appointmentid=${appointmentid}`
    );
  }
  public SendSms(data: {
    AppointmentId: number;
    ToNumber: string;
    MessageContent: string;
  }) {
    return this.httpClient.post(this.GET_BASE_URL() + 'SMS/sendmessage', data);
  }

  public VA_GetAssociatedAppointment(callId: number, clinicID: number) {
    const route =
      this.GET_BASE_URL() +
      `VA/${clinicID}/IncomingCalls/GetAssociatedAppointment?callId=${callId}`;

    return this.httpClient.get(route);
  }

  public IsUserPrinter(clinicID: number) {
    const route =
      this.GET_BASE_URL() +
      `ClinicPermissions/IsUserPrinter?ClinicID=${clinicID}`;

    return this.httpClient.get(route);
  }

  public GetReminderSchedulesConfig(clinicid: number, appointmentDate: Date) {
    const route =
      this.GET_BASE_URL() +
      `ReminderSchedule/GetReminderSchedulesConfig?ClinicID=${clinicid}&appointmentDate=${appointmentDate.toISOString()}`;

    return this.httpClient.get<GetReminderSchedulesConfigResult[]>(route);
  }

  public GetTCMGraphsData(
    clinicID: number,
    fromDate: Date,
    toDate: Date,
    searchTerm: string
  ) {
    const route =
      this.GET_BASE_URL() +
      `TC/TransitionalCareRecords/GetTCMGraphsData?clinicID=${clinicID}&fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}${
        searchTerm ? `&searchTerm=${searchTerm}` : ''
      }`;

    return this.httpClient.get<
      Array<{
        appointmentTypeId: number;
        data: Array<{
          date: Date;
          count: number;
        }>;
      }>
    >(route);
  }

  public GetGoogleConnection(doctorID: number) {
    const route =
      this.GET_BASE_URL() +
      `api/GoogleConnection/GetGoogleConnectionForDoctor?doctorId=${doctorID}`;

    return this.httpClient.get<GoogleTokenId>(route);
  }

  public DisconnectGoogleConnection(token: GoogleTokenId) {
    const route =
      this.GET_BASE_URL() +
      `api/googleconnection/disconnect-google-calendar?GoogleTokenId=${token.googleTokenId}`;

    return this.httpClient.post(route, null);
  }

  public GetCallTimingSprocs(
    currentPage: number,
    pageSize: number,
    searchTearm: string
  ) {
    const route =
      this.GET_BASE_URL() +
      `CallTimingSproc/GetCallTimingSprocs?&page=${currentPage}&pagesize=${pageSize}&searchTerm=${searchTearm}`;
    return this.httpClient.get(route);
  }

  public GetCallTimingSproc(sprocID: number) {
    return this.httpClient.get<GetCallTimingSprocDTO>(
      this.GET_BASE_URL() +
        'CallTimingSproc/GetCallTimingSproc?sprocID=' +
        sprocID
    );
  }

  public SearchCallTimingSproc(searchTerm: string) {
    const route =
      this.GET_BASE_URL() +
      `CallTimingSproc/SearchCallTimingSproc?searchTerm=${searchTerm}`;
    return this.httpClient.get(route);
  }

  public CreateCallTimingSproc(sproc: CallTimingSprocDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'CallTimingSproc/CreateCallTimingSproc',
      sproc
    );
  }

  public UpdateCallTimingSproc(sproc: CallTimingSprocDTO) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'CallTimingSproc/UpdateCallTimingSproc',
      sproc
    );
  }

  public GetVirtualAssistants(clinicID: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + `VA/${clinicID}/config/GetList`
    );
  }

  public GetInsurancePlan(insurancePlanID: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `InsurancePlans/GetInsurancePlan?insurancePlanID=${insurancePlanID}`
    );
  }

  public GetInsurancePlans() {
    const route = this.GET_BASE_URL() + `InsurancePlans/GetAllInsurancePlans`;
    return this.httpClient.get(route);
  }

  public CreateInsurancePlan(insurancePlan: InsurancePlansDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'InsurancePlans/CreateInsurancePlan',
      insurancePlan
    );
  }

  public UpdateInsurancePlan(insurancePlan: InsurancePlansDTO) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'InsurancePlans/UpdateInsurancePlan',
      insurancePlan
    );
  }

  public GetPatients(
    currentPage: number,
    pageSize: number,
    clinicID: number,
    searchTearm: string
  ) {
    let PageListRequest = {
      pageIndex: currentPage,
      pageSize: pageSize,
      clinicID: clinicID,
      searchTerm: searchTearm
    };
    const route = `api/PM/PatientRecords/GetPagedList`;
    return this.httpClient.post(this.GET_BASE_URL() + route, PageListRequest);
  }

  public GetPatient(patientId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + `api/PM/PatientRecords/${patientId}`
    );
  }

  public CreatePatient(patientRecord: PatientDetail) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'api/PM/PatientRecords',
      patientRecord
    );
  }

  public UpdatePatient(patientRecord: PatientDetail) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `api/PM/PatientRecords/${patientRecord.patientRecordId}`,
      patientRecord
    );
  }
  public DeletePatientRecord(patientId: number, clinicId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        `api/PM/PatientRecords/${patientId}?clinicId=${clinicId}`
    );
  }

  public GetPhoneNumbers(patientId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + `api/PM/PhoneNumbers?PatientRecordId=${patientId}`
    );
  }

  public CreatePhoneNumber(phoneNumber: PatientPhoneNumber) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'api/PM/PhoneNumbers',
      phoneNumber
    );
  }

  public UpdatePhoneNumbers(phoneList: PatientPhoneNumber[]) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `api/PM/PhoneNumbers/UpdateAll`,
      phoneList
    );
  }

  public DeletePhoneNumber(phoneNumberId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() + 'api/PM/PhoneNumbers/' + phoneNumberId
    );
  }

  public GetBookingServices(clinicId: number) {
    return this.httpClient.get<BookingService[]>(
      this.GET_BASE_URL() + `api/bookings/BookingServices?clinicId=${clinicId}`
    );
  }

  public CreateBookingService(bookingService: BookingService) {
    return this.httpClient.post<BookingService>(
      this.GET_BASE_URL() + 'api/bookings/BookingServices',
      bookingService
    );
  }

  public UpdateBookingService(bookingService: BookingService) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `api/bookings/BookingServices/${bookingService.bookingServiceId}`,
      bookingService
    );
  }

  public DeleteBookingService(bookingService: BookingService) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        'api/bookings/BookingServices/' +
        bookingService.bookingServiceId
    );
  }

  public GetBookingServiceDoctors(clinicId: number, bookingServiceId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `api/bookings/BookingServiceDoctors?clinicId=${clinicId}&bookingServiceId=${bookingServiceId}`
    );
  }

  public GetBookingServiceDoctor(bookingServiceDoctorId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `api/bookings/BookingServiceDoctors/` +
        bookingServiceDoctorId
    );
  }

  public CreateBookingServiceDoctor(bookingServiceDoctor: Doctor) {
    return this.httpClient.post<BookingService>(
      this.GET_BASE_URL() + 'api/bookings/BookingServiceDoctors/',
      bookingServiceDoctor
    );
  }

  public UpdateBookingServiceDoctor(bookingServiceDoctor: Doctor) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `api/bookings/BookingServiceDoctors/${bookingServiceDoctor.doctorId}`,
      bookingServiceDoctor
    );
  }

  public DeleteBookingServiceDoctor(bookingServiceDoctorId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        'api/bookings/BookingServiceDoctors/' +
        bookingServiceDoctorId
    );
  }

  public GetBookingAppointments(
    clinicID: number,
    page: number,
    pageSize: number,
    patientRecordId: number[],
    from?: Date,
    to?: Date,
    bookingServiceId?: number[],
    bookingStatusId?: number[],
    doctorId?: number[]
  ) {
    const payload: GetBookingsRequest = {
      clinicID: clinicID,
      pageIndex: page - 1 || 0,
      pageSize: pageSize,
      from: from,
      to: to,
      patientRecordsIds: (patientRecordId || []).filter((v) => v),
      bookingServicesId: (bookingServiceId || []).filter((v) => v),
      bookingStatusesId: (bookingStatusId || []).filter((v) => v),
      doctorsId: (doctorId || []).filter((v) => v)
    };

    let route = `api/bookings/GetBookingsList`;

    return lastValueFrom(
      this.httpClient.post<{
        list: BookingAppointmentsList;
        patients: Record<number, BookingPatient>;
      }>(this.GET_BASE_URL() + route, {
        ...payload,
        to: localToUtcIsoString(payload.to),
        from: localToUtcIsoString(payload.from)
      })
    ).then((eventsList) => {
      eventsList.list.items.forEach((item) => {
        item.startDateTime = utcToLocalDate(item.startDateTime);
        item.endDateTime = utcToLocalDate(item.endDateTime);
        if (item.bookingRecurrences && item.bookingRecurrences.length) {
          item.bookingRecurrences = item.bookingRecurrences.map(
            (recurrence) => {
              recurrence.startDateTime = utcToLocalDate(
                recurrence.startDateTime
              );
              recurrence.endDateTime = utcToLocalDate(recurrence.endDateTime);
              return recurrence;
            }
          );
        }
      });

      return eventsList;
    });
  }

  public GetBookingStatuses(clinicId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + `api/bookings/BookingStatus?clinicId=${clinicId}`
    );
  }

  public CreatetBookingStatuses(payload: BookingStatusesDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `api/bookings/BookingStatus/`,
      payload
    );
  }

  public UpdateBookingStatuses(payload: BookingStatusesDTO) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `api/bookings/BookingStatus/${payload.bookingStatusId}`,
      payload
    );
  }

  public DeleteBookingStatus(bookingStatusId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() + `api/bookings/BookingStatus/${bookingStatusId}`
    );
  }

  public GetSuggestedRecurrences(appointmentDate: Date) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `api/Recurrence/GetSuggestedRecurrences?dateStart=${new Date(
          appointmentDate
        ).toISOString()}`
    );
  }
  public RecordRecurrenceException(
    bookingID: number,
    exceptionDateUtc: Date,
    clinicID: number
  ) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        `api/RecurringBookingException/RecordRecurrenceException?bookingID=${bookingID}&ExceptionDateUtc=${localToUtcIsoString(
          exceptionDateUtc
        )}&ClinicID=${clinicID}`
    );
  }
  public SetRecurrenceEnd(
    bookingID: number,
    recurrenceEnd: Date,
    ClinicID: number
  ) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `api/bookings/SetRecurrenceEnd?bookingID=${bookingID}&recurrenceEnd=${localToUtcIsoString(
          recurrenceEnd
        )}&clinicId=${ClinicID}`,
      null
    );
  }

  public CreateBooking(
    booking: BookingModel,
    copyExceptionsFromBookingId?: number
  ) {
    const payload = {
      ...booking,
      startDateTimeUTC: moment(booking.startDateTime).utc().toISOString(),
      endDateTime: moment(booking.endDateTime).utc().toISOString()
    };

    const apiParams = removeFalsyValues({
      copyExceptionsFromBookingId
    });

    let params = new URLSearchParams(apiParams).toString();

    params = params ? '?' + params : params;

    return this.httpClient.post(
      `${this.GET_BASE_URL()}api/bookings${params}`,
      payload
    );
  }

  public UpdateBooking(booking: BookingModel) {
    const payload = {
      ...booking,
      endDateTime: localToUtcIsoString(booking.endDateTime),
      startDateTime: localToUtcIsoString(booking.startDateTime)
    };
    return this.httpClient.put(
      this.GET_BASE_URL() + `api/bookings/${booking.bookingID}`,
      payload
    );
  }

  public ChangeBookingStatus(booking: BookingModel) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `api/bookings/ChangeBookingStatus?BookingID=${booking.bookingID}&clinicID=${booking.clinicId}&BookingStatusId=${booking.bookingStatusId} `,
      {}
    );
  }
  public GetBooking(bookingId: number) {
    return this.httpClient
      .get<BookingModel>(this.GET_BASE_URL() + `api/bookings/${bookingId}`)
      .toPromise()
      .then((response) => {
        response.startDateTime = moment
          .utc(response.startDateTime)
          .local()
          .toDate();
        response.endDateTime = moment
          .utc(response.endDateTime)
          .local()
          .toDate();

        return response;
      });
  }
  public CreateApiUser(newApiUser: ApiUserDTO) {
    return this.httpClient.post<ApiUserDTO>(
      this.GET_BASE_URL() + 'ApiUsers/',
      newApiUser
    );
  }
  public UpdateApiUser(apiUserUpdate: ApiUserDTO) {
    return this.httpClient.put<ApiUserDTO>(
      this.GET_BASE_URL() + `ApiUsers/${apiUserUpdate.apiUserId}`,
      apiUserUpdate
    );
  }
  public DeleteBooking(clinicID: number, bookingId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() + `api/bookings/${bookingId}?&clinicID=${clinicID}`
    );
  }
  public GetApiUser(apiUserId: number) {
    return this.httpClient.get(this.GET_BASE_URL() + `ApiUsers/${apiUserId}`);
  }
  public GrantAccessToClinic(ApiUserId: number, clinicid: number) {
    return this.httpClient.post(
      this.GET_BASE_URL() +
        `ApiUsers/GrantClinicAccess?ApiUserId=${ApiUserId}&clinicid=${clinicid}`,
      clinicid
    );
  }
  public RevokeAccessToClinic(ApiUserId: number, clinicid: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        `ApiUsers/RevokeClinicAccess?ApiUserId=${ApiUserId}&clinicid=${clinicid}`
    );
  }
  public GetAssociates() {
    return this.httpClient.get(this.GET_BASE_URL() + `Associate/GetAll`);
  }

  public GetApiUsers(
    currentPage: number,
    pageSize: number,
    clinicID: number,
    searchTerm: string
  ) {
    const route = `ApiUsers/GetPagedList?page=${currentPage}&pageSize=${pageSize}
      ${clinicID ? `&clinicId=${clinicID}` : ''}
      ${searchTerm ? `&searchTerm=${searchTerm}` : ''}`;
    return this.httpClient.get<IApiUserList>(this.GET_BASE_URL() + route);
  }

  public GetClinics(searchTerm?: string, clinicId?: number) {
    const queryParams = {
      ...(searchTerm ? { searchTerm } : {}),
      ...(clinicId ? { clinicId } : {})
    };
    return this.httpClient.get<AdminViewAsClinicDTO[]>(
      this.GET_BASE_URL() +
        `Clinics/GetClinics?${new URLSearchParams(
          queryParams as any
        ).toString()}`
    );
  }

  public GetAccountWithClinicInfo(clinicId: number) {
    return this.httpClient.get<GetAccountWithClinicInfo>(
      this.GET_BASE_URL() + `VA/${clinicId}/config/GetAccountWithClinicInfo`
    );
  }

  public getGoogleCalendars(clinicId: number, doctorId: number) {
    return this.httpClient.get<GoogleCalendarDto[]>(
      this.GET_BASE_URL() +
        `api/GoogleConnection/get-google-calendars?doctorId=${doctorId}&clinicId=${clinicId}`
    );
  }

  public EnableCalendarSync(
    clinicId: number,
    doctorId: number,
    calendar: {
      googleTokenId: number;
      googleCalendarId: string;
      description: string;
      summary: string;
    }
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() +
        `api/GoogleConnection/enable-calendar-sync?doctorId=${doctorId}&clinicId=${clinicId}`,
      calendar
    );
  }

  public DisableCalendarSync(
    clinicId: number,
    doctorId: number,
    googleConnectedCalendarId: number
  ) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `api/GoogleConnection/disable-calendar-sync?doctorId=${doctorId}&clinicId=${clinicId}&GoogleConnectedCalendarId=${googleConnectedCalendarId}`,
      {}
    );
  }

  public TogglePrimaryCalendarSync(
    clinicId: number,
    doctorId: number,
    GoogleTokenId: number
  ) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `api/GoogleConnection/toggle-primary-calendar-sync?doctorId=${doctorId}&clinicId=${clinicId}&GoogleTokenId=${GoogleTokenId}`,
      {}
    );
  }

  public CreateWhiteListedPhone(whiteListedPhone: WhiteListedPhoneNumbers) {
    return this.httpClient.post<WhiteListedPhoneNumbers>(
      this.GET_BASE_URL() + 'WhiteListedPhoneNumber/AddPhone/',
      whiteListedPhone
    );
  }

  public GetAllWhiteListClinic(
    clinicID: number,
    page: number,
    pageSize: number,
    searchTerm: string
  ) {
    const route = `WhiteListedPhoneNumber/GetAllWhiteListClinic?clinicId=${clinicID}&page=${page}&pageSize=${pageSize}${
      searchTerm ? `&searchTerm=${searchTerm}` : ''
    }`;
    return this.httpClient.get<WhiteListedPhoneNumbersList>(
      this.GET_BASE_URL() + route
    );
  }

  public DeleteWhiteListedPhone(whiteListedPhoneNumberId: number) {
    return this.httpClient.delete<WhiteListedPhoneNumbersList>(
      this.GET_BASE_URL() +
        'WhiteListedPhoneNumber/DeletePhoneNumber?WhiteListedPhoneNumberId=' +
        whiteListedPhoneNumberId
    );
  }

  public GetEngineSettings(
    currentPage: number,
    pageSize: number,
    searchTerm: string
  ) {
    const route = `Constants/GetListConstants?page=${currentPage}&pageSize=${pageSize}
      ${searchTerm ? `&searchTerm=${searchTerm}` : ''}`;
    return this.httpClient.get<IEngineSettingsList>(
      this.GET_BASE_URL() + route
    );
  }
  public CreateConstant(EngineSetting: EngineSetting) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `Constants/CreateConstant`,
      EngineSetting
    );
  }
  public UpdateConstant(EngineSetting: EngineSetting) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `Constants/UpdateConstant`,
      EngineSetting
    );
  }
  public DeleteConstant(constantID: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() + `Constants/DeleteConstant?ConstantID=${constantID}`
    );
  }
  public GetAvailablePhoneNumbers(clinicId: number) {
    return this.httpClient.get<GetAvailableMobileNumbersResult>(
      this.GET_BASE_URL() +
        `va/${clinicId}/PhoneNumbers/GetAvailableMobileNumbers`
    );
  }

  public BuyAndActivateVANumber(clinicId: number, phone: string) {
    return this.httpClient.post(
      this.GET_BASE_URL() +
        `va/${clinicId}/PhoneNumbers/BuyAndActivateNumber?phoneNumber=${phone}`,
      {}
    );
  }

  public GetUserDefinedCallStatuses(
    clinicId: number,
    page: number,
    quantity: number
  ) {
    return this.httpClient.get<GenericList<UserDefinedCallStatus>>(
      this.GET_BASE_URL() +
        `VA/${clinicId}/UserCallStatus/GetList?page=${page}&pageSize=${quantity}`
    );
  }

  public UpdateIncomingCall(
    clinicId: number,
    callID: number,
    dto: {
      CallerName: string;
      UserCallStatusId: number;
    }
  ) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `va/${clinicId}/incomingcalls/update-incoming-call?callId=${callID}`,
      removeFalsyValues(dto)
    );
  }

  public GetCustomIntentsList(
    clinicId: number,
    page: number,
    quantity: number
  ) {
    return this.httpClient.get<{
      list: GenericList<CustomIntent>;
      configs: IntentConfiguration[];
      featuresStatus: {
        allowAppointmentRequests: boolean;
        enableRecordings: boolean;
      };
    }>(
      `${this.GET_BASE_URL()}va/api/CustomIntents/get-list?accountid=${clinicId}&page=${page}&quantity=${quantity}`
    );
  }

  public CreateCustomIntent(customIntent: CustomIntent) {
    return this.httpClient.post<CustomIntent>(
      `${this.GET_BASE_URL()}va/api/CustomIntents/create`,
      customIntent
    );
  }

  public UpdateCustomIntent(customIntent: CustomIntent) {
    return this.httpClient.post<CustomIntent>(
      `${this.GET_BASE_URL()}va/api/CustomIntents/update`,
      customIntent
    );
  }

  public DeleteCustomIntent(customIntentId: number) {
    return this.httpClient.delete<CustomIntent>(
      `${this.GET_BASE_URL()}va/api/CustomIntents/${customIntentId}`
    );
  }

  public UpsertIntentsConfiguration(
    accountId: number,
    intentsConfiguration: IntentConfiguration[]
  ) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `va/${accountId}/config/upsert-intents-configuration`,
      intentsConfiguration
    );
  }

  public SaveBotActionfiguration(
    accountId: number,
    botActionfiguration: BotActionDto
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `va/${accountId}/BotActions`,
      botActionfiguration
    );
  }
  public UpdateBotActionfiguration(
    accountId: number,
    botActionfiguration: BotActionDto
  ) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `va/${accountId}/BotActions`,
      botActionfiguration
    );
  }

  public GetIntentActions(
    accountId: number,
    intentType: number,
    customIntentId: number
  ) {
    return this.httpClient.get<BotActionDto[]>(
      this.GET_BASE_URL() +
        `VA/${accountId}/BotActions/get-intent-actions?intentType=${intentType}&customIntentId=${customIntentId}`
    );
  }

  public DeleteBotAction(accountId: number, botActionId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        `VA/${accountId}/BotActions/delete?actionId=${botActionId}`
    );
  }
  public DeleteBotsActionByCustomIntents(
    accountId: number,
    customIntentId: number
  ) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        `VA/${accountId}/BotActions/deletebycustomintent?customIntentId=${customIntentId}`
    );
  }

  public UpsertBotActions(accountID: number, botActionDto: BotActionDto[]) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountID}/BotActions/upsert`,
      botActionDto
    );
  }

  public GetSmsStatsInRange(from: Date, to: Date) {
    return this.httpClient.get<SmsStatsInRange[]>(
      `${this.GET_BASE_URL()}api/system/get-sms-stats-in-range?from=${toDateOnlyFormat(
        from
      )}&to=${toDateOnlyFormat(to)}`
    );
  }
  public GetSmsStats(date: Date) {
    return this.httpClient
      .get<SmsStats>(
        `${this.GET_BASE_URL()}api/system/get-sms-stats?date=${toDateOnlyFormat(
          date
        )}`
      )
      .pipe(
        map((d) => {
          if (!d.statsPerClinic?.length) {
            return d;
          }
          const total = d.statsPerProvider
            .map((s) => s.count)
            .reduce((accumulator, a) => {
              return accumulator + a;
            });

          d.statsPerProvider.forEach((e) => {
            e.percetage = String(((e.count / total) * 100).toFixed(2));
          });
          d.total = total;
          d.statsPerClinic = orderBy(
            d.statsPerClinic,
            ['count', 'clinic'],
            'desc'
          );
          return d;
        })
      );
  }

  public GetAppointmentsStatsPerRange(from: Date, to: Date, clinicId?: number) {
    // const queryParams = {
    //   ...(searchTerm ? { searchTerm } : {}),
    //   ...(clinicId ? { clinicId } : {}),
    // };
    // return this.httpClient.get<AdminViewAsClinicDTO[]>(
    //   this.GET_BASE_URL() +
    //     `Clinics/GetClinics?${new URLSearchParams(
    //       queryParams as any
    //     ).toString()}`
    // );

    const qParams = removeFalsyValues({
      from: toDateOnlyFormat(from),
      to: toDateOnlyFormat(to),
      clinicId
    });
    return this.httpClient.get<AppointmentStatsPerRangeItem[]>(
      `${this.GET_BASE_URL()}api/system/get-appointments-stats-in-range?${new URLSearchParams(
        qParams
      )}`
    );
  }

  public CreateCustomerNote(customerNote: CustomerNote) {
    const route = this.GET_BASE_URL() + `api/CustomerNotes/create`;
    return this.httpClient.post<GetCustomerNoteDTO>(route, customerNote);
  }

  public UpdateCustomerNote(customerNote: CustomerNote) {
    const route = this.GET_BASE_URL() + `api/CustomerNotes/update`;
    return this.httpClient.put(route, customerNote);
  }

  public GetAccountStatsByCallStatus(callDate: Date, clinicId?: number) {
    const qParams = removeFalsyValues({
      calldate: toDateOnlyFormat(callDate),
      clinicId
    });

    return this.httpClient.get<ClinicStatByStatus[]>(
      this.GET_BASE_URL() +
        `accounts/GetStatsByCallStatus?${new URLSearchParams(qParams)}`
    );
  }

  public GetCustomerNotes(
    page: number,
    pageSize: number,
    entityId: number,
    entityType: number,
    from?: Date,
    to?: Date
  ) {
    const qParams = removeFalsyValues({
      page,
      pageSize,
      entityId,
      entityType,
      from: from ? toDateOnlyFormat(from) : null,
      to: to ? toDateOnlyFormat(to) : null
    });

    const route =
      this.GET_BASE_URL() +
      `api/CustomerNotes/get-paged-list?${new URLSearchParams(qParams)}`;

    return this.httpClient.get<GenericList<GetCustomerNoteDTO>>(route);
  }

  public GetConversationsChangeLogs(
    page: number,
    pageSize: number,
    conversationId: number,
    from?: Date,
    to?: Date
  ) {
    const qParams = Object({
      ...removeFalsyValues({
        page,
        pageSize,
        from: from ? toDateOnlyFormat(from) : null,
        to: to ? toDateOnlyFormat(to) : null
      }),
      conversationId
    });

    const route =
      this.GET_BASE_URL() +
      `api/Conversations/get-conversation-change-logs?${new URLSearchParams(
        qParams
      )}`;

    return this.httpClient.get<GenericList<GetConversationsChangeLogItem>>(
      route
    );
  }

  public GetNotesCounts(entityId: number[], entityType: number) {
    const entitiesParam = entityId.map((e) => `entitiesIds=${e}`).join('&');

    const route =
      this.GET_BASE_URL() +
      `api/CustomerNotes/GetNotesCounts?${entitiesParam}&entityType=${entityType}`;

    return this.httpClient.get<{ entityId: number; count: number }[]>(route);
  }

  public DeleteCustomerNote(customerNoteId: number) {
    return this.httpClient.delete(
      `${this.GET_BASE_URL()}api/CustomerNotes/${customerNoteId}`
    );
  }

  public AddAppointmentsList(
    clinicId: number,
    appointments: AddAppointmentListItem[]
  ) {
    const route =
      this.GET_BASE_URL() + `appointments/AddAppointmentsList/${clinicId}`;
    return this.httpClient.post(route, appointments);
  }

  public UploadFile(apiRoute: string, file: File) {
    let formData = new FormData();
    formData.append('upload', file);

    let params = new HttpParams();
    const options = {
      params: params
    };

    const req = new HttpRequest('POST', apiRoute, formData, options);
    return this.httpClient.request(req);
  }

  public ContinuePrinterExtractionFromJob(
    clinicId: number,
    jobId: string,
    type: number,
    ehr: number
  ) {
    const route =
      this.GET_BASE_URL() +
      `Printer/v2/continue-from-job?clinicId=${clinicId}&jobId=${jobId}&type=${type}&ehr=${ehr}`;
    return this.httpClient.get(route);
  }

  public UpdateCallStatus(
    appointmentID: number,
    CallStatusID: string,
    NewCallStatusID: string,
    CallTypeId: number,
    ClinicID: number
  ) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `CallStatus/UpdateCallStatus?appointmentID=${appointmentID}&CallStatusID=${CallStatusID}&NewCallStatusID=${NewCallStatusID}&CallTypeId=${CallTypeId}&ClinicID=${ClinicID}`,
      {}
    );
  }

  public HasPermissionOrFeature(slug: string, clinicId: number) {
    const qParams = removeFalsyValues({
      slug,
      clinicId
    });

    return this.httpClient.get<boolean>(
      this.GET_BASE_URL() +
        `Permission/HasPermissionOrFeature?${new URLSearchParams(qParams)}`
    );
  }

  public GetConversations(
    page: number,
    pageSize: number,
    clinicId: number,
    searchTerm: string = '',
    statusFilter: ConversationStatus = ConversationStatus.ACTIVE
  ) {
    let route = `${this.GET_BASE_URL()}api/Conversations/get-paged-list?page=${page}&pageSize=${pageSize}&clinicid=${clinicId}&searchTerm=${searchTerm}&status=${statusFilter}`;
    return this.httpClient.get<GenericList<Conversation>>(route);
  }

  public GetEnumTelephonyProviders() {
    return this.httpClient.get(
      this.GET_BASE_URL() + `api/ClinicProviders/GetEnumTelephonyProviders`
    );
  }
  public GetProviderForClinicAsync(ClinicId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `api/ClinicProviders/GetProviderForClinicAsync?clinicId=${ClinicId}`
    );
  }
  public SetClinicProviders(ClinicProviders: ClinicProviders) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `api/ClinicProviders/SetClinicProviders`,
      ClinicProviders
    );
  }

  public GetVaStatuses(from: Date, to: Date, page: number, pageSize: number) {
    const qParams = removeFalsyValues({
      from: toDateOnlyFormat(from),
      to: toDateOnlyFormat(to),
      page,
      pageSize
    });
    return this.httpClient.get<GenericList<VaCallsSummary>>(
      `${this.GET_BASE_URL()}va/1/incomingcalls/get-accounts-report?${new URLSearchParams(
        qParams
      )}`
    );
  }

  public ToggleBookingConfirmations(bookingId: number, value: boolean) {
    return this.httpClient.put<EnableBookingConfirmationResult>(
      `${this.GET_BASE_URL()}api/Bookings/toggle-booking-confirmation?bookingID=${bookingId}&value=${String(
        value
      )}`,
      {}
    );
  }
  public DeleteBookingList(
    clinicID: number,
    bookingRemoveList: BookingAppointments[]
  ) {
    if (!clinicID) {
      throw Error('The clinicId is required');
    }
    const route =
      this.GET_BASE_URL() + `api/Bookings/DeleteBookingList/${clinicID}`;
    return this.httpClient.post(route, bookingRemoveList);
  }

  public CreateConversation(dto: CreateConversationDto) {
    if (!dto.clinicID) {
      throw Error('The clinicId is required');
    }
    return this.httpClient.post(
      `${this.GET_BASE_URL()}api/Conversations/create-conversation`,
      dto
    );
  }

  public GetUnreadMessagesCount() {
    return this.httpClient.get<number>(
      `${this.GET_BASE_URL()}api/Conversations/get-unread-messages-count`
    );
  }

  public SendConversationMessage(dto: SendConversationMessage) {
    return this.httpClient.post(
      `${this.GET_BASE_URL()}api/Conversations/send-conversation-message`,
      dto
    );
  }

  public GetConversationsByStatusCounts(clinicId: number) {
    return this.httpClient.get<ConversationStatusCount[]>(
      `${this.GET_BASE_URL()}api/Conversations/get-conversations-count-by-status?clinicId=${clinicId}`
    );
  }

  public UpdateConversationStatus(
    status: ConversationStatus,
    conversationId: number
  ) {
    return this.httpClient.put(
      `${this.GET_BASE_URL()}api/Conversations/change-status`,
      {
        conversationId,
        status
      }
    );
  }

  public retryCallSchedule(callScheduleId: number) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `Appointments/retry?callScheduleId=${callScheduleId}`,
      {}
    );
  }

  public GetSpecialitiesForClinics(clinicId: number) {
    return this.httpClient.get<Speciality[]>(
      `${this.GET_BASE_URL()}doctors/get-specialities-for-clinics/${clinicId}`
    );
  }

  public GetClinicLocations(
    clinicId: number,
    page: number,
    pageSize: number,
    searchTearm: string = ''
  ) {
    return this.httpClient.get<GenericList<LocationDTO>>(
      `${this.GET_BASE_URL()}Locations/get-locations-list?clinicId=${clinicId}&page=${page}&pageSize=${pageSize}&searchTerm=${searchTearm}`
    );
  }

  public GetLocationDetails(locationId: number, clinicId: number) {
    return this.httpClient.get<LocationDTO>(
      `${this.GET_BASE_URL()}Locations/${locationId}?&clinicId=${clinicId}`
    );
  }

  public AddLocation(newLocation: LocationDTO) {
    return this.httpClient.post<LocationDTO>(
      `${this.GET_BASE_URL()}Locations`,
      newLocation
    );
  }

  public UpdateLocation(Location: LocationDTO) {
    return this.httpClient.put<LocationDTO>(
      `${this.GET_BASE_URL()}Locations/${Location.locationId}`,
      Location
    );
  }

  public DeleteLocation(LocationId: number) {
    return this.httpClient.delete(
      `${this.GET_BASE_URL()}Locations/${LocationId}`
    );
  }

  public GetAllQuickReplies(clinicId: number) {
    return this.httpClient.get<QuickReply[]>(
      `${this.GET_BASE_URL()}quickReplies?clinicId=${clinicId}`
    );
  }

  public CreateQuickReply(quickReply: QuickReply) {
    return this.httpClient.post<QuickReply>(
      `${this.GET_BASE_URL()}quickReplies`,
      quickReply
    );
  }

  public UpdateQuickReply(quickReply: QuickReply) {
    return this.httpClient.put<QuickReply>(
      `${this.GET_BASE_URL()}quickReplies/${quickReply.quickReplyId}`,
      quickReply
    );
  }

  public deleteQuickReply(quickReplyId: number) {
    return this.httpClient.delete(
      `${this.GET_BASE_URL()}quickReplies/${quickReplyId}`
    );
  }

  public LoadBoard(clinicID: number, boardId: number) {
    return this.httpClient.get<Board>(
      this.GET_BASE_URL() +
        `Boards/GetBoard?ClinicID=${clinicID}&BoardId=${boardId}`
    );
  }

  public LoadBoardPatients(
    boardId: number,
    clinicID: number,
    dateFilter: string
  ) {
    return this.httpClient.get<WaitingListPatient[]>(
      this.GET_BASE_URL() +
        `Boards/GetBoardPatients?ClinicID=${clinicID}&BoardId=${boardId}&date=${dateFilter}`
    );
  }
  public CreateTransition(transition: BoardTransitions) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Boards/CreateBoardTransition',
      transition
    );
  }
  public updateWaitlistPatient(patient: WaitingListPatient) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Boards/UpdateBoardPatient',
      patient
    );
  }

  public SendMessageTransition(transitionId: number, patientId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `Boards/SendMessage?TransitionId=${transitionId}&PatientID=${patientId}`
    );
  }
  public CreateWaitlistPatient(newPatient: WaitingListPatient) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Boards/CreateBoardPatient',
      newPatient
    );
  }

  public GetAppointmentCallSchedules(appointmentid: number) {
    return this.httpClient.get<any[]>(
      this.GET_BASE_URL() + `appointments/${appointmentid}/get-call-schedules`
    );
  }

  public GetPhoneAppointmentsHistory(clinicid: number, phoneNumber: string) {
    return this.httpClient.get<any[]>(
      this.GET_BASE_URL() +
        `appointments/get-from-patient-phone/${clinicid}/${phoneNumber}`
    );
  }

  public GetVirtualPhones() {
    return this.httpClient.get<any[]>(
      this.GET_BASE_URL() + `api/virtualphones`
    );
  }

  public CreateVirtualPhonesInBatch(phones: string) {
    return this.httpClient.post<any[]>(
      this.GET_BASE_URL() + `api/virtualphones/create-batch`,
      {
        phones
      }
    );
  }

  public retryAppointments(dto: { appointmentIds: number[] }) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `appointments/retry-appointments`,
      dto
    );
  }

  public getChangeLogs(entityType: CitamedEntity, entityId: number) {
    return this.httpClient.get<ChangeLogRoot[]>(
      this.GET_BASE_URL() +
        `api/changelog/get-all?entity=${entityType}&id=${entityId}`
    );
  }

  public getAppointmentCallScheduleIds(appointmentId: number) {
    return this.httpClient.get<number[]>(
      this.GET_BASE_URL() +
        `Appointments/get-appointment-call-schedule-ids?appointmentid=${appointmentId}`
    );
  }

  public getVaCallStats(
    clinicId: number,
    from: Date,
    to: Date,
    monthTotal?: Date
  ) {
    return this.httpClient.get<{
      stats: { date: Date; count: number }[];
      monthTotalCount: number;
    }>(
      this.GET_BASE_URL() +
        `VA/${clinicId}/IncomingCalls/get-stats?from=${toDateOnlyFormat(
          from
        )}&to=${toDateOnlyFormat(to)}&monthTotal=${toDateOnlyFormat(
          monthTotal
        )}`
    );
  }

  public getVaShownInResultsQuestions(clinicId: number) {
    return this.httpClient.get<
      {
        label: string;
        botQuestionId: number;
      }[]
    >(this.GET_BASE_URL() + `VA/${clinicId}/BotQuestions/get-shown-in-results`);
  }

  public GetListGetIntakeForm(
    accountId: number,
    page: number,
    pageSize: number,
    searchTerm: string = ''
  ) {
    return this.httpClient.get<IntakeFormDTOResultDTO>(
      `${this.GET_BASE_URL()}VA/${accountId}/IntakeForms/paged-list?&page=${page}&size=${pageSize}&searchTerm=${searchTerm}`
    );
  }
  public CreateIntakeForm(accountId: number, intakeForm: IntakeFormDTO) {
    return this.httpClient.post<number>(
      `${this.GET_BASE_URL()}VA/${accountId}/IntakeForms`,
      intakeForm
    );
  }
  public GetIntakeForm(accountId: number, intakeFormId: number) {
    return this.httpClient.get<IntakeFormDTO>(
      `${this.GET_BASE_URL()}VA/${accountId}/IntakeForms/get?&intakeFormId=${intakeFormId}`
    );
  }

  public UpdateIntakeForm(
    accountId: number,
    intakeFormId: number,
    intakeForm: IntakeFormDTO
  ) {
    return this.httpClient.put<IntakeFormDTO>(
      `${this.GET_BASE_URL()}VA/${accountId}/IntakeForms?&intakeFormId=${intakeFormId}`,
      intakeForm
    );
  }

  public DeleteIntakeFormVirtualAssistant(
    accountId: number,
    intakeFormId: number
  ) {
    return this.httpClient.delete(
      `${this.GET_BASE_URL()}VA/${accountId}/IntakeForms/delete?&intakeFormId=${intakeFormId}`
    );
  }
  public UpsertQuestionsntakeForm(
    accountID: number,
    intakeFormId: number,
    intakeFormDTO: IntakeFormDTO
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() +
        `VA/${accountID}/IntakeForms/upsert?&intakeFormId=${intakeFormId}`,
      intakeFormDTO
    );
  }
  public GetIntakeFormList(accountID: number) {
    return this.httpClient.get<IntakeFormSelect[]>(
      this.GET_BASE_URL() + `VA/${accountID}/IntakeForms/GetIntakeFormList`
    );
  }

  public GetNotesAssociatedByPhones(clinicId: number, phoneNumber: string) {
    return this.httpClient.get<GetNotesAssociatedByPhonesResponse>(
      this.GET_BASE_URL() +
        `api/customernotes/get-notes-associated-by-phone?clinicid=${clinicId}&phoneNumber=${phoneNumber}`
    );
  }

  public getShortenedLinks() {
    return this.httpClient.get<ShortenedLinkGet[]>(
      this.GET_BASE_URL() + 'api/shortened-links'
    );
  }

  public createShortenedLink(link: ShortenedLinkGet) {
    return this.httpClient.post<ShortenedLinkGet[]>(
      this.GET_BASE_URL() + 'api/shortened-links',
      link
    );
  }

  public deleteShortenedLink(linkId: string) {
    return this.httpClient.delete<ShortenedLinkGet[]>(
      this.GET_BASE_URL() + 'api/shortened-links/' + linkId
    );
  }

  public getUserPermissions(userid: number) {
    return this.httpClient.get<GetUserPermissionDto[]>(
      `${this.GET_BASE_URL()}Permission/get-user-permissions?userid=${userid}`
    );
  }

  public ToggleUserPermission(userid: number, permissionId: number) {
    return this.httpClient.put<GetUserPermissionDto[]>(
      `${this.GET_BASE_URL()}Permission/toggle-user-permissions?userid=${userid}&permissionId=${permissionId}`,
      {}
    );
  }

  public GetClinicsByStatus(accountStatus: AccountStatus) {
    return this.httpClient.get<GetClinincsByStatus[]>(
      `${this.GET_BASE_URL()}clinics/get-clinics-by-status?status=${accountStatus}`
    );
  }

  public GetClinicsWithNoCallDates() {
    return this.httpClient.get<GetClinicsWithNoCallDates[]>(
      `${this.GET_BASE_URL()}clinics/get-clinics-with-no-call-dates`
    );
  }

  public GetPaginatedSmsLogs(
    clinicId?: number,
    page: number = 1,
    pageSize = 30,
    direction: string = '',
    from: string = '',
    to: string = '',
    selectedStatuses: string[] = []
  ) {
    const qParams = removeFalsyValues({
      page,
      pageSize,
      clinicId,
      direction,
      from,
      to,
      statuses: selectedStatuses
    });

    return this.httpClient.get<GenericList<SmsLogGet>>(
      `${this.GET_BASE_URL()}sms/logs?${new URLSearchParams(qParams)}`
    );
  }

  public GetVaConversations(
    clinicId: number,
    page: number,
    pageSize: number,
    searchTearm: string = ''
  ) {
    return this.httpClient.get<GenericList<VaConversation>>(
      `${this.GET_BASE_URL()}VA/${clinicId}/Conversations/get-list?page=${page}&pageSize=${pageSize}`
    );
  }

  public GetVaConversationMessages(
    clinicId: number,
    conversationId: number,
    page: number,
    pageSize: number
  ) {
    return this.httpClient.get<GenericList<VaConversationMessage>>(
      `${this.GET_BASE_URL()}VA/${clinicId}/Conversations/get-messages-list?page=${page}&pageSize=${pageSize}&conversationId=${conversationId}`
    );
  }
}

export interface GetClinicsWithNoCallDates extends GetClinincsByStatus {
  noCallDates: Date[];
}
export interface GetClinincsByStatus {
  clinicId: number;
  clinicName: string;
  createDate: Date;
}
export interface GetUserPermissionDto {
  permissionId: number;
  permissionName: string;
  description: string;
  enabled: boolean;
}

export interface GetAvailableMobileNumbersResult {
  phones: AvailableVirtualPhoneNumber[];
}
export interface GetReminderSchedulesConfigResult {
  reminderScheduleId: number;
  doctorId: number;
  reminderSetName: string;
  doctorName: string;
  callTypeId: number;
  callTypeName: string;
  callTimingSetId: number;
  callTimingSetName: string;
  description: string;
  callAttempts: number;
  forAppointmentOn: string;
  callOn: string;
  callDate: string;
}

export interface SmsLogGet {
  smsLogId: number;
  createDate: string;
  clinic: {
    clinicName: string;
    clinicId: number;
  };
  direction: string;
  messageContent: string;
  segments: number;
  telephonyProvider: number;
  toNumber: string;
  fromNumber: string;
  externalId: string;
  messageStatus: string;
  media: any[];
  _formatedCreateDate: string;
  _formatedHour: string;
}
