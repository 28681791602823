// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-message {
  display: block;
  padding: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 80%;
  min-height: inherit;
  max-height: inherit;
  position: relative;
  z-index: 9999;
}

.title {
  font-weight: bolder;
  font-size: 1.4375rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImhlYWRlci1zeXN0ZW0tbWVzc2FnZXMuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxjQUFBO0VBQ0EsYUFBQTtFQUNBLGtCQUFBO0VBQ0Esc0JBQUE7RUFDQSxjQUFBO0VBQ0EsVUFBQTtFQUNBLFdBQUE7RUFDQSxXQUFBO0VBQ0EsbUJBQUE7RUFDQSxtQkFBQTtFQUNBLGtCQUFBO0VBQ0EsYUFBQTtBQUNGOztBQUVBO0VBQ0UsbUJBQUE7RUFDQSxvQkFBQTtBQUNGIiwiZmlsZSI6ImhlYWRlci1zeXN0ZW0tbWVzc2FnZXMuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuaGVhZGVyLW1lc3NhZ2Uge1xuICBkaXNwbGF5OiBibG9jaztcbiAgcGFkZGluZzogMTJweDtcbiAgYm9yZGVyLXJhZGl1czogNHB4O1xuICBib3gtc2l6aW5nOiBib3JkZXItYm94O1xuICBvdmVyZmxvdzogYXV0bztcbiAgb3V0bGluZTogMDtcbiAgd2lkdGg6IDEwMCU7XG4gIGhlaWdodDogODAlO1xuICBtaW4taGVpZ2h0OiBpbmhlcml0O1xuICBtYXgtaGVpZ2h0OiBpbmhlcml0O1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gIHotaW5kZXg6IDk5OTk7XG59XG5cbi50aXRsZSB7XG4gIGZvbnQtd2VpZ2h0OiBib2xkZXI7XG4gIGZvbnQtc2l6ZTogMS40Mzc1cmVtO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/Components/header-system-messages/header-system-messages.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,cAAA;EACA,UAAA;EACA,WAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;AACF;;AAEA;EACE,mBAAA;EACA,oBAAA;AACF;AACA,45BAA45B","sourcesContent":[".header-message {\n  display: block;\n  padding: 12px;\n  border-radius: 4px;\n  box-sizing: border-box;\n  overflow: auto;\n  outline: 0;\n  width: 100%;\n  height: 80%;\n  min-height: inherit;\n  max-height: inherit;\n  position: relative;\n  z-index: 9999;\n}\n\n.title {\n  font-weight: bolder;\n  font-size: 1.4375rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
