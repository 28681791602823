import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAppointmentListItem } from '../../DTOS/Appointments/AppointmentListItem';
import { ReminderSetDto } from '../../DTOS/ReminderSets/ReminderSetDto';
import { AuthorDto } from 'src/app/DTOS/AuthorDto';

export interface AppointmentDetailsDialogData {
  appointmentData: IAppointmentListItem;
}

export interface Attempts {
  timings: Timing[];
  lastAttemptOrder: number;
  callDate: Date;
}
export interface Timing {
  callOrder: number;
  callTime: string;
  callTimeUnformatted: string;
}

export interface AppointmentDetails {
  details: CallHistory[];
  attempts: Attempts;
  appointmentDetails: {
    createDate: Date;
    author?: AuthorDto;
  };
}

export interface MessageApiLog {
  messageApiLogId: number;
  smsLogId: number;
  createDate: string;
  description: string;
  raw: string;
  errorCode: string;
  type: string;
}

export interface CallHistory {
  attempt: number;
  callTime: Date;
  appointmentStatusName: string;
  callDuration: number;
  callStatus: string;
  twilioCallStatus: string;
  appointmentId: number;
  callScheduleId: number;
  _formatedDate: string;
  callLogId: number;
  twilioCallSid: string;
  imageCallStatus: string;
  reminderSetId: number;
  reminderSetName: string;
  apiLogs: MessageApiLog[];
}

@Component({
  selector: 'app-appointment-details-dialog',
  templateUrl: './appointment-details-dialog.component.html',
  styleUrls: ['./appointment-details-dialog.component.scss']
})
export class AppointmentDetailsDialogComponent implements AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AppointmentDetailsDialogData,
    public dialogRef: MatDialogRef<AppointmentDetailsDialogComponent>,
    @Inject('BASE_URL') baseUrl: string,
    http: HttpClient
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }
  minSelectedDate: Date = new Date();
  public _callHistory: CallHistory[];
  selectedReminderSet: ReminderSetDto;

  httpClient: HttpClient;
  current_Audio_Hawl: any;
  isLoading = false;
  isPlayingAudio = false;
  baseUrl: string;
  ngAfterViewInit() {
    this.FetchDoctors();
  }
  public onDismiss() {
    this.dialogRef.close(false);
  }

  public FetchDoctors() {
    this.isLoading = true;
    const ClinicID = this.data.appointmentData.appointmentId;
    const AppointmentID = this.data.appointmentData.clinicId;

    this.httpClient
      .get<
        CallHistory[]
      >(this.baseUrl + `appointments/GetAppointmentDetails?clinicid=${ClinicID}&appointmentid=${AppointmentID}`)
      .subscribe(
        (result) => {
          const res = result as CallHistory[];
          this._callHistory = res;
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
  }
}
