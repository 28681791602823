import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { applicationRoutes } from '../app.routes';
import { PermissionSlugsService } from '../Services/PermissionSlugsService';
import { AuthenticationService } from '../_services';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      if (user.landingRoute) {
        this.router.navigate([user.landingRoute]);
      } else {
        this.router.navigate([applicationRoutes.ConfirmationsPage]);
      }
    });
  }
}
