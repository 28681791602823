import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { removeFalsyValues } from 'src/app/Helpers/helper-functions';

function removeAccents(str: string) {
  const accents = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',
    Á: 'A',
    É: 'E',
    Í: 'I',
    Ó: 'O',
    Ú: 'U',
    à: 'a',
    è: 'e',
    ì: 'i',
    ò: 'o',
    ù: 'u',
    À: 'A',
    È: 'E',
    Ì: 'I',
    Ò: 'O',
    Ù: 'U',
    â: 'a',
    ê: 'e',
    î: 'i',
    ô: 'o',
    û: 'u',
    Â: 'A',
    Ê: 'E',
    Î: 'I',
    Ô: 'O',
    Û: 'U',
    ã: 'a',
    õ: 'o',
    ñ: 'n',
    ä: 'a',
    ë: 'e',
    ï: 'i',
    ö: 'o',
    ü: 'u',
    Ä: 'A',
    Ë: 'E',
    Ï: 'I',
    Ö: 'O',
    Ü: 'U',
    ç: 'c',
    Ç: 'C',
    ß: 'ss',
    ÿ: 'y',
    Ý: 'Y'
  };

  return str
    .split('')
    .map((char) => accents[char] || char)
    .join('');
}

@Pipe({
  name: 'searchTermFilter'
})
@Injectable()
export class SearchTermFilter implements PipeTransform {
  transform(
    items: any[],
    searchTerm: string,
    col1: string,
    col2: string,
    col3: string
  ): any {
    if (!searchTerm) {
      return items;
    }
    return items.filter((item) => {
      return Object.values(
        removeFalsyValues({
          [col1]: item[col1],
          [col2]: item[col2],
          [col3]: item[col3]
        })
      ).some(
        (v) =>
          removeAccents(String(v)?.toLowerCase())?.indexOf(
            searchTerm?.toLowerCase()
          ) !== -1
      );
    });
  }
}
