import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isString } from 'lodash';
import { IAppointmentListItem } from 'src/app/DTOS/Appointments/AppointmentListItem';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from 'src/app/_dialogs/confirm-dialog/confirm-dialog.component';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.scss']
})
export class AppointmentDetailsComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private _snackBar: MatSnackBar
  ) {}

  @Output() appointmentChange = new EventEmitter<any>();

  public User: User;
  public showCallSchedulesHistory: boolean = false;
  @Input() expandedElement: IAppointmentListItem = {} as any;

  public copy = (val: string | number) => {
    this._snackBar.open(`"${val}" copiado al porta papeles`, 'ok', {
      horizontalPosition: 'start',
      verticalPosition: 'bottom'
    });
    navigator.clipboard.writeText(String(val));
  };

  public getCallSchedulesObsr(appointmentId: number) {
    return this.API.GetAppointmentCallSchedules(appointmentId);
  }

  public openJson(jsonString: string | object, title: string) {
    if (!this.User.isAdmin) {
      return;
    }

    let stringVal = '';
    if (isString(jsonString)) {
      stringVal = JSON.stringify(JSON.parse(jsonString), null, 2);
    } else {
      stringVal = JSON.stringify(jsonString, null, 2);
    }
    this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogModel(
        title,
        null,
        null,
        'cerrar',
        '',
        `<pre>${stringVal}</pre>`
      )
    });
  }

  public retryCallSchedule(callScheduleId: number) {
    this.dialog
      .open(ConfirmDialogComponent, {
        maxWidth: '600px',
        data: new ConfirmDialogModel(
          'Estas segur@ de querer reintentar este envio?',
          'Esto reescribira el status del intento',
          'Okay',
          'Cancelar'
        )
      })
      .afterClosed()
      .subscribe((response: boolean) => {
        if (!response) {
          return;
        }
        this.API.retryCallSchedule(callScheduleId).subscribe(() => {
          this.appointmentChange.emit();
          this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '600px',
            data: new ConfirmDialogModel(
              'Listo',
              'Listo, se volvera a contactar al paciente',
              'Okay',
              null
            )
          });
        });
      });
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
    });
  }

  public hasAppointmentBeenConfirmed() {
    return this.expandedElement?.CallHistory?.some((history) => {
      return ['cancelado', 'confirmado'].includes(
        history.callStatus?.toLowerCase()
      );
    });
  }
}
