import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ApiService } from '../api.service';
import { AuthenticationService } from '../_services';
import { CacheService } from './CacheService';

@Injectable()
export class PermissionSlugsService {
  public permissionsListener = new BehaviorSubject<string[]>([]);

  public async fetchInitialData() {
    const clinicPermissions = await firstValueFrom(this.API.GetPermissions());
    this.permissionsListener.next(clinicPermissions);
  }

  constructor(
    private API: ApiService,
    private auth: AuthenticationService,
    private cacheService: CacheService
  ) {}
}
