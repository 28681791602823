import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
/** UpdateAppointmentCallStatus component*/
import { MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Howl } from 'howler';
import * as moment from 'moment';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { GetReminderSchedulesConfigResult } from 'src/app/api.service';
import { AdminViewAsClinicDTO } from 'src/app/DTOS/AdminViewAsClinicDTO';
import { Doctor } from '../../DTOS/Doctors/DoctorDTO';
import { ReminderSetDto } from '../../DTOS/ReminderSets/ReminderSetDto';

type AddAppointmentsStep =
  | 'Form'
  | 'CSV_IMPORT_MAPPER'
  | 'Preview'
  | 'ScheduleConfigPage'
  | 'SyncScreen';
const SessionStorageKey = 'CSVIMPORT_CITAMED';
type PatientField =
  | 'patientName'
  | 'patientPhoneNumber'
  | 'appointmentDate'
  | 'appointmentTime';
@Component({
  selector: 'app-csv-import-dialog',
  templateUrl: './csv-import-dialog.html',
  styleUrls: ['./csv-import-dialog.scss']
})
export class CSVImportDialogComponent {
  constructor(
    @Inject('BASE_URL') baseUrl: string,
    http: HttpClient,
    public dialogRef: MatDialogRef<CSVImportDialogComponent>,
    private ngxCsvParser: NgxCsvParser,
    authService: AuthenticationService
  ) {
    this.baseUrl = baseUrl;
    this.httpClient = http;
    authService.currentUser.subscribe((curUser) => {
      this.clinicID = curUser?.clinicId;
      this.User = curUser;
    });
  }
  public clinicID: number;
  public step: AddAppointmentsStep = 'Form';
  public csvTotalCount = 0;
  public headerColumnMap: PatientField[] = [];
  csvRecords: any[][] = [];
  header = false;
  public callDate: Date;
  ViewAs: AdminViewAsClinicDTO;
  public doctorID: number;
  public reminderSetId: number;
  public patientFields: Array<{ col: PatientField; friendlyName: string }> = [
    { col: 'patientName', friendlyName: 'Nombre del paciente' },
    { col: 'patientPhoneNumber', friendlyName: 'Telefono' },
    { col: 'appointmentDate', friendlyName: 'fecha de la cita' },
    { col: 'appointmentTime', friendlyName: 'hora de la cita' }
  ];
  httpClient: HttpClient;
  current_Audio_Hawl: any;
  isLoading = false;
  isPlayingAudio = false;
  getReminderSchedulesConfigResults: GetReminderSchedulesConfigResult[];
  public doctors: Doctor[] = [];
  public ReminderSets: ReminderSetDto[] = [];
  public callTime: string;
  baseUrl: string;
  public previewHeaders: Array<{ col: PatientField; friendlyName: string }> =
    [];
  public previewData: any[] = [];
  public importUrl: SafeResourceUrl;
  public User: User;
  public getheaderColumnMap = () => this.headerColumnMap.join(',');
  public closeDialog = () => this.dialogRef.close();

  public getProgressValue = () => {
    let progressVal = 0;
    switch (this.step) {
      case 'Form':
        progressVal = 20;
        break;
      case 'CSV_IMPORT_MAPPER':
        progressVal = 40;
        break;

      case 'Preview':
        progressVal = 60;
        break;

      case 'ScheduleConfigPage':
        progressVal = 80;
        break;
      case 'SyncScreen':
        progressVal = 95;
        break;
      default:
        progressVal = 0;
        break;
    }
    return progressVal;
  };

  public doCsvParse = (limit: number = null) => {
    const usesTimeCol = this.headerColumnMap.some(
      (v) => v === 'appointmentTime'
    );
    const indexMap = this.patientFields.map((v) => {
      return {
        col: v.col,
        index: this.headerColumnMap.findIndex((val) => val === v.col)
      };
    });
    let arr = JSON.parse(sessionStorage.getItem(SessionStorageKey)) as any[][];
    if (limit) {
      arr = arr.slice(0, limit);
    }
    const elems = arr.map((value) => {
      const patNameIndex = indexMap.find((p) => p.col === 'patientName').index;
      const phoneIndex = indexMap.find(
        (p) => p.col === 'patientPhoneNumber'
      ).index;
      const appointmentDateIndex = indexMap.find(
        (p) => p.col === 'appointmentDate'
      ).index;
      const appointmentTimeIndex = indexMap.find(
        (p) => p.col === 'appointmentTime'
      ).index;
      const patientName = value[patNameIndex];

      const appointmentDateVal = moment(
        value[appointmentDateIndex] +
          (usesTimeCol ? ' ' + value[appointmentTimeIndex] : '')
      ).toDate();

      return {
        patientName,
        patientPhoneNumber: value[phoneIndex],
        appointmentDate: appointmentDateVal, // value[appointmentDateIndex],
        appointmentTime: value[appointmentTimeIndex]
      };
    });
    return elems as Array<{
      patientName: string;
      patientPhoneNumber: string;
      appointmentDate: Date;
      appointmentTime: string;
    }>;
  };

  public runParsePreview = () => {
    const elems = this.doCsvParse(7);
    this.previewData = elems;
    this.previewHeaders = Object.keys(elems[0]).map((c: PatientField) => {
      return {
        col: c,
        friendlyName: this.patientFields.find((v) => v.col === c).friendlyName
      };
    });
  };

  fileChangeListener($event: any): void {
    const files = $event.srcElement.files;
    this.header =
      (this.header as unknown as string) === 'true' || this.header === true;

    this.ngxCsvParser
      .parse(files[0], { header: this.header, delimiter: ',' })
      .pipe()
      .subscribe(
        (result: any[][]) => {
          const first10Elemnts = result.slice(0, 7);
          sessionStorage.setItem(SessionStorageKey, JSON.stringify(result));
          this.csvRecords = first10Elemnts;
          this.csvTotalCount = result.length;
          this.headerColumnMap = first10Elemnts[0].map((v) => null);
          this.step = 'CSV_IMPORT_MAPPER';
        },
        (error: NgxCSVParserError) => {
          console.error('Error', error);
        }
      );
  }
  public isFieldSelectionValid = (field: PatientField) => {
    if (field === 'appointmentTime') {
      return (
        this.headerColumnMap.some((v) => v === 'appointmentDate') ||
        this.headerColumnMap.some((v) => v === field)
      );
    }
    return this.headerColumnMap.some((v) => v === field);
  };

  public isheaderColumnMapValid = () => {
    return (
      this.headerColumnMap.some((e) => e === 'appointmentDate') &&
      this.headerColumnMap.some((e) => e === 'patientName') &&
      this.headerColumnMap.some((e) => e === 'patientPhoneNumber')
    );
  };
  public FetchReminderSets() {
    const clinicid = this.ViewAs?.clinicId || this.User.clinicId;
    this.isLoading = true;
    this.httpClient
      .get<
        ReminderSetDto[]
      >(this.baseUrl + `ReminderSet/GetAll?ClinicID=` + clinicid)
      .subscribe(
        (result) => {
          const res = result as ReminderSetDto[];
          this.ReminderSets = res;
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
  }
  public FetchDoctors() {
    this.isLoading = true;
    const clinicid = this.ViewAs?.clinicId || this.User.clinicId;
    this.httpClient
      .get<Doctor[]>(this.baseUrl + 'Doctors/GetAll?ClinicID=' + clinicid)
      .subscribe(
        (result) => {
          this.doctors = result as Doctor[];
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
  }

  SetIsAudioPlaying(val: boolean) {
    this.isPlayingAudio = val;
  }
  PlayAudioChain(file_names: string[]) {
    if (file_names.length) {
      this.isPlayingAudio = true;
      const arg = {
        src: file_names[0],
        onend: () => {
          file_names.shift();
          if (file_names.length > 0) {
            this.isPlayingAudio = true;
            this.PlayAudioChain(file_names);
          } else {
            this.isPlayingAudio = false;
          }
        }
      };
      this.current_Audio_Hawl = new Howl(arg);
      this.current_Audio_Hawl.play();
    } else {
      this.isPlayingAudio = false;
    }
  }
  StopAudio() {
    this.current_Audio_Hawl.stop();
    this.isPlayingAudio = false;
  }

  public playReminderSetAudios(remset: ReminderSetDto) {
    const links = remset.audios
      .filter((a) => a.messageOrPath.indexOf('http') > -1)
      .map((e) => e.messageOrPath);
    this.PlayAudioChain(links);
  }

  public moveToScheduleConfigPage() {
    this.step = 'ScheduleConfigPage';
    this.FetchDoctors();
    this.FetchReminderSets();
  }
  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.FetchDoctors();
    this.FetchReminderSets();
  }
  public DoTheImport = () => {
    this.step = 'SyncScreen';
    const clinicId = this.ViewAs?.clinicId || this.User.clinicId;
    const appointments = this.doCsvParse();
    const batch = {
      callDate: this.callDate,
      doctorID: this.doctorID,
      reminderSetId: this.reminderSetId,
      appointments,
      callTime: this.callTime,
      userID: this.ViewAs ? this.ViewAs.viewAsUserID : this.User.userId,
      clinicid: clinicId
    };
  };
}
