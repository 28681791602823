import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

type Theme = 'citamed' | 'ai-bot';
@Component({
  selector: 'app-chat-selector',
  templateUrl: './chat-selector.component.html',
  styleUrl: './chat-selector.component.scss'
})
export class ChatSelectorComponent implements OnChanges {
  @Input() isSelected: boolean = false;
  @Input() name: string = '';
  @Input() number: string = '';
  @Input() lastMessageContent: string = '';
  @Input() lastMessageDateFormatted: string = '';
  @Input() theme: Theme = 'citamed';

  @Input() unreadSmsResponsesCount: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isSelected']) {
    }
  }

  public getThemePhotoBg() {
    if (this.theme === 'ai-bot') {
      if (this.isSelected) {
        return 'f0daf3';
      }
      return 'D8C5DA';
    }
    return 'c2c2c2';
  }
}
