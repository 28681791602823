import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as moment from 'moment';
import { ApiService } from 'src/app/api.service';
import { SmsStatsInRange } from 'src/app/DTOS/System/SmsStats';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-admin-appointments-stats-in-range-card',
  templateUrl: './admin-appointments-stats-in-range-card.component.html',
  styleUrls: ['./admin-appointments-stats-in-range-card.component.scss']
})
export class AdminAppointmentsStatsInRangeCardComponent
  implements OnInit, OnChanges
{
  list: SmsStatsInRange[];
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  constructor(private API: ApiService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.clinicId.currentValue) {
      this.clinicId = changes?.clinicId.currentValue;
      this.load();
    }
  }

  @Input() public clinicId?: number;
  @Input() public from: Date;
  @Input() public to: Date;

  ngOnInit(): void {
    if (!this.from) {
      this.from = moment().startOf('month').toDate();
    }

    this.to = moment(this.from).add(15, 'day').toDate();
    this.load();
  }

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'Citas',
        backgroundColor: 'rgba(52,170,172,0.5)',
        borderColor: '#1c6666',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin'
      }
    ],
    labels: []
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5
      }
    },

    responsive: true,

    plugins: {
      legend: { display: true }
    }
  };
  public monthDateFormat(date: Date) {
    return moment(date).format('MMM DD');
  }
  public load() {
    this.API.GetAppointmentsStatsPerRange(
      this.from,
      this.to,
      this.clinicId
    ).subscribe((response) => {
      this.list = response;
      this.lineChartData.datasets[0].data = this.list.map((r) => r.count);
      this.lineChartData.labels = this.list.map((r) =>
        moment(r.date).format('MMM DD')
      );
      this.chart.update();
    });
  }
}
