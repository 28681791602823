import { Component, AfterViewInit, Inject } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import { Board } from 'src/app/DTOS/Boards/Board';
import { BoardColumns } from 'src/app/DTOS/Boards/BoardColumn';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/_services';
import { MatDialog } from '@angular/material/dialog';
import { AdminViewAsClinicDTO } from 'src/app/DTOS/AdminViewAsClinicDTO';
import { User } from 'src/app/_models';
import {
  LoadingDialogComponent,
  LoadingDialogModel
} from 'src/app/loading-dialog/loading-dialog.component';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from 'src/app/_dialogs/confirm-dialog/confirm-dialog.component';
import {
  WaitingListPatient,
  _Map
} from './../../DTOS/Boards/WaitingListPatient';
import { Doctor, checked as Selectable } from 'src/app/DTOS/Doctors/DoctorDTO';
import * as moment from 'moment';
import { cloneDeep, sortBy } from 'lodash-es';
import { BoardTransitions } from './../../DTOS/Boards/BoardTransaction';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';
import { HttpErrorSnackbarComponent } from 'src/shared/Components/http-error-snackbar/http-error-snackbar.component';
import { ApiService } from 'src/app/api.service';

function filterBySearchTerm(
  pats: WaitingListPatient[],
  searchTerm: string
): WaitingListPatient[] {
  return pats
    .filter((str) =>
      containsSearchTerm(
        `${str.patientName} ${str.patientPhoneNumber ?? ''}`.trim(),
        searchTerm
      )
    )
    .sort((a, b) => {
      const aTemplate = `${a.patientName} ${a.patientPhoneNumber ?? ''}`.trim();
      const bTemplate = `${b.patientName} ${b.patientPhoneNumber ?? ''}`.trim();
      const aMatchCount = (aTemplate.match(new RegExp(searchTerm, 'ig')) || [])
        .length;
      const bMatchCount = (bTemplate.match(new RegExp(searchTerm, 'ig')) || [])
        .length;
      return bMatchCount - aMatchCount;
    });
}

function containsSearchTerm(input: string, searchTerm: string): boolean {
  const regex = new RegExp(searchTerm.replace(/\s+/g, '|'), 'i');
  return regex.test(input);
}

const newBoard = Object.freeze({
  boardId: 0,
  boardName: '',
  isMainBoard: false,
  createDate: null,
  deleteDate: null,
  createdByUserId: 0,
  deletedByUserId: null,
  clinicId: 0,
  doctorId: null,
  boardColumns: []
});

@Component({
  selector: 'app-digital-waiting-list',
  templateUrl: './digital-waiting-list.component.html',
  styleUrls: ['./digital-waiting-list.component.scss']
})
export class DigitalWaitingListComponent implements AfterViewInit {
  ViewAs: AdminViewAsClinicDTO;
  public User: User;
  isLoading: boolean;
  loadingRef: any;
  baseUrl: string;
  httpClient: HttpClient;
  selectedDate: Date;
  newWaitingListPatient: WaitingListPatient;
  doctors: Doctor[];
  checked_doctors: Selectable<Doctor>[];
  public sendMessages: boolean = true;
  public newTransition: BoardTransitions;
  hasDWL_Permission: boolean;
  isLoadingPermissions: boolean = false;
  public searchTerm: string;

  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private permissionSlugsService: PermissionSlugsService,
    private API: ApiService
  ) {
    this.SetDefaultBoard();
    this.newWaitingListPatient = new WaitingListPatient();
    this.baseUrl = baseUrl;
    this.httpClient = http;
    this.selectedDate = new Date();
    this.columnsData = {};
    this.newTransition = new BoardTransitions();
  }

  ngAfterViewInit() {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
      this.LoadBoards();
      this.FetchDoctors();
      this.permissionSlugsService.permissionsListener.subscribe((r) => {
        this.hasDWL_Permission = r.includes(
          PermissionSlugs.DIGITAL_WAITING_LIST
        );
      });
    });
  }

  public selectAllDoctors(selection_value: boolean) {
    this.checked_doctors = this.doctors.map((e) => {
      return {
        selected: selection_value,
        value: e
      };
    });
  }

  public areAllDoctorsSelected() {
    return (
      this.doctors?.length ==
      this.checked_doctors?.filter((e) => e.selected)?.length
    );
  }

  public drawerHasBackdrop: boolean = true;
  public createBoardDrawerOpened: boolean = false;
  public notificationsDrawerOpened: boolean = false;
  public patDrawerOpened: Boolean = false;
  public syncDrawerOpened: Boolean = false;
  public newBoard: Board = cloneDeep(newBoard);
  public openedBoard: Board;
  public openedColumn: BoardColumns;
  public Boards: Board[];

  public copyObj = (obj: any) => cloneDeep(obj);

  public notificationsChannels = [
    {
      name: 'SMS',
      value: 1,
      disabled: false
    },
    {
      name: 'Llamada',
      value: 2,
      disabled: true
    },
    {
      name: 'Email',
      value: 3,
      disabled: true
    }
  ];
  public toggleNotificationsDrawer = (column: BoardColumns) => {
    if (this.notificationsDrawerOpened) {
      this.notificationsDrawerOpened = false;
      this.openedColumn = {} as any;
      this.newTransition = {} as any;
    } else {
      const newTrans: BoardTransitions = {
        toBoardColumnId: column.boardColumnId,
        patientOrder: 1,
        action: 1
      };
      this.newTransition = newTrans;
      this.openedColumn = column;
      this.notificationsDrawerOpened = true;
    }
  };

  public getNarr(n: number) {
    return Array.from(Array(n).keys()).map((k) => k + 1);
  }
  public getNarrObj(n: number) {
    return this.getNarr(n).map((c) => ({ number: c }));
  }
  public columnsData: _Map<WaitingListPatient[]>;
  public unfilteredColumnsData: _Map<WaitingListPatient[]>;

  public getClinicID = () => {
    let _clinicid = this.User.clinicId;
    if (this.ViewAs) _clinicid = this.ViewAs.clinicId;
    return _clinicid;
  };

  public FetchDoctors() {
    this.isLoading = true;
    let _clinicid = this.getClinicID();
    this.API.FetchDoctors(_clinicid).subscribe(
      (result) => {
        var res = result as Doctor[];
        this.doctors = res;
        this.checked_doctors = res.map((e) => {
          return { selected: false, value: e };
        });
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }
  private tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }
  public setNewWaitingListPatient(col: BoardColumns) {
    this.newWaitingListPatient = new WaitingListPatient();
    this.newWaitingListPatient.boardColumnId = col.boardColumnId;
    this.patDrawerOpened = true;
  }
  public CreateWaitlistPatient() {
    this.isLoading = true;

    let _apptDate = moment
      .utc(
        moment(this.selectedDate).format('YYYY-MM-DD') +
          ' ' +
          this.tConvert(this.newWaitingListPatient.appointmentTime)
      )
      .toISOString();

    this.newWaitingListPatient.clinicId = this.getClinicID();
    this.newWaitingListPatient.createdByUserId = this.User.userId;
    this.newWaitingListPatient.appointmentDate = _apptDate;

    this.API.CreateWaitlistPatient(this.newWaitingListPatient).subscribe(
      () => {
        this.LoadBoardPatients(this.openedBoard.boardId);
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  private SetDefaultBoard() {
    this.setNewBoard();

    const createColumn = (name: string) => {
      let c = new BoardColumns();
      c.columnName = name;
      return c;
    };

    this.newBoard.boardColumns = [
      createColumn('En espera'),
      createColumn('Atendiendo'),
      createColumn('Visto')
    ];
  }
  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.clear();
    this.LoadBoards();
    this.FetchDoctors();
  }

  private clear() {
    this.Boards = [];
    this.openedBoard = null;
    this.doctors = [];
    this.columnsData = {};
  }
  public LoadBoards() {
    let _clinicid = this.getClinicID();

    this.httpClient
      .get<
        Board[]
      >(this.baseUrl + 'Boards/GetClinicBoards?ClinicID=' + _clinicid)
      .subscribe(
        (result) => {
          var res = result as Board[];
          this.Boards = res;
          this.isLoading = false;
          //preselects main board
          if (!this.openedBoard) {
            const mainBoard =
              res.find((e) => e.isMainBoard) || res.find((e) => true);
            this.LoadBoard(mainBoard.boardId);
          }
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
  }

  public onEditBoardClicked = () => {
    this.createBoardDrawerOpened = true;
    this.newBoard = cloneDeep(this.openedBoard);
  };

  public setNewBoard() {
    this.newBoard = cloneDeep(newBoard);
    if ((this.Boards || []).length === 0) {
      this.newBoard.isMainBoard = true;
    }
  }

  // Removes accents from templates
  public transitionSmsInputBlur() {
    this.newTransition.template;
    const str = this.newTransition?.template || '';
    this.newTransition.template = str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace('¿', '')
      .replace('¡', '');
  }

  public LoadBoard(boardId: number) {
    let _clinicid = this.getClinicID();
    this.API.LoadBoard(_clinicid, boardId).subscribe(
      (result) => {
        var res = result as Board;
        this.openedBoard = res;
        this.setBoardData();
        this.LoadBoardPatients(boardId);
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  private setBoardData() {
    if (!this.openedBoard?.boardColumns?.length) return;

    this.columnsData = {};

    this.openedBoard.boardColumns.forEach((c) => {
      this.columnsData[c.boardColumnId] = [];
    });
  }

  public applyFilter() {
    try {
      if (!this.searchTerm?.trim()) {
        this.columnsData = structuredClone(this.unfilteredColumnsData);
        return;
      }
      const columnsInfo: _Map<WaitingListPatient[]> = structuredClone(
        this.unfilteredColumnsData
      );

      Object.keys(columnsInfo).forEach((k) => {
        const pats: WaitingListPatient[] = columnsInfo[k];
        const filtered = filterBySearchTerm(pats, this.searchTerm);
        columnsInfo[k] = filtered;
      });
      this.columnsData = columnsInfo;
    } catch (error) {
      console.error(error);
      this._snackBar.openFromComponent(HttpErrorSnackbarComponent, {
        duration: 5000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: ['bg-red-800', 'text-white']
      });
      this.columnsData = structuredClone(this.unfilteredColumnsData);
    }
  }

  public LoadBoardPatients(boardId: number) {
    let _clinicid = this.getClinicID();
    this.setBoardData();

    let dateFilter = moment(this.selectedDate).format('YYYY-MM-DD');

    this.API.LoadBoardPatients(boardId, _clinicid, dateFilter).subscribe(
      (result) => {
        var res = result as WaitingListPatient[];

        let colData: _Map<WaitingListPatient[]> = {};

        this.openedBoard.boardColumns.forEach(
          (c) => (colData[c.boardColumnId] = [])
        );

        res?.forEach((c) => {
          let colD = colData[c.boardColumnId] || [];
          colD.push(c);
          colData[c.boardColumnId] = colD;
        });

        Object.keys(colData).forEach((k) => {
          colData[k] = sortBy(colData[k], 'listOrder');
        });
        this.columnsData = colData;
        this.unfilteredColumnsData = structuredClone(colData);
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }
  public createBoard(newBoard: Board) {
    let _clinicid = this.getClinicID();

    this.isLoading = true;

    let dto = {
      ...newBoard,
      boardColumns: newBoard.boardColumns.map((bc, i) => {
        let board_col = bc as any;
        return {
          columnName: bc.columnName,
          colorCode: board_col.colorCode,
          ColumnOrder: i,
          boardColumnId: bc.boardColumnId
        };
      }),
      clinicId: _clinicid,
      CreatedByUserId: this.User.userId
    };

    let route = '';
    if (newBoard.boardId) route = this.baseUrl + 'Boards/UpdateBoard';
    else route = 'Boards/CreateBoard';

    this.httpClient.post(route, dto).subscribe(
      () => {
        if (newBoard.boardId) this.LoadBoard(newBoard.boardId);
        this.LoadBoards();
        this.setNewBoard();
      },
      (error) => {
        this.hideLoading();
        this.showError(error);
      }
    );
  }
  public createTransition(transition: BoardTransitions) {
    this.ShowLoading();
    try {
      transition.fromBoardColumnId = transition.toBoardColumnId;
      this.API.CreateTransition(transition).subscribe(
        () => {
          this.hideLoading();
          this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '600px',
            data: new ConfirmDialogModel(
              'Listo',
              'La configuración ha sido actualizada correctamente.',
              'Okay',
              null
            )
          });
          this.LoadBoard(this.openedBoard.boardId);
          this.newTransition = {} as BoardTransitions;
        },
        (error) => {
          this.hideLoading();
          this.showError(error);
        }
      );
    } catch (e) {
      this.hideLoading();
      this.showError(e);
    }
  }

  public hideLoading() {
    if (this.loadingRef) this.loadingRef.close();
    this.isLoading = false;
  }
  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false)
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
    this.isLoading = true;
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel(
        'Ha ocurrido un error',
        '',
        'Okay',
        null,
        error.display_message
      )
    });
  }

  public GetTextColor(
    bgColor,
    lightColor = '#ffffff',
    darkColor = '#2b3b54'
  ): string {
    try {
      var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
      var r = parseInt(color.substring(0, 2), 16); // hexToR
      var g = parseInt(color.substring(2, 4), 16); // hexToG
      var b = parseInt(color.substring(4, 6), 16); // hexToB
      var uicolors = [r / 255, g / 255, b / 255];
      var c = uicolors.map((col) => {
        if (col <= 0.03928) {
          return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
      });
      var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
      return L > 0.179 ? darkColor : lightColor;
    } catch (error) {
      return darkColor;
    }
  }

  public async updateWaitlistPatient(
    patient: WaitingListPatient,
    onSuccess: Function,
    onError: Function
  ) {
    await this.API.updateWaitlistPatient(patient).subscribe(
      () => {
        if (onSuccess) onSuccess();
      },
      (error) => {
        this.showError(error);
        if (onError) onError();
      }
    );
  }

  private DELETE(route: string, title: string = '', onSuccess: Function) {
    const message = `¿Estas seguro de querer eliminar ${title}?`;
    const dialogData = new ConfirmDialogModel(
      'Confirmación',
      message,
      'Si',
      'Cancelar'
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.httpClient.get('Boards/' + route).subscribe(() => {
          if (onSuccess) onSuccess();
        }, this.showError);
      }
    });
  }

  public DeleteBoardColumn(boardColumn: BoardColumns) {
    this.DELETE(
      'DeleteBoardColumn?BoardColumnId=' + boardColumn.boardColumnId,
      'esta columna',
      () => this.LoadBoard(this.openedBoard.boardId)
    );
  }
  public DeleteBoard(board: Board) {
    this.DELETE('DeleteBoard?BoardID=' + board.boardId, 'este tablero', () => {
      if (this.openedBoard.boardId === board.boardId) {
        this.openedBoard = null;
      }
      this.LoadBoards();
    });
  }
  public DeleteTransition(boardTransition: BoardTransitions) {
    this.DELETE(
      `DeleteBoardTransition?clinicID=${
        this.ViewAs?.clinicId || this.User.clinicId
      }&TransitionId=${boardTransition.transitionId}`,
      'esta notificación',
      () => this.LoadBoard(this.openedBoard.boardId)
    );
  }
  public DeletePatient(waitlistPatient: WaitingListPatient) {
    this.DELETE(
      `DeleteBoardPatient?clinicID=${
        this.ViewAs?.clinicId || this.User.clinicId
      }&PatientID=${waitlistPatient.patientId}`,
      'a este paciente',
      () => this.LoadBoardPatients(this.openedBoard.boardId)
    );
  }
  public getSelectedDoctorsCount() {
    return this.checked_doctors?.filter((f) => f.selected)?.length;
  }

  public syncAppointments() {
    this.ShowLoading();
    try {
      let _clinicid = this.getClinicID();

      const dto = {
        doctors: this.checked_doctors
          .filter((d) => d.selected)
          .map((d) => d.value.doctorId),
        boardID: this.openedBoard.boardId,
        date: this.selectedDate.toISOString(),
        clinicid: _clinicid
      };
      this.httpClient
        .post(this.baseUrl + 'Boards/LoadAppointments', dto)
        .subscribe(
          () => {
            this.hideLoading();

            this._snackBar.open(
              'Los pacientes han sido cargados correctamente.',
              'ok ',
              {
                duration: 8000,
                horizontalPosition: 'left',
                verticalPosition: 'bottom',
                panelClass: 'success-dialog'
              }
            );
            this.LoadBoardPatients(this.openedBoard.boardId);
          },
          (error) => {
            this.hideLoading();
            this.showError(error);
          }
        );
    } catch (e) {
      this.hideLoading();
      this.showError(e);
    }
  }

  drop(event: CdkDragDrop<WaitingListPatient[]>, boardColumnId: number) {
    const dropped_item = event.previousContainer.data[event.previousIndex];

    if (
      event.previousContainer?.id === event.container?.id &&
      event.previousIndex === event.currentIndex
    ) {
      return;
    }

    if (event.previousContainer?.id === event.container?.id) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    const onUpdateError = () => {
      this.LoadBoardPatients(this.openedBoard.boardId);
    };
    this.updateWaitlistPatient(
      {
        ...dropped_item,
        boardColumnId: boardColumnId,
        listOrder: event.currentIndex
      },
      () => {
        const updateReqs = event.container.data
          .map((pat, index) => {
            pat.listOrder = index;
            if (pat.patientId != dropped_item.patientId)
              return this.updateWaitlistPatient(
                {
                  ...pat,
                  boardColumnId: boardColumnId,
                  listOrder: index
                },
                null,
                onUpdateError
              );
          })
          .filter((a) => !!a);

        Promise.all(updateReqs).then(() => {
          if (this.sendMessages) {
            const _colPatientsArray = this.columnsData[boardColumnId];

            this.openedBoard.boardColumns
              .find((b) => b.boardColumnId == boardColumnId)
              .transitions.forEach((tran) => {
                const patToNotify = _colPatientsArray.find(
                  (v, index) => index === tran.patientOrder - 1
                );

                if (patToNotify) {
                  this.API.SendMessageTransition(
                    tran.transitionId,
                    patToNotify.patientId
                  ).subscribe(
                    () => {
                      this._snackBar.open(
                        `Mensaje de texto enviado a ${patToNotify.patientName}`,
                        'ok ',
                        {
                          duration: 8000,
                          horizontalPosition: 'left',
                          verticalPosition: 'bottom',
                          panelClass: 'success-dialog'
                        }
                      );
                    },
                    (error) => {
                      this.showError(error);
                    }
                  );
                }
              });
          }
        });
      },
      onUpdateError
    );
  }
}
