import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import cloneDeep from 'lodash-es/cloneDeep';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from 'src/app/api.service';
import { ConfirmDialogComponent } from 'src/app/_dialogs/confirm-dialog/confirm-dialog.component';
import { Associate } from 'src/app/DTOS/Associate/Associate';
import { AddCompanyDTO } from 'src/app/DTOS/Company/AddCompanyDTO';
import { Company } from 'src/app/DTOS/Company/Company';
import { RatePlan } from 'src/app/DTOS/RatePlans/RatePlan';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services';
import { NgForm } from '@angular/forms';
import { LONG_DATE_FORMAT } from 'src/app/Constants/constants';
import { Vertical } from 'src/app/DTOS/Vertical/Vertical';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {
  isLoading = false;
  @ViewChild('AppointmentsDatePicker')
  AppointmentsDatePicker;
  @ViewChild('AppointmentsCallDatePicker')
  AppointmentsCallDatePicker;
  User: User;
  minSelectedDate: Date = new Date();
  public RatePlans: RatePlan[];
  public Associates: Associate[];
  public company: AddCompanyDTO = {} as any;
  public vertical: Vertical[] = [];
  public backup_company: AddCompanyDTO = {} as any;
  public isCustomPlan = false;
  public isActive = true;
  public isEditing = false;
  public _isActive_updt: boolean;
  @ViewChild('companyForm', { static: false }) public companyForm: NgForm;
  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.User = this.authenticationService.currentUserValue;
    const companyId = this.route.snapshot.params.companyId;
    this.fetchVerticalTypes();
    if (companyId != null && companyId > 0) {
      this.fetchCompany(companyId);
    } else {
      this.company = {} as AddCompanyDTO;
      this.vertical = [] as Vertical[];
    }
    this.FetchRatePlans();
    this.FetchAssociates();
  }

  public fetchVerticalTypes = (callback?: () => void) => {
    this.isLoading = true;
    this.API.GetVerticalTypes().subscribe(
      (result: Vertical[]) => {
        this.vertical = result;
        this.isLoading = false;
        if (callback) callback();
      },
      (error) => {
        this.isLoading = false;
        console.error('Error fetching vertical types:', error);
      }
    );
  };

  public formatDate = (d: Date) =>
    moment.utc(d).local().format(LONG_DATE_FORMAT);

  public fetchCompany = (companyId: number) => {
    this.isLoading = true;
    this.API.GetCompanyById(companyId).subscribe((r) => {
      let result = r as Company;
      this.setData(result);
      this.isLoading = false;
    });
  };

  public getVertical(vertical) {
    this.vertical = vertical;
  }

  public setData(company: Company) {
    this.company = {
      companyId: company.companyId,
      city: company.city,
      companyName: company.companyName,
      verticalTypeId: company.verticalTypeId,
      address1: company.address1,
      postalCode: company.postalCode,
      isActive: company.isActive,
      companyLegalName: company.companyLegalName,
      companyTaxNumber: company.companyTaxNumber,
      address2: company.address2,
      associateId: company.associateId,
      emailAddress: company.emailAddress,
      ratePlanId: company.ratePlan.ratePlanId,
      phoneNumber: company.phoneNumber,
      state: company.state,
      mainContact: company.mainContact,
      contractEndDate: company.contractEndDate,
      contractStartDate: company.contractStartDate,
      _ratePlan: {} as any,
      deactivation: company.deactivation
    } as AddCompanyDTO;
    this._isActive_updt = company.isActive;
    this.isActive = this.company?.isActive === true ? true : false;
  }

  public setAsNewRecord() {
    this.company = new AddCompanyDTO();
    this.company.contractStartDate = new Date();
    this.isActive = this.company?.isActive === true ? true : false;
  }
  public searchForPlan(rateplan: RatePlan) {
    const matchingPlan = this.RatePlans.find(
      (r) =>
        r.monthlyFee === rateplan.monthlyFee &&
        r.pricePerMinute === rateplan.pricePerMinute &&
        r.maximumMinutes === rateplan.maximumMinutes
    );

    if (matchingPlan) {
      this.company._ratePlan = cloneDeep(matchingPlan);
      this.company.ratePlanId = this.company._ratePlan.ratePlanId;
      this.isCustomPlan = false;
    } else {
      this.isCustomPlan = true;
      this.company.ratePlanId = null;
      this.company._ratePlan = {
        ratePlanName: ('CUSTOM-' + this.company.companyName).substring(0, 49),
        maximumMinutes: rateplan.maximumMinutes,
        pricePerMinute: rateplan.pricePerMinute,
        monthlyFee: rateplan.monthlyFee,
        activeAccounts: 0,
        ratePlanId: null
      };
    }
  }
  public FetchRatePlans() {
    this.isLoading = true;
    this.API.GetAllRatePlans().subscribe(
      (result) => {
        const res = result as RatePlan[];
        this.RatePlans = res;
        this.isLoading = false;
        this.company._ratePlan = cloneDeep(
          res.find((r) => r.ratePlanId === this.company.ratePlanId)
        );
        this.company._ratePlan =
          this.company._ratePlan ||
          ({
            ratePlanName: '',
            maximumMinutes: 0,
            monthlyFee: 0,
            pricePerMinute: 0
          } as any);
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  public FetchAssociates() {
    this.isLoading = true;
    this.API.GetAssociates().subscribe(
      (result) => {
        const res = result as Associate[];
        this.Associates = res;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  public async Save() {
    let actionLabel = this.company.companyId ? 'Actualizar' : 'Registrar';
    let data = {
      title: `${actionLabel} Cuentas de Facturación`,
      htmlContent: `Desea ${actionLabel} la Cuentas de Facturación </strong> ${this.company.companyName} <strong>`,
      yesBtnLabel: 'Continuar',
      noBtnLabel: 'Cancelar'
    };

    this.dialog
      .open(ConfirmDialogComponent, {
        data: data,
        width: '31.25rem'
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (!confirmado) {
          return;
        }
        const executeCrud = () => {
          if (this.company.companyId) {
            this.updateCompany(this.company);
          } else {
            this.createCompany(this.company);
          }
        };
        if (this.isCustomPlan) {
          this.API.CreateRatePlan({
            ...this.company._ratePlan,
            ratePlanId: 0
          }).subscribe((r: { ratePlanId: number }) => {
            this.company.ratePlanId = r.ratePlanId;
            executeCrud();
          });
        } else {
          executeCrud();
        }
      });
  }

  private createCompany(company: AddCompanyDTO) {
    const companyDTO = cloneDeep(company);
    delete companyDTO._ratePlan;
    this.API.CreateCompany(companyDTO).subscribe(
      (r: { companyId: number }) => {
        this.isLoading = false;
        this._snackBar.open(
          '¡Listo!, se ha creado la Cuentas de Facturación.',
          'OK ',
          {
            duration: 10000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog'
          }
        );
        this.router.navigate(['/company']);
      },
      (f) => {
        this.isLoading = false;
        console.error('Error al crerar la Cuentas de Facturación', f);
      }
    );
  }
  private updateCompany(_company: AddCompanyDTO) {
    const company = { ..._company };
    if (this._isActive_updt !== _company.isActive) {
      company.isActive = this._isActive_updt;
    }
    this.API.UpdateCompany(company).subscribe(
      (r: { companyId: number }) => {
        this.isLoading = false;
        this._snackBar.open(
          '¡Listo!, se ha actualizado la Cuentas de Facturación.',
          'OK ',
          {
            duration: 10000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog'
          }
        );
        this.router.navigate(['/company']);
      },
      (f) => {
        this.isLoading = false;
        console.error('Error al actualizar la Cuentas de Facturación', f);
      }
    );
  }
  public FormatCompanyId(number: number): string {
    let numberStr = number.toString();
    let zerosToAdd = 4 - numberStr.length;
    for (let i = 0; i < zerosToAdd; i++) {
      numberStr = '0' + numberStr;
    }
    return numberStr;
  }
}
