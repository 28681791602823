import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'no-appointments-animation',
  templateUrl: './NoAppointmentsAnimation.component.html'
})
export class NoAppointmentsAnimation implements OnInit {
  constructor() {}

  ngOnInit() {}
}
