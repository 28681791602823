import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextFieldModule } from '@angular/cdk/text-field';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgChartsModule } from 'ng2-charts';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CalendarModule } from 'primeng/calendar';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { OrderListModule } from 'primeng/orderlist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { MasterUserViewAsSelectorComponent } from 'src/shared/Components/master-user-view-as-selector/master-user-view-as-selector.component';
import { DateSelectionRibbonComponent } from 'src/app/Components/date-selection-ribbon/date-selection-ribbon.component';
import { NoAppointmentsAnimation } from './Components/no-appointments-animation/NoAppointmentsAnimation.component';
import { CustomerNotesComponent } from './Components/customer-notes/customer-notes.component';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { HelpIconComponent } from 'src/app/Components/help-icon/help-icon.component';
import { TelephonyProviderComponent } from './Components/telephony-provider/telephony-provider.component';
import { UsersComponent } from './Components/users/users.component';
import { DoctorsListComponent } from './Components/doctors-list/doctors-list.component';
import { ClinicPermissionsComponent } from './Components/clinic-permissions/clinic-permissions.component';
import { DoctorAssistanceComponent } from './Components/doctor-assistance/doctor-assistance.component';
import { AddUserComponent } from './Components/add-user/add-user.component';
import { ConfirmDialogComponent } from 'src/app/_dialogs/confirm-dialog/confirm-dialog.component';
import { SearchTermFilter } from './helpers/filter.pipe';
import { ImageModule } from 'primeng/image';
import { EntityAuthorLabelComponent } from './Components/entity-author-label/entity-author-label.component';
import { UserPermissionsTableComponent } from 'src/app/user-permissions-table/user-permissions-table.component';
import { HeaderSystemMessagesComponent } from 'src/app/Components/header-system-messages/header-system-messages.component';
import { TimeInputComponent } from 'src/app/time-input/time-input.component';

@NgModule({
  declarations: [
    MasterUserViewAsSelectorComponent,
    DateSelectionRibbonComponent,
    CustomerNotesComponent,
    NoAppointmentsAnimation,
    HelpIconComponent,
    TelephonyProviderComponent,
    UsersComponent,
    DoctorsListComponent,
    ClinicPermissionsComponent,
    DoctorAssistanceComponent,
    AddUserComponent,
    ConfirmDialogComponent,
    SearchTermFilter,
    UserPermissionsTableComponent,
    EntityAuthorLabelComponent,
    HeaderSystemMessagesComponent,
    TimeInputComponent
  ],
  exports: [
    HttpClientModule,
    MatSnackBarModule,
    MatExpansionModule,
    ConfirmPopupModule,
    MatBadgeModule,
    MatInputModule,
    MatNativeDateModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule,
    NgChartsModule,
    MatSlideToggleModule,
    MatDividerModule,
    HeaderSystemMessagesComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    TextFieldModule,
    FormsModule,
    MatSidenavModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSelectModule,
    MatStepperModule,
    MatSliderModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatProgressBarModule,
    MatCardModule,
    MatGridListModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatMenuModule,
    MatTabsModule,
    DragDropModule,
    MatSidenavModule,
    SidebarModule,
    PasswordModule,
    TableModule,
    OverlayPanelModule,
    InputNumberModule,
    FieldsetModule,
    MatDialogModule,
    DropdownModule,
    ColorPickerModule,
    MatChipsModule,
    FileUploadModule,
    CalendarModule,
    MessageModule,
    MessagesModule,
    MatRippleModule,
    MatAutocompleteModule,
    NgxCsvParserModule,
    CalendarModule,
    OrderListModule,
    MatTreeModule,
    MatRadioModule,
    CommonModule,
    MasterUserViewAsSelectorComponent,
    DateSelectionRibbonComponent,
    NoAppointmentsAnimation,
    TabViewModule,
    CustomerNotesComponent,
    OverlayModule,
    NgxMaskDirective,
    NgxMaskPipe,
    HelpIconComponent,
    UserPermissionsTableComponent,
    TelephonyProviderComponent,
    UsersComponent,
    DoctorsListComponent,
    ClinicPermissionsComponent,
    DoctorAssistanceComponent,
    AddUserComponent,
    ConfirmDialogComponent,
    SearchTermFilter,
    ImageModule,
    EntityAuthorLabelComponent,
    CalendarModule,
    TimeInputComponent
  ],
  imports: [
    MatSnackBarModule,
    MatExpansionModule,
    ConfirmPopupModule,
    MatBadgeModule,
    MatInputModule,
    MatNativeDateModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule,
    NgChartsModule,
    MatSlideToggleModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TextFieldModule,
    FormsModule,
    MatSidenavModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSelectModule,
    MatStepperModule,
    MatSliderModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatProgressBarModule,
    MatCardModule,
    MatGridListModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatMenuModule,
    MatTabsModule,
    DragDropModule,
    MatSidenavModule,
    SidebarModule,
    PasswordModule,
    TableModule,
    OverlayPanelModule,
    InputNumberModule,
    FieldsetModule,
    MatDialogModule,
    DropdownModule,
    ColorPickerModule,
    MatChipsModule,
    FileUploadModule,
    CalendarModule,
    MessageModule,
    MessagesModule,
    MatRippleModule,
    MatAutocompleteModule,
    NgxCsvParserModule,
    OrderListModule,
    MatTreeModule,
    MatRadioModule,
    CommonModule,
    TabViewModule,
    OverlayModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ImageModule,
    CalendarModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class SharedModule {}
