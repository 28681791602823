import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import orderBy from 'lodash-es/orderBy';
import range from 'lodash-es/range';
import { ConfirmationService } from 'primeng/api';
import { User } from '../../../app/_models/user';
import { AuthenticationService } from '../../../app/_services/authentication.service';
import { ApiService } from '../../../app/api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from '../../../app/_dialogs/confirm-dialog/confirm-dialog.component';
import { UserEditDTO, UserGetDTO } from '../../../app/DTOS/Users/Users';
import {
  LoadingDialogComponent,
  LoadingDialogModel
} from '../../../app/loading-dialog/loading-dialog.component';
import { cloneDeep } from 'lodash';
import { AdminViewAsClinicDTO } from 'src/app/DTOS/AdminViewAsClinicDTO';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';

enum EditOptions {
  changePassword = 1,
  edit = 2,
  permissions = 3
}
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements AfterViewInit, OnChanges, OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private confirmationService: ConfirmationService,
    private permissionsSlugService: PermissionSlugsService
  ) {}
  ngOnInit(): void {
    this.permissionsSlugService.permissionsListener.subscribe((permissions) => {
      this.canCreateUsers = permissions.includes(PermissionSlugs.CREATE_USERS);
    });
  }

  public EditOptions = EditOptions;
  public editOption: EditOptions;

  httpClient: HttpClient;
  baseUrl: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public userToEdit = new UserEditDTO();
  public userToCreate = new UserEditDTO();
  password_strength = 'La contraseña es débil';
  pswrdStrengthColor = 'Red';
  isPasswordValid = false;
  displayedColumns: string[] = [
    'username',
    'emailAddress',
    'loginname',
    'isActive',
    'sendAppointmentsReport',
    'Edit'
  ];
  @Input() public isStandalone: boolean;
  public isUpdatingUser = false;
  public User: User;
  public editSidePanelOpen = false;
  @Input() public createSidePanelOpen = false;
  minSelectedDate: Date = new Date();
  public users: UserGetDTO[];
  dataSource: MatTableDataSource<UserGetDTO>;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  @Input() public ViewAs: AdminViewAsClinicDTO;
  public canCreateUsers: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    this.LoadUsers();
  }

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.LoadUsers();
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadUsers();
  }

  public LoadUsers() {
    try {
      if (this.isStandalone && !this.ViewAs?.clinicId) {
        return;
      }

      const clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
      this.ShowLoading();

      this.API.GetAllUsers(clinicId).subscribe(
        (result) => {
          const res = orderBy(result as UserGetDTO[], 'isActive', 'desc');
          this.dataSource = new MatTableDataSource<UserGetDTO>(res);
          this.dataSource.paginator = this.paginator;

          if (res.length < 10) {
            range(10 - res.length).forEach(() => {
              res.push({} as any);
            });
          }
          this.users = res;

          this.hideLoading();
        },
        (error) => {
          console.error(error);
          this.hideLoading();
          this.showError(error);
        }
      );
    } catch (e) {
      this.hideLoading();
    }
  }

  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false)
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }
  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }
  public openUserCreatePanel() {
    const dto: UserEditDTO = new UserEditDTO();
    dto.isActive = true;
    this.userToCreate = dto;
    this.createSidePanelOpen = true;
  }

  public openUserEditPanel(user: UserGetDTO) {
    this.userToEdit = structuredClone({
      ...user,
      clinicID: user.clinicId,
      newPassword: '',
      newPasswordRepeat: '',
      userID: user.userId
    });
    this.editSidePanelOpen = true;
  }

  public closeUserEditPanel() {
    this.userToEdit = new UserEditDTO();
    this.editSidePanelOpen = false;
    this.LoadUsers();
  }

  public closeUserCreatePanel(event) {
    this.userToEdit = new UserEditDTO();
    this.createSidePanelOpen = event;
    this.LoadUsers();
  }

  public doUpdate(user: UserEditDTO, event) {
    if (user.newPassword && user.newPassword !== user.newPasswordRepeat) {
      return;
    }

    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás seguro/a de querer actualizar este usuario?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        // confirm action
        this.isUpdatingUser = true;

        let dto = cloneDeep(user);

        if (this.editOption === EditOptions.edit) {
          dto = {
            ...dto,
            newPassword: '',
            newPasswordRepeat: ''
          };
        }
        if (this.editOption === EditOptions.changePassword) {
          this.ChangePassword(dto);
        } else {
          this.EditUser(dto);
        }
      },
      reject: () => {
        // reject action
      }
    });
  }

  public EditUser(user: UserEditDTO) {
    this.ShowLoading();
    try {
      this.API.EditUser(user).subscribe(
        () => {
          this.hideLoading();
          this.closeUserEditPanel();
          this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '600px',
            data: new ConfirmDialogModel(
              'Listo',
              'El usuario ha sido actualizado correctamente.',
              'Okay',
              null
            )
          });
        },
        (e) => {
          this.hideLoading();
          this.editSidePanelOpen = false;
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '600px',
            data: new ConfirmDialogModel(
              'Ha ocurrido un error',
              e,
              'Okay',
              null
            )
          });
          dialogRef.afterClosed().subscribe((response: Boolean) => {
            if (response) {
              this.editSidePanelOpen = true;
            }
          });
        }
      );
    } catch (e) {
      this.hideLoading();
      this.showError(e);
    }
  }

  public ChangePassword(user: UserEditDTO) {
    this.ShowLoading();
    try {
      this.API.ChangePassword(user).subscribe(
        () => {
          this.hideLoading();
          this.closeUserEditPanel();
          this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '600px',
            data: new ConfirmDialogModel(
              'Listo',
              'la contraseña ha sido actualizada correctamente.',
              'Okay',
              null
            )
          });
        },
        (e) => {
          this.hideLoading();
          this.editSidePanelOpen = false;
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '600px',
            data: new ConfirmDialogModel(
              'Ha ocurrido un error',
              e,
              'Okay',
              null
            )
          });
          dialogRef.afterClosed().subscribe((response: Boolean) => {
            if (response) {
              this.editSidePanelOpen = true;
            }
          });
        }
      );
    } catch (e) {
      this.hideLoading();
      this.showError(e);
    }
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null)
    });
  }
  public CheckPasswordStrength(password) {
    if (!password) {
      return;
    }

    this.isPasswordValid = false;
    this.password_strength = '';
    this.pswrdStrengthColor = '';

    if (password.length <= 7) {
      this.password_strength = 'La contraseña debe tener mínimo 8 caracteres.';
      this.pswrdStrengthColor = 'Red';
      return;
    }
    // Regular Expressions
    const regex = new Array();
    regex.push('[A-Z]'); // For Uppercase Alphabet
    regex.push('[a-z]'); // For Lowercase Alphabet
    regex.push('[0-9]'); // For Numeric Digits
    // regex.push("[$@$!%*#?&]"); //For Special CharFacters
    let passed = 0;

    // Validation for each Regular Expression
    for (let i = 0; i < regex.length; i++) {
      if (new RegExp(regex[i]).test(password)) {
        passed++;
      }
    }

    // Validation for Length of Password
    if (passed > 2) {
      passed++;
    }

    // Display of Status
    let color = '';
    let passwordStrength = '';

    switch (passed) {
      case 0:
        break;
      case 1:
        passwordStrength = 'La contraseña es débil.';
        color = 'Red';
        this.isPasswordValid = false;
        break;
      case 2:
        passwordStrength = 'La contraseña es buena.';
        color = 'darkorange';
        this.isPasswordValid = true;
        break;
      case 3:
        break;
      case 4:
        passwordStrength = 'La contraseña es segura.';
        this.isPasswordValid = true;
        color = 'Green';
        break;
      case 5:
        this.isPasswordValid = true;
        passwordStrength = 'La contraseña es muy segura.';
        color = 'darkgreen';
        break;
    }
    this.password_strength = passwordStrength;
    this.pswrdStrengthColor = color;
  }
  public validateEmail(email) {
    if (!email) {
      return false;
    }
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
