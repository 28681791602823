// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  position: sticky;
  top: 0px;
  z-index: 900;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
}
.navbar .avatar {
  border-radius: 50px;
  margin-right: 1rem;
  width: 2.5rem;
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5hdmlnYXRpb24tYmFyLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQUEsZ0JBQUE7RUFBQSxRQUFBO0VBQUEsWUFBQTtFQUFBLHFCQUFBO0VBQUEsYUFBQTtFQUFBLDhCQUFBO0VBQUEsaUJBQUE7RUFBQTtBQUFBO0FBQ0E7RUFDRSxtQkFBQTtFQUNBLGtCQUFBO0VBQUEsYUFBQTtFQUFBLGVBQUE7QUFFSiIsImZpbGUiOiJuYXZpZ2F0aW9uLWJhci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5uYXZiYXIge1xuICBAYXBwbHkgZmxleCBqdXN0aWZ5LWJldHdlZW4gcHgtMCBtYi0yIHN0aWNreSB0b3AtMCB6LVs5MDBdO1xuICAuYXZhdGFyIHtcbiAgICBib3JkZXItcmFkaXVzOiA1MHB4O1xuICAgIEBhcHBseSBtci00IGN1cnNvci1wb2ludGVyIHctMTA7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/Components/navigation-bar/navigation-bar.component.scss"],"names":[],"mappings":"AACE;EAAA,gBAAA;EAAA,QAAA;EAAA,YAAA;EAAA,qBAAA;EAAA,aAAA;EAAA,8BAAA;EAAA,iBAAA;EAAA,kBAAA;AAAA;AACA;EACE,mBAAA;EACA,kBAAA;EAAA,aAAA;EAAA,eAAA;AAEJ;AAUA,ooBAAooB","sourcesContent":[".navbar {\n  @apply flex justify-between px-0 mb-2 sticky top-0 z-[900];\n  .avatar {\n    border-radius: 50px;\n    @apply mr-4 cursor-pointer w-10;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
