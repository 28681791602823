import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from '../../_dialogs/confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from '../../DTOS/AdminViewAsClinicDTO';
import {
  IAddAppointmentsBatch,
  IAddAppointmentsBatchItem,
  IAddAppointmentsBatchItemError,
  IAddAppointmentsBatchItemServerResult
} from '../../DTOS/Appointments/AddAppointmentsBatch';
import { Doctor } from '../../DTOS/Doctors/DoctorDTO';
import { ReminderSetDto } from '../../DTOS/ReminderSets/ReminderSetDto';

import {
  LoadingDialogComponent,
  LoadingDialogModel
} from '../../loading-dialog/loading-dialog.component';

import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/api.service';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';
import { ClinicAppointmentType } from 'src/app/DTOS/Appointments-types/ClinicAppointmentType';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';

@Component({
  selector: 'app-add-appointments',
  templateUrl: './add-appointments.component.html',
  styleUrls: ['./add-appointments.component.scss']
})
export class AddAppointmentsComponent implements AfterViewInit {
  constructor(
    private API: ApiService,
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private permissionSlugsService: PermissionSlugsService
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
    this.appointmentsDate = (() => {
      const result = new Date();
      result.setDate(result.getDate() + 1);
      return result;
    })();
    this.callTime = moment(new Date()).format('HH:mm');
  }
  ClinicAppointmentTypes: ClinicAppointmentType[] = [];
  public ViewAs: AdminViewAsClinicDTO;
  public User: User;
  public hasAntiPermAppointmentsManagement = false;
  isLoadingPermissions = true;

  @ViewChild('AppointmentsDatePicker') AppointmentsDatePicker;
  @ViewChild('AppointmentsCallDatePicker') AppointmentsCallDatePicker;
  httpClient: HttpClient;
  baseUrl: string;
  appointmentsDate: Date;
  callDate: Date = new Date();
  callTime: string;
  // Errores al enviar citas
  AppointmentsErrors: IAddAppointmentsBatchItemError[];
  // Citas Añadidas correctamente.
  AddedAppointments: IAddAppointmentsBatchItem[] =
    new Array<IAddAppointmentsBatchItem>();
  public isLoadingDoctors = false;
  public isLoadingReminderSets = false;
  public selectedAppointmentTypeId: number;

  public ReminderSets: ReminderSetDto[] = new Array<ReminderSetDto>();
  public Doctors: Doctor[] = new Array<Doctor>();

  public _unFiltered_ReminderSets: ReminderSetDto[] =
    new Array<ReminderSetDto>();
  public _unFiltered_Doctors: Doctor[] = new Array<Doctor>();

  public selectedReminderSet: ReminderSetDto;
  public selectedDoctor: Doctor;
  public rawAppointments = '';

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.FetchDoctors();
    this.FetchReminderSets();
    this.LoadClinicAppointmentTypes();
  }

  public LoadClinicAppointmentTypes() {
    this.API.GetClinicAppointmentTypes(
      this.ViewAs?.clinicId || this.User.clinicId
    ).subscribe((r: ClinicAppointmentType[]) => {
      this.ClinicAppointmentTypes = r;
    });
  }
  public isViewLoading(): boolean {
    return this.isLoadingDoctors || this.isLoadingReminderSets;
  }

  ngAfterViewInit() {
    this.authenticationService.currentUser.subscribe((u) => {
      this.User = u;
      this.FetchDoctors();
      this.FetchReminderSets();
      this.LoadClinicAppointmentTypes();
      this.isLoadingPermissions = true;
      this.permissionSlugsService.permissionsListener.subscribe((r) => {
        this.hasAntiPermAppointmentsManagement = r.includes(
          PermissionSlugs.BLOCK_APPOINTMENT_MANAGEMENT
        );
        this.isLoadingPermissions = false;
      });
    });
  }

  public onDoctorFilterStringChange(filter: string) {
    if (!filter) {
      this.Doctors = structuredClone<Doctor[]>(this._unFiltered_Doctors);
    }
    filter = filter.toLowerCase();
    const filteredDoctors = new Array<Doctor>();
    for (let i = 0; i < this._unFiltered_Doctors.length; i++) {
      const option = this._unFiltered_Doctors[i];
      if (
        (option.doctorId + ' - ' + option.doctorName)
          .toLowerCase()
          .indexOf(filter) >= 0
      ) {
        filteredDoctors.push(structuredClone<Doctor>(option));
      }
    }
    this.Doctors = filteredDoctors;
  }

  public onRemindersetFilterStringChange(filter: string) {
    if (!filter) {
      this.ReminderSets = structuredClone<ReminderSetDto[]>(
        this._unFiltered_ReminderSets
      );
    }
    filter = filter.toLowerCase();
    const filteredReminderSets = new Array<ReminderSetDto>();
    for (let i = 0; i < this._unFiltered_ReminderSets.length; i++) {
      const option = this._unFiltered_ReminderSets[i];
      if (
        (option.reminderSetId + ' - ' + option.reminderSetName)
          .toLowerCase()
          .indexOf(filter) >= 0
      ) {
        filteredReminderSets.push(structuredClone<ReminderSetDto>(option));
      }
    }
    this.ReminderSets = filteredReminderSets;
  }

  openAppointmentsDatePicker() {
    this.AppointmentsDatePicker.open();
  }

  public FetchDoctors() {
    this.isLoadingDoctors = true;
    let clinicid = this.User.clinicId;
    if (this.ViewAs) {
      clinicid = this.ViewAs.clinicId;
    }

    this.httpClient
      .get<Doctor[]>(this.baseUrl + `Doctors/GetAll?ClinicID=` + clinicid)
      .subscribe(
        (result) => {
          const res = result as Doctor[];
          this._unFiltered_Doctors = res;
          this.Doctors = structuredClone<Doctor[]>(this._unFiltered_Doctors);
          this.isLoadingDoctors = false;
        },
        (error) => console.error(error)
      );
  }

  public FetchReminderSets() {
    this.isLoadingReminderSets = true;
    let clinicid = this.User.clinicId;
    if (this.ViewAs) {
      clinicid = this.ViewAs.clinicId;
    }
    this.httpClient
      .get<
        ReminderSetDto[]
      >(this.baseUrl + `ReminderSet/GetAll?ClinicID=` + clinicid)
      .subscribe(
        (result) => {
          const res = result as ReminderSetDto[];
          this.ReminderSets = res;
          this._unFiltered_ReminderSets = res;
          this.isLoadingReminderSets = false;
        },
        (error) => console.error(error)
      );
  }

  private IsAValidPhoneNumber(phoneNum: string) {
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const isPossible = phoneUtil.isValidNumber(
        phoneUtil.parse(phoneNum, 'US')
      );
      return isPossible;
    } catch (e) {
      return false;
    }
  }

  public addAppointments() {
    const args = this.rawAppointments.split('\n');
    const appointmentsToAddTotal = args.length;
    const message = `¿Estas seguro de querer añadir (${appointmentsToAddTotal}) citas?`;
    const dialogData = new ConfirmDialogModel(
      'Confirmación',
      message,
      'Si',
      'Cancelar'
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      const result = dialogResult as boolean;
      if (result) {
        const dialogRef = this.dialog.open(LoadingDialogComponent, {
          maxWidth: '400px',
          data: new LoadingDialogModel('Cargando', '', false),
          disableClose: true
        });
        try {
          this.AppointmentsErrors = new Array<IAddAppointmentsBatchItemError>();
          const appointmentsBatch = new Array<IAddAppointmentsBatchItem>();
          args.forEach((e) => {
            const appointmentArgs = e.split(',');
            if (appointmentArgs.length === 3) {
              const appointmentTime = moment(
                moment(this.appointmentsDate).format('YYYY-MM-DD') +
                  ' ' +
                  appointmentArgs[2]
                    .toUpperCase()
                    .split('AM')
                    .join(' AM')
                    .split('PM')
                    .join(' PM')
              );
              if (appointmentTime.isValid()) {
                const patientName = appointmentArgs[0];
                if (patientName) {
                  const PhoneNumber = appointmentArgs[1];
                  if (this.IsAValidPhoneNumber(PhoneNumber)) {
                    const newAppointment = {
                      patientName,
                      patientPhoneNumber: PhoneNumber,
                      appointmentHour: appointmentTime.format('HH:mm'),
                      uid: Guid.create().toString()
                    };
                    appointmentsBatch.push(newAppointment);
                    this.AddedAppointments.push(newAppointment);
                  } else {
                    this.AppointmentsErrors.push({
                      appointment: e,
                      errorMessage: 'El telefono no es valido.'
                    });
                  }
                } else {
                  this.AppointmentsErrors.push({
                    appointment: e,
                    errorMessage: 'El nombre del paciente es requerido.'
                  });
                }
              } else {
                this.AppointmentsErrors.push({
                  appointment: e,
                  errorMessage: 'Hora invalida.'
                });
              }
            } else {
              this.AppointmentsErrors.push({
                appointment: e,
                errorMessage: 'Cita invalida.'
              });
            }
          });

          const batch: IAddAppointmentsBatch = {
            appointmentDate: this.appointmentsDate,
            callDate: this.callDate,
            doctorID: this.selectedDoctor.doctorId,
            reminderSetId: this.selectedReminderSet.reminderSetId,
            appointments: appointmentsBatch,
            callTime: this.callTime,
            userID: this.ViewAs ? this.ViewAs.viewAsUserID : this.User.userId,
            AppointmentTypeId: this.selectedAppointmentTypeId
          };
          this.httpClient
            .post(this.baseUrl + 'appointments/AddAppointmentsBatch', batch)
            .subscribe(
              (result) => {
                const res = result as IAddAppointmentsBatchItemServerResult[];
                res
                  .filter((e) => e.isSuccessful === false)
                  .forEach((e) => {
                    const appointmentObj = this.AddedAppointments.filter(
                      (a) => a.uid === e.uid
                    )[0];
                    this.AppointmentsErrors.push({
                      appointment: [
                        appointmentObj.patientName,
                        appointmentObj.patientPhoneNumber,
                        appointmentObj.appointmentHour
                      ].join(','),
                      errorMessage: 'Citamed no ha podido procesar esta cita.'
                    });
                    const index =
                      this.AddedAppointments.indexOf(appointmentObj);
                    if (index > -1) {
                      this.AddedAppointments.splice(index, 1);
                    }
                  });
                if (this.AppointmentsErrors.length) {
                  this.rawAppointments = this.AppointmentsErrors.map(
                    (e) => e.appointment
                  ).join('\n');
                } else {
                  this.rawAppointments = '';
                }
                dialogRef.close();
              },
              (error) => {
                dialogRef.close();
                console.error('Batch Post Error:', error);
              }
            );
        } catch (e) {
          dialogRef.close();
        }
      }
    });
  }
}
