import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User } from '../../../app/_models/user';
import { AuthenticationService } from '../../../app/_services/authentication.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from '../../../app/_dialogs/confirm-dialog/confirm-dialog.component';

import {
  LoadingDialogComponent,
  LoadingDialogModel
} from '../../../app/loading-dialog/loading-dialog.component';
import { ApiService } from '../../../app/api.service';
import { isUndefined } from 'lodash-es';
import { AdminViewAsClinicDTO } from 'src/app/DTOS/AdminViewAsClinicDTO';
import { UserCreateDTO } from 'src/app/DTOS/Users/Users';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
/** Add-User component*/
export class AddUserComponent implements AfterViewInit {
  /** Add-User ctor */
  public User: User;
  httpClient: HttpClient;
  baseUrl: string;
  @Input() public ViewAs: AdminViewAsClinicDTO;
  @Input() public onCreated: Function;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  password_strength = 'La contraseña es débil';
  pswrdStrengthColor = 'Red';
  isPasswordValid = false;
  public NewUser = new UserCreateDTO();
  public isLoading = false;
  public createSidePanelOpen = false;
  @Output() messageEvent = new EventEmitter<boolean>();

  constructor(
    private API: ApiService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {}

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
  }

  public validateEmail(email) {
    if (!email) {
      return false;
    }
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false)
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
    this.isLoading = true;
  }
  public CheckPasswordStrength(password) {
    if (!password) {
      return;
    }

    this.isPasswordValid = false;
    this.password_strength = '';
    this.pswrdStrengthColor = '';

    if (password.length <= 7) {
      this.password_strength = 'La contraseña debe tener mínimo 8 caracteres.';
      this.pswrdStrengthColor = 'Red';
      return;
    }
    // Regular Expressions
    const regex = new Array();
    regex.push('[A-Z]'); // For Uppercase Alphabet
    regex.push('[a-z]'); // For Lowercase Alphabet
    regex.push('[0-9]'); // For Numeric Digits
    // regex.push("[$@$!%*#?&]"); //For Special CharFacters
    let passed = 0;

    // Validation for each Regular Expression
    for (let i = 0; i < regex.length; i++) {
      if (new RegExp(regex[i]).test(password)) {
        passed++;
      }
    }

    // Validation for Length of Password
    if (passed > 2) {
      passed++;
    }

    // Display of Status
    let color = '';
    let passwordStrength = '';

    switch (passed) {
      case 0:
        break;
      case 1:
        passwordStrength = 'La contraseña es débil.';
        color = 'Red';
        this.isPasswordValid = false;
        break;
      case 2:
        passwordStrength = 'La contraseña es buena.';
        color = 'darkorange';
        this.isPasswordValid = true;
        break;
      case 3:
        break;
      case 4:
        passwordStrength = 'La contraseña es segura.';
        this.isPasswordValid = true;
        color = 'Green';
        break;
      case 5:
        this.isPasswordValid = true;
        passwordStrength = 'La contraseña es muy segura.';
        color = 'darkgreen';
        break;
    }
    this.password_strength = passwordStrength;
    this.pswrdStrengthColor = color;
  }
  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
    this.isLoading = false;
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null)
    });
  }

  public CreateUser() {
    this.ShowLoading();
    try {
      const ClinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
      this.NewUser.clinicId = ClinicID;
      this.API.CreateUser(this.NewUser, ClinicID).subscribe(
        () => {
          this.hideLoading();
          this.closeUserCreatePanel();
          this.NewUser = new UserCreateDTO();
          this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '600px',
            data: new ConfirmDialogModel(
              'Listo',
              'El usuario ha sido registrado correctamente.',
              'Okay',
              null
            )
          });
          if (this.onCreated) {
            this.onCreated();
          }
        },
        (e) => {
          this.hideLoading();
          this.closeUserCreatePanel(false);
          this.messageEvent.emit(this.createSidePanelOpen);
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '600px',
            data: new ConfirmDialogModel(
              'Ha ocurrido un error',
              e,
              'Okay',
              null
            )
          });
          dialogRef.afterClosed().subscribe((response: Boolean) => {
            if (response) {
              this.closeUserCreatePanel(true);
            }
          });
        }
      );
    } catch (e) {
      this.hideLoading();
      this.showError(e);
    }
  }

  public closeUserCreatePanel(status?: boolean) {
    this.createSidePanelOpen = false;
    if (isUndefined(status)) this.NewUser = new UserCreateDTO();
    this.messageEvent.emit(this.createSidePanelOpen);
  }
}
