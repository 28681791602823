import { Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: 'app-ai-icon',
  templateUrl: './ai-icon.component.html',
  styleUrl: './ai-icon.component.scss'
})
export class AiIconComponent {
  options: AnimationOptions = {
    path: '/animations/ai-icon-animation.json'
  };

  wasAnimationCreated: boolean;
  animationCreated(event: any) {
    this.wasAnimationCreated = true;
  }
}
