import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  error = '';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private API: ApiService
  ) {}
  get f() {
    return this.loginForm.controls;
  }
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required]
    });
  }

  private onErrorOcurred(error: any) {
    this._snackBar.open('¡Oops! Ha ocurrido un error :(', 'ok ', {
      duration: 8000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      panelClass: 'success-dialog'
    });

    this.submitted = false;
    this.loading = false;
    console.error(error);
  }

  // TODO: add recaptcha component
  public isCaptchaValid: boolean = true;
  showResponse(response: { response: string }) {
    this.isCaptchaValid = !!response.response;
    //TODO: call to a backend to verify against recaptcha with private key
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    try {
      this.API.CreatePasswordResetToken(this.f.username.value).subscribe(() => {
        this._snackBar.open(
          '¡Listo! Revisa tu correo electrónico para continuar con el proceso',
          'ok ',
          {
            duration: 8000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog'
          }
        );
        this.loading = false;
      }, this.onErrorOcurred);
    } catch (e) {
      this.onErrorOcurred(e);
    }
  }
}
