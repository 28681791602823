import {
  CallHistory,
  Timing
} from '../../_dialogs/appointment-details-dialog/appointment-details-dialog.component';
import { AuthorDto } from '../AuthorDto';

export interface IAppointmentListItem {
  appointmentId: number;
  smsResponse?: any;
  clinicId: number;
  doctorId: number;
  doctorName: string;
  reminderSetId: number;
  reminderSetName: string;
  appointmentDate: string;
  _appointmentDateUnformatted: Date;
  patientName: string;
  patientPhoneNumber: string;
  patientAltPhoneNumber: string;
  patientRecord: string;
  callStatusId: string;
  callStatusName: string;
  callTypeId: number;
  callTypeName: string;
  description: string;
  smallIconFilename: string;
  mediumIconFilename: string;
  lastCall: string;
  _lastCallUnformatted: Date;
  recordingURL: string;
  is_gray: boolean;
  CallHistory: CallHistory[];
  timing: Timing[];
  _callDate: string;
  _isLoadingDetails: Boolean;
  unreadSmsResponsesCount: number;
  assistanceStatusId: number;
  _relativeDate: string;
  createDate?: Date;
  _createDate?: string;
  locationName?: string;
  locationId?: number;
  otherInfo: string;
  author: AuthorDto;
}
export interface IStatusSummary {
  callStatusId: string;
  callStatusName: string;
  callTypeId: number;
  callTypeName: string;
  description: string;
  smallIconFilename: string;
  mediumIconFilename: string;
  showInSummary: boolean;
  statusCount: number;
}

export interface ISummaryCallType {
  callTypeId: number;
  callTypeName: string;
  callTypeAlias: string;
  description: string;
}
export interface INoCallDate {
  doctor: INoCallDateDoctor;
}
export interface INoCallDateDoctor {
  doctorName: string;
  doctorId: number;
}

export enum SystemAnnouncementType {
  BANNER = 1,
  MODAL = 2
}
export interface SystemMessage {
  systemAnnouncementId: number;
  title: string;
  message: string;
  saveViewsLogs: boolean;
  announcementType: string;

  displayType: SystemAnnouncementType;
}
export interface HomeViewModel {
  appointments: IAppointmentListItem[];

  callTypeId: number;
  nocallDates: INoCallDate[];
  lastSyncDate: Date;

  totalPages: number;
  totalCount: number;
  pageSize: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ClinicAssistance {
  doctorId: number;
  doctorName: string;
  assistanceStatusId: number;
  assistanceStatusName: string;
  color: string;
  messageTemplate: string;
}

export interface ClinicAssistanceViewModel {
  assistanceStatusId: number;
  assistanceStatusName: string;
  color: string;
  isDefault: boolean;
}
