import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';

import { ApiService } from '../api.service';
import {
  ActionType,
  BotActionDto,
  CustomIntent
} from '../DTOS/VA_Configurations/CustomIntent';
import GenericList from '../DTOS/Generics/GenericList';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import {
  IntentConfiguration,
  IntentType
} from '../DTOS/VA_Configurations/IntentConfiguration';
import { orderBy, cloneDeep, some, find } from 'lodash-es';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { IntakeFormSelect } from '../DTOS/VA_Configurations/VA_Configurations';
import { ConfirmationService } from 'primeng/api';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
const smsVariables = [
  {
    variable: '{$Name$}',
    maxLength: 0,
    description: 'El nombre de la persona que llama'
  },
  {
    variable: '{$AccountName$}',
    maxLength: 0,
    description: 'El nombre de la cuenta (o clínica) del asistente virtual'
  }
];

type Intent = CustomIntent & {
  isSystemIntent: boolean;
  _sortOrder?: number;
};
@Component({
  selector: 'app-custom-intents',
  templateUrl: './custom-intents.component.html',
  styleUrls: ['./custom-intents.component.scss']
})
export class CustomIntentsComponent implements OnInit, OnChanges {
  list: GenericList<Intent>;
  public customIntentFormModel: CustomIntent = {
    customIntentId: 0,
    accountID: 0,
    prompt: '',
    response: '',
    customIntentName: '',
    isSystemIntent: false,
    primaryColor: '',
    secondaryColor: ''
  };
  public slidePanelOpen: boolean;
  public isSubmitting: boolean;
  IntakeFormSelect = [] as IntakeFormSelect[];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  emailCtrl = new FormControl('');
  filteredemails: Observable<string[]>;
  emails: string[] = [];
  allemails: string[] = [];
  BotAction = {} as BotActionDto;
  botActions = [] as BotActionDto[];
  emailTargets: string[] = [];
  public smsVariables = smsVariables;
  public smsVariableDescriptions = smsVariables.reduce(
    (o, variablObjt) => ({
      ...o,
      [variablObjt.variable]: variablObjt.description
    }),
    {}
  );

  constructor(
    private API: ApiService,
    private _snackBar: MatSnackBar,
    private confirmationService: ConfirmationService
  ) {
    this.emailTargets = [];
    this.BotAction.type = 0;
  }

  @Input() public accountID: number;
  @Input() public isStandalone: boolean;
  @ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement>;
  announcer = inject(LiveAnnouncer);
  public page: number;
  public quantity: number;
  public isLoading: boolean;
  public searchTearm: string;
  public showAddAction = false;
  public BotActionList = [] as BotActionDto[];
  public isEditing = false;

  ngOnInit(): void {
    this.InitializeList();
  }

  private async InitializeList() {
    this.page = 1;
    this.quantity = 15;
    await this.LoadList();
  }

  ngOnChanges(): void {
    this.InitializeList();
  }

  public handlePage(event: PageEvent) {}

  public openIntent = (record: CustomIntent) => {
    this.customIntentFormModel = record;
    this.slidePanelOpen = true;
  };

  public async LoadList() {
    this.isLoading = true;
    return new Promise<void>((resolve, reject) => {
      this.API.GetCustomIntentsList(
        this.accountID,
        this.page,
        this.quantity
      ).subscribe(
        (result) => {
          let items: Intent[] = result.list.items.map((r) => {
            return { ...r, isSystemIntent: false };
          });

          items = [
            {
              isSystemIntent: true,
              customIntentId: 1,
              accountID: 0,
              prompt: '',
              response: '',
              customIntentName: 'Solicitud de cita',
              primaryColor: '',
              secondaryColor: ''
            },
            {
              isSystemIntent: true,
              customIntentId: 2,
              accountID: 0,
              prompt: '',
              response: '',
              customIntentName: 'Servicios',
              primaryColor: '',
              secondaryColor: ''
            },
            {
              isSystemIntent: true,
              customIntentId: 3,
              accountID: 0,
              prompt: '',
              response: '',
              customIntentName: 'Direccion',
              primaryColor: '',
              secondaryColor: ''
            },
            {
              isSystemIntent: true,
              customIntentId: 4,
              accountID: 0,
              prompt: '',
              response: '',
              customIntentName: 'Horarios',
              primaryColor: '',
              secondaryColor: ''
            },
            {
              isSystemIntent: true,
              customIntentId: 6,
              accountID: 0,
              prompt: '',
              response: '',
              customIntentName: 'Dejar mensaje de voz',
              primaryColor: '',
              secondaryColor: ''
            },
            ...items
          ];

          if (result.configs?.length) {
            let sortedItems: Intent[] = items.map((item) => {
              const sortConfig = result.configs.find((c) => {
                if (item.isSystemIntent) {
                  return c.intentType === item.customIntentId;
                } else {
                  return c.customIntentId === item.customIntentId;
                }
              });
              if (sortConfig) {
                item._sortOrder = sortConfig.sortOrder;
              } else if (item.isSystemIntent) {
                item._sortOrder = 0;
              } else {
                item._sortOrder = items.length + 1;
              }
              return item;
            });

            items = orderBy(sortedItems, (i) => i._sortOrder);
          }

          if (!result.featuresStatus.allowAppointmentRequests) {
            items = items.filter(
              (i) =>
                !(
                  i.customIntentId === IntentType.APPOINTMENT_REQUEST &&
                  i.isSystemIntent
                )
            );
          }

          if (!result.featuresStatus.enableRecordings) {
            items = items.filter(
              (i) =>
                !(
                  i.customIntentId === IntentType.RECORD_VOICE_MAIL &&
                  i.isSystemIntent
                )
            );
          }

          this.list = {
            ...result.list,
            items: items
          };
          this.isLoading = false;
          this.GetIntakeFormList()
            .then(async (f) => {
              await this.GetIntentActions();
              resolve();
            })
            .catch((err) => reject(err));
        },
        (err) => reject(err)
      );
    });
  }

  public closeFormSidePanel() {
    this.customIntentFormModel = null;
    this.slidePanelOpen = false;
    this.isSubmitting = false;
    this.slidePanelOpen = false;
    this.InitializeList();
    this.BotAction = {} as BotActionDto;
    this.showAddAction = false;
    this.BotActionList = [] as BotActionDto[];
    this.isEditing = false;
  }

  public initializeAddNewForm() {
    this.BotAction = {} as BotActionDto;
    this.showAddAction = false;
    this.customIntentFormModel = {
      accountID: this.accountID,
      customIntentId: 0,
      prompt: '',
      response: '',
      customIntentName: '',
      endsTheSession: false,
      primaryColor: '',
      secondaryColor: ''
    };
    this.slidePanelOpen = true;
  }

  public Save() {
    if (this.BotAction?.type === 2) {
      this.BotAction.target = this.emailTargets?.toString();
    }
    if (this.customIntentFormModel?.isSystemIntent) {
      this.UpdateSaveBotAction();
    } else {
      this.SaveFormChanges();
    }
  }

  public async SaveFormChanges() {
    this.isSubmitting = true;
    try {
      if (this.customIntentFormModel.customIntentId) {
        await this.updateCustomIntent();
      } else {
        await this.createCustomIntent();
      }
      this.LoadList();
      this.resetBotAction();
    } catch (error) {
      console.error('Error saving form changes:', error);
      this._snackBar.open(
        'Ocurrió un error al guardar los cambios. Por favor, intenta de nuevo.',
        'OK',
        {
          duration: 1000 * 5,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'error-dialog'
        }
      );
    } finally {
      this.isSubmitting = false;
    }
  }

  private async updateCustomIntent() {
    await this.API.UpdateCustomIntent(this.customIntentFormModel).toPromise();
    if (!this.customIntentFormModel.endsTheSession && this.showAddAction) {
      if (this.BotAction?.customIntentId) {
        await this.UpdateSaveBotAction(
          this.customIntentFormModel.customIntentId
        );
      } else {
        await this.SaveBotActions(this.customIntentFormModel.customIntentId);
      }
    }
    this._snackBar.open(
      `¡Listo!, la habilidad personalizada "${this.customIntentFormModel.customIntentName}" ha sido actualizada.`,
      'OK',
      {
        duration: 1000 * 5,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'success-dialog'
      }
    );
  }

  private async createCustomIntent() {
    await this.API.CreateCustomIntent(this.customIntentFormModel).subscribe(
      async (d) => {
        let customIntentId = d;
        this.customIntentFormModel.customIntentId = Number(customIntentId);
        this._snackBar.open(
          '¡Listo!, la habilidad personalizada ha sido añadida.',
          'OK',
          {
            duration: 1000 * 5,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog'
          }
        );
        this.ResetSitePanel();
      }
    );
  }

  public DeleteCustomIntent({
    customIntentId,
    customIntentName
  }: CustomIntent) {
    const confirm = window.confirm(
      '¿Estás segur@ de querer eliminar esta habilidad?'
    );
    if (!confirm) return;
    this.API.DeleteCustomIntent(customIntentId).subscribe(() => {
      this._snackBar.open(
        `¡Listo!, la habilidad personalizada "${customIntentName}" ha sido eliminada. `,
        'OK ',
        {
          duration: 1000 * 5,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog'
        }
      );
      this.LoadList();
      this.closeFormSidePanel();
    });
  }

  public SaveIntentsConfiguration() {
    const items = this.list.items.map((item, idx) => {
      const config: IntentConfiguration = {
        intentConfigurationId: 0,
        customIntentId: item.isSystemIntent ? null : item.customIntentId,
        accountID: this.accountID,
        intentType: item.isSystemIntent ? item.customIntentId : null,
        sortOrder: idx
      };

      return config;
    });

    this.API.UpsertIntentsConfiguration(this.accountID, items).subscribe(
      (s) => {
        this.LoadList();
        this._snackBar.open(
          `¡Listo!, el orden de las habilidades ha sido actualizado. `,
          'OK ',
          {
            duration: 1000 * 5,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog'
          }
        );
      }
    );
  }

  public UpdateSaveBotAction(customIntentId?: number) {
    if (!this.BotAction?.botActionId) {
      this.SaveBotActions(customIntentId);
    } else {
      this.UpdateBotActions();
    }
  }

  async SaveBotActions(customIntentId?: number) {
    this.BotAction.customIntentId = customIntentId;
    this.BotAction.sortOrder =
      this.BotActionList != null ? this.BotActionList?.length : 0;
    this.API.SaveBotActionfiguration(this.accountID, this.BotAction).subscribe(
      (r) => {
        this._snackBar.open(
          `¡Listo!, la habilidad se ha sido añadida correctamente. `,
          'OK ',
          {
            duration: 1000 * 5,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog'
          }
        );
        this.ResetSitePanel();
      }
    );
  }
  async UpdateBotActions(customIntentId?: number) {
    if (customIntentId) {
      this.BotAction.customIntentId = customIntentId;
    }
    this.API.UpdateBotActionfiguration(
      this.accountID,
      this.BotAction
    ).subscribe((r) => {
      this._snackBar.open(
        `¡Listo!, la habilidad se ha sido añadida correctamente. `,
        'OK ',
        {
          duration: 1000 * 5,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog'
        }
      );
      this.ResetSitePanel();
    });
  }

  async GetIntentActions(intentType?: number, customIntentId?: number) {
    return new Promise<void>((resolve, reject) => {
      this.API.GetIntentActions(
        this.accountID,
        intentType ?? 0,
        customIntentId ?? 0
      ).subscribe(
        async (r) => {
          this.botActions = r as BotActionDto[];
          await this.AssociateCustomIntentsWithBotActions();
          resolve();
        },
        (err) => reject(err)
      );
    });
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (value) {
      if (!emailPattern.test(value)) {
        alert('El correo electrónico no es válido.');
      } else if (some(this.emailTargets, (d) => d === value)) {
        alert('El correo electrónico ya existe en la lista.');
      } else {
        this.emailTargets.push(value);
      }
    }
    event.chipInput!.clear();
    this.emailCtrl.setValue(null);
  }

  remove(email: string): void {
    const index = this.emailTargets.indexOf(email);

    if (index >= 0) {
      this.emailTargets.splice(index, 1);

      this.announcer.announce(`Removed ${email}`);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.emails.push(event.option.viewValue);
    this.emailInput.nativeElement.value = '';
    this.emailCtrl.setValue(null);
  }
  public async GetIntakeFormList() {
    this.isLoading = true;
    return this.API.GetIntakeFormList(this.accountID).subscribe(
      (result) => {
        this.IntakeFormSelect = result;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public openEditSidePanel(item: CustomIntent, isSystemIntent: boolean) {
    this.BotAction = {} as BotActionDto;
    this.BotActionList = [] as BotActionDto[];
    this.slidePanelOpen = true;
    this.customIntentFormModel = item;
    if (item?.botAction && item.botAction.length > 0) {
      this.BotActionList = cloneDeep(item.botAction);

      if (this.BotAction.type === 2) {
        this.emailTargets = this.BotAction.target
          .split(',')
          .map((email) => email.trim());
      }
    }
    if (this.BotAction.type === 3 || this.BotAction.type === 2) {
      this.BotAction.intakeFormId = item.botAction[0]?.intakeForm?.intakeFormId;
    } else if (item.isSystemIntent) {
      this.BotAction.intentType = item.customIntentId;

      this.customIntentFormModel.endsTheSession = false;
    }
    this.BotAction.type = 0;
    this.showAddAction = false;
  }

  public async AssociateCustomIntentsWithBotActions() {
    this.list.items.map((customIntent) => {
      if (customIntent.isSystemIntent) {
        customIntent.botAction = this.botActions.filter(
          (botAction) => botAction.intentType === customIntent.customIntentId
        );
      } else {
        customIntent.botAction = this.botActions.filter(
          (botAction) =>
            botAction.customIntentId === customIntent.customIntentId
        );
      }
      return customIntent;
    });
  }

  GetActionDescription(type: number): string {
    const actionMap: { [key: number]: string } = {
      0: 'Sin Acción',
      1: 'Enviar SMS',
      2: 'Enviar Email',
      3: 'Completar formulario',
      4: 'Recopilar Arbol de menú'
    };

    return actionMap[type] || 'Tipo desconocido';
  }
  public onBotActionChange() {
    if (this.BotAction.type === 2) {
      this.emailTargets = [];
    }
  }
  public onDrop(): void {
    this.BotActionList.forEach((item, index) => {
      item.sortOrder = index;
    });
  }

  GetActionType(value: number): string {
    switch (value) {
      case ActionType.SinAccion:
        return 'Sin Acción';
      case ActionType.EnviarSMS:
        return 'Enviar SMS';
      case ActionType.EnviarEmail:
        return 'Enviar Email';
      case ActionType.CompletarFormulario:
        return 'Completar formulario';
      case ActionType.RecopilarArbolMenu:
        return 'Recopilar Arbol de menú';
      case ActionType.Decir:
        return 'Decir';
      case ActionType.EnviarSmsATercero:
        return 'Enviar SMS a tercero';
      default:
        return 'Valor no reconocido';
    }
  }

  public async ResetSitePanel() {
    let customIntentFormModel = cloneDeep(this.customIntentFormModel);

    await this.InitializeList();

    this.customIntentFormModel = find(this.list?.items, {
      customIntentId: customIntentFormModel.customIntentId
    });
    this.openEditSidePanel(
      this.customIntentFormModel,
      this.customIntentFormModel.isSystemIntent
    );
    this.isSubmitting = false;
    this.isEditing = false;
  }

  public EditBotAction(item: BotActionDto) {
    this.BotAction = item;
    if (this.BotAction.intakeForm) {
      this.BotAction.intakeFormId = this.BotAction.intakeForm.intakeFormId;
    }
    if (this.BotAction.type === 2) {
      this.emailTargets = this.BotAction.target
        .split(',')
        .map((email) => email.trim());
    }
    this.showAddAction = true;
    this.isEditing = true;
  }

  onToggleEndsTheSession(event: MatSlideToggleChange) {
    this.customIntentFormModel.endsTheSession = event.checked;

    if (
      this.customIntentFormModel.endsTheSession &&
      this.BotActionList.length > 0
    ) {
      this.handleEndsTheSessionToggle();
    }
  }

  private async handleEndsTheSessionToggle() {
    if (this.customIntentFormModel.customIntentId) {
      const confirm = window.confirm(
        'Si activa esta opción se eliminarán las acciones del Bot habilidad en este de esta acción personalizada. ¿Estás segur@ de querer eliminar las acciones del bot?'
      );
      if (!confirm) {
        this.resetEndsTheSession();
        return;
      }
      await this.DeleteBotsActionByCustomIntents();
    } else {
      this.resetBotAction();
    }
  }

  private resetEndsTheSession() {
    this.customIntentFormModel.endsTheSession = false;
    this.resetBotAction();
  }

  private resetBotAction() {
    this.BotAction = {} as BotActionDto;
    this.BotAction.type = 0;
  }

  public DeleteBotAction(event: Event, botActionId: number) {
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer eliminar esta acción del bot?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, eliminar',
      rejectLabel: 'Cancelar',
      accept: () =>
        this.API.DeleteBotAction(this.accountID, botActionId).subscribe((r) => {
          this._snackBar.open(
            `¡Listo!,  acción eliminada  correctamente. `,
            'OK ',
            {
              duration: 70000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog'
            }
          );
          this.ResetSitePanel();
        }),
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  private async DeleteBotsActionByCustomIntents() {
    try {
      await this.API.DeleteBotsActionByCustomIntents(
        this.accountID,
        this.customIntentFormModel.customIntentId
      ).toPromise();
      this.updateCustomIntent().then((d) => {
        this.closeFormSidePanel();
      });

      this.isSubmitting = false;
      this.isLoading = false;

      this._snackBar.open('¡Listo!, acción eliminada correctamente.', 'OK', {
        duration: 7000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'success-dialog'
      });
    } catch (error) {
      console.error('Error deleting bot action:', error);
      this._snackBar.open(
        'Ocurrió un error al eliminar la acción. Por favor, intenta de nuevo.',
        'OK',
        {
          duration: 7000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'error-dialog'
        }
      );
    }
  }
  public UpdateBotActionOrder(botActionDto: BotActionDto[]) {
    this.BotActionList.forEach((item, index) => {
      if (item.type === 3 || item.type === 2) {
        item.intakeFormId = item?.intakeForm?.intakeFormId;
      }
    });
    this.isSubmitting = true;
    this.isLoading = true;
    this.API.UpsertBotActions(this.accountID, this.BotActionList).subscribe(
      () => {
        this.LoadList();
        this.resetBotAction();
        this.isSubmitting = false;
        this.isLoading = false;

        this._snackBar.open(
          `¡Listo!, Orden Actualizado Correctamente correctamente. `,
          'OK ',
          {
            duration: 70000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog'
          }
        );
      }
    );
  }

  public onDropBotAction(): void {
    this.BotActionList.forEach((item, index) => {
      item.sortOrder = index;
    });
  }
}
