// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-notes .avatar {
  border-radius: 50px;
  margin-right: 1rem;
  width: 2.5rem;
  cursor: pointer;
}
.customer-notes .user-name {
  font-weight: 700;
}
.customer-notes .note-box {
  margin-top: 1.25rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImN1c3RvbWVyLW5vdGVzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0UsbUJBQUE7RUFDQSxrQkFBQTtFQUFBLGFBQUE7RUFBQSxlQUFBO0FBQUo7QUFHSTtFQUFBO0FBQUE7QUFHQTtFQUFBO0FBQUEiLCJmaWxlIjoiY3VzdG9tZXItbm90ZXMuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY3VzdG9tZXItbm90ZXMge1xuICAuYXZhdGFyIHtcbiAgICBib3JkZXItcmFkaXVzOiA1MHB4O1xuICAgIEBhcHBseSBtci00IGN1cnNvci1wb2ludGVyIHctMTA7XG4gIH1cbiAgLnVzZXItbmFtZSB7XG4gICAgQGFwcGx5IGZvbnQtYm9sZDtcbiAgfVxuICAubm90ZS1ib3gge1xuICAgIEBhcHBseSBtdC01O1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/shared/Components/customer-notes/customer-notes.component.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;EACA,kBAAA;EAAA,aAAA;EAAA,eAAA;AAAJ;AAGI;EAAA,gBAAA;AAAA;AAGA;EAAA,mBAAA;AAAA;AAGJ,wlBAAwlB","sourcesContent":[".customer-notes {\n  .avatar {\n    border-radius: 50px;\n    @apply mr-4 cursor-pointer w-10;\n  }\n  .user-name {\n    @apply font-bold;\n  }\n  .note-box {\n    @apply mt-5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
