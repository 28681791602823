import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthenticationService } from '../../app/_services';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';
import { HttpErrorSnackbarComponent } from '../Components/http-error-snackbar/http-error-snackbar.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor, ErrorHandler {
  constructor(
    private authenticationService: AuthenticationService,
    private _snackBar: MatSnackBar,
    private zone: NgZone
  ) {}

  handleError(error: any): void {
    // Check if it's an error from an HTTP response
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection; // get the error object
    }
    this.zone.run(() =>
      this._snackBar.openFromComponent(HttpErrorSnackbarComponent, {
        duration: this.durationInSeconds * 1000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: ['bg-red-800 text-white']
      })
    );

    console.error('Error from global error handler', error);
  }

  durationInSeconds: number = 5;
  public openErrorMessage() {
    this._snackBar.openFromComponent(HttpErrorSnackbarComponent, {
      duration: this.durationInSeconds * 1000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      panelClass: ['bg-red-800', 'text-white']
    });
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          if (!window.localStorage['hasShownExpiredMessage']) {
            window.localStorage['hasShownExpiredMessage'] = true;
            window.location.href = '/auth/logout?returnUrl=/login';
          }
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
        } else {
          this.openErrorMessage();
        }

        const error = err.error || err.statusText;
        return throwError(error);
      })
    );
  }
}
