// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h4 {
  font-size: 80px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-right: 20px;
}

h5 {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 20px;
}

img {
  width: 35%;
  height: auto;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVuYXV0aG9yaXplZE1lc3NhZ2UuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0VBQ0EsaUJBQUE7RUFDQSxrQkFBQTtFQUNBLGtCQUFBO0FBQ0Y7O0FBQ0E7RUFDRSxnQkFBQTtFQUNBLGtCQUFBO0VBQ0EsZUFBQTtBQUVGOztBQUFBO0VBQ0UsVUFBQTtFQUNBLFlBQUE7QUFHRiIsImZpbGUiOiJ1bmF1dGhvcml6ZWRNZXNzYWdlLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaDQge1xuICBmb250LXNpemU6IDgwcHg7XG4gIGZvbnQtd2VpZ2h0OiBib2xkO1xuICBtYXJnaW4tYm90dG9tOiAwcHg7XG4gIG1hcmdpbi1yaWdodDogMjBweDtcbn1cbmg1IHtcbiAgbWFyZ2luLXRvcDogMTVweDtcbiAgbWFyZ2luLWJvdHRvbTogNXB4O1xuICBmb250LXNpemU6IDIwcHg7XG59XG5pbWcge1xuICB3aWR0aDogMzUlO1xuICBoZWlnaHQ6IGF1dG87XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/Pages/unauthorizedMessage/unauthorizedMessage.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AACA;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;AAEF;;AAAA;EACE,UAAA;EACA,YAAA;AAGF;AACA,osBAAosB","sourcesContent":["h4 {\n  font-size: 80px;\n  font-weight: bold;\n  margin-bottom: 0px;\n  margin-right: 20px;\n}\nh5 {\n  margin-top: 15px;\n  margin-bottom: 5px;\n  font-size: 20px;\n}\nimg {\n  width: 35%;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
