import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import cloneDeep from 'lodash-es/cloneDeep';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import { AdminViewAsClinicDTO } from 'src/app/DTOS/AdminViewAsClinicDTO';
import { AppointmentType } from '../../DTOS/Appointments-types/appointmentType';
import {
  TransitionalCareConfigMessage,
  TransitionalCareConfigMessageDTO,
  TransitionalCareConfiguration
} from '../../DTOS/TransitionalCareAdmisionsAndDischarges/TransitionalCareAdmisionsAndDischarges';
import { LoadingDialogComponent } from '../../loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-clinic-transitional-care-with-appointment-tab',
  templateUrl: './clinic-transitional-care-with-appointment-tab.component.html',
  styleUrls: ['./clinic-transitional-care-with-appointment-tab.component.scss']
})
export class ClinicTransitionalCareWithAppointmentTabComponent
  implements AfterViewInit
{
  @Input() public ViewAs: AdminViewAsClinicDTO;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onImportClicked = new EventEmitter();
  public User: User;
  slidePanelOpen: Boolean = false;
  isSubmitting: Boolean = false;
  isLoading: Boolean = false;
  sidepanelItems = {} as any;
  _transitionalCareConfigMessage: TransitionalCareConfigMessageDTO =
    {} as TransitionalCareConfigMessageDTO;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  @Input() public isStandalone: boolean;
  public pageSize = 10;
  public currentPage = 1;
  public totalSize = 0;
  transitionalCareConfiguration: TransitionalCareConfiguration;
  listItems: TransitionalCareConfigMessage[];
  isEditing = false;
  showForm = false;
  pageEvent: any;
  message = {} as any;
  backup_message = {} as any;
  public dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  searchTearm = '';
  minSelectedDate: Date = new Date();
  @ViewChild('AppointmentsDatePicker')
  AppointmentsDatePicker;
  @ViewChild('AppointmentsCallDatePicker')
  AppointmentsCallDatePicker;
  callDate: Date = new Date();
  callTime: string;
  displayedColumns: string[] = [
    'Message',
    'Recipient',
    'callTime',
    'createDate',
    'IsActive',
    'Actions'
  ];

  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar
  ) {
    moment.locale('es');
    this.callTime = moment(new Date()).format('HH:mm');
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadLists();
  }
  ngAfterViewInit(): void {
    this.User = this.authenticationService.currentUserValue;
    this.LoadLists();
  }
  public openAddNewSidePanel() {
    this.slidePanelOpen = true;
    this.sidepanelItems = {} as any;
  }
  public openEditSidePanel(item) {
    this.slidePanelOpen = true;
    this._transitionalCareConfigMessage = cloneDeep(item);
    this.callTime = item.callTime;
  }
  public closeAddNewSidePanel() {
    this.slidePanelOpen = false;
    this._transitionalCareConfigMessage = {} as any;
    this.LoadLists();
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.LoadLists();
  }

  public clearFilter() {
    this.searchTearm = '';
  }

  public discardEditionChanges = () => {
    this.isEditing = false;
    this.showForm = false;
    this.message = cloneDeep(this.backup_message);
    this.backup_message = {} as any;
    this.LoadLists();
  };

  public LoadLists() {
    this.isSubmitting = true;
    this.isLoading = true;
    const clinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    const appointmentTypeID = AppointmentType.TransitionalCareAltasconCita;
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    this.API.GetTransitionalCareMessage(clinicID, appointmentTypeID).subscribe(
      (result) => {
        const res = result as TransitionalCareConfiguration;
        if (res?.transitionalCareConfigMessage) {
          this.listItems = res?.transitionalCareConfigMessage || [];
          this.dataSource =
            new MatTableDataSource<TransitionalCareConfigMessage>(
              this.listItems
            );
          this.dataSource.paginator = this.paginator;
          this.transitionalCareConfiguration = res;
          this.isSubmitting = false;
          this.isLoading = false;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public Add() {
    this.isSubmitting = true;
    this._transitionalCareConfigMessage.clinicID = this.ViewAs
      ? this.ViewAs.clinicId
      : this.User.clinicId;
    this._transitionalCareConfigMessage.callTime = this.callTime;
    this._transitionalCareConfigMessage.appointmentTypeID =
      AppointmentType.TransitionalCareAltasconCita;

    this.API.CreateTransitionalCareMessage(
      this._transitionalCareConfigMessage
    ).subscribe(() => {
      this.LoadLists();
      this.isSubmitting = false;
      this.closeAddNewSidePanel();
      this._snackBar.open(`¡Listo!, la mensaje creado correctamente. `, 'OK ', {
        duration: 70000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'success-dialog'
      });
    });
  }

  public Update() {
    this.isSubmitting = true;
    this._transitionalCareConfigMessage.clinicID = this.ViewAs
      ? this.ViewAs.clinicId
      : this.User.clinicId;
    this._transitionalCareConfigMessage.callTime = this.callTime;
    this._transitionalCareConfigMessage.appointmentTypeID =
      AppointmentType.TransitionalCareAltasconCita;

    this.API.UpdateTransitionalCareMessage(
      this._transitionalCareConfigMessage
    ).subscribe(() => {
      this.LoadLists();
      this.isSubmitting = false;
      this.closeAddNewSidePanel();
      this._snackBar.open(
        `¡Listo!, Mensaje actualizado correctamente. `,
        'OK ',
        {
          duration: 70000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog'
        }
      );
    });
  }

  public Delete(event: Event, messageTemplate: TransitionalCareConfigMessage) {
    if (this.User?.isAdmin === false) {
      return;
    }
    const clinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer eliminar este registro?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, eliminar',
      rejectLabel: 'Cancelar',
      accept: () =>
        this.API.DeleteTransitionalCareMessageTemplaste(
          clinicID,
          messageTemplate.message_TemplateId
        ).subscribe((r) => {
          this.LoadLists();
        }),
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  public ActiveMessageTemplate(
    event: Event,
    messageTemplate: TransitionalCareConfigMessage
  ) {
    if (this.User?.isAdmin === false) {
      return;
    }
    const clinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer activar este registro?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, Activar',
      rejectLabel: 'Cancelar',
      accept: () =>
        this.API.ActiveTransitionalCareMessageTemplaste(
          clinicID,
          messageTemplate.message_TemplateId
        ).subscribe((r) => {
          this.LoadLists();
        }),
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  public formatDate(date: Date) {
    if (date) {
      moment.locale('es');
      return moment(date).format('DD MMMM [del] YYYY');
    }
  }
  public calltimeToDate(calltime: string) {
    return new Date('2021-01-01 ' + calltime);
  }
}
