// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dot {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
}

.mat-column-Doctor {
  width: 25% !important;
}

.mat-column-status {
  width: 25% !important;
}

.mat-column-template {
  padding-left: 15px;
}

.newRecord {
  background-color: #faebd7;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRvY3Rvci1hc3Npc3RhbmNlLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsWUFBQTtFQUNBLFdBQUE7RUFDQSxrQkFBQTtFQUNBLHFCQUFBO0FBQ0Y7O0FBQ0E7RUFDRSxxQkFBQTtBQUVGOztBQUFBO0VBQ0UscUJBQUE7QUFHRjs7QUFEQTtFQUNFLGtCQUFBO0FBSUY7O0FBRkE7RUFDRSx5QkFBQTtBQUtGIiwiZmlsZSI6ImRvY3Rvci1hc3Npc3RhbmNlLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmRvdCB7XG4gIGhlaWdodDogMTNweDtcbiAgd2lkdGg6IDEzcHg7XG4gIGJvcmRlci1yYWRpdXM6IDUwJTtcbiAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xufVxuLm1hdC1jb2x1bW4tRG9jdG9yIHtcbiAgd2lkdGg6IDI1JSAhaW1wb3J0YW50O1xufVxuLm1hdC1jb2x1bW4tc3RhdHVzIHtcbiAgd2lkdGg6IDI1JSAhaW1wb3J0YW50O1xufVxuLm1hdC1jb2x1bW4tdGVtcGxhdGUge1xuICBwYWRkaW5nLWxlZnQ6IDE1cHg7XG59XG4ubmV3UmVjb3JkIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2ZhZWJkNztcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/shared/Components/doctor-assistance/doctor-assistance.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;AACF;;AACA;EACE,qBAAA;AAEF;;AAAA;EACE,qBAAA;AAGF;;AADA;EACE,kBAAA;AAIF;;AAFA;EACE,yBAAA;AAKF;AACA,4zBAA4zB","sourcesContent":[".dot {\n  height: 13px;\n  width: 13px;\n  border-radius: 50%;\n  display: inline-block;\n}\n.mat-column-Doctor {\n  width: 25% !important;\n}\n.mat-column-status {\n  width: 25% !important;\n}\n.mat-column-template {\n  padding-left: 15px;\n}\n.newRecord {\n  background-color: #faebd7;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
