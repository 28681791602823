import flatMap from 'lodash/flatMap';

type SmsReminderVariable = {
  variable: string;
  maxLength: number;
  description: string;
};

type VariablesGrouping = {
  groupName: string;
  variables: SmsReminderVariable[];
};

const smsReminderVariablesGrouping: Readonly<VariablesGrouping[]> =
  Object.freeze([
    {
      groupName: 'Paciente de la Cita',
      variables: [
        {
          variable: '{$PatientName$}',
          maxLength: 0,
          description: 'El nombre del paciente'
        },
        {
          variable: '{$Campaign:PatientFirstName$}',
          maxLength: 0,
          description:
            "Used for campaigns. Selects the first word of the PatientName field. If changing the variable for the first name of the patient results in a SMS with more segments than replacing the variable with an empty string, then the variable is just replaced. Otherwise, it renders the patient's first name."
        }
      ]
    },
    {
      groupName: 'Fecha de la Cita',
      variables: [
        {
          variable: '{$Weekday$}',
          maxLength: 0,
          description: 'Día de la cita. Ej. Lunes, Martes... Domingo'
        },
        {
          variable: '{$Date$}',
          maxLength: 0,
          description:
            'Solo la fecha de la cita, en formato M/dd/yyyy. Ej: 9/26/2022'
        },
        {
          variable: '{$DateA$}',
          description:
            'Solo la fecha de la cita, en formato dd MMM yyyy. Ej: 22 Oct 2023',
          maxLength: 0
        }
      ]
    },
    {
      groupName: 'Hora de la cita',
      variables: [
        {
          variable: '{$Time$}',
          maxLength: 0,
          description: 'Hora de la cita. Ej 9:45 AM'
        },
        {
          variable: '{$HourOnly$}',
          maxLength: 0,
          description:
            'Hora sin minutos. Ejemplo: si la cita es a las 9:30 AM, esta variable mostraría: 9:00 AM'
        },
        {
          variable: '{$GroupHour$}',
          maxLength: 0,
          description:
            'Calcula la hora dependiendo del grupo de la cita y el rango de fecha en metadata. IMPORTANTE: Configuración adicional es necesaria para esta variable.'
        }
      ]
    },
    {
      groupName: 'Ubicación',
      variables: [
        {
          variable: '{$LocationName$}',
          maxLength: 0,
          description:
            'Nombre de la ubicación asociada a la cita. Si la ubicación cuenta con un ALIAS, este toma prioridad.'
        },
        {
          variable: '{$LocationPinnedUrl$}',
          maxLength: 0,
          description: 'Url de la ubicación asociada a la cita.'
        },
        {
          variable: '{$LocationVoiceAlias$}',
          maxLength: 0,
          description: 'Alias for voice channel'
        },
        {
          variable: '{$LocationSmsAlias$}',
          maxLength: 0,
          description: 'Alias for sms channel'
        }
      ]
    },
    {
      groupName: 'Clinica',
      variables: [
        {
          variable: '{$ClinicName$}',
          maxLength: 0,
          description: 'El nombre de la clinica'
        },
        {
          variable: '{$ClinicPhone$}',
          maxLength: 0,
          description: 'El télefono de la clinica'
        }
      ]
    },
    {
      groupName: 'Doctor',
      variables: [
        {
          variable: '{$DoctorName$}',
          maxLength: 0,
          description: 'Nombre del doctor'
        }
      ]
    }
  ]);

const smsReminderVariables: Readonly<SmsReminderVariable[]> = Object.freeze(
  flatMap(smsReminderVariablesGrouping, (c) => c.variables)
);

export { smsReminderVariables, smsReminderVariablesGrouping };
