import { AfterViewInit, Component, Inject } from '@angular/core';
/** UpdateAppointmentCallStatus component*/
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface reportsViewerData {
  link: string;
}
@Component({
  selector: 'app-reports-viewer',
  templateUrl: './reports-viewer.component.html',
  styleUrls: ['./reports-viewer.component.scss']
})
/** reports-viewer component*/
export class ReportsViewerComponent {
  baseUrl: string;
  public reportURL: string;
  /** reports-viewer ctor */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: reportsViewerData,
    public dialogRef: MatDialogRef<ReportsViewerComponent>,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.baseUrl = baseUrl;
    this.reportURL = this.data.link;
  }

  public onDismiss() {
    this.dialogRef.close(false);
  }
}
