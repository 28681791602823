import * as moment from 'moment';

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-date-selection-ribbon',
  templateUrl: './date-selection-ribbon.component.html',
  styleUrls: ['./date-selection-ribbon.component.scss']
})
export class DateSelectionRibbonComponent {
  constructor() {
    moment.locale('es');
  }
  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Input() public date: Date = new Date();

  @Input() public justify: string = 'start';

  public onNextDateClicked = () => {
    this.AddDays(1);
  };
  public onPrevDayClicked = () => {
    this.AddDays(-1);
  };

  private AddDays(days: number) {
    this.date = moment(this.date).add(days, 'days').toDate();
    this.fireEmit();
  }
  public fireEmit = () => this.dateChange.emit(this.date);
  public formatDate = () => {
    return moment(this.date).format('dddd DD [de] MMMM, YYYY');
  };

  public onTodayClicked = () => {
    const today = new Date();
    if (
      moment(today).format('YYYY-MM-DD') ===
      moment(this.date).format('YYYY-MM-DD')
    ) {
      return;
    }

    this.date = today;
    this.fireEmit();
  };
}
