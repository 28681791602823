import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';

/** UpdateAppointmentCallStatus component*/
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/api.service';
import { IAppointmentListItem } from '../../DTOS/Appointments/AppointmentListItem';
import { CallStatusGET_DTO } from '../../DTOS/CallStatus/DTOS';

export interface UpdateAppointmentCallStatusDialogData {
  appointment: IAppointmentListItem;
  callStatusID: string;
  callTypeId: number;
  clinicID: number;
  callStatuses: CallStatusGET_DTO[];
}
@Component({
  selector: 'app-update-appointment-call-status',
  templateUrl: './update-appointment-call-status.component.html',
  styleUrls: ['./update-appointment-call-status.component.scss']
})
export class UpdateAppointmentCallStatusDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UpdateAppointmentCallStatusDialogData,
    public dialogRef: MatDialogRef<UpdateAppointmentCallStatusDialogComponent>,
    @Inject('BASE_URL') baseUrl: string,
    http: HttpClient,
    private API: ApiService
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }

  httpClient: HttpClient;
  isLoading = false;
  baseUrl: string;
  public selectedCallStatus: CallStatusGET_DTO;
  public onDismiss() {
    this.dialogRef.close(false);
  }

  public SaveChanges() {
    this.isLoading = true;
    this.API.UpdateCallStatus(
      this.data.appointment.appointmentId,
      this.data.appointment.callStatusId,
      this.selectedCallStatus.callStatusId,
      this.data.callTypeId,
      this.data.clinicID
    ).subscribe(
      (result) => {
        this.isLoading = false;
        this.dialogRef.close(true);
      },
      (e) => {
        this.isLoading = false;
        console.error('ERROR', e);
      }
    );
  }
  catch(e) {
    this.isLoading = false;
    this.dialogRef.close(false);
    console.error('ERROR', e);
    alert('Ha ocurrido un error');
  }
}
