export class SystemMessagesConfig {
  title: string;
  message: string;
  type: string;
  showHeaderMessage: boolean;
  constructor(
    title: string,
    message: string,
    type: string,
    showHeaderMessage?: boolean
  ) {
    this.title = title;
    this.message = message;
    this.type = type;
    this.showHeaderMessage = showHeaderMessage;
  }
}

export interface SystemAnnouncementDetail {
  systemAnnouncementId: number;
  saveViewsLogs: boolean;
  title: string;
  message: string;
  expireDate: Date;
  deleteDate: Date;
  expireDateView: string;
  showHeaderMessage: boolean;
  clinicId?: number;
  companyId?: number;
  displayType: number;
  announcementType: string;
  clinic: ClinicDto;
  autor: AutorDto;
}

export interface ClinicDto {
  clinicId: number;
  clinicName: string;
  isActive: boolean;
}
export interface AutorDto {
  userId: number;
  username: string;
}
