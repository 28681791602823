import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TelephonyProvider } from 'src/app/DTOS/Clinic/clinic-details';
import { AddCompanyDTO } from 'src/app/DTOS/Company/AddCompanyDTO';
import { Company } from 'src/app/DTOS/Company/Company';
import {
  AssociatedProductsBillingAccountsDto,
  DesactivateBillingDto
} from 'src/app/DTOS/billing-accounts/billing-accountsDto';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from 'src/app/_dialogs/confirm-dialog/confirm-dialog.component';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import {
  LoadingDialogComponent,
  LoadingDialogModel
} from 'src/app/loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-billing-accounts',
  templateUrl: './billing-accounts.component.html',
  styleUrl: './billing-accounts.component.scss'
})
export class BillingAccountsComponent implements OnInit {
  isLoading = false;
  isSubmitted = false;
  @ViewChild('billingaccountForm', { static: false })
  public companyForm: NgForm;
  User: User;

  loadingRef: MatDialogRef<LoadingDialogComponent>;
  public company: AddCompanyDTO = {} as any;
  public desactivateBilling = {} as DesactivateBillingDto;
  public ProductsBillingAccounts: AssociatedProductsBillingAccountsDto =
    {} as any;
  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.User = this.authenticationService.currentUserValue;
    const companyId = this.route.snapshot.params.companyId;
    if (companyId != null && companyId > 0) {
      this.fetchCompany(companyId);
    } else {
      this.company = {} as AddCompanyDTO;
    }
  }

  public fetchCompany = (companyId: number) => {
    this.isLoading = true;
    this.API.GetCompanyById(companyId).subscribe((r) => {
      let result = r as Company;
      this.setData(result);
      this.fetchAssociatedProducts(companyId);
      this.isLoading = false;
    });
  };
  public setData(company: Company) {
    this.company = {
      companyId: company.companyId,
      city: company.city,
      companyName: company.companyName,
      address1: company.address1,
      postalCode: company.postalCode,
      isActive: company.isActive,
      address2: company.address2,
      associateId: company.associateId,
      emailAddress: company.emailAddress,
      ratePlanId: company.ratePlan.ratePlanId,
      phoneNumber: company.phoneNumber,
      state: company.state,
      mainContact: company.mainContact,
      contractEndDate: company.contractEndDate,
      companyTaxNumber: company.companyTaxNumber,
      companyLegalName: company.companyLegalName,
      verticalTypeId: company.verticalTypeId,
      contractStartDate: company.contractStartDate,
      _ratePlan: {} as any,
      deactivation: company.deactivation
    } as AddCompanyDTO;
  }

  public fetchAssociatedProducts = (companyId: number) => {
    this.isLoading = true;
    this.ShowLoading();
    this.API.FetchAssociatedProducts(companyId).subscribe((r) => {
      this.ProductsBillingAccounts = r as AssociatedProductsBillingAccountsDto;
      this.isLoading = false;
      this.hideLoading();
    });
  };

  public FormatCompanyId(number: number): string {
    let numberStr = number.toString();
    let zerosToAdd = 4 - numberStr.length;
    for (let i = 0; i < zerosToAdd; i++) {
      numberStr = '0' + numberStr;
    }
    return numberStr;
  }
  GetVirtualAssistant(clinicId: number): string | undefined {
    const clinic = this.ProductsBillingAccounts.clinics.find(
      (c) => c.clinicId === clinicId
    );
    return clinic ? `${clinic.clinicId} - ${clinic.clinicName}` : undefined;
  }

  getTelephonyProviderName(providerNumber: number): string | undefined {
    switch (providerNumber) {
      case TelephonyProvider.MESSAGE_BIRD:
        return 'Message Bird';
      case TelephonyProvider.TWILIO:
        return 'Twilio';
      case TelephonyProvider.BANDWIDTH:
        return 'Bandwidth';
      default:
        return undefined;
    }
  }

  formatPhoneNumber(phoneNumber: string): string {
    const cleanedNumber = phoneNumber.replace(/\D/g, '');
    const formattedNumber = `${cleanedNumber.slice(0, 3)}-${cleanedNumber.slice(
      3,
      6
    )}-${cleanedNumber.slice(6)}`;
    return formattedNumber;
  }
  public DeactivateBillingAccounts() {
    const message = `¿Estás seguro de que deseas cancelar esta Cuenta de Facturación? Esta acción es NO REVERSIBLE. Los números de teléfonos de los asistentes virtuales no podrán ser recuperados. Por favor, confirma tu decisión para continuar.`;
    const dialogData = new ConfirmDialogModel(
      'Confirmación Cancelación ',
      message,
      'Si, Cancelar',
      'Cancelar'
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ShowLoading();
        this.isSubmitted = true;
        this.desactivateBilling.companyId = this.company.companyId;
        this.API.DesactivateCompany(this.desactivateBilling).subscribe((r) => {
          this.isSubmitted = false;
          this.hideLoading();
          this.Success();
          this.router.navigate(['/company', this.desactivateBilling.companyId]);
          this.desactivateBilling = {} as DesactivateBillingDto;
        });
      }
    });
  }
  Success() {
    this.isSubmitted = false;
    this.hideLoading();
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel(
        'Listo',
        'Cuenta de Facturación cancelada correctamente..',
        'Okay',
        null
      )
    });
  }
  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
    this.isLoading = false;
  }
  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false)
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
    this.isLoading = true;
  }
  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null)
    });
  }
}
