import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import {
  IntakeFormDTO,
  IntakeFormDTOResultDTO
} from 'src/app/DTOS/VA_Configurations/IntakeForm.DTO';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-va-intake-forms',
  templateUrl: './va-intake-forms.component.html',
  styleUrl: './va-intake-forms.component.scss'
})
export class VaIntakeFormsComponent {
  public User: User;
  isLoading: Boolean = false;
  @Input() public accountID: number;
  @Input() public isStandalone: boolean;
  public pageSize = 10;
  public currentPage = 0;
  public totalSize = 0;
  public searchTerm = '';
  IntakeForm: IntakeFormDTOResultDTO;
  searchTearm = '';
  public dataSource: MatTableDataSource<IntakeFormDTO>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'intakeFormId',
    'intakeFormName',
    'createDate',
    'Actions'
  ];
  showForm: boolean;
  account: IntakeFormDTO;
  backup_account: IntakeFormDTO;

  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngAfterViewInit() {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
      this.LoadLists();
    });
  }

  ngOnChanges(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
      this.LoadLists();
    });
  }

  public LoadLists() {
    this.isLoading = true;
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    this.API.GetListGetIntakeForm(
      this.accountID,
      this.currentPage,
      this.pageSize,
      this.searchTerm
    ).subscribe(
      (result) => {
        this.isLoading = false;
        this.IntakeForm = result;
        for (let i = 0; i < this.IntakeForm.items.length; i++) {
          this.IntakeForm.items[i].createDateView = moment(
            this.IntakeForm.items[i].createDate
          ).format('DD MMMM [del] YYYY');
        }
        this.dataSource = new MatTableDataSource<IntakeFormDTO>(
          this.IntakeForm.items
        );
        this.dataSource.paginator = this.paginator;
        this.totalSize = this.IntakeForm.totalCount;
        this.pageSize = this.IntakeForm.pageSize;
        this.currentPage = this.IntakeForm.pageIndex;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public DeleteIntakeForm(event: Event, intakeFormId: number) {
    if (this.User?.isAdmin === false) {
      return;
    }
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer eliminar este registro?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, eliminar',
      rejectLabel: 'Cancelar',
      accept: () =>
        this.API.DeleteIntakeFormVirtualAssistant(
          this.accountID,
          intakeFormId
        ).subscribe((r) => {
          this._snackBar.open(
            `¡Listo!, la questionario eliminado correctamente. `,
            'OK ',
            {
              duration: 70000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog'
            }
          );
          this.LoadLists();
        }),
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  public handlePage(e: PageEvent) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.LoadLists();
  }

  GoDetailsIntakeForm(intakeFormId?: number) {
    if (intakeFormId) {
      this.router.navigate([
        `asistente-virtual/Configuracion/${this.accountID}/questionario`,
        intakeFormId
      ]);
    } else {
      this.router.navigate([
        `asistente-virtual/Configuracion/${this.accountID}/nuevo-questionario`
      ]);
    }
  }
}
