type screen = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

const getCurrentScreen = (): screen => {
  let returnval: screen = 'sm';
  if (window.innerWidth >= 768) {
    returnval = 'md';
  }
  if (window.innerWidth >= 1024) {
    returnval = 'lg';
  }
  if (window.innerWidth >= 1280) {
    returnval = 'xl';
  }
  if (window.innerWidth >= 1536) {
    returnval = '2xl';
  }
  if (window.innerWidth >= 1700) {
    returnval = '3xl';
  }
  return returnval;
};

export { getCurrentScreen };
