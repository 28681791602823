// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.in-use {
  background-color: #2b9091 !important;
  color: white !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlbWluZGVyc2V0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0Usb0NBQUE7RUFDQSx1QkFBQTtBQUNGIiwiZmlsZSI6InJlbWluZGVyc2V0LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmluLXVzZSB7XHJcbiAgYmFja2dyb3VuZC1jb2xvcjogIzJiOTA5MSAhaW1wb3J0YW50O1xyXG4gIGNvbG9yOiB3aGl0ZSAhaW1wb3J0YW50O1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/Pages/reminderset/reminderset.component.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,uBAAA;AACF;AACA,gZAAgZ","sourcesContent":[".in-use {\r\n  background-color: #2b9091 !important;\r\n  color: white !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
