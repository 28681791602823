import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
/** UpdateAppointmentCallStatus component*/
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import { Company } from 'src/app/DTOS/Company/Company';
import { Associate } from '../../DTOS/Associate/Associate';
import { AddCompanyDTO } from '../../DTOS/Company/AddCompanyDTO';
import { RatePlan } from '../../DTOS/RatePlans/RatePlan';
import { User } from './../../_models/user';
import { LONG_DATE_FORMAT } from 'src/app/Constants/constants';
export interface CompanyDialogData {
  Company: Company;
  title: String;
}

@Component({
  selector: 'app-company-dialog',
  templateUrl: './company-dialog.component.html',
  styleUrls: ['./company-dialog.component.scss']
})
/** add-appointments-dialog component*/
export class CompanyDialogComponent implements AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CompanyDialogData,
    public dialogRef: MatDialogRef<CompanyDialogComponent>,
    private _snackBar: MatSnackBar,
    @Inject('BASE_URL') baseUrl: string,
    http: HttpClient,
    private API: ApiService,
    private authenticationService: AuthenticationService
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;

    if (this.data.Company) {
      this.setData(this.data.Company);
    } else {
      this.setAsNewRecord();
    }
  }
  User: User;
  minSelectedDate: Date = new Date();
  public RatePlans: RatePlan[];
  public Associates: Associate[];
  public company: AddCompanyDTO = { _ratePlan: {} as any } as any;
  public isCustomPlan = false;
  public isActive = true;
  public _isActive_updt: boolean;

  httpClient: HttpClient;
  isLoading = false;
  baseUrl: string;
  @ViewChild('AppointmentsDatePicker')
  AppointmentsDatePicker;
  @ViewChild('AppointmentsCallDatePicker')
  AppointmentsCallDatePicker;
  ngAfterViewInit() {
    this.FetchRatePlans();
    this.FetchAssociates();
    this.User = this.authenticationService.currentUserValue;
  }

  public formatDate = (d: Date) =>
    moment.utc(d).local().format(LONG_DATE_FORMAT);

  public setData(company: Company) {
    this.company = {
      companyId: company.companyId,
      city: company.city,
      companyName: company.companyName,
      address1: company.address1,
      postalCode: company.postalCode,
      isActive: company.isActive,
      address2: company.address2,
      associateId: company.associateId,
      emailAddress: company.emailAddress,
      ratePlanId: company.ratePlan.ratePlanId,
      phoneNumber: company.phoneNumber,
      state: company.state,
      mainContact: company.mainContact,
      contractEndDate: company.contractEndDate,
      contractStartDate: company.contractStartDate,
      _ratePlan: {} as any,
      deactivation: company.deactivation
    } as AddCompanyDTO;
    this._isActive_updt = company.isActive;
    this.isActive = this.company?.isActive === true ? true : false;
  }

  public setAsNewRecord() {
    this.company = new AddCompanyDTO();
    this.company.contractStartDate = new Date();
    this.isActive = this.company?.isActive === true ? true : false;
  }

  public close() {
    this.dialogRef.close();
  }

  public onDismiss() {
    this.dialogRef.close(false);
  }

  public searchForPlan(rateplan: RatePlan) {
    const matchingPlan = this.RatePlans.find(
      (r) =>
        r.monthlyFee === rateplan.monthlyFee &&
        r.pricePerMinute === rateplan.pricePerMinute &&
        r.maximumMinutes === rateplan.maximumMinutes
    );

    if (matchingPlan) {
      this.company._ratePlan = structuredClone(matchingPlan);
      this.company.ratePlanId = this.company._ratePlan.ratePlanId;
      this.isCustomPlan = false;
    } else {
      this.isCustomPlan = true;
      this.company.ratePlanId = null;
      this.company._ratePlan = {
        ratePlanName: ('CUSTOM-' + this.company.companyName).substring(0, 49),
        maximumMinutes: rateplan.maximumMinutes,
        pricePerMinute: rateplan.pricePerMinute,
        monthlyFee: rateplan.monthlyFee,
        activeAccounts: 0,
        ratePlanId: null
      };
    }
  }
  public FetchRatePlans() {
    this.isLoading = true;
    const route = this.baseUrl + `RatePlan/GetAall`;

    this.httpClient.get<RatePlan[]>(route).subscribe(
      (result) => {
        const res = result as RatePlan[];
        this.RatePlans = res;
        this.isLoading = false;

        this.company._ratePlan = structuredClone(
          res.find((r) => r.ratePlanId === this.company.ratePlanId)
        );

        this.company._ratePlan =
          this.company._ratePlan ||
          ({
            ratePlanName: '',
            maximumMinutes: 0,
            monthlyFee: 0,
            pricePerMinute: 0
          } as any);
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  public FetchAssociates() {
    this.isLoading = true;
    const route = this.baseUrl + `Associate/getall`;

    this.httpClient.get<Associate[]>(route).subscribe(
      (result) => {
        const res = result as Associate[];
        this.Associates = res;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  public async Save() {
    const confirm = window.confirm(
      `¿Estás segur@ de querer ${
        this.company.companyId ? 'actualizar' : 'crear'
      } esta compañia?`
    );
    if (!confirm) {
      return;
    }

    const executeCrud = () => {
      if (this.company.companyId) {
        this.updateCompany(this.company);
      } else {
        this.createCompany(this.company);
      }
    };

    if (this.isCustomPlan) {
      this.API.CreateRatePlan({
        ...this.company._ratePlan,
        ratePlanId: 0
      }).subscribe((r: { ratePlanId: number }) => {
        this.company.ratePlanId = r.ratePlanId;
        executeCrud();
      });
    } else {
      executeCrud();
    }
  }

  private createCompany(company: AddCompanyDTO) {
    const route = this.baseUrl + `Company/CreateCompany`;
    const companyDTO = structuredClone(company);
    delete companyDTO._ratePlan;

    return this.httpClient.post(route, companyDTO).subscribe(
      () => {
        this.isLoading = false;
        this.dialogRef.close(true);
        this._snackBar.open('¡Listo!, se ha creado la compañia.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog'
        });
      },
      (f) => {
        this.isLoading = false;
        console.error('ERROR AL CREAR COMPAÑIA', f);
      }
    );
  }
  private updateCompany(_company: AddCompanyDTO) {
    const company = { ..._company };

    if (this._isActive_updt !== _company.isActive) {
      company.isActive = this._isActive_updt;
    }

    const route = this.baseUrl + `Company/UpdateCompany`;
    return this.httpClient.post(route, company).subscribe(
      () => {
        this.isLoading = false;
        this.dialogRef.close(true);
        this._snackBar.open('¡Listo!, se ha actualizado la compañia.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog'
        });
      },
      (f) => {
        this.isLoading = false;
        console.error('ERROR AL actualizar COMPAÑIA', f);
      }
    );
  }
}
