export default interface AppointmentTypeDto {
  appointmentTypeId: number;
  appointmentTypeName: string;
  appointmentTypeDescription: string;
  createDate: Date;
  deleteDate?: any;
  authorUserid: number;
  authorUser?: any;
  appointment: any[];
  appointmentTypesClinics: any[];
}

export enum AppointmentType {
  Cumpleaños = 1,
  Mamografía = 2,
  Examenfísicoanual = 3,
  TransitionalCareHospitalizados = 101,
  TransitionalCareAltasconCita = 102,
  TransitionalCareAltassinCita = 103
}
