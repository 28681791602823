import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  Conversation,
  ConversationSource,
  ConversationStatus,
  ConversationStatusCount,
  CreateConversationDto
} from 'src/app/DTOS/Conversations/Conversation';
import GenericList from 'src/app/DTOS/Generics/GenericList';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ConversationFormComponent,
  ConversationFormModel
} from 'src/app/conversation-form/conversation-form.component';
import moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounce, isFunction } from 'lodash';
import { Title } from '@angular/platform-browser';
import { Howl } from 'howler';
import { AdminViewAsClinicDTO } from 'src/app/DTOS/AdminViewAsClinicDTO';
import { ActivatedRoute, Router } from '@angular/router';
import { VaConversation } from 'src/app/DTOS/VaConversation/va-conversation';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';

enum ChatType {
  Chat = 1,
  AiBot = 2
}
type Page = Conversation & {
  nameFormatted: string;
};

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit, OnDestroy {
  constructor(
    private authenticationService: AuthenticationService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private API: ApiService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    public permissionSlugsService: PermissionSlugsService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');

    this._mobileQueryListener = () => changeDetectorRef.detectChanges();

    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  public isLoading: boolean;
  public selectedChat: Conversation;
  public pageList: GenericList<Page>;
  public User: User;
  public page: number = 1;
  public pageSize: number = 30;
  public currentUnreadCount: number = undefined;
  timeout: any;
  public searchTerm: string = '';
  public chatType: ChatType = 1;
  public hasAiAssistantPermission: boolean = false;

  mobileQuery: MediaQueryList;
  fillerContent = Array.from(
    { length: 50 },
    () =>
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
       labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
       laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
       voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
  );
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
  private _mobileQueryListener: () => void;

  public ViewAs: AdminViewAsClinicDTO;

  onViewAsClinicIdChange(event: AdminViewAsClinicDTO) {
    this.ViewAs = event;
    const newRoute = `/admin/clinic/${this.getClinicID()}/chat`;
    this.loadChats();
    this.router.navigate([newRoute]);
    this.loadConversationsByStatusCounts();
  }

  public isChatSelected(chat: Page) {
    if (!chat || !this.selectedChat?.conversationId) return false;
    return (
      Number(chat.conversationId) === Number(this.selectedChat.conversationId)
    );
  }

  public archivedConversations: ConversationStatusCount;
  public selectedStatusFilter: ConversationStatus;

  public loadConversationsByStatusCounts() {
    this.API.GetConversationsByStatusCounts(this.getClinicID()).subscribe(
      (response) => {
        this.archivedConversations = response.find(
          (c) => c.status === ConversationStatus.ARCHIVED
        ) ?? {
          status: ConversationStatus.ARCHIVED,
          count: 0
        };
      }
    );
  }

  public debouncedSearch = debounce(() => this.loadChats(), 1500, {});

  public openCreateConversationForm() {
    this.dialog
      .open(ConversationFormComponent)
      .afterClosed()
      .subscribe((result: ConversationFormModel | null) => {
        if (!result) {
          return;
        }
        const dto: CreateConversationDto = {
          name: result.name,
          number: result.number,
          source: ConversationSource.CHAT,
          clinicID: this.getClinicID()
        };

        this.isLoading = true;

        this.API.CreateConversation(dto).subscribe(
          (convResult: Conversation) => {
            this.API.SendConversationMessage({
              messageContent: result.textMessageContent,
              conversationId: convResult.conversationId
            }).subscribe(() => {
              this._snackBar.open(
                `¡Listo!, el mensaje ha sido enviado. `,
                'OK ',
                {
                  duration: 10000,
                  horizontalPosition: 'left',
                  verticalPosition: 'bottom',
                  panelClass: 'success-dialog'
                }
              );
              this.loadChats(() => {
                this.selectedChat = this.pageList.items.find(
                  (conv) => conv.conversationId === convResult.conversationId
                );
              });
            });
          }
        );
      });
  }

  ngOnInit(): void {
    this.permissionSlugsService.permissionsListener.subscribe((permissions) => {
      this.hasAiAssistantPermission = permissions.some(
        (p) => p === PermissionSlugs.AI_CHAT_BOT
      );
    });
    this.route.params.subscribe((c) => {
      const clinicId = Number(c?.clinicId);
      const focusedChatType = Number(c.focusedChatType);

      if (focusedChatType) {
        this.chatType = focusedChatType;
      }

      if (clinicId) {
        this.ViewAs = {
          clinicId: clinicId
        } as any;
      }
      this.authenticationService.currentUser.subscribe((user) => {
        this.User = user;
        this.loadChats();
        this.loadConversationsByStatusCounts();
      });
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          this.loadChats(undefined, true);
        }
      });
    });
  }

  public getClinicID = () => {
    const clinicIdFromRoute = Number(this.route.snapshot.params['clinicId']);

    if (clinicIdFromRoute) {
      return clinicIdFromRoute;
    }

    let clinicid = this.User.clinicId;

    if (this.ViewAs) clinicid = this.ViewAs.clinicId;

    return clinicid;
  };

  private triggerNotification() {
    const notification = new Notification('Tienes nuevos mensajes de texto ', {
      icon: '/images/logo_footer.png',
      body: 'Haz recibido nuevos mensajes de texto.'
    });
    notification.onclick = function () {
      window.open('https://app.citamed.net/chat');
    };
  }

  public notifyNewMessages() {
    const howl = new Howl({
      src: '/audio/sms-notification-sound.mp3'
    });
    howl.play();

    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    } else {
      this.triggerNotification();
    }
  }

  public loadMore() {
    this.pageSize = this.pageSize + 10;
    this.loadChats();
  }

  public ChangeConversationStatus(conversation: Conversation) {
    const nextStatus =
      conversation.status === ConversationStatus.ACTIVE
        ? ConversationStatus.ARCHIVED
        : ConversationStatus.ACTIVE;

    this.API.UpdateConversationStatus(
      nextStatus,
      conversation.conversationId
    ).subscribe(() => {
      conversation.status = nextStatus;
      if (this.selectedStatusFilter === ConversationStatus.ARCHIVED) {
        this.toggleArchived();
      }
      if (nextStatus === ConversationStatus.ARCHIVED) {
        this.pageList.items = this.pageList.items.filter(
          (item) => item.conversationId !== conversation.conversationId
        );
      }
      this.loadConversationsByStatusCounts();
    });
  }

  public isShowingArchivedChats = false;

  public toggleArchived() {
    this.isShowingArchivedChats = !this.isShowingArchivedChats;
    this.selectedStatusFilter = this.isShowingArchivedChats
      ? ConversationStatus.ARCHIVED
      : ConversationStatus.ACTIVE;
    this.loadChats(undefined, false, true);
  }

  public loadChats(
    callback?: () => void,
    hideLoader: boolean = false,
    skipNotifications: boolean = false
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.loadChats(undefined, true);
    }, 30000);

    this.isLoading = hideLoader ? false : true;
    const clinicId = this.getClinicID();

    this.API.GetConversations(
      this.page,
      this.pageSize,
      clinicId,
      this.searchTerm,
      this.selectedStatusFilter
    ).subscribe((result) => {
      const nowFormatted = moment().format('DD-MM-YYYY');
      this.isLoading = false;
      const items: Page[] = result.items.map((chat) => {
        const chatData: Page = {
          ...chat,
          nameFormatted: chat.name
        };

        if (chatData.name) {
          try {
            chatData.name = chatData.name
              .trim()
              .split('  ')
              .join(' ')
              .split('(')[0]
              .replace(/[0-9]/g, '');
          } catch (error) {
            chatData.name = chat.name;
          }
        }

        chat.number = chat.number.trim();
        if (chat.number?.startsWith('1')) {
          chatData.number = chat.number.slice(1);
        }
        if (chat.lastMessage?.createDate) {
          const dMoment = moment.utc(chat.lastMessage.createDate).local();
          const dateFormatted = dMoment.format('DD-MM-YYYY');
          if (dateFormatted === moment().add(-1, 'days').format('DD-MM-YYYY')) {
            chatData.lastMessageDateFormatted = 'Ayer';
          } else if (dateFormatted === nowFormatted) {
            chatData.lastMessageDateFormatted = dMoment.format('hh:mm a');
          } else {
            chatData.lastMessageDateFormatted = dateFormatted;
          }
        }
        return chatData;
      });
      this.pageList = { ...result, items: items };

      const totalUnread = items
        .map((s) => s.unreadSmsResponsesCount || 0)
        .reduce((a, b) => a + b);

      if (
        this.currentUnreadCount != undefined &&
        totalUnread > this.currentUnreadCount &&
        skipNotifications === false
      ) {
        this.notifyNewMessages();
      }

      this.currentUnreadCount = totalUnread;

      if (totalUnread) {
        const newTitle = `(${totalUnread}) Citamed - chats`;
        this.titleService.setTitle(newTitle);
      } else {
        this.titleService.setTitle('Citamed - chats');
      }

      if (callback && isFunction(callback)) {
        callback();
      }
    });
  }

  public selectedVaConversation: VaConversation;
  public onAiChatBotClicked = (conversation: VaConversation) => {
    this.selectedVaConversation = conversation;
  };
}
