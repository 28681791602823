export interface ReminderSetDto {
  reminderSetId: number;
  reminderSetName: string;
  audios: ReminderSetAudio[];
  calltypeId?: number;
  reminderScheduleId?: number;
  reminderScheduleCount?: number;
  reminderSetType?: ReminderSetType;
}
export interface ReminderSetAudio {
  reminderType: string;
  messageOrPath: string;
}

export interface RemsetRemsetDoctor {
  doctorName: string;
  doctorId: number;
}

export interface RemSet {
  reminderSetName: string;
  reminderSetId: number;
}

export interface ReminderSetReminderSet {
  createDate: Date;
  doctor: RemsetRemsetDoctor;
  finalReminder: RemSet;
  initialReminderSet: RemSet;
  reminderSetReminderSetId: number;
}
export enum ReminderSetType {
  SMS = 6,
  VoiceCall = 1
}
