import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { NoteInfo } from 'src/app/DTOS/CustomerNotes/AssociatedToPhone';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-phone-number-appointments-history',
  templateUrl: './phone-number-appointments-history.component.html',
  styleUrls: ['./phone-number-appointments-history.component.scss']
})
export class PhoneNumberAppointmentsHistoryComponent implements OnInit {
  phoneNumberId: string;
  notes: NoteInfo[];
  clinicId: number;

  constructor(
    private route: ActivatedRoute,
    private API: ApiService
  ) {
    moment.locale('es');
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.phoneNumberId = params.phoneNumberId;
      this.clinicId = params.clinicId;
      this.GetNotesAssociatedByPhones(this.phoneNumberId);
    });
  }

  getHistoryObservable(phoneNumberId: string) {
    return this.API.GetPhoneAppointmentsHistory(this.clinicId, phoneNumberId);
  }

  public formatDate = (d: Date) => {
    return moment(d).format('ddd DD MMMM, YYYY hh:mm a');
  };

  async GetNotesAssociatedByPhones(phoneNumberId: string) {
    const notes = await firstValueFrom(
      this.API.GetNotesAssociatedByPhones(this.clinicId, phoneNumberId)
    );
    this.notes = orderBy(
      [
        ...notes.appointmentsConfirmationsNotes,
        ...notes.virtualAssistantCallsNotes
      ].map((el) => ({
        ...el,
        createDate: moment(el.createDate).local().toDate()
      })),
      (e) => e.createDate,
      'desc'
    );
  }
}
