import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

export type ConversationFormModel = {
  name: string;
  number: string;
  textMessageContent: string;
};
@Component({
  selector: 'app-conversation-form',
  templateUrl: './conversation-form.component.html',
  styleUrls: ['./conversation-form.component.scss']
})
export class ConversationFormComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ConversationFormComponent>
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      number: ['', Validators.required],
      textMessageContent: ['', [Validators.required, Validators.maxLength(160)]]
    });
  }

  public cancel() {
    this.dialogRef.close(null);
  }
  // This method can be used to check if the form is valid and proceed with saving data
  onSave() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
      // Perform the save operation here
    } else {
      // Form is invalid, handle validation errors
    }
  }
}
