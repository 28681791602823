export enum IntentType {
  APPOINTMENT_REQUEST = 1,
  SERVICES_INFO = 2,
  PHYSICAL_ADDRES = 3,
  SCHEDULES = 4,
  RECORD_VOICE_MAIL = 6,
  TERMINATE = 5
}
export interface IntentConfiguration {
  intentConfigurationId: number;
  customIntentId: number | null;
  accountID: number;
  intentType: IntentType | null;
  sortOrder: number;
}
